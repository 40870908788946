import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTotalEmissionsData } from "../../config/services/ApiServices";
import { getGlobalSummaryView } from "../../config/services/ApiServices";

 
export const fetchEmissionChart = createAsyncThunk('emission-chart/fetch', async ({ startDate, endDate, customerId, timeZone, siteIds ,query,orderBy}: { startDate: Date, endDate: Date, customerId: string, timeZone?: any, siteIds?:any, query?:any,orderBy:number }) => {
    const response = await getTotalEmissionsData({ startDate, endDate, customerId, timeZone, siteIds,query,orderBy });
    return response;
})

export const fetchEmissionTable = createAsyncThunk('emission-table/fetch', async ({ siteIds, endDate, customerId, timeZone,}: {endDate: Date, customerId: string, timeZone?: any, siteIds?:any })  => {
    const response = await getGlobalSummaryView({endDate, customerId, timeZone,  siteIds });
    return response;
})
