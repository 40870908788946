import React, { useEffect, useRef } from 'react';
import "./modal.css";
import { enterpriseRedirectUrl } from '../Analytics/reports/troubleshooting-stats/assets/const';
import { useSelector } from "react-redux";
import { hasPermission } from '../../utils/permissions';
import { getActiveRole } from '../../utils/role';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void; 
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
 
  const modalRef = useRef<HTMLDivElement>(null);
  const userPrefstate = useSelector((state: any) => state.userPreferenceState);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current  && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);


  if (!isOpen) return null;

  const handleDownload = () => {
    window.open(process.env.REACT_APP_ENTERPRISE_URL + enterpriseRedirectUrl[0]['download-data'], '_blank');
    onClose();
  };

  const handleSoofieUptime = () => {
    window.open(process.env.REACT_APP_ENTERPRISE_URL + enterpriseRedirectUrl[0]['soofie-uptime'], '_blank');
    onClose();
  }

  const handleChannelsSupport = () => { 
    window.open(process.env.REACT_APP_ENTERPRISE_URL + enterpriseRedirectUrl[0]['channel-support'], '_blank');
    onClose();
  }

  const handleUserSummary = () => {
    window.open(process.env.REACT_APP_ENTERPRISE_URL + enterpriseRedirectUrl[0]['user-summary'], '_blank');
    onClose();
  }

  const handleAssetSummary = () => {
    window.open(process.env.REACT_APP_ENTERPRISE_URL + enterpriseRedirectUrl[0]['asset-summary'], '_blank');
    onClose();
  }

  const handleAlarmSummary = () => {  
    window.open(process.env.REACT_APP_ENTERPRISE_URL + enterpriseRedirectUrl[0]['alarm-summary'], '_blank');
    onClose();
  }

  return (
    <div className="fixed inset-0 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className={`relative top-20 left-16 border shadow-lg rounded-md bg-[var(--Light-and-Dark-Grey-3,#2F333E)] arrow-left modal-card`} ref={modalRef}>
        <div className="flex flex-wrap ">
        {hasPermission(getActiveRole(userPrefstate.data), 'download-data') && <button onClick={handleDownload} className=" flex-1 px-4 py-2 bg-[var(--Light-and-Dark-Outline,#373D49)] text-white rounded-md mx-1  modal-button">Download Data</button>}
        {hasPermission(getActiveRole(userPrefstate.data), 'channels-support') &&  <button onClick={handleChannelsSupport} className="flex-1 px-4 py-2 bg-[var(--Light-and-Dark-Outline,#373D49)] text-white rounded-md mx-1  modal-button">Channels Support</button>}        
        {hasPermission(getActiveRole(userPrefstate.data), 'user-summary') &&  <button onClick={handleUserSummary} className="flex-1 px-4 py-2 bg-[var(--Light-and-Dark-Outline,#373D49)] text-white rounded-md mx-1  modal-button">User Summary</button>}
        {hasPermission(getActiveRole(userPrefstate.data), 'asset-summary') &&  <button onClick={handleAssetSummary} className="flex-1 px-4 py-2 bg-[var(--Light-and-Dark-Outline,#373D49)] text-white rounded-md mx-1  modal-button">Asset Summary</button>}
        {hasPermission(getActiveRole(userPrefstate.data), 'alarm-summary') &&  <button onClick={handleAlarmSummary} className="flex-1 px-4 py-2 bg-[var(--Light-and-Dark-Outline,#373D49)] text-white rounded-md mx-1  modal-button">Alarm Summary</button>}
        </div>
          
      </div>
    </div>
  );
};

export default Modal;