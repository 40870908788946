import { Slice, createSlice } from "@reduxjs/toolkit";
import { fetchRefreshToken } from "../thunk/LoginThunk";

const initialData: any = [];

const RefreshTokenChartSlice: Slice<any, {}, "RefreshTokenChartSlice"> = createSlice({
    name: "RefreshTokenChartSlice",
    initialState: { data: initialData, isLoading: false, error: "" },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchRefreshToken.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchRefreshToken.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        });
        builder.addCase(fetchRefreshToken.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message ?? ""
        });
    }
})

export default RefreshTokenChartSlice
