import { Slice, createSlice } from "@reduxjs/toolkit";
import { fetchSoofieUptimeExport } from "../thunk/SitesOverviewThunk";

const initialData: any = [];

const SoofieUptimeExportChartSlice: Slice<any, {}, "SoofieUptimeExportChartSlice"> = createSlice({
    name: "SoofieUptimeExportChartSlice",
    initialState: { data: initialData, isLoading: false, error: "" },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchSoofieUptimeExport.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchSoofieUptimeExport.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        });
        builder.addCase(fetchSoofieUptimeExport.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message ?? ""
        });
    }
})

export default SoofieUptimeExportChartSlice
