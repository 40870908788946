import { Slice, createSlice } from "@reduxjs/toolkit";
import { fetchSiteHistoryUptimeReport } from "../thunk/SitesOverviewThunk";

const initialData: any = [];

const SiteHistoryUptimeReportChartSlice: Slice<any, {}, "SiteHistoryUptimeReportChartSlice"> = createSlice({
    name: "SiteHistoryUptimeReportChartSlice",
    initialState: { data: initialData, isLoading: false, error: "" },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchSiteHistoryUptimeReport.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchSiteHistoryUptimeReport.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        });
        builder.addCase(fetchSiteHistoryUptimeReport.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message ?? ""
        });
    }
})

export default SiteHistoryUptimeReportChartSlice
