import {
  DataTable as PrimeDatatable,
  DataTableFilterMeta,
} from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import EyeEnabled from "../../assets/img/eye_enabled.svg";
import { CSVLink } from "react-csv";
import CustomerServices from "../../config/services/customer-services/customer.service";
import moment from 'moment';
import EditIcon from './../Analytics/assets/edit.png';
import DeleteIcon from './../Analytics/assets/trash.png';
import notifyIcon from './assets/imgs/msg_icon.jpg';
import sample_customer_logo from './assets/imgs/sample_customer_logo.jpg';
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTimzeZoneUI, isValidUrl } from "../../utils/CommonUtils";


const AllCustomers = (props: any) => {

  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  let timezoneForUI = getTimzeZoneUI(userPrefstate.data.timeZone);
  const timeRange = props.timeRange;
  const SiteOptionSelected = props.SiteOptionSelected;
  const [csvData, setCsvData] = useState<any>([])
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [SortField, setSortField] = useState("order");
  const [SortFieldSeq, setSortFieldSeq] = useState<any>(-1);
  const [position, setPosition] = useState('center');
  const [readOnlySiteName, SetreadOnlySiteName] = useState('');
  const [readNoteCat, SetreadNoteCat] = useState('');
  const [readNoteTag, SetreadNoteTag] = useState('');
  const [readDetails, SetreadDetails] = useState('');
  const [readLoggedBy, SetreadLoggedBy] = useState('');
  const [readLoggedDate, SetreadLoggedDate] = useState('');
  const [readLastUpdated, SetreadLastUpdated] = useState('');
  const [filteredValues, SetfilteredValues] = useState<any>([]);
  const [originalValues, SetOriginalValues] = useState<any>([]);
  const [tempLoading, SettempLoading] = useState<any>(true);
  const [searchTerm, setSearchTerm] = useState<any>("");

  useEffect(() => {
    if ((searchTerm && originalValues?.length > 0)) {
      let serachedTerm = originalValues.filter(
        (item: any) => {
          return item.customerName
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        }
      );

      SetfilteredValues(serachedTerm)

      setCsvData([[`Customer Name`, "Contact Name", "Customer Contact Email", "Sales Contact Name", `Last Modified On`, "Last Modified By"],
      ...serachedTerm.map((item: any, index: number) => {

        return [item.customerName, item.contactName, item.contact_email, item.salesFinanceContactName, item.lastModifiedOn, item.lastModifiedBy];
      }),
      ])

    } else {
      SetfilteredValues(originalValues);
      setCsvData([[`Customer Name`, "Contact Name", "Customer Contact Email", "Sales Contact Name", `Last Modified On`, "Last Modified By"],
      ...originalValues.map((item: any, index: number) => {

        return [item.customerName, item.contactName, item.contact_email, item.salesFinanceContactName, item.lastModifiedOn, item.lastModifiedBy];
      }),
      ])
    }

  }, [searchTerm, SiteOptionSelected])

  useEffect(() => {
    SettempLoading(true)

    CustomerServices.GetCustomers(timeRange).then((res: any) => {

      SetOriginalValues(res)
      SetfilteredValues(res)

      setCsvData([[`Customer Name`, "Contact Name", "Customer Contact Email", "Sales Contact Name", `Last Modified On`, "Last Modified By"],
      ...res.map((item: any, index: number) => {

        return [item.customerName, item.contactName, item.contact_email, item.salesFinanceContactName, item.lastModifiedOn, item.lastModifiedBy];
      }),
      ])
      setTimeout(() => {
        SettempLoading(false)
      }, 2000)
    }).catch(() => {
      setTimeout(() => {
        SettempLoading(false)
      }, 2000)
    })

  }, [timeRange])

  const dialogFuncMap: any = {
    'displayBasic': setDisplayBasic,
    'displayEdit': setDisplayEdit,
    'deleteConfirm': setDeleteConfirm
  }



  const onClick = (name: any, position: any, data: any) => {



    SetreadOnlySiteName(data.SiteName)
    SetreadNoteCat(data.NoteCat);
    SetreadNoteTag(data.Tags);
    SetreadDetails(data.Details);
    SetreadLoggedBy(data.LoggedBy);
    SetreadLoggedDate(data.LoggedDate);
    SetreadLastUpdated(data.LastUpdated);
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  }

  const onHide = (name: any) => {
    dialogFuncMap[`${name}`](false);
  }





  const renderFooter = (name: any) => {
    return (
      <div onClick={() => onHide(name)} style={{ textDecoration: "underline", cursor: "pointer" }}>
        Close
      </div>
    );
  }

  const renderFooterDelete = (name: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>


        <div>
          <Button onClick={() => { onHide(name) }} label="Cancel" style={{ backgroundColor: "white", border: "1px solid black", color: "black", height: "30px", marginLeft: "10px" }} />
        </div>
        <div>
          <Button onClick={() => { handleEditSubmit(name) }} label="Delete" style={{ backgroundColor: "#f5b334", border: "0px solid #f5b334", color: "black", height: "30px", marginLeft: "10px" }} />
        </div>
      </div>
    );
  }
  const handleEditSubmit = (name: any) => {

    let post_data = {
      readOnlySiteName: readOnlySiteName,
      readNoteCat: readNoteCat,
      readNoteTag: readNoteTag,
      readDetails: readDetails,
      readLoggedBy: readLoggedBy,
      readLoggedDate: moment(new Date()).format('D MMM-YYYY')
    }
    CustomerServices.PutCustomers(post_data).then((res: any) => {

      onHide(name)

    })


  }
  const renderFooterEdit = (name: any) => {
    return (
      <div style={{ display: "flex", float: "right" }}>
        <div onClick={() => onHide(name)} style={{ textDecoration: "underline", cursor: "pointer", marginTop: "3px" }}>
          Close
        </div>
        <div>
          <Button onClick={() => { handleEditSubmit(name) }} label="Submit" style={{ backgroundColor: "#f5b334", color: "black", border: "0px solid #f5b334", height: "30px", marginLeft: "10px" }} />
        </div>
      </div>

    );
  }

  const renderLoading = (
    <div>
      <div className="flex justify-center items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
      </div>
      <div className="flex flex-wrap align-items-center justify-content-center text-sm text-textColor">Loading Data...</div>
    </div>
  );

  return <>
    <div className="tett">
      {/* Start Delete */}
      <Dialog header={<>
        <div style={{ display: "flex" }}>
          <img src={DeleteIcon}></img>
          {/* <i className="pi pi-delete-left" style={{fontSize:"20px"}}></i> */}
          <div style={{ fontSize: "16px", color: "#25476a", marginLeft: "8px", fontWeight: "500" }}>Delete Note</div>

        </div>
      </>} visible={deleteConfirm} style={{ width: '20vw' }} footer={renderFooterDelete('deleteConfirm')} onHide={() => onHide('deleteConfirm')}>Please confirm that you want to delete this note.Once deleted,the note would be irretrievable</Dialog>
      {/* deleteConfirm */}
      {/* Delete End */}
      {/* Edit Start */}

      <Dialog header={<>
        <div style={{ display: "flex" }}>
          <img src={EditIcon}></img>
          {/* <i className="pi pi-file-edit" style={{fontSize:"20px"}}></i> */}
          <div style={{ fontSize: "16px", color: "#25476a", marginLeft: "8px", fontWeight: "500" }}>Edit Note</div>

        </div>
      </>} visible={displayEdit} style={{ width: '40vw' }} footer={renderFooterEdit('displayEdit')} onHide={() => onHide('displayEdit')}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Site Name
          </div>

          <div style={{ flex: "90%" }}>
            <InputText value={readOnlySiteName} disabled style={{ height: "70%", width: "100%" }} />
          </div>
        </div>


        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Note Category
          </div>

          <div style={{ flex: "90%" }}>
            <InputText value={readNoteCat} disabled style={{ height: "70%", width: "100%" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Note Tags
          </div>

          <div style={{ flex: "90%" }}>
            <InputText value={readNoteTag} disabled style={{ height: "70%", width: "100%" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Details
          </div>

          <div style={{ flex: "90%" }}>

            <InputTextarea value={readDetails} onChange={(e) => {
              SetreadDetails(e.target.value)
            }} rows={4} style={{ width: "100%" }} />

          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Logged By
          </div>

        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "70%" }}>
            <InputText value={readLoggedBy} disabled style={{ height: "70%", width: "50%" }} />
          </div>

        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "50%" }}>Logged Date/Time</div>
          <div style={{ flex: "50%" }}>Last Updated</div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "50%" }}>
            <InputText value={readLoggedDate} disabled style={{ height: "70%", width: "95%" }} />
          </div>
          <div style={{ flex: "50%" }}>
            <InputText value={readLastUpdated} disabled style={{ height: "70%", width: "95%" }} />
          </div>
        </div>

      </Dialog>

      {/* Edit End */}


      {/* Read only Start */}

      <Dialog header={<>
        <div style={{ display: "flex" }}>
          <img
            // src={EyeDisabled}
            src={EyeEnabled}
            alt=""
          />
          <div style={{ fontSize: "16px", color: "#25476a", marginLeft: "8px", fontWeight: "500" }}>View Note</div>

        </div>
      </>} visible={displayBasic} style={{ width: '40vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Site Name
          </div>

          <div style={{ flex: "90%" }}>
            <InputText value={readOnlySiteName} disabled style={{ height: "70%", width: "100%" }} />
          </div>
        </div>


        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Note Category
          </div>

          <div style={{ flex: "90%" }}>
            <InputText value={readNoteCat} disabled style={{ height: "70%", width: "100%" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Note Tags
          </div>

          <div style={{ flex: "90%" }}>
            <InputText value={readNoteTag} disabled style={{ height: "70%", width: "100%" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Details
          </div>

          <div style={{ flex: "90%" }}>

            <InputTextarea value={readDetails} disabled rows={4} style={{ width: "100%" }} />

          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "30%" }}>
            Logged By
          </div>

        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "70%" }}>
            <InputText value={readLoggedBy} disabled style={{ height: "70%", width: "50%" }} />
          </div>

        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "50%" }}>Logged Date/Time</div>
          <div style={{ flex: "50%" }}>Last Updated</div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "50%" }}>
            <InputText value={readLoggedDate} disabled style={{ height: "70%", width: "95%" }} />
          </div>
          <div style={{ flex: "50%" }}>
            <InputText value={readLastUpdated} disabled style={{ height: "70%", width: "95%" }} />
          </div>
        </div>


      </Dialog>
      {/* Read only End */}
      {
        tempLoading == true ? <></> :

          <div style={{ display: "flex" }}>
            <div className="p-2">
              <div className="p-0.5 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center">
                <div className="text-white p-1 hover:cursor-pointer" >
                  <svg width="15" height="19" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                      fill="#838588"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  className="w-60 text-sm text-textColor bg-transparent focus:outline-none"
                  placeholder="Search Customers"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                // onKeyDown={handleKeyPress}
                // onKeyUp={handleKeyUp}
                // onFocus={handleFocus}
                />
              </div>

            </div>
            <div style={{ flex: "10%" }}>
              <button className="ml-auto flex items-center space-x-1" style={{ marginBottom: "10px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#D4D4D4"
                  className="bi bi-download"
                  viewBox="0 0 16 16"
                  style={{ marginTop: "0.4em", fontSize: "0.8em" }}
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg> <CSVLink className="ml-auto flex items-center space-x-1" data={csvData} filename={"customers.csv"}>
                  <span className="text-sm text-textColor" style={{ marginTop: "0.5em", fontSize: "0.8em !important" }}> &nbsp;Download Spreadsheet</span>
                </CSVLink></button>

            </div>
            <div style={{ border: "1px solid #b3b3b0", height: "2em", marginLeft: "0.5em" }}>
            </div>
            <div>
              <NavLink to={`/add-customer`}>
                <button className="bg-bgYellow ml-3 mr-5 px-3 py-1 mt-[0.1em] text-bgColor600 font-semibold">
                  <span>+</span> New Customer</button>
              </NavLink>
            </div>
          </div>
      }

      {tempLoading == false ? (
        <>
          <PrimeDatatable
            stripedRows
            sortField={SortField}
            sortOrder={SortFieldSeq}
            value={filteredValues}
            paginator
            className="notes_table_css"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            rows={10}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
          >

            <Column
              //   filterElement={testBody}
              headerStyle={{ fontSize: "14px" }}
              style={{ borderTopLeftRadius: "8px", fontSize: "13px" }}
              // sortable
              showFilterMatchModes={false}
              field="customer_logo"
              body={(body_val) => {
                return (
                  <>
                    {/* {body_val.customer_logo} */}
                    <NavLink to={`/CustomerDetails/${body_val.customerId}`}>
                      <img src={isValidUrl(body_val?.customerLogo) ? body_val?.customerLogo : sample_customer_logo} width={166} height={96} style={{ height: "6rem", cursor: "pointer" }} />
                    </NavLink>
                  </>
                );
              }}
              header={`Customer Logo`}
            ></Column>

            <Column
              //   filterElement={testBody}
              headerStyle={{ fontSize: "14px" }}
              style={{ borderTopLeftRadius: "8px", fontSize: "13px" }}
              // sortable
              showFilterMatchModes={false}
              field="customerName"
              body={(body_val) => {
                return (
                  <>
                    <NavLink to={`/CustomerDetails/${body_val.customerId}`}>
                      {body_val.customerName}
                    </NavLink>

                  </>
                );
              }}
              header={`Customer Name`}
            ></Column>


            {/* Details Start */}

            <Column
              //   filterElement={testBody}
              headerStyle={{ fontSize: "14px" }}
              style={{ borderTopLeftRadius: "8px", fontSize: "13px" }}
              // sortable
              showFilterMatchModes={false}
              field="contactName"
              body={(body_val) => {

                return (
                  <>
                    {body_val.contactName}
                  </>
                );

              }}
              header={`Contact Name`}
            ></Column>

            {/* Details End */}

            {/* Tags Start */}

            <Column
              //   filterElement={testBody}
              headerStyle={{ fontSize: "14px" }}
              style={{ borderTopLeftRadius: "8px", fontSize: "13px" }}
              // sortable
              showFilterMatchModes={false}
              field="contact_email"
              body={(body_val) => {
                return (
                  <>
                    {body_val?.customerContactEmail?.join(", ")}
                  </>
                );
              }}
              header={`Customer Contact Email`}
            ></Column>
            {/* Tags End */}

            <Column
              //   filterElement={testBody}
              headerStyle={{ fontSize: "14px" }}
              style={{ borderTopLeftRadius: "8px", fontSize: "13px" }}
              // sortable
              showFilterMatchModes={false}
              field="salesFinanceContactName"
              body={(body_val) => {
                return (
                  <>
                    {body_val.salesFinanceContactName}

                  </>
                );
              }}
              header={`Sales Contact Name`}
            ></Column>

            {/* LoggedBy Start */}

            <Column
              //   filterElement={testBody}
              headerStyle={{ fontSize: "14px" }}
              style={{ borderTopLeftRadius: "8px", fontSize: "13px" }}
              // sortable
              showFilterMatchModes={false}
              field="lastModifiedOn"
              body={(body_val) => {
                return (
                  <>
                    {body_val.lastModifiedOn + " " + timezoneForUI}
                  </>
                );
              }}
              header={`Last Modified On`}
            ></Column>

            {/* LoggedBy End */}

            {/* LoggedDate Start */}
            <Column
              //   filterElement={testBody}
              headerStyle={{ fontSize: "14px" }}
              style={{ borderTopLeftRadius: "8px", fontSize: "13px" }}
              // sortable
              showFilterMatchModes={false}
              field="lastModifiedBy"
              body={(body_val) => {
                return (
                  <>
                    {body_val.lastModifiedBy}
                  </>
                );
              }}
              header={`Last Modified By`}
            ></Column>
            {/* LoggedDate End */}

            {/* LastUpdated Start */}

            {/* LastUpdated End */}

            {/* Action Start */}
            <Column
              //   filterElement={testBody}
              headerStyle={{ fontSize: "14px" }}
              style={{ borderTopLeftRadius: "8px", fontSize: "13px" }}

              showFilterMatchModes={false}
              // field="LastUpdated"
              body={(body_val) => {
                return (
                  <>
                    <div style={{ display: "flex" }}>

                      <NavLink to={`/CustomerDetails/${body_val?.customerId}`}>
                        <img src={EyeEnabled}
                          // onClick={() => 
                          //   onClick('displayEdit', 'center', body_val)
                          //   }
                          title="View Details"  alt="View Details"
                          style={{ fontSize: '1.5rem', cursor: "pointer", marginRight: "6px" }}></img>


                      </NavLink>
                      <NavLink to={`/edit-customer/${body_val.customerId}`}>
                      {/* <img src={EditIcon}
                        // onClick={() => 
                        //   onClick('displayEdit', 'center', body_val)
                        //   }
                        style={{ fontSize: '1.5rem', cursor: "pointer", marginLeft: "6px" }}></img> */}
                        <i className="pi pi-file-edit text-textColor text-lg ml-[6px] cursor-pointer mt-1" 
                        title="Edit File" aria-label="Edit File"></i>
                      </NavLink>


                      {/* <img src={DeleteIcon} onClick={() => {
                    // onClick('deleteConfirm', 'center', body_val)

                  }} style={{ fontSize: '1.5rem', marginLeft: "12px", cursor: "pointer" }}></img> */}

                    </div >
                  </>
                );
              }}
              header={`Actions`}
            ></Column>
            {/* Action End */}
          </PrimeDatatable >
        </>
      ) : (
        renderLoading
      )}
    </div >

  </>
}


export default AllCustomers;