import { Slice, createSlice } from "@reduxjs/toolkit";
import { fetchProbableSourceChart } from "../thunk/SitesOverviewThunk";

const initialData: any = [];

const ProbableSourceChartSlice: Slice<any, {}, "ProbableSourceChartSlice"> = createSlice({
    name: "ProbableSourceChartSlice",
    initialState: { data: initialData, isLoading: false, error: "" },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchProbableSourceChart.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchProbableSourceChart.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        });
        builder.addCase(fetchProbableSourceChart.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message ?? ""
        });
    }
})

export default ProbableSourceChartSlice
