import React, { ReactNode, useEffect } from "react";
import "./styles.css";

interface EmissionOverlayProps {
  children: ReactNode;
  setShowOverlay: (show: boolean) => void;
  showOverlay: boolean;
}

const EmissionOverlay: React.FC<EmissionOverlayProps> = ({
  children,
  setShowOverlay,
  showOverlay
}) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        (event.target as HTMLElement).classList.contains("overlay") ||
        (event.target as HTMLElement).classList.contains("close-button")
      ) {
        setShowOverlay(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [setShowOverlay]);

  return (
  <div className="sleek-scrollbar overlay-content absolute top-0 right-0 w-1/3 h-full z-10 bg-bgColor600 shadow-lg p-1">
    <button
      className="absolute top-3 right-3 text-2xl text-activeTextColor close-button clear cursor-pointer"
      onClick={() => setShowOverlay(false)}
    >
      <i className="pi pi-times" />
    </button>
    <div className="mt-5">
      {children}
    </div>
  </div>
  );
};

export default EmissionOverlay;
