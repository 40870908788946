import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoteServices from "../../config/services/notes-services/notes-services";
import { getTimzeZoneForAPI } from "../../utils/CommonUtils";
import Select from 'react-select';
import TimecardDatepicker from "../timeslider/TimecardDatepicker";
import Notes from "../Analytics/Notes";
import CustomerDetailsTab from "./CustomerDetailsTab";
import { NavLink } from "react-router-dom";
import CustomerServices from "../../config/services/customer-services/customer.service";
import CustomerAssets from "./components/customer-assets";
import CustomerUsers from "./components/customer-assets/users/UsersList";
import { TabMenu } from "primereact/tabmenu";

const CustomerDetails = (props: any) => {
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [CustomerOption, SetCustomerOption] = useState<any>([]);
  const [HierOption, SetHierOption] = useState<any>([]);
  const [SiteOption, SetSiteOption] = useState<any>([]);
  const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>([]);
  const [HierOptionSelected, SetHierOptionSelected] = useState<any>([]);
  const [SiteOptionSelected, SetSiteOptionSelected] = useState<any>([]);
  const [CustomerDetailsByID, SetCustomerDetailsByID] = useState<any>([]);
  const [tempLoading, SettempLoading] = useState<any>(true);
  const items = [
    { label: "Customer Details" },
    { label: "Users" },
    { label: "Assets" },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const userPrefstate = useSelector((state: any) => state.userPreferenceState);

  useEffect(() => {
    SettempLoading(true)
    CustomerServices.GetCustomerByID(props.CustomerId).then((res: any) => {
      SetCustomerDetailsByID(res);
      setTimeout(() => {
        SettempLoading(false)
      }, 2000)
    }).catch(() => {
      setTimeout(() => {
        SettempLoading(false)
      }, 2000)
    })
  }, [])

  let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
  interface DateTimeType {
    StartDate: String;
    EndTime: String;
  }
  const initialTimeRange: DateTimeType = {
    StartDate: moment
      .tz(timezoneForAPI)
      .subtract(1, "days")
      .format("MM-DD-YYYY HH:mm"),
    EndTime: moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm"),
  };

  const [timeRange, setTimeRange] = useState<DateTimeType>(initialTimeRange);
  const updatedSelectedDate = useCallback<Function>(
    (dt: any) => {
      setTimeRange(dt);
    },
    [timeRange]
  );

  const options = [
    { value: 'notes', label: 'Notes' }
    // { value: 'reports', label: 'Reports', disabled: true }
  ]

  const handleOptionSelect = (e: any) => {
    SetSiteOption([]);
    SetHierOptionSelected([]);
    SetSiteOptionSelected([]);
    SetHierOption([]);
    SetCustomerOptionSelected(e);
    if (e?.children?.length > 0) {
      let temp_hier: any = []
      e?.children?.map((cust_val: any, index: any) => {
        temp_hier.push({
          value: index + 1
          , ...cust_val
        })
      });
      SetHierOption(temp_hier);
    }
  }

  const handleOptionSelectHier = (e: any) => {
    SetHierOptionSelected(e);
    if (e?.children?.length > 0) {
      let temp_hier: any = []
      e?.children?.map((cust_val: any) => {
        temp_hier.push({
          value: cust_val.customerId
          , ...cust_val
        })
      });
      SetSiteOption(temp_hier);
    }
  }

  const handleOptionSelectSite = (e: any) => {
    SetSiteOptionSelected(e);
  }

  const itemTemplate = (item: any, options: any) => {
    const isActive = options.index === activeIndex;
    const itemStyle = {
      color: isActive ? '#036475' : 'black',
      borderBottom: isActive ? '2px solid #fba86f' : 'none',
      padding: '10px 20px',
      cursor: 'pointer',
      display: 'inline-block',
    };

    return (
      <div
        key={options.index}
        onClick={() => setActiveIndex(options.index)}
        style={itemStyle}
        className={`p-menuitem ${isActive ? 'p-menuitem-active' : ''}`}
      >
        {item.label}
      </div>
    );
  };

  return (
    <div className="bg-bgColor600">
      <div className="bg-bgColor600 text-textColor">
        <header className="w-full body-font">
          <div className="p-3">
            <div className="bg-bgColor600">
              <div className="min-width-200px op-select-dd-container cursor-pointer">
                <div className="all_customers_breadcrum">
                  <span style={{ fontWeight: "400" }}>
                    <NavLink to="/customers">
                      <span
                        style={{
                          textDecoration: "underline",
                          textDecorationThickness: "from-font",
                        }}
                      >
                        Customers
                      </span>
                    </NavLink>
                    \
                  </span>
                  <span
                    className="all_customers_breadcrum"
                    style={{
                      textDecoration: "underline",
                      textDecorationThickness: "from-font",
                    }}
                  >
                    {CustomerDetailsByID?.contact_name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div
        className="body-tab-selection-layout rounded-tl-lg rounded-tr-md rounded-bl-sm rounded-br-sm bg-bgColor400"
        style={{ width: "100%" }}
      >
        <div className="mr-2">{""}</div>
      </div>
      <div className="bg-bgColor400">
        <TabMenu
          model={items.map((item, index) => ({
            ...item,
            template: (options: any) =>
              itemTemplate(item, { ...options, index }),
          }))}
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        />
        {activeIndex === 0 && (
          <div style={{ padding: "0 20px", marginTop: "20px" }}>
            <CustomerDetailsTab
              customerData={CustomerDetailsByID}
              tempLoading={tempLoading}
            />
          </div>
        )}
        {activeIndex === 1 && (
          <div style={{ padding: "0 20px" }}>
            <br />
            <CustomerUsers />
          </div>
        )}
        {activeIndex === 2 && (
          <div style={{ padding: "0 20px" }}>
            <br />
            <CustomerAssets CustomerDetailsByID={CustomerDetailsByID} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;
