import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-dropdown-tree-select/dist/styles.css";
import moment from "moment";
import "../assets/css/custom-op-style.css";
import { AppDispatch } from "../store";
import { fetchAssetHierarchy } from "../store/thunk/MapSliceThunk";
import statusIcon from "../assets/img/status_icon.png";
import chartIcon from "../assets/img/bar_icon.png";
import { NavLink, useNavigate, Route, Routes } from "react-router-dom";
//import DropdownTreeSelect from '../components/form/dropdown/DropdownTreeSelect';
// import OperartionOverviewGoogleMaps from "../components/Map/OperartionOverviewGoogleMaps";
import { SiteType } from "../components/Types/OperationsOverviewTypes";
import DatepickerCommon from "../components/DatepickerCommon";
import { AlertTab, OperationSite } from "../components/Types/OperationsSite";
import SearchTab from "../components/SearchTab";
import CustomSelect from "../components/form/CustomSelect";
import SiteEmissionAlert from "../components/AlertInfo/SiteEmissionAlert";
import AssetAlerts from "../components/AlertInfo/AssetAlerts";
import ToolTip from "../components/ToolTip";
import HealthMultiSelect from "../components/form/dropdown/HealthMultiSelect";
//import { fetchUserPreferences } from "../store/thunk/OperationsOverviewThunk";
import { getTimzeZoneForAPI } from "../utils/CommonUtils";
import { appInsights } from "../utils/appInsights";
import { getClientIP } from "../utils/CommonUtils";
// import DropdownTreeSelect from "react-dropdown-tree-select";
import championX_icon from "../assets/img/ChampionX_logo.png";
import { hasPermission } from "../utils/permissions";
import { getActiveRole } from "../utils/role";
import {
  defaultSelecetedKeys,
  statusOptions,
  alertTypeOptions,
  selectedKeysData,
  opOverviewoptions,
  infoTootltip,
} from "../utils/Constants";
import OperartionOverviewMapboxMap from "../components/Map/OperationsOverviewMapboxMap";
import InfoIcon from "../components/OperationsOverview/InfoIcon";

const OperationsOverview: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const siteDetails = useSelector((state: any) => state.mapSiteState);
  const emissionDetails = useSelector((state: any) => state.emissionAlertState);
  const assetDetails: any = useSelector((state: any) => state.assetAlertState);
  const assetHierarchyDD = useSelector(
    (state: any) => state.assetHierarchyState
  );
  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  const [emissionMarker, setEmissionMarker] = useState<any>("");
  const [customerIdValue, setCustomerIdValue] = useState<string>("");
  let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);

  const initialStartDate = moment
    .tz(timezoneForAPI)
    .subtract(1, "days")
    .toDate();
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  // const [startDate, setStartDate] = useState<Date>(new Date(moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm")));

  const initialEndDate = moment.tz(timezoneForAPI).toDate();
  const [endDate, setEndDate] = useState<Date>(initialEndDate);
  // const [endDate, setEndDate] = useState<Date>(new Date(moment.tz(timezoneForAPI).format('MM-DD-YYYY HH:mm')));
  const [alertTypeTab, setAlertTypeTab] = useState<AlertTab>("site");
  const initialSiteInfo: SiteType[] = [];
  const [siteInfo, setSiteInfo] = useState<SiteType[]>(initialSiteInfo);
  //const [statuses, setStatuses] = useState<{value: string, checked: boolean}[]>([])
  const [selectedKeys, setSelectedKeys] = useState<any>(defaultSelecetedKeys);
  const [selectAllStatuses, setSelectAllStatuses] = useState<boolean>(false);
  const [selectedKeysText, setSelectedKeysText] = useState<string>("");
  const [alertTypeResult, setAlertTypeResult] = useState<OperationSite[]>(
    emissionDetails.data
  );

  if (
    hasPermission(getActiveRole(userPrefstate.data), "emission-performance") &&
    !opOverviewoptions.some((option) => option.value === "Emission Performance")
  ) {
    opOverviewoptions.push({
      value: "Emission Performance",
      label: "Emission Performance",
      icon: chartIcon,
    });
  }

  const [selectedOption, setSelectedOption] = useState(opOverviewoptions[0]);
  const [selectedStatusOption, setSelectedStatusOption] = useState(
    statusOptions[0]
  );
  const [selectedAlertTypeOptions, setSelectedAlertTypeOptions] = useState(
    alertTypeOptions[0]
  );
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [searchResults, setSearchResults] = useState<OperationSite[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isClearSearchText, setIsClearSearchText] = useState<boolean>(false);
  const [activeMarker, setActiveMarker] = useState<string | null>(null);

  useEffect(() => {
    if (siteDetails.error !== "") {
      console.error("Error fetching getOpSiteDetails:", siteDetails.error);
      return;
    }
    setSiteInfo([...siteDetails.data]);
  }, [siteDetails, setSiteInfo]);
  useEffect(() => {
    dispatch(fetchAssetHierarchy());
  }, []);
  useEffect(() => {
    setAlertTypeResult(emissionDetails.data);
  }, [emissionDetails.data]);
  useEffect(() => {
    setStartDate(initialStartDate);
    // setStartDate(new Date(moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm")));
    setEndDate(initialEndDate);
    // setEndDate(new Date(moment.tz(timezoneForAPI).format('MM-DD-YYYY HH:mm')));
  }, [userPrefstate.data]);
  useEffect(() => {
    if (selectAllStatuses) {
      setSelectedKeys(selectedKeysData);
    } else {
      const { "Select All": selectAll, ...rest } = selectedKeys;
      setSelectedKeys(rest);
    }
  }, [selectAllStatuses]);
  useEffect(() => {
    if (selectedKeys) {
      if ("Select All" in selectedKeys) {
        setSelectedKeysText("Select All");
        if (Object.keys(selectedKeys).length < 10) {
          if (selectAllStatuses) {
            setSelectAllStatuses(false);
          } else {
            setSelectAllStatuses(true);
          }
        }
      } else {
        if (
          "Flagged Assets" in selectedKeys &&
          "No Wind" in selectedKeys &&
          "No Data" in selectedKeys &&
          "Bad Methane" in selectedKeys &&
          "Bad Temperature" in selectedKeys &&
          "Fan Failed" in selectedKeys &&
          "No Polygon configured" in selectedKeys &&
          "No Alert configured" in selectedKeys &&
          "Healthy Assets" in selectedKeys
        ) {
          setSelectAllStatuses(true);
        } else {
          setSelectAllStatuses(false);
        }
        const keys = Object.keys(selectedKeys).join(", ");
        setSelectedKeysText(keys);
      }
    }
  }, [selectedKeys, selectedKeysText]);
  const handleStatusDDSelectChange = (selectedOp: any) => {
    setSelectedStatusOption(selectedOp);
    if (selectedOp.value === "Emission Alerts") {
      // Redirect to /SiteEmissionAlert
      navigate("/operations-overview");
      setAlertTypeTab("site");
    } else if (selectedOp.value === "Asset Alerts") {
      // Redirect to /AssetAlert
      setAlertTypeTab("asset");
      navigate("/AssetAlert");
    }
  };
  function filterByAlertType(emissionData: any[], alertTypes: string[]) {
    const result = emissionData.reduce((acc: any, curr: any) => {
      const alerts = curr.alerts;
      const newCurr = Object.assign({}, curr);
      const filteredAlerts = alerts.filter((e: any) => {
        return alertTypes.includes(e.type);
      });
      newCurr.alerts = [...filteredAlerts];
      if (newCurr.alerts.length) {
        acc.push(newCurr);
      }
      return acc;
    }, []);
    return result;
  }
  const updatedAlerts = (alertType: string) => {
    let dataList = [];
    if (alertType === "Emission Concentration Alerts") {
      // Filter Emission Concentration Alerts
      dataList = filterByAlertType(emissionDetails.data, [
        "concentration-CH4",
        "LEL",
      ]);
    } else if (alertType === "Select All") {
      // Display All Alerts
      dataList = emissionDetails.data;
    } else {
      //Filter Emission Rate Alerts
      dataList = filterByAlertType(emissionDetails.data, [
        "Custom",
        "Standard",
      ]);
    }
    setAlertTypeResult(dataList);
  };
  const handleAlertTypeDDSelectChange = (selectedOpt: any) => {
    //setSearchText("");
    //setIsClearSearchText(true);
    setSelectedAlertTypeOptions(selectedOpt);
    updatedAlerts(selectedOpt.value);
    // call serach icon click here
  };

  const handleIsClearSearchText = () => {
    setIsClearSearchText(false);
    //    setSelectedAlertTypeOptions(alertTypeOptions[0]);
    //  updatedAlerts(alertTypeOptions[0].value);
  };
  const handleSearch = (searchResultsFromSearchTab: OperationSite[]) => {
    setSearchResults(searchResultsFromSearchTab);
  };
  const updateSearchText = (textValue: string) => {
    setSearchText(textValue);
  };
  const handleSelectChange = (newValue: any) => {
    setSelectedOption(newValue);
    if (newValue.value === "Emission Performance") {
      props.updateOverviewTypeTab("emission");
      navigate("/emission-overview");
    }
    if (newValue.value === "Operations Overview") {
      props.updateOverviewTypeTab("operation");
      navigate("/operations-overview");
    }
  };

  useEffect(() => {
    const fetchIPAndTrackEvent = async () => {
      try {
        const ip = await getClientIP();
        let currentUser: any = localStorage.getItem("user");
        currentUser = JSON.parse(currentUser);
        try {
          appInsights.trackEvent({
            name: "User Auth Details - Operation Overview Page",
            properties: {
              userEmail: currentUser?.userName,
              userName: currentUser?.lastName + " " + currentUser?.firstName,
              authenticateUserId: currentUser?._id,
              currentTime: new Date().toISOString(),
              clientIP: ip,
            },
          });
        } catch (error) {
          console.error("Error with Azure AppInsights:", error);
        }
      } catch (error) {
        console.error("Error fetching IP:", error);
      }
    };
    fetchIPAndTrackEvent();
  }, []);
  return (
    <div style={{ fontSize: "14px" }} className="bg-bgColor300">
      <div className="op-page-container">
        <div className="op-header-panel">
          <div className="header-layout">
            <div className="min-width-200px op-select-dd-container cursor-pointer">
              <CustomSelect
                options={opOverviewoptions}
                value={selectedOption}
                onChange={handleSelectChange}
              />
            </div>
            <div className="hd-right-elements">
              <div className="pl-3 group flex">
                <DatepickerCommon
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
                <div className="p-2 cursor-pointer">
                  <InfoIcon setShowInfoTooltip={setShowInfoTooltip} />
                  {showInfoTooltip && (
                    <>
                      <ToolTip title={infoTootltip} />
                    </>
                  )}
                </div>
              </div>
              <div>
                {/* <DropdownTreeSelect
                  data={assetHierarchyDD.data}
                  onChange={onChange}
                  onAction={onAction}
                  onNodeToggle={onNodeToggle}
                /> */}
                {alertTypeTab === "asset" && (
                  <HealthMultiSelect
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    selectedKeysText={selectedKeysText}
                  />
                )}
              </div>
              {alertTypeTab === "site" && (
                <div className="alert-type-dd" style={{ cursor: "pointer" }}>
                  <CustomSelect
                    options={alertTypeOptions}
                    value={selectedAlertTypeOptions}
                    onChange={handleAlertTypeDDSelectChange}
                    placeholderStr={"Status"}
                    placeholderIcon={statusIcon}
                  />
                </div>
              )}
              {/* <div className="status-dd pl-2" style={{ cursor: "pointer" }}>
                <CustomSelect
                  options={statusOptions}
                  value={selectedStatusOption}
                  onChange={handleStatusDDSelectChange}
                  placeholderStr={"Status"}
                  placeholderIcon={statusIcon}
                />
              </div>               */}
              {/* &nbsp;
              <img
                src={championX_icon}
                width="150px"
                alt="Path"
                style={{ marginBottom: "7px" }}
              ></img> */}
            </div>
          </div>
          <SearchTab
            siteDetails={siteDetails.data}
            emissionDetails={emissionDetails.data}
            onSearch={handleSearch}
            updateSearchText={updateSearchText}
            assetDetails={assetDetails.data}
            isClearSearchText={isClearSearchText}
            handleIsClearSearchText={handleIsClearSearchText}
            startDate={startDate}
            endDate={endDate}
            selectedAlertTypeOptions={selectedAlertTypeOptions}
            alertTypeTab={alertTypeTab}
          />
        </div>
        <div className="flex-grow w-full relative mt-2 operations-overview">
          <OperartionOverviewMapboxMap
            searchResults={searchResults}
            startDate={startDate}
            endDate={endDate}
            searchText={searchText}
            emissionMarker={emissionMarker}
            customerIdValue={customerIdValue}
            alertTypeResult={alertTypeResult}
            selectedKeys={selectedKeys}
            activeMarker={activeMarker}
            setActiveMarker={setActiveMarker}
            setAlertTypeTab={setAlertTypeTab}
            alertTypeTab={alertTypeTab}
            setEmissionMarker={setEmissionMarker}
            setCustomerIdValue={setCustomerIdValue}
            selectedAlertTypeOptions={selectedAlertTypeOptions}
          />
        </div>
      </div>
    </div>
  );
};
try {
  const pageViewTelemetry = {
    name: "overview",
    uri: window.location.href,
  };
  let currentUserDetail: any = localStorage.getItem("user");
  appInsights.trackPageView(pageViewTelemetry);
  appInsights.setAuthenticatedUserContext(currentUserDetail?.userName);
  // Log user spent time metric when the application is about to unload
  let startTime = Date.now();
  window.addEventListener("beforeunload", () => {
    const endTime = Date.now();
    const durationInSeconds = Math.floor((endTime - startTime) / 1000);
    appInsights.trackMetric({
      name: "overviewpage-UserSpentTime",
      average: durationInSeconds,
    });
  });
} catch (error) {
  console.error("Error with Azure AppInsights:", error);
}
export default OperationsOverview;
