import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOpSiteDetails, getEmissionAlerts, getAssetHierarchy, getAssetAlerts, getUserPreferences, getHeatMapData } from "../../config/services/ApiServices";
import customersJson from '../../assets/json/GetCustomers.json';

export const fetchSiteDetails = createAsyncThunk('site-details/fetch', async ({ startDate, endDate, timeZone }: { startDate: Date, endDate: Date, timeZone: string }) => {
    const response = await getOpSiteDetails({ startDate, endDate, timeZone });
    return response;
})

export const fetchEmissionAlerts = createAsyncThunk('emissionAlert-details/fetch', async ({ startDate, endDate, timeZone }: { startDate: Date, endDate: Date, timeZone: string }) => {
    const response = await getEmissionAlerts({ startDate, endDate, timeZone });
    return response;
})

export const fetchAssetAlerts = createAsyncThunk('assetAlerts-details/fetch', async ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
    const response = await getAssetAlerts({ startDate, endDate });
    return response;
})

export const fetchAssetHierarchy = createAsyncThunk('asset-hierarchy/fetch', async () => {
    const response = await getAssetHierarchy();
    return response
})

export const fetchHeatMap = createAsyncThunk('heat-map/fetch', async ({ siteId, siteName, customerId="", customerName, region, startTime, endTime, units, comparison, threshold }: { siteId: string, siteName: string, customerId: string, customerName:string, region:string, startTime:Date ,endTime:Date, units: string, comparison: string, threshold: string}) => {
    //console.log({ siteId, siteName, customerId, customerName, region, startTime, endTime, units, comparison, threshold })
    const response = await getHeatMapData({ siteId, siteName, customerId, customerName, region, startTime, endTime, units, comparison, threshold });
    return response
})
