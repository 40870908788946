import moment from 'moment';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calenderIcon from "../assets/img/calender-icon.svg";
import { CalendarPickerType } from './Types/OperationsSite';
import { getTimzeZoneForAPI, getTimzeZoneUI } from '../utils/CommonUtils';
import { useSelector } from 'react-redux';

function DateTimePicker({ startDate, endDate, setStartDate, setEndDate }: CalendarPickerType) {

  const maxDate = new Date();
  const [isReset, setIsReset] = useState(false);
  const datePickerRef = useRef<any>(null);
  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => {
    const sDate = moment(startDate).format("MM-DD-YYYY HH:mm");
    const eDate = moment(endDate).format("MM-DD-YYYY HH:mm");
    return (
      <button
        className="datepicker-custom-input text-textColor"
        onClick={onClick}
        ref={ref}
      >
        <div className="mr-1 mt-0.5">
          {" "}
          <svg
            width="15"
            height="20"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 8C6.75 8 7 8.25 7 8.5V11.5C7 11.7812 6.75 12 6.5 12H3.5C3.21875 12 3 11.7812 3 11.5V8.5C3 8.25 3.21875 8 3.5 8H6.5ZM4 0C4.40625 0 4.75 0.34375 4.75 0.75V2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0ZM12.5 6H1.5V14C1.5 14.2812 1.71875 14.5 2 14.5H12C12.25 14.5 12.5 14.2812 12.5 14V6Z"
              fill="#4A90A8"
            />
          </svg>
        </div>{" "}
        <div style={{ paddingTop: "1px" }}>
          {`${sDate}`} - {`${eDate}`}{" "}
          {`${getTimzeZoneUI(userPrefstate.data.timeZone)}`}{" "}
        </div>
      </button>
    );
  });

  const CustomActions = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className='flex ma'>
      <button className="dt-custom-btn" onClick={onClick} ref={ref}>Last 24 Hours</button>
    </div>
  ));

  const resetDate = () => {
    setIsReset(true);
  }

  useEffect(() => {
    setIsReset(false);
    setStartDate(new Date(moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm")));
    setEndDate(new Date());
  }, [isReset])

  const onChangeHandler = (date: any) => {
    if (!isReset) {
      const currentDateAt12AM = moment(date).startOf('day').toDate();
      const next24hrsAt12AM = moment(date).add(1, 'day').startOf('day').toDate();
      setStartDate(new Date(currentDateAt12AM));
      setEndDate(new Date(next24hrsAt12AM));
    }
  };

  const dayClassName = (date: Date) => {
    if (moment(date).isSame(startDate, 'day')) {
      return 'custom-active-date';
    }
    return '';
  };

  const handleCloseDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(false);
    }
  };

  let singleDateStyle = `
  .custom-active-date{
    background-color: #FFB217 !important;
    border-color: transparent !important;
    color: #001023!important;
    border-radius: 0.3rem;`

  let multiDateStyle = `
  .custom-active-date, 
  .custom-active-date + div {
    background-color: #FFB217 !important;
    border-color: transparent !important;
    color: #001023 !important;
    border-radius: 0.3rem;`

  let activeDateStyle = (moment(startDate).format('DD') !== moment(endDate).format('DD') && moment(endDate).minutes() !== 0) ? multiDateStyle : singleDateStyle;

  return (
    <div className="flex">
      <style> {activeDateStyle} </style>
      <DatePicker
        dateFormat="MM-dd-yyyy HH:mm"
        selected={startDate}
        onChange={onChangeHandler}
        monthsShown={1}
        customInput={<CustomInput />}
        todayButton={<CustomActions onClick={resetDate} />}
        shouldCloseOnSelect={true}
        ref={datePickerRef}
        maxDate={maxDate}
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
        }) => (
          <div>
            <button aria-label="Previous Month" className={"react-datepicker__navigation react-datepicker__navigation--previous"} onClick={decreaseMonth}>
              <span className={"react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"}>{"<"}</span>
            </button>
            <span className="react-datepicker__current-month">{monthDate.toLocaleString("en-US", { month: "long", year: "numeric" })} </span>
            <button aria-label="Next Month" className={"react-datepicker__navigation react-datepicker__navigation--next"} onClick={increaseMonth}>
              <span className={"react-datepicker__navigation-icon react-datepicker__navigation-icon--next"}>{">"}</span>
            </button>
          </div>
        )}
        dayClassName={dayClassName}
      />
    </div>
  );
}

export default DateTimePicker;
