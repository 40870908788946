import React from 'react'
import './AlertSensorPopup.css';
import 'primeicons/primeicons.css';
import { OperationSite } from './Types/OperationsSite';
import SensorDetailsPopupItem from './SensorDetailsPopupItem';

const SensorDetailsPopup = ({ site }: { site: OperationSite }) => {
  const isFlaggedAsset = ['No Data', 'Bad Methane', 'Bad Temperature', 'Fan Failed', 'No Polygon configured', 'No Alert configured', 'No Wind'];
  return (
    <>
      <div style={{ padding: 0, margin: 0 }}>
        <div className="block max-w-lg p-2 bg-bgColor600 text-textColor rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <div className='flex items-center mb-3'>
            <div className='mr-4 site-name-style'>
              {site.siteName}
            </div>
            <div className='text-sm'>
              {/*<div className="custom_active_alert bg-red-100 text-red-800 text-sm font-medium mr-2 px-1.5 pt-2 rounded dark:bg-red-900 dark:text-red-300">
                Asset Alert
  </div>*/}
            </div>

            <div className="flex items-center" style={{ flex: "1" }}>
              <label
                className={site?.alertDetails?.alertInfo.some((alert: any) =>
                  isFlaggedAsset.includes(alert.alertType)
                ) ? "assetalert-Statushighlight" : "assetalert-Greenhighlight"}
                style={{ marginLeft: "auto", color: "black" }}
              >
                {site?.alertDetails?.alertInfo.some((alert: any) =>
                  isFlaggedAsset.includes(alert.alertType)
                ) ? "Flagged Assets" : "Active Asset"}
              </label>
            </div>

          </div>

          {/* <div className='flex mb-2'>
            <div className='mr-1 alert-details-style mb-2'>
              Sensors
            </div>
          </div> */}
          <div className='device-panel mb-2'>
            <div className='flex flex-row items-center mb-4'>
              <div className='mr-1 font-normal'>
                Alerting Device(s)
              </div>
            </div>
            {site?.alertDetails?.assetAlertCount! > 0 ? site?.alertDetails?.alertInfo.map((alertInfo, i) => (<SensorDetailsPopupItem key={`alert_${i + 1}`} alertInfo={alertInfo} />)) :
              <div>
                <div className="flex flex-row  mb-3">
                  <div className="flex items-center ">
                    <div className="flex-grow pl-1 soofedata-first-item">
                      <div className="text-textColor text-xs">Asset Name</div>
                      <div className={`text-textColor100 text-sm alertdata-first-width`}>
                        {site.siteName}
                      </div>
                    </div>
                    <div className='soofedata-second-item ml-4'>
                      <div className="text-gray-600 text-xs mb-1">Alert Type</div>
                      <div className={`text-gray-800 text-sm p-1 pr-0 assetalert-highlight alertdata-first-width`} style={{ backgroundColor: "#FEEBB5" }}>
                        Online
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
          <div className="flex flex-row mt-4">
            
            <button type="button"
              className="font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 w-full btn-site-details border-borderColor bg-bgColor300 text-textColor"
              onClick={() => {
                const url = `/mapview?AssetId=${site.id}&CustomerId=${site.customerID}`
                window.location.href = url
              }}>View Site Emission</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SensorDetailsPopup