import { DataTable as PrimeDatatable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import ReportServices from "../../../../../config/services/report-services/troubleshooting-stats.service";
import { useSelector } from "react-redux";
import { getTimzeZoneForAPI, getDateStrDMY } from "../../../../../utils/CommonUtils";
import { initTroublStatsTableOverview } from '../assets/const';
import moment from "moment";

const TableOverview = (props: any) => {
    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    const [overviewData, setOverviewData] = useState<any>([]);
    const [SortField, setSortField] = useState("order");
    const [SortFieldSeq, setSortFieldSeq] = useState<any>(-1);
    const [tempLoading, SettempLoading] = useState<any>(true);
    const [chartNodata, setChartNodata] = useState(false);

    useEffect(() => {
        ReportServices.GetTableOverview({ startDate: props.timeRange.StartDate, endDate: props.timeRange.EndTime }).then((res: any) => {
            SettempLoading(false);
            setOverviewData(res);
        })
    }, [props.timeRange])

    const downloadOverviewCsv = async (fileName: string, date: string) => {
        try {
            const response: any = await ReportServices.GetTableOverviewRowDownload(fileName, date);
            const rows: any = response.split('\r\n');
            const headers: any = rows[0].split(',');
            let bodyData = rows.slice(1).map((row: any) => row.split(',').join(',')).join('\r\n');
            const csvData = headers.join(',') + '\r\n' + (!chartNodata ? bodyData : '');
            const formattedDate = moment(date, 'DD-MM-YYYY').format('DD-MMM-YYYY');
            const filename = `Troublesites_${formattedDate}.csv`;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const pseudoelement = document.createElement("a");
            pseudoelement.href = window.URL.createObjectURL(blob);
            pseudoelement.download = filename;
            document.body.appendChild(pseudoelement);
            pseudoelement.click();
            document.body.removeChild(pseudoelement);
        } catch (error) {
            console.error("Error Troubleshooting Stas Table Overview Sites CSV:", error);
        }
    };
    const renderLoading = (
        <div>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm">Loading Data...</div>
        </div>
    );

    const formatDate = (time: string) => {
        const d = new Date(time);
        return `${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}-${d.getFullYear()}`;
    };

    return (
        <div className="tett">
            {
                <div className="flex md:flex md:flex-grow flex-row justify-end space-x-1 top_btns">
                    {/* <i className="pi pi-download" ></i><span className="text-sm"> &nbsp;Download Spreadsheet</span> */}
                    {/* <CSVLink data={csvData} filename={"Troubleshooting Stats Table Overview.csv"} className="xl_download_btn">
                        <span>
                            <i className="pi pi-download" ></i><span className="text-sm"> &nbsp;Download Spreadsheet</span>
                        </span>
                    </CSVLink> */}
                </div>
            }
            {tempLoading == false ? <PrimeDatatable
                stripedRows
                sortField={SortField}
                sortOrder={SortFieldSeq}
                value={overviewData}
                paginator
                className="trbl_stats_table_css notes_table_css"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                rows={12}
                rowsPerPageOptions={[12, 20, 30, 40, 50]}
            >
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    field="time"
                    header={`Date`}
                    body={({ time }) => formatDate(time)}
                ></Column>
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    field="deployed"
                    header={`Deployed`}
                    body={(body_val) => {
                        return (
                            <>
                                {body_val.deployed}
                            </>
                        );
                    }}
                ></Column>
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    field="offline"
                    header={`Offline`}
                    body={(body_val) => {
                        return (
                            <>
                                {body_val.offline}
                            </>
                        );
                    }}
                ></Column>
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    field="failure"
                    header={`Failure(%)`}
                    body={(body_val) => {
                        return (
                            <>
                                {body_val.failure && `${parseFloat(body_val.failure).toFixed(2)}`}
                            </>
                        );
                    }}
                ></Column>
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    field="stats"
                    header={`Stats`}
                    body={(body_val) => {
                        return (<i className="pi pi-download" onClick={() => downloadOverviewCsv(body_val.fileName, getDateStrDMY(body_val.time))}></i>);
                    }}
                ></Column>
            </PrimeDatatable> : (
                (renderLoading)
            )}
        </div>
    )
}
export default TableOverview;