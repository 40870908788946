import React, { useState, useEffect, useRef } from "react";
import { Calendar } from 'primereact/calendar';
import { Nullable } from "primereact/ts-helpers";
import moment from "moment";
import { Toast } from 'primereact/toast';

interface UptimeReportDatePickerProps {
    onFromDateChange: (date: Date) => void;
    onToDateChange: (date: Date) => void;
}

const UptimeReportDatePicker: React.FC<UptimeReportDatePickerProps> = ({ onFromDateChange, onToDateChange }) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const defaultToDate = moment().startOf('month');
    const defaultFromDate = moment(defaultToDate).subtract(1, 'year').startOf('month').add(1, 'month');

    const [fromDate, setFromDate] = useState<Nullable<Date>>(defaultFromDate.toDate());
    const [toDate, setToDate] = useState<Nullable<Date>>(defaultToDate.toDate());

    const maxDate = new Date(currentYear, currentMonth, 1);

    const toast: any = useRef(null);
    const fromDateRef = useRef<any>(null);

    useEffect(() => {
        if (fromDate && toDate) {
            const fromYear = fromDate.getFullYear();
            const fromMonth = fromDate.getMonth() + 1;
            const toYear = toDate.getFullYear();
            const toMonth = toDate.getMonth() + 1;

            const monthDiff = (toYear - fromYear) * 12 + toMonth - fromMonth;

            if (monthDiff >= 12) {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Selected date range should be within 12 months.' });
            } else {
                onFromDateChange(fromDate);
                onToDateChange(toDate);
            }

            if (toDate < fromDate || fromDate > toDate) {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Please select a valid range.' });
            }
        }
    }, [fromDate, toDate])

 

    return (
      <>
        <div className="card flex justify-content-centers pt-1">
          <div className="pt-1">
            <svg
              width="15"
              height="18"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 8C6.75 8 7 8.25 7 8.5V11.5C7 11.7812 6.75 12 6.5 12H3.5C3.21875 12 3 11.7812 3 11.5V8.5C3 8.25 3.21875 8 3.5 8H6.5ZM4 0C4.40625 0 4.75 0.34375 4.75 0.75V2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0ZM12.5 6H1.5V14C1.5 14.2812 1.71875 14.5 2 14.5H12C12.25 14.5 12.5 14.2812 12.5 14V6Z"
                fill="#D4D4D4"
              />
            </svg>
          </div>
          &nbsp;
          <div className="p-grid p-fluid">
            <div className="flex space-x-4">
              <div className="flex items-center">
                <Calendar
                  value={fromDate}
                  view="month"
                  dateFormat="M-yy"
                  className="w-16 text-sm"
                  maxDate={maxDate}
                  onChange={(e) => setFromDate(moment(e.value).toDate())}
                />
              </div>
              <div className="flex items-center">-</div>
              <div className="flex items-center">
                <Calendar
                  value={toDate}
                  view="month"
                  dateFormat="M-yy"
                  className="w-16 text-sm"
                  maxDate={maxDate}
                  onChange={(e) => setToDate(moment(e.value).toDate())}
                />
              </div>
            </div>
          </div>
        </div>
        <Toast ref={toast} />
      </>
    );
}

export default UptimeReportDatePicker;