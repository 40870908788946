import { useState } from 'react';
import { Tag } from 'primereact/tag';
import { InputSwitch } from 'primereact/inputswitch';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Select from 'react-select'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Customer_logo from './assets/imgs/sample_customer_logo.jpg'
import { NavLink } from 'react-router-dom';

const AddCustomer = (props: any) => {
  const [domainName, setDomainName] = useState([]);
  const [uomDropDown, setUomDropDown] = useState<any>("");
  const [customerActive, setCustomerActive] = useState(false);
  const [emissionUnitShow, setEmissionUnitShow] = useState(false);
  const [UpdateDomainName, setUpdateDomainName] = useState("");
  const [domainTags, setDomainTags] = useState<any>([]);

  const options = [
    { value: 'Value 1', label: 'Metric' },
    { value: 'Value 2', label: 'Imperial' },
  ]

  const formik: any = useFormik({
    initialValues: {
      logoFile: '',
      logo: '',
      customer_name: '',
      contact_name: '',
      sales_finance_name: '',
      domain_name: '',
      customer_status: customerActive,
      emission_unit: emissionUnitShow,
      uom: '',
    },
    validationSchema: Yup.object().shape({
      customer_name: Yup.string().required('This field is required'),
      contact_name: Yup.string().required('This field is required'),
      sales_finance_name: Yup.string().required('This field is required'),
      domain_name: Yup.array().required('This field is required'),
      customer_status: Yup.string().required('This field is required'),
      emission_unit: Yup.string().required('This field is required'),
      uom: Yup.string().required('This field is required'),
    }),
    onSubmit: async (values: any) => {
      //const formData_redux = {
      //_id: prop?.id ? prop?.id : prop?.data?.id,
      //};
      //var formData = new FormData();
      //formData.append("aogiSiteID", prop?.id ? prop?.id : prop?.data?.id);
    },
  });

  const handleLogoChange = (e: any) => {
    let pictureFile = e.target.files[0];
    formik.setFieldValue('logoFile', pictureFile);
    formik.setFieldValue('logo', pictureFile?.name);
  }

  const handleDomainName = (e: any) => {
    var currTxt = UpdateDomainName?.split(",");
    if (UpdateDomainName.length >= 1) {
      setDomainTags((prevState: Set<string>) => {
        const updatedTags = new Set([...prevState, ...currTxt]);
        return [...updatedTags];
      });
      setUpdateDomainName("")
    }
    formik.setFieldValue('domain_name', domainTags);
  }

  const handleUomChange = (e: any) => {
    formik.setFieldValue('uom', e?.value);
    setUomDropDown({ 'value': e?.value, 'label': e?.label })
  }

  const deleteTags = (tid: any) => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => {
        const filteredTag = domainTags?.filter((tag: any, index: any) => index !== tid);
        setDomainTags(filteredTag);
        if (filteredTag?.length == 0) {
          formik.setFieldValue('domain_name', "");
        } else {
          formik.setFieldValue('domain_name', filteredTag);
        }
      },
      reject: () => {

      }
    });

  }

  return <>
    <form className="" onSubmit={formik.handleSubmit} encType={'multipart/form-data'}>
      <div style={{ padding: '0px 20px' }}>
        <ConfirmDialog />
        <div style={{ display: "flex" }}>
          <div className="tett" style={{ flex: "40%" }}>
            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "1.5rem", flex: "50%" }}>
                <label htmlFor="choose-file">
                  <img src={Customer_logo} style={{ height: "8rem" }} />
                </label>
                <input style={{ display: "none" }} accept="image/gif, image/jpeg, image/png" id="choose-file" type="file" name={'logo'} onChange={(e) => handleLogoChange(e)} />
              </div>

            </div>
            <div style={{ display: "flex", paddingTop: '10px' }}>
              <div className='customer_details_tab_card_heading'>
                Customer Name
              </div>
            </div>
            <div>
              <div className=''>
                <input autoComplete="off" name='customer_name' onChange={formik.handleChange} value={formik.values.customer_name} onBlur={formik.handleBlur} className="border border-borderColor pl-[5px] border-solid rounded w-96 text-textColor bg-bgColor200 leading-tight focus:outline-none focus:shadow-outline" id="customer_name" type="text" placeholder="Customer Name" style={{ height: '35px' }} />
              </div>
              {formik.touched.customer_name && formik.errors.customer_name ? <div className="text-red-400 text-sm">{formik.errors.customer_name}</div> : null}
            </div>
          </div>
          <div className="tett table_card" style={{ flex: "40%", marginLeft: "1em" }}>

            <div style={{ display: "flex" }}>
              <div className='customer_details_tab_card_heading'>
                Preffered Email Domains
              </div>

            </div>
            <div>
              <div style={{ display: "flex", marginTop: "1em", alignItems: "center" }}>
                <div className='customer_details_tab_card_heading'>
                  Domain Name
                </div>
              </div>
              <div style={{ marginTop: "0.5em" }} className='text-textColor'>
                <div style={{ display: "flex", alignItems: "center" }} >
                  <input autoComplete="off" value={UpdateDomainName} name='customer_name' className="border border-borderColor pl-[5px] border-solid rounded w-96 text-textColor bg-bgColor200 leading-tight focus:outline-none focus:shadow-outline" id="customer_name" type="text" onChange={(e: any) => setUpdateDomainName(e?.target.value)} placeholder="Customer Name" style={{ height: '35px' }} />
                  {/* <Chips value={formik.values.domain_name} onBlur={formik.handleBlur} name="sales_finance_name" className="border border-gray-500 pl-[5px] border-solid rounded" onChange={(e: any) => handleDomainName(e)} separator="," style={{ width: '85%' }} /> */}
                  <button type='button' className='btn_yllw_blk py-1 px-3 rounded' style={{ height: '38px', marginLeft: '1em' }} onClick={(e: any) => handleDomainName(e)}>
                    <b><i className="pi pi-plus"></i></b>
                  </button>
                </div>
                <div className='text-gray-400 text-sm'>
                  You can enter several domains seperated by comma.
                </div>
                {formik.touched.domain_name && formik.errors.domain_name ? <div className="text-red-400 text-sm">{formik.errors.domain_name}</div> : null}
                {
                  domainTags?.map((arr: any, idx: any) => {
                    return (
                      <Tag key={idx} className={'ml-2'} style={{ background: '#F1F1F0' }}>
                        <div className="flex align-items-center gap-2" style={{ cursor: 'pointer' }}>
                          <span style={{ color: '#D4D4D4' }}>{arr.trim()}</span>
                          <div className='' onClick={() => deleteTags(idx)}>
                            <i className="pi pi-times text-xs"></i>
                          </div>
                        </div>
                      </Tag>
                    )
                  })
                }

              </div>
            </div>

          </div>
          <div style={{ flex: "15%" }}>

          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div className="tett table_card" style={{ flex: "40%", marginTop: "1em" }}>

            <div style={{ display: "flex" }}>
              <div className='customer_details_tab_card_heading'>
                Main Contacts
              </div>

            </div>

            <div>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "1.5rem", flex: "50%" }}   className='text-textColor'>
                  Contact Name :
                </div>
              </div>
              <div className=''>
                <input autoComplete="off" onChange={formik.handleChange} value={formik.values.contact_name} onBlur={formik.handleBlur} name="contact_name"
                 className="border border-borderColor pl-[5px] border-solid rounded w-96 text-textColor bg-bgColor200 leading-tight focus:outline-none focus:shadow-outline" id="contact_name" type="text" placeholder="Contact Name" style={{ height: '35px' }} />
                {formik.touched.contact_name && formik.errors.contact_name ? <div className="text-red-400 text-sm">{formik.errors.contact_name}</div> : null}
              </div>
            </div>

            <div>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "1rem", flex: "50%" }} className='text-textColor'>
                  Sales/Finance Contact Name:
                </div>

              </div>
              <div className=''>
                <input autoComplete="off" onChange={formik.handleChange} value={formik.values.sales_finance_name} onBlur={formik.handleBlur} name="sales_finance_name" 
                className="border border-borderColor pl-[5px] border-solid rounded w-96 text-textColor bg-bgColor200 leading-tight focus:outline-none focus:shadow-outline" id="sales_finance_name" type="text" placeholder="Sales/Finance Contact Name" style={{ height: '35px' }} />
                {formik.touched.sales_finance_name && formik.errors.sales_finance_name ? <div className="text-red-400 text-sm">{formik.errors.sales_finance_name}</div> : null}
              </div>
            </div>
          </div>

          <div className="tett table_card" style={{ flex: "40%", marginLeft: "1em", marginTop: "1em" }}>

            <div style={{ display: "flex" }}>
              <div className='customer_details_tab_card_heading'>
                Settings
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "1rem", flex: "5%" }} className='text-textColor'>
                Customer Status:
              </div>
              <div style={{ marginTop: "1rem", flex: "2%", display: "flex", alignItems: "center" }} className='text-textColor'>
                <label htmlFor="customer_status">
                  {
                    customerActive == true ? "Active" : "Inactive"
                  }
                </label>
              </div>
              <div style={{ marginTop: "1rem", display: "flex", flex: "20%", alignItems: "center" }}>
                <InputSwitch
                  name='customer_status'
                  value={formik.values.customer_status}
                  onBlur={formik.handleBlur}
                  id='customer_status'
                  checked={customerActive}
                  onChange={(e) => {
                    setCustomerActive(e.value)
                    formik.setFieldValue('customer_status', e?.value)
                  }
                  } />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "1rem", flex: "5%" }} className='text-textColor'>
                Emission Unit:
              </div>
              <div style={{ marginTop: "1rem", flex: "2%", display: "flex", alignItems: "center" }} className='text-textColor'>
                <label htmlFor="emission_unit">
                  {
                    emissionUnitShow == true ? "Show" : "Hide"
                  }
                </label>
              </div>
              <div style={{ marginTop: "1rem", display: "flex", flex: "20%", alignItems: "center" }}>
                <InputSwitch
                  name='emission_unit'
                  value={formik.values.emission_unit}
                  onBlur={formik.handleBlur}
                  id='emission_unit'
                  checked={emissionUnitShow}
                  onChange={(e) => {
                    setEmissionUnitShow(e.value)
                    formik.setFieldValue('emission_unit', e?.value)
                  }
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "1rem", flex: "5%" }} className='text-textColor'>
                UOM:
              </div>
              <div style={{ marginTop: "1rem", flex: "46%", display: "flex", alignItems: "center" }}>
                <Select
                  name="uom"
                  id="uom"
                  placeholder={'Select....'}
                  options={options}
                  className='customer_uom bg-bgColor400'
                  value={uomDropDown}
                  onChange={(e) => handleUomChange(e)}
                  onBlur={formik.handleBlur}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                      color: state.isFocused ? "#000000" : "#D4D4D4",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: "310px", // Limits height of dropdown
                      overflowY: "auto", // Ensures dropdown scrolls internally
                      zIndex: 999, // Prevents conflicts with other elements
                      backgroundColor: "#131F2E", 
                      color: "#D4D4D4",
                      border: "1px solid #3C4F61"
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 9999, // Ensures dropdown stays on top of all elements
                    }),}}
                />

              </div>
            </div>
            {formik.touched.uom && formik.errors.uom ? <div style={{ marginLeft: '160px', marginTop: '1px' }} className="text-red-400 text-sm">{formik.errors.uom}</div> : null}
          </div>
          <div style={{ flex: "15%" }}>

          </div>
        </div>

      </div>
      <div className='flex p-4'>
        <div style={{ flex: "40%" }}>
          <div className='float-right'>
            <NavLink className="bg-white p-2 m-2 rounded-sm font-semibold" style={{ textDecoration: 'underline from-font' }} to={'/customers'} title='Cancel'>Cancel</NavLink>
            &nbsp;&nbsp;&nbsp;&nbsp;

            <button
              type='submit'
              // disabled={!(formik.isValid && formik.dirty)}
              className={!(formik.isValid && formik.dirty) ? 'bg-blue-500 opacity-50 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' : 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'}
            >
              Next | Users
            </button>
          </div>
        </div>
      </div>
    </form>
  </>
}

export default AddCustomer;