import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Checkbox, Divider, LinearProgress, Link } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import MapModal from './MapModalPopup';

interface SiteDetailsProps {
    data: any;
    onDataChange: (data: any) => void;
}
const UploadCard = styled(Card)(({ theme }) => ({
    border: '2px solid #BEBEBC',
    backgroundColor: '#F9F9F9',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: '#424548',
    '&:hover': {
        borderColor: '#306CC9',
    },
}));

const SiteDetails: React.FC<SiteDetailsProps> = ({ data, onDataChange }) => {
    const [selectedFileName, setSelectedFileName] = React.useState<string | null>(null);
    const [uploadProgress, setUploadProgress] = React.useState<number | null>(null);
    const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);
    const [selectedFileSize, setSelectedFileSize] = useState<string | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
    const [status, setstatus] = useState(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [locationValue, setLocationValue] = useState<string>("");
    const [selectedChampionXContact, setSelectedChampionXContact] = useState<string | null>(null);
    const [selectedCustomerContact, setSelectedCustomerContact] = useState<string | null>(null);
    const [selectedComponentCategory, setSelectedComponentCategory] = useState<string | null>(null);
    const [serialNo, setSerialNo] = useState<string | null>(null);
    const [costCenter, setCostCenter] = useState<string | null>(null);
    const [selectedNotificationGroup, setSelectedNotificationGroup] = useState<string | null>(null);
    const [selectedAlarmSelection, setSelectedAlarmSelection] = useState<string | null>(null);
    const [selectedProducts, setSelectedProducts] = useState({
        AOGI: false,
        SOOFIE: false,
        DRONE: false,
        CAMERA: false,
        FIXEDWING: false,
    });

    const handleCheckboxChange = (event: any) => {
        const { name, checked } = event.target;
        setSelectedProducts(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleSerialNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSerialNo(e.target.value);
    };

    const handleCostCenterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCostCenter(e.target.value);
    };

    const handleNotificationGroupChange = (e: { value: string }) => {
        setSelectedNotificationGroup(e.value);
    };

    const handleAlarmSelectionChange = (e: { value: string }) => {
        setSelectedAlarmSelection(e.value);
    };

    const handleChampionXContactChange = (e: any) => {
        setSelectedChampionXContact(e.value);
    };

    const handleCustomerContactChange = (e: any) => {
        setSelectedCustomerContact(e.value);
    };

    const handleComponentCategoryChange = (e: any) => {
        setSelectedComponentCategory(e.value);
    };

    const startUploadProgress = () => {
        let progress = 0;
        const interval = setInterval(() => {
            progress += 2;
            setUploadProgress(progress);

            if (progress >= 100) {
                clearInterval(interval);
                setTimeout(() => {
                    setUploadCompleted(true);
                }, 300);
            }
        }, 50);
    };

    const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            setSelectedFileName(file.name);

            setUploadProgress(0);
            setUploadCompleted(false);

            const fileSizeInKB = file.size / 1024;
            const fileSizeInMB = fileSizeInKB / 1024;
            const formattedSize = fileSizeInKB < 1024
                ? `${fileSizeInKB.toFixed(2)} KB`
                : `${fileSizeInMB.toFixed(2)} MB`;

            setSelectedFileSize(formattedSize);
            startUploadProgress();
        }
    };
    const handleDataChange = () => {
        const data = {
            selectedFileName,
            selectedFileSize,
            serialNo,
            costCenter,
            selectedNotificationGroup,
            selectedAlarmSelection,
            selectedProducts,
            selectedCustomer,
            status,
            locationValue,
            selectedChampionXContact,
            selectedCustomerContact,
            selectedComponentCategory,

        };
        onDataChange(data);
    };

    useEffect(() => {
        handleDataChange();
    }, [
        selectedFileName,
        selectedFileSize,
        serialNo,
        costCenter,
        selectedNotificationGroup,
        selectedAlarmSelection,
        selectedProducts,
        selectedCustomer,
        status,
        locationValue,
        selectedChampionXContact,
        selectedCustomerContact,
        selectedComponentCategory,
    ]);


    const handleFileClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx,.json,.csv';
        input.onchange = (e: any) => {
            const files = e.target.files;
            if (files.length > 0) {
                const file = files[0];
                setSelectedFileName(file.name);

                setUploadProgress(0);
                setUploadCompleted(false);

                const fileSizeInKB = file.size / 1024;
                const fileSizeInMB = fileSizeInKB / 1024;
                const formattedSize = fileSizeInKB < 1024
                    ? `${fileSizeInKB.toFixed(2)} KB`
                    : `${fileSizeInMB.toFixed(2)} MB`;

                setSelectedFileSize(formattedSize);
                startUploadProgress();
            }
        };
        input.click();
    };

    const handleGroupChange = (e: any) => {
        setSelectedCustomer(e.value);
    };

    const toggleUserStatus = () => {
        setstatus(!status);
    };
    const handleSaveLocation = (latLng: any) => {
        setLocationValue(`${latLng.lat}, ${latLng.lng}`);
        setModalVisible(false);
    };

    return (
        <div>
            <React.Fragment>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                    <Box sx={{ width: "32%", backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: 2 }}>
                        <label className='p-2' style={{ fontSize: "20px" }}>Import Site Configuration Data</label>
                        <Divider />
                        <div className='p-1'>
                            {selectedFileName === null && (
                                <div className="p-2 rounded-md shadow" style={{ display: "flex", background: "#E8F1FE", border: "1px #4287F5 solid" }}>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 3C17.5078 3 22 7.49219 22 13C22 18.5469 17.5078 23 12 23C6.45312 23 2 18.5469 2 13C2 7.49219 6.45312 3 12 3ZM12 21.125C16.4531 21.125 20.125 17.4922 20.125 13C20.125 8.54688 16.4531 4.875 12 4.875C7.50781 4.875 3.875 8.54688 3.875 13C3.875 17.4922 7.50781 21.125 12 21.125ZM13.5625 16.125C14.0703 16.125 14.5 16.5547 14.5 17.0625C14.5 17.6094 14.0703 18 13.5625 18H10.4375C9.89062 18 9.5 17.6094 9.5 17.0625C9.5 16.5547 9.89062 16.125 10.4375 16.125H11.0625V13.625H10.75C10.2031 13.625 9.8125 13.2344 9.8125 12.6875C9.8125 12.1797 10.2031 11.75 10.75 11.75H12C12.5078 11.75 12.9375 12.1797 12.9375 12.6875V16.125H13.5625ZM12 10.5C11.2969 10.5 10.75 9.95312 10.75 9.25C10.75 8.58594 11.2969 8 12 8C12.6641 8 13.25 8.58594 13.25 9.25C13.25 9.95312 12.6641 10.5 12 10.5Z" fill="#014361" />
                                    </svg> &nbsp;
                                    <label style={{ fontSize: "14px" }}>
                                        Let us help you speed through this process. Upload a site configuration file and we’ll auto-fill new asset data.
                                    </label>
                                </div>
                            )}
                        </div>
                        <UploadCard
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleFileDrop}
                            style={{ cursor: 'pointer', padding: "0px" }}
                        >
                            <CardContent>
                                <UploadFileIcon sx={{ fontSize: 35, color: '#424548' }} />
                                <Typography color="textSecondary" style={{ fontSize: "16px" }}>
                                    Drag and drop file or{' '}
                                    <Link
                                        component="button"
                                        variant="body1"
                                        onClick={handleFileClick}
                                        sx={{ textDecoration: 'underline', cursor: 'pointer', color: "#FFB217" }}
                                    >
                                        click here
                                    </Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    (Excel, JSON, CSV)
                                </Typography>
                                {selectedFileName !== null && (
                                    <div className="p-2 rounded-md shadow" style={{ background: "#FFFFFF", border: "1px #FFB217 solid" }} >
                                        <div style={{ display: "flex" }}>
                                            <div className='col-1'>
                                                <svg width="39" height="39" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="32" height="32" rx="16" fill="#FFF7E8" />
                                                    <rect x="2" y="2" width="32" height="32" rx="16" stroke="#FFF7E8" stroke-width="4" />
                                                    <path d="M12 12C12 10.9062 12.875 10 14 10H19.1562C19.6875 10 20.1875 10.2188 20.5625 10.5938L23.4062 13.4375C23.7812 13.8125 24 14.3125 24 14.8438V24C24 25.125 23.0938 26 22 26H14C12.875 26 12 25.125 12 24V12ZM22.5 24V15H20C19.4375 15 19 14.5625 19 14V11.5H14C13.7188 11.5 13.5 11.75 13.5 12V24C13.5 24.2812 13.7188 24.5 14 24.5H22C22.25 24.5 22.5 24.2812 22.5 24Z" fill="#FFB217" />
                                                </svg>
                                            </div>

                                            <div className='col-10'>
                                                <div style={{ textAlign: "left" }}>
                                                    <label className="p-1">
                                                        {selectedFileName}
                                                    </label>
                                                    <br />
                                                    <label style={{ fontSize: "12px" }}>
                                                        {selectedFileSize}
                                                    </label>
                                                    <br />
                                                </div>
                                                {uploadProgress !== null && (
                                                    <Box sx={{ width: '100%' }}>
                                                        <div className='flex' style={{ alignItems: 'center', width: '100%' }}>
                                                            <div style={{ flexGrow: 1, marginRight: '10px' }}>
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={uploadProgress}
                                                                    sx={{
                                                                        height: 8,
                                                                        backgroundColor: '#e0e0e0',
                                                                        '& .MuiLinearProgress-bar': {
                                                                            backgroundColor: '#FFB217'
                                                                        },
                                                                        borderRadius: 5,
                                                                    }}
                                                                />
                                                            </div>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {`${uploadProgress}%`}
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                )}
                                            </div>
                                            <div className='col-1'>
                                                {uploadCompleted && (
                                                    <Typography variant="body2" sx={{ color: 'green', mt: 1 }}>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#34A852" />
                                                            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#279741" />
                                                            <path d="M11.3334 5.5L6.75008 10.0833L4.66675 8" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </Typography>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </CardContent>
                        </UploadCard>
                    </Box>
                    <Box sx={{ width: "32%", height: "20%", backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: 2 }}>
                        <label className='p-2' style={{ fontSize: "20px" }}>Additional Site Information (optional)</label>
                        <Divider />
                        <div className="flex">
                            <div className="col-12">
                                <label htmlFor="serial_no" className='text-base' style={{ float: "left" }}>Serial No. / Site ID (optional)</label>
                                <InputText name="serial_no" value={serialNo || ""}
                                    onChange={handleSerialNoChange}
                                    placeholder='Enter serial no. or site ID' required className="w-full h-11 border border-solid border-[#ccc] px-2 py-1 rounded text-base" />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="col-12">
                                <label className='text-base' style={{ float: "left" }}>Cost Center / AFE (optional)</label>
                                <InputText name="cost_center" value={costCenter || ""}
                                    onChange={handleCostCenterChange} placeholder='Enter cost center or AFE' className="text-left w-[100%] h-11 border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                            </div>
                        </div>
                    </Box>
                    <Box sx={{ width: "32%", height: "20%", backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: 2 }}>
                        <label className='p-2' style={{ fontSize: "20px" }} >Additional Site Information (optional)</label>
                        <Divider />
                        <div className="flex">
                            <div className="col-12">
                                <label htmlFor="notification" className='text-base' style={{ float: "left" }}>Notification Groups</label>
                                <Dropdown
                                    name="notificationGroups"
                                    className="w-full border border-solid border-[#ccc] rounded"
                                    options={[{ label: 'Group 1', value: 'group1' }, { label: 'Group 2', value: 'group2' }, { label: 'Group 3', value: 'group3' }]}
                                    placeholder="- Select -"
                                    value={selectedNotificationGroup}
                                    onChange={handleNotificationGroupChange}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="col-12">
                                <label htmlFor="alarm" className='text-base' style={{ float: "left" }}>Alarm selection</label>
                                <Dropdown
                                    name="alarmSelections"
                                    className="text-left w-[100%] border border-solid border-[#ccc] rounded"
                                    options={[
                                        { label: 'Selection 1', value: 'selection1' },
                                        { label: 'Selection 2', value: 'selection2' },
                                        { label: 'Selection 3', value: 'selection3' }
                                    ]}
                                    placeholder="- Select -"
                                    value={selectedAlarmSelection}
                                    onChange={handleAlarmSelectionChange}
                                />
                            </div>
                        </div>
                    </Box>
                    <Box sx={{ width: "32%", height: "30%", backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: 2 }}>
                        <label className='p-2' style={{ fontSize: "20px" }}>Site Information</label>
                        <Divider />
                        <div className="flex">
                            <div className="col-12">
                                <label htmlFor="customer" className="text-base" style={{ float: "left" }}>
                                    Customer
                                </label>
                                <Dropdown
                                    name="customer"
                                    className="text-left w-[100%] border border-solid border-[#ccc] rounded"
                                    options={[
                                        { label: 'Customer 1', value: 'customer1' },
                                        { label: 'Customer 2', value: 'customer2' },
                                        { label: 'Customer 3', value: 'customer3' }
                                    ]}
                                    placeholder="- Select -"
                                    value={selectedCustomer}
                                    onChange={handleGroupChange}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="col-12">
                                <label htmlFor="siteName" className='text-base' style={{ float: "left" }}>Site Name
                                </label>
                                <InputText name="siteName" placeholder="Enter site name" className="text-left w-[100%] h-11 border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="col-6">
                                <label htmlFor="siteName" className='text-base' style={{ float: "left" }}>Location
                                </label>
                                <InputText name="siteName" placeholder="Enter site coordinates" className="text-left w-[100%] h-11 border border-solid border-[#ccc] px-2 py-1 rounded" value={locationValue} />
                            </div>
                            <div className="col-2">
                                <div className='pt-4 cursor-pointer' onClick={() => setModalVisible(true)}                                                    >
                                    <svg width="40" height="40" viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="40" height="50" rx="6" fill="#F5B334" />
                                        <path d="M23.3984 18.625C23.2812 18.8594 23.1641 19.0547 23.0078 19.25C22.8516 19.5234 22.6562 19.7969 22.5 20.0703V20.1094C21.7578 21.1641 21.0547 22.1016 20.5469 22.6875C20.2734 23.0781 19.6875 23.0781 19.4141 22.6875C18.9062 22.1016 18.2031 21.1641 17.5 20.1094C16.7578 19.0547 16.0547 17.8438 15.6641 16.7891C15.625 16.7109 15.5859 16.6328 15.5469 16.5547C15.3906 16.0469 15.3125 15.6172 15.3125 15.1875C15.3125 15.0312 15.3125 14.8359 15.3125 14.6797C15.5859 12.3359 17.5781 10.5 20 10.5C22.5781 10.5 24.6875 12.6094 24.6875 15.1875C24.6875 15.5391 24.6094 15.9297 24.4531 16.3203C24.2578 17.0234 23.8672 17.8438 23.3984 18.625ZM24.375 19.4844V28.2344L29.375 26.4375V16.5547L25.1172 18.0781C25.1953 17.9219 25.2734 17.7266 25.3516 17.5312C25.5859 16.9844 25.7812 16.3984 25.8594 15.8125L29.9609 14.3281C30.2734 14.2109 30.5859 14.25 30.8203 14.4453C31.0938 14.6016 31.25 14.9141 31.25 15.1875V27.0625C31.25 27.4922 30.9766 27.8438 30.625 27.9609L23.75 30.4609C23.5156 30.5391 23.3203 30.5391 23.0859 30.4609L16.5625 28.0781L10 30.4609C9.6875 30.5781 9.375 30.5391 9.14062 30.3438C8.86719 30.1875 8.75 29.875 8.75 29.5625V17.6875C8.75 17.2969 8.98438 16.9453 9.33594 16.8281L14.0625 15.1094C14.0625 15.1484 14.0625 15.1875 14.0625 15.1875C14.0625 15.8125 14.1797 16.3984 14.375 16.9844L10.625 18.3516V28.2344L15.625 26.4375V19.4844C15.6641 19.6016 15.7422 19.7188 15.8203 19.8359C16.3672 20.6953 16.9531 21.5547 17.5 22.2578V26.4375L22.5 28.2344V22.2578C23.0078 21.5547 23.5938 20.6953 24.1406 19.8359C24.2188 19.7188 24.2969 19.6016 24.375 19.4844Z" fill="#2F333E" />
                                    </svg>
                                </div>

                            </div>
                            <MapModal
                                visible={modalVisible}
                                onHide={() => setModalVisible(false)}
                                onSave={handleSaveLocation}
                                initialLatLng={locationValue}
                            />
                            <div className='col-4'>
                                <div className='pt-5 flex justify-between items-center'>
                                    <label className='font-base'>Status</label>
                                    <div style={{ width: 48, height: 24, position: 'relative', cursor: "pointer" }}>
                                        <div
                                            className={`h-6.5 rounded-full p-1 duration-300 ease-in-out`}
                                            onClick={toggleUserStatus}
                                            style={{ width: "50px", backgroundColor: status ? "#F5B334" : "gray", paddingLeft: "10px" }}
                                        >
                                            <div className={`h-5 w-6 bg-white rounded-full shadow-md transform transition-transform duration-400 ease-in-out ${status ? "translate-x-5" : ""}`}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    <Box sx={{ width: "32%", height: "30%", backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: 2 }}>
                        <label className='p-2' style={{ fontSize: "20px" }} >Point of Contact Details</label>
                        <Divider />
                        <div className="flex">
                            <div className="col-12">
                                <label htmlFor="championXContact" className='text-base' style={{ float: "left" }}>
                                    ChampionX Contact
                                </label>
                                <div className="w-[100%] pr-2">
                                    <Dropdown
                                        name="championXContact"
                                        className="text-left w-[100%] border border-solid border-[#ccc] rounded"
                                        options={[
                                            { label: 'emissions.support@championx.com', value: 'emissions.support@championx.com' },
                                            { label: 'sales@championx.com', value: 'sales@championx.com' },
                                            { label: 'support@championx.com', value: 'support@championx.com' }
                                        ]}
                                        placeholder="- Select ChampionX Contact -"
                                        value={selectedChampionXContact}
                                        onChange={handleChampionXContactChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="col-12">
                                <label htmlFor="customerContact" className='text-base' style={{ float: "left" }}>
                                    Customer Contact
                                </label>
                                <div className="w-[100%] pr-2">
                                    <Dropdown
                                        name="customerContact"
                                        className="text-left w-[100%] border border-solid border-[#ccc] rounded"
                                        options={[
                                            { label: 'John Johnson (john.johnson@acmeoil.com)', value: 'john.johnson@acmeoil.com' },
                                            { label: 'Jane Doe (jane.doe@acmeoil.com)', value: 'jane.doe@acmeoil.com' },
                                            { label: 'Sam Smith (sam.smith@acmeoil.com)', value: 'sam.smith@acmeoil.com' }
                                        ]}
                                        placeholder="- Select Customer Contact -"
                                        value={selectedCustomerContact}
                                        onChange={handleCustomerContactChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="col-12">
                                <label htmlFor="componentCategory" className='text-base' style={{ float: "left" }}>
                                    Component Category
                                </label>
                                <div className="w-[100%] pr-2">
                                    <Dropdown
                                        name="componentCategory"
                                        className="text-left w-[100%] border border-solid border-[#ccc] rounded"
                                        options={[
                                            { label: 'Lorem ipsum dolor', value: 'lorem1' },
                                            { label: 'Lorem ipsum sit amet', value: 'lorem2' },
                                            { label: 'Lorem ipsum consectetur', value: 'lorem3' },
                                            { label: 'Lorem ipsum adipiscing elit', value: 'lorem4' }
                                        ]}
                                        placeholder="- Select Component Category -"
                                        value={selectedComponentCategory}
                                        onChange={handleComponentCategoryChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                    <Box sx={{ width: "32%", backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: 2 }}>
                        <label className='p-2' style={{ fontSize: "20px" }}   >Products & Subscriptions</label>
                        <Divider />
                        <br />
                        <div className="p-2 rounded-md shadow" style={{ background: "white", border: "1px #B3B3B0 solid" }}>
                            <div className='' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="32" height="32" rx="4" fill="#FFF7E8" />
                                    <path d="M10.75 9.40625C10.75 9.05078 11.0234 8.75 11.4062 8.75H22.3438C22.6992 8.75 23 9.05078 23 9.40625C23 9.78906 22.6992 10.0625 22.3438 10.0625H17.75V12.25H18.8438C21.1133 12.25 23 14.1367 23 16.4062V17.9375C23 18.9219 22.207 19.6875 21.2227 19.6875H16C15.5352 19.6875 15.0977 19.5234 14.7695 19.1953L12.3359 16.7617L8.50781 15.0391C8.31641 14.957 8.15234 14.793 8.125 14.5742L7.57812 11.8125C7.44141 11.1562 7.96094 10.5 8.64453 10.5H8.80859C9.24609 10.5 9.65625 10.6914 9.95703 11.0469L11.0234 12.25H16.4375V10.0625H11.4062C11.0234 10.0625 10.75 9.78906 10.75 9.40625ZM12.9648 15.6133C13.0469 15.6406 13.1016 15.668 13.1562 15.7227L15.6992 18.2656C15.7812 18.3477 15.8906 18.375 16 18.375H16.4375V13.5625H10.7227C10.5586 13.5625 10.3672 13.4805 10.2305 13.3438L8.97266 11.8945C8.94531 11.8945 8.94531 11.8672 8.91797 11.8672L9.35547 14L12.9648 15.6133ZM21.2227 18.375C21.4688 18.375 21.6875 18.1836 21.6875 17.9375V16.4062C21.6875 14.8477 20.4023 13.5625 18.8438 13.5625H17.75V18.375H21.2227ZM23.875 22.2305C23.4375 22.5859 22.918 22.75 22.3711 22.75H14.0312C13.6484 22.75 13.375 22.4766 13.375 22.0938C13.375 21.7383 13.6484 21.4375 14.0312 21.4375H22.3711C22.6172 21.4375 22.8633 21.3555 23.0547 21.2188L23.6836 20.7266C23.957 20.4805 24.3672 20.5352 24.5859 20.8086C24.832 21.1094 24.7773 21.5195 24.4766 21.7383L23.875 22.2305Z" fill="#FFB217" />
                                </svg>
                                <label className='font-base' style={{ marginRight: '8px' }}>AOGI</label>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
                                    <Checkbox
                                        sx={{ color: '#FFB217', '&.Mui-checked': { color: '#FFB217' } }}
                                        name="AOGI"
                                        checked={selectedProducts.AOGI}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="p-2 rounded-md shadow" style={{ background: "white", border: "1px #B3B3B0 solid" }}>
                            <div className='' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="32" height="32" rx="4" fill="#FFF7E8" />
                                    <path d="M14.75 12.6875C16.5312 11.125 19.4375 11.125 21.2188 12.6875C21.4062 12.8438 21.5 13.0625 21.5 13.25C21.5 13.4375 21.4375 13.625 21.2812 13.7188C21.0312 14.0312 20.5625 14.0625 20.25 13.8125C19 12.7188 16.9688 12.7188 15.7188 13.8125C15.4062 14.0938 14.9375 14.0625 14.6562 13.75C14.4062 13.4375 14.4375 12.9688 14.75 12.6875ZM13.25 11.3125C12.9375 11.5938 12.4688 11.5625 12.1875 11.2812C11.9062 10.9688 11.9062 10.5 12.2188 10.2188C15.4062 7.28125 20.5625 7.28125 23.75 10.2188C23.9062 10.3438 24 10.5625 24 10.75C24 10.9375 23.9062 11.125 23.7812 11.2812C23.5 11.5625 23.0312 11.5938 22.7188 11.3125C20.125 8.90625 15.8438 8.90625 13.25 11.3125ZM23 17.5C24.0938 17.5 25 18.4062 25 19.5312V22C25 23.125 24.0938 24 23 24H9C7.875 24 7 23.125 7 21.9688V19.5C7 18.375 7.875 17.5 9 17.5H17.25V15.75C17.25 15.3438 17.625 15 18 15C18.4062 15 18.75 15.3125 18.75 15.75V17.5H23ZM23.5 22V19.5C23.5 19.2188 23.25 19 23 19H9C8.71875 19 8.5 19.2188 8.5 19.5V22C8.5 22.25 8.71875 22.5 9 22.5H23C23.25 22.5 23.5 22.25 23.5 22ZM12.75 20C13.1562 20 13.5 20.3438 13.5 20.75C13.5 21.1562 13.1562 21.5 12.75 21.5C12.3125 21.5 12 21.1562 12 20.75C12 20.3125 12.3125 20 12.75 20ZM10.25 20C10.6562 20 11 20.3438 11 20.75C11 21.1875 10.6562 21.5 10.25 21.5C9.8125 21.5 9.5 21.1562 9.5 20.75C9.5 20.3125 9.8125 20 10.25 20Z" fill="#FFB217" />
                                </svg>
                                <label className='font-base' style={{ marginRight: '8px' }}>SOOFIE</label>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
                                    <Checkbox
                                        sx={{ color: '#FFB217', '&.Mui-checked': { color: '#FFB217' } }}
                                        name="SOOFIE"
                                        checked={selectedProducts.SOOFIE}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="p-2 rounded-md shadow" style={{ background: "white", border: "1px #B3B3B0 solid" }}>
                            <div className='' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="32" height="32" rx="4" fill="#FFF7E8" />
                                    <path d="M10.9688 11.375C11.2422 11.375 11.4883 11.5664 11.5703 11.8125H14.4688C14.8242 11.8125 15.125 12.1133 15.125 12.4688C15.125 12.8516 14.8242 13.125 14.4688 13.125H7.90625C7.52344 13.125 7.25 12.8516 7.25 12.4688C7.25 12.1133 7.52344 11.8125 7.90625 11.8125H10.3398C10.4219 11.5664 10.668 11.375 10.9688 11.375ZM21.0312 11.375C21.3047 11.375 21.5508 11.5664 21.6328 11.8125H24.0938C24.4492 11.8125 24.75 12.1133 24.75 12.4688C24.75 12.8516 24.4492 13.125 24.0938 13.125H17.5312C17.1484 13.125 16.875 12.8516 16.875 12.4688C16.875 12.1133 17.1484 11.8125 17.5312 11.8125H20.4023C20.4844 11.5664 20.7305 11.375 21.0312 11.375ZM10.3125 15.75V14H11.625V15.3672L11.8984 15.5312L13.2109 14.7656C14.0586 14.2734 15.0156 14 16 14C16.957 14 17.9141 14.2734 18.7617 14.7656L20.0742 15.5312L20.375 15.3672V14H21.6875V15.75C21.6875 15.9961 21.5508 16.2422 21.332 16.3516L19.9102 17.0898L20.0469 17.2266C20.5391 17.8281 20.8125 18.5664 20.8125 19.332V19.4688C20.8125 19.8516 20.5117 20.125 20.1562 20.125C19.7734 20.125 19.5 19.8516 19.5 19.4688V19.332C19.5 18.8672 19.3359 18.4297 19.0352 18.0742L18.7344 17.7188L17.6133 18.3203C17.5039 18.3477 17.3945 18.375 17.3125 18.375H14.6875C14.5781 18.375 14.4688 18.3477 14.3594 18.3203L13.2383 17.7188L12.9375 18.0742C12.6367 18.4297 12.5 18.8672 12.5 19.332V19.4688C12.5 19.8516 12.1992 20.125 11.8438 20.125C11.4609 20.125 11.1875 19.8516 11.1875 19.4688V19.332C11.1875 18.5664 11.4336 17.8281 11.9258 17.2266L12.0625 17.0898L10.6406 16.3516C10.4219 16.2422 10.3125 15.9961 10.3125 15.75ZM18.707 16.2422L18.1055 15.8867C17.4492 15.5312 16.7383 15.3125 16 15.3125C15.2344 15.3125 14.5234 15.5312 13.8672 15.8867L13.2656 16.2422L14.8242 17.0625H17.1484L18.707 16.2422Z" fill="#FFB217" />
                                </svg>
                                <label className='font-base' style={{ marginRight: '8px' }}>Drone</label>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
                                    <Checkbox
                                        sx={{ color: '#FFB217', '&.Mui-checked': { color: '#FFB217' } }}
                                        name="DRONE"
                                        checked={selectedProducts.DRONE}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="p-2 rounded-md shadow" style={{ background: "white", border: "1px #B3B3B0 solid" }}>
                            <div className='' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="32" height="32" rx="4" fill="#FFF7E8" />
                                    <path d="M12.5 17C12.5 15.0938 14.0625 13.5 16 13.5C17.9062 13.5 19.5 15.0938 19.5 17C19.5 18.9375 17.9062 20.5 16 20.5C14.0625 20.5 12.5 18.9375 12.5 17ZM16 15C14.875 15 14 15.9062 14 17C14 18.125 14.875 19 16 19C17.0938 19 18 18.125 18 17C18 15.9062 17.0938 15 16 15ZM19.3125 10.0312L19.6562 11H22C23.0938 11 24 11.9062 24 13V21C24 22.125 23.0938 23 22 23H10C8.875 23 8 22.125 8 21V13C8 11.9062 8.875 11 10 11H12.3125L12.6562 10.0312C12.8438 9.4375 13.4062 9 14.0625 9H17.9062C18.5625 9 19.125 9.4375 19.3125 10.0312ZM10 12.5C9.71875 12.5 9.5 12.75 9.5 13V21C9.5 21.2812 9.71875 21.5 10 21.5H22C22.25 21.5 22.5 21.2812 22.5 21V13C22.5 12.75 22.25 12.5 22 12.5H18.5625L17.9062 10.5H14.0625L13.4062 12.5H10Z" fill="#FFB217" />
                                </svg>
                                <label className='font-base' style={{ marginRight: '8px' }}>Camera</label>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
                                    <Checkbox
                                        sx={{ color: '#FFB217', '&.Mui-checked': { color: '#FFB217' } }}
                                        name="CAMERA"
                                        checked={selectedProducts.CAMERA}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="p-2 rounded-md shadow" style={{ background: "white", border: "1px #B3B3B0 solid" }}>
                            <div className='' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="32" height="32" rx="4" fill="#FFF7E8" />
                                    <path d="M23.875 15.75C23.875 17.0898 21.8516 17.9375 20.8125 17.9375H18.5703L15.8359 22.3125C15.6719 22.5859 15.3984 22.75 15.0703 22.75H13.293C13.0195 22.75 12.7461 22.6406 12.582 22.4219C12.418 22.2031 12.3633 21.9023 12.4453 21.6289L13.6211 17.9375H12.2812L11.2148 19.3594C11.0508 19.5781 10.8047 19.6875 10.5312 19.6875H8.97266C8.72656 19.6875 8.45312 19.5781 8.28906 19.3594C8.125 19.1406 8.07031 18.8672 8.15234 18.5938L8.97266 15.75L8.125 12.9062C8.07031 12.6602 8.125 12.3867 8.28906 12.168C8.45312 11.9492 8.72656 11.8125 8.97266 11.8125H10.5312C10.8047 11.8125 11.0508 11.9492 11.2148 12.168L12.2812 13.5625H13.6211L12.4453 9.87109C12.3633 9.625 12.418 9.32422 12.582 9.10547C12.7461 8.88672 13.0195 8.75 13.293 8.75H15.0703C15.3711 8.75 15.6992 8.94141 15.8086 9.1875L18.543 13.5625H20.7852C21.8516 13.5625 23.875 14.4375 23.875 15.75ZM22.5352 15.75C22.4805 15.4766 21.4961 14.875 20.7852 14.875H17.832L14.8242 10.0625H13.8945L15.3984 14.875H11.625L10.3125 13.125H9.57422L10.3398 15.75L9.57422 18.375H10.3125L11.625 16.625H15.3984L13.8945 21.4375H14.8242L17.832 16.625H20.7852C21.5234 16.625 22.5078 16.0508 22.5352 15.75Z" fill="#FFB217" />
                                </svg>
                                <label className='font-base' style={{ marginRight: '8px' }}>Fixed wing</label>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
                                    <Checkbox
                                        sx={{ color: '#FFB217', '&.Mui-checked': { color: '#FFB217' } }}
                                        name="FIXEDWING"
                                        checked={selectedProducts.FIXEDWING}
                                        onChange={handleCheckboxChange}
                                    /> 
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </React.Fragment>
        </div>
    );
};

export default SiteDetails;