import { DataTable as PrimeDatatable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState, useRef } from "react";
import ResetIcon from '../../../../assets/img/reset.png';
import EditIcon from '../../../../assets/img/edit.png';
import DeleteIcon from '../../../../assets/img/trash.png';
import { getTimzeZoneForAPI, IsNullOrEmpty, UTCToDesiredTimezone, getTimzeZoneUI } from '../../../../utils/CommonUtils';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import NoteServices from "../../../../config/services/notes-services/notes-services";
import { InputText } from 'primereact/inputtext';

const AssetsTable = (props: any) => {
    const [SortField, setSortField] = useState("order");
    const [SortFieldSeq, setSortFieldSeq] = useState<any>(-1);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [displayEdit, setDisplayEdit] = useState(false);
    const [deleteId, setDeleteId] = useState<any>("");
    const [deleteAlarmId, setDeleteAlarmId] = useState<any>("");
    const [position, setPosition] = useState('center');
    const [tempLoading, SettempLoading] = useState<any>(true);
    const [readOnlyID, SetreadOnlyID] = useState('');
    const [readOnlySiteName, SetreadOnlySiteName] = useState('');
    const [readNoteCat, SetreadNoteCat] = useState('');
    const [readNoteTag, SetreadNoteTag] = useState('');
    const [readDetails, SetreadDetails] = useState('');
    const [readLoggedBy, SetreadLoggedBy] = useState('');
    const [readLoggedDate, SetreadLoggedDate] = useState('');
    const [readLastUpdated, SetreadLastUpdated] = useState('');
    const [getAlmId, setGetAlmId] = useState<any>(null);

    const toastBottomCenter = useRef<any>(null);

    const renderNoData = (
        <div className="px-5 py-5 text-bold text-center">
            <div className="flex justify-center items-center text-textColor">
                <h1>No data available.</h1>
            </div>
        </div>
    );


    const renderLoading = (
        <div>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-borderColor"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm text-textColor">Loading Data...</div>
        </div>
    );
    const getStatusStyle = (status: string) => {
        return <div className={(status === "Completed") ? "green-status" : "gray-status"}> {status} </div>
    }
    const assetCellClickHandler = () => {
        console.log("Asset Name cell clicked...!");
    }

    let filteredData = props.state.data.filter((item: any) => {
        return Object.values(item).some((val) =>
            val ? val.toString().toLowerCase().includes(props.searchTerm.toLowerCase()) : false
        );
    });

    const dialogFuncMap: any = {
        'displayEdit': setDisplayEdit,
        'deleteConfirm': setDeleteConfirm
    }



    const onClickDelete = (name: any, position: any, data: any) => {

        setDeleteId(data.id)
        setDeleteAlarmId(data.alarmNoteId);
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name: any) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooterDelete = (name: any) => {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>

                <div>
                    <Button onClick={() => { onHide(name) }} label="Cancel" style={{ backgroundColor: "white", border: "1px solid black", color: "black", height: "30px", marginLeft: "10px", padding: "8px" }} />
                </div>
                <div>
                    <Button disabled onClick={() => { handleDeleteSubmit(name) }} label="Delete" style={{ backgroundColor: "#f5b334", border: "0px solid #f5b334", color: "black", height: "30px", marginLeft: "10px", padding: "8px" }} />
                </div>
            </div>
        );
    }

    const handleDeleteSubmit = (name: any) => {

        NoteServices.DeleteNote(deleteId, deleteAlarmId).then((res: any) => {

            onHide(name);
            if (res == "OK") {
                toastBottomCenter.current.show({ severity: "success", summary: "Delete", detail: "Customer Asset Deleted Successfully", life: 3000 });
            } else {
                toastBottomCenter.current.show({ severity: "error", summary: "Delete", detail: "Something went wrong", life: 3000 });
            }
        })
    }

    //Edit popup code 

    const renderFooterEdit = (name: any) => {
        return (
            <div style={{ display: "flex", float: "right" }}>
                <button onClick={() => onHide(name)} style={{ textDecoration: "underline", cursor: "pointer" }}>Close</button>&nbsp;&nbsp;
                <Button disabled className="btn_yllw_blk font-bold py-2 px-4 rounded" onClick={() => { handleEditSubmit(name) }}>Submit</Button>
            </div>

        );
    }


    const onClick = (name: any, position: any, data: any) => {

        SetreadOnlySiteName(data.siteName);
        SetreadOnlyID(data.id);
        SetreadNoteCat(data.category);
        SetreadNoteTag(data.tag);
        SetreadDetails(data.details);
        SetreadLoggedBy(data.loggedBy);
        SetreadLoggedDate(data.loggedOn);
        SetreadLastUpdated(data.updatedOn);
        setGetAlmId(data.alarmNoteId);
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const handleEditSubmit = (name: any) => {
        SettempLoading(true)
        let post_data = {
            //id: readOnlyID,
            //SiteName: readOnlySiteName,
            //category: readNoteCat,
            //Tags: readNoteTag,
            NoteId: readOnlyID,
            Description: readDetails,
            //AlaramId: getAlmId,
            //LoggedBy: readLoggedBy,
            //LoggedDate: readLoggedDate,
            //LastUpdated: moment(new Date()).format('D MMM-YYYY')
        }
        NoteServices.PutNote(post_data, readOnlyID).then((res: any) => {
            onHide(name);
            if (res == true) {
                toastBottomCenter.current.show({ severity: "success", summary: "Update", detail: "Customer Asset updated", life: 3000 });
            } else {
                //onHide(name);
                toastBottomCenter.current.show({ severity: "error", summary: "Update", detail: "Something went wrong", life: 3000 });
            }
        })
        setTimeout(() => {
            SettempLoading(false)
        }, 2000)
    }


    return (
        <>
            <Toast ref={toastBottomCenter} position="bottom-center" />
            <div className="tett">

                {/* Edit Start */}
                <Dialog header={<>
                    <div style={{ display: "flex" }}>
                        <img src={EditIcon} alt="Edit Icon"></img>
                        {/* <i className="pi pi-file-edit" style={{fontSize:"20px"}}></i> */}
                        <div style={{ fontSize: "16px", color: "#D4D4D4", marginLeft: "8px", fontWeight: "500" }}>Edit Note</div>

                    </div>
                </>} visible={displayEdit} style={{ width: '40vw' }} footer={renderFooterEdit('displayEdit')} onHide={() => onHide('displayEdit')}>

                    <div className="flex mb-2" style={{ marginTop: "1.5rem" }}>
                        <div className="pt-2 w-[30%] notes_form_text_font">
                            Asset Name
                        </div>

                        <div className="w-[90%]">
                            <InputText value={readOnlySiteName} disabled className="text-left w-[100%] h-[38px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font" />
                        </div>
                    </div>


                    <div className="flex mb-2">
                        <div className="pt-2 w-[30%] notes_form_text_font">
                            Asset Type
                        </div>

                        <div className="w-[90%]">
                            <InputText value={readNoteCat} disabled className="text-left w-[100%] h-[38px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font" />
                        </div>
                    </div>

                    <div className="flex mb-2">
                        <div className="pt-2 w-[30%] notes_form_text_font">
                            Model
                        </div>

                        <div className="w-[90%]">
                            <InputText value={readNoteTag} disabled className="text-left w-[100%] h-[38px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font" />
                        </div>
                    </div>
                    <div className="flex mb-2">
                        <div className="pt-2 w-[30%] notes_form_text_font">
                            Serial No
                        </div>

                        <div className="w-[90%]">
                            <InputText value={readNoteTag} disabled className="text-left w-[100%] h-[38px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font" />
                        </div>
                    </div>

                    <div className="flex mb-2">
                        <div className="pt-2 w-[30%] notes_form_text_font">
                            First Activation Date
                        </div>

                        <div className="w-[90%]">
                            <InputText value={readNoteTag} disabled className="text-left w-[100%] h-[38px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font" />
                        </div>
                    </div>

                    <div className="flex mb-2">
                        <div className="pt-2 w-[30%] notes_form_text_font">
                            Warranty Exp Date
                        </div>

                        <div className="w-[90%]">
                            <InputText value={readNoteTag} disabled className="text-left w-[100%] h-[38px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font" />
                        </div>
                    </div>
                </Dialog>

                {/* Edit End */}

                {/* Start Delete */}
                <Dialog header={<>
                    <div style={{ display: "flex" }}>
                        <img src={DeleteIcon} alt="Delete Icon"></img>
                        {/* <i className="pi pi-delete-left" style={{fontSize:"20px"}}></i> */}
                        <div style={{ fontSize: "16px", color: "#25476a", marginLeft: "8px", fontWeight: "500" }}>Delete Note</div>

                    </div>
                </>} visible={deleteConfirm} style={{ width: '20vw' }} footer={renderFooterDelete('deleteConfirm')} onHide={() => onHide('deleteConfirm')}>
                    <div style={{ marginTop: "15px" }}>
                        Please confirm that you want to delete this note. Once deleted,the note would be irretrievable
                    </div>
                </Dialog>
                {/* deleteConfirm */}
                {/* Delete End */}

                {props.state.isLoading ? (
                    renderLoading
                ) : (
                    <>
                        {IsNullOrEmpty(props.state.data) ? (
                            renderNoData
                        ) : (
                            <PrimeDatatable
                                stripedRows
                                sortField={SortField}
                                sortOrder={SortFieldSeq}
                                value={filteredData}
                                paginator
                                className="notes_table_css"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                rows={12}
                                rowsPerPageOptions={[12, 20, 30, 40, 50]}
                            >
                                <Column
                                    headerStyle={{ fontSize: "14px" }}
                                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                                    showFilterMatchModes={false}
                                    field="name"
                                    header={`Asset Name`}
                                    sortable
                                    body={(body_val) => {
                                        return (
                                            <>
                                                <div className="tbl-cell-link" onClick={assetCellClickHandler}>{body_val.name}</div>
                                            </>
                                        );
                                    }}
                                ></Column>
                                <Column
                                    headerStyle={{ fontSize: "14px" }}
                                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                                    showFilterMatchModes={false}
                                    field="assetType"
                                    header={`Asset Type`}
                                    sortable
                                    body={(body_val) => {
                                        return (
                                            <>
                                                {body_val.assetType}
                                            </>
                                        );
                                    }}
                                ></Column>
                                <Column
                                    headerStyle={{ fontSize: "14px" }}
                                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                                    showFilterMatchModes={false}
                                    field="model"
                                    header={`Model`}
                                    sortable
                                    body={(body_val) => {
                                        return (
                                            <>
                                                {body_val.model}
                                            </>
                                        );
                                    }}
                                ></Column>
                                <Column
                                    headerStyle={{ fontSize: "14px" }}
                                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                                    showFilterMatchModes={false}
                                    field="serialNumber"
                                    header={`Serial No`}
                                    sortable
                                    body={(body_val) => {
                                        return (
                                            <>
                                                {body_val.serialNumber === "undefined" ? " " : body_val.serialNumber}
                                            </>
                                        );
                                    }}
                                ></Column>
                                <Column
                                    headerStyle={{ fontSize: "14px" }}
                                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                                    showFilterMatchModes={false}
                                    field="activationDate"
                                    header={`First Activation Date`}
                                    sortable
                                    body={({ activationDate }) => {
                                        const d = new Date(activationDate);
                                        return `${String(d.getDate()).padStart(2, '0')}-${String(d.getMonth() + 1).padStart(2, '0')}-${d.getFullYear()}`;
                                    }}
                                ></Column>
                                <Column
                                    headerStyle={{ fontSize: "14px" }}
                                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                                    showFilterMatchModes={false}
                                    field="warrantyExpirationDate"
                                    header={`Warranty Exp Date`}
                                    sortable
                                    body={(body_val) => {
                                        return (
                                            <>
                                                {body_val.warrantyExpirationDate}
                                            </>
                                        );
                                    }}
                                ></Column>
                                <Column
                                    headerStyle={{ fontSize: "14px" }}
                                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                                    showFilterMatchModes={false}
                                    field="status"
                                    header={`Status`}
                                    sortable
                                    body={(body_val) => {
                                        return (
                                            <>
                                                {body_val.status}
                                            </>
                                        );
                                    }}
                                ></Column>
                                <Column
                                    headerStyle={{ fontSize: "14px" }}
                                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                                    showFilterMatchModes={false}
                                    body={(body_val) => {
                                        return (<div style={{ display: "flex" }}>
                                            <img src={ResetIcon} alt="Reset Icon"></img>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <img src={EditIcon} onClick={() => onClick('displayEdit', 'center', body_val)} className="edit-icon" alt="Edit Icon" title="Edit" />&nbsp;&nbsp;&nbsp;&nbsp;
                                            <img src={DeleteIcon} onClick={() => onClickDelete('deleteConfirm', 'center', body_val)} className="delete-icon" alt="Delete Icon" title="Delete" />
                                        </div>);
                                    }}
                                ></Column>
                            </PrimeDatatable>
                        )}
                    </>
                )}
            </div>
        </>
    )
}
export default AssetsTable;