import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from '@mui/material';
import upload from '../../../../assets/img/upload_icon.png';
import Components from './EDComponents';
import DeviceTable from './EDDevices';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import MapModal from './MapModalPopup';
import EDMap from './EDMap';

interface Device {
    type: string;
    name: string;
    deviceId: string;
    activationDate: Date | null;
    terminationDate: Date | null;
    locationValue: string;
    selectedOptions: string[];
    polemountstyle: string;
    componentCategory: string;
    sku: string;
    dsn: string;
    version: string;
}

interface EquipmentAndDevicesProps {
    data: any;
    onDataChange: (data: any) => void;
}

const EquipmentAndDevices: React.FC<EquipmentAndDevicesProps> = ({ data, onDataChange }) => {

    const [showDialog, setShowDialog] = useState(false);
    const [type, setType] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [name, setName] = useState<string>('');
    const [deviceId, setDeviceId] = useState<string>('');
    const [activationDate, setActivationDate] = useState<Date | null>(null);
    const [terminationDate, setTerminationDate] = useState<Date | null>(null);
    const [locationValue, setLocationValue] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [polemountstyle, setPolemountstyle] = useState('');
    const [componentCategory, setComponentCategory] = useState('');
    const [version, setVersion] = useState('');
    const [sku, setSku] = useState('');
    const [dsn, setDsn] = useState('');
    const [devices, setDevices] = useState<Device[]>([]);
    const [selectedTab, setSelectedTab] = useState('components');

    const componentOptions = [
        { label: 'Berm ', value: 'berm' },
        { label: 'Tank', value: 'tank' },
        { label: 'Separator', value: 'separator' },
        { label: 'Pump', value: 'pump' },
        { label: 'Compressor', value: 'compressor' },
        { label: 'Combuster', value: 'combuster' },
    ];

    const poleMountOptions = [
        { label: 'Permanent Mount', value: 'Permanentmount' },
        { label: 'Mobile Tripod', value: 'Mobiletripod' },
        { label: 'Mobile Concrete Base', value: 'Mobileconcretebase' },
        { label: 'Mobile Welded Base', value: 'Mobileweldedbase' },
        { label: 'Mobile Customer Base', value: 'Mobilecustomerbase' }
    ];

    const typeOptions = [
        { label: 'SOOFIE Device', value: 'SOOFIE Device' },
        { label: 'Equipment', value: 'Equipment' },
    ];

    const onOptionsChange = (e: any) => {
        const { value, checked } = e.target;

        setSelectedOptions((prev) => {
            if (checked) {
                return [...prev, value];
            } else {
                return prev.filter((item) => item !== value);
            }
        });
    };
    const openDialog = () => setShowDialog(true);
    const closeDialog = () => setShowDialog(false);

    const addDevice = () => {
        const newDevice = {
            type,
            name,
            deviceId,
            activationDate,
            terminationDate,
            locationValue,
            selectedOptions,
            polemountstyle,
            componentCategory,
            sku,
            dsn,    
            version,
        };

        // Save the new device to the array
        setDevices([...devices, newDevice]);

        // Reset form fields
        setType('');
        setName('');
        setDeviceId('');
        setActivationDate(null);
        setTerminationDate(null);
        setLocationValue('');
        setSelectedOptions([]);
        setPolemountstyle('');
        setComponentCategory('');
        setSku('');
        setDsn('');
        setVersion('');

        // Close the dialog
        closeDialog();
    };

    useEffect(() => {
            setSelectedTab('components');
    }, []);
 
    const handleSaveLocation = (latLng: any) => {
        setLocationValue(`${latLng.lat}, ${latLng.lng}`);
        setModalVisible(false);
    };
    const handleDataChange = () => {
        const data = {
           devices
        };
        onDataChange(data);
    };
    useEffect(() => {
        handleDataChange();
    }, [devices]);

    return (
        <div
            className="p-2"
            style={{
                width: '100%',
                height: '100%',
                background: 'white',
                boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.15)',
                borderRadius: 8,
            }}
        >
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ flex: '60%', border: '2px solid white', padding: '1px' }}>
                    <div
                        className="p-2"
                        style={{
                            color: '#424548',
                            fontSize: 20,
                            fontFamily: 'Mulish',
                            fontWeight: '500',
                            wordWrap: 'break-word',
                        }}
                    >
                        Equipment & Devices
                    </div>
                    <nav className="mt-1 bg-white w-180 p-1 rounded-md ml-1">
                        <Router>
                            <div className="flex items-center justify-between">
                                <div className="space-x-4 flex">
                                <NavLink
                                    to="/components"
                                    className={({ isActive }) =>
                                        `transition duration-300 ${
                                        isActive
                                            ? 'text-cyan-800 border-b-2 border-orange-400'
                                            : ''
                                        }`
                                    }
                                    onClick={() => setSelectedTab('components')}
                                    >
                                    <span className="text-sm">Components</span>
                                    </NavLink>;
                                    <NavLink
                                        to={`/devices`}
                                        className={`transition duration-300 ${selectedTab === 'devices' ? 'text-cyan-800 border-b-2 border-orange-400' : ''}`}
                                        onClick={() => setSelectedTab('devices')}
                                    >
                                        <span className="text-sm">Devices</span>
                                    </NavLink>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        className="inline-flex items-center px-4 py-1 bg-white-500 text-black text-sm rounded hover:bg-white-600 transition duration-300"
                                        onClick={() => alert('Upload Site button clicked')}
                                    >
                                        <img src={upload} alt="Upload" />
                                        <span className="ml-2" style={{ whiteSpace: 'nowrap' }}>Upload site configuration</span>
                                    </button>
                                    <Button label="New device" icon="pi pi-plus" className="px-4 py-1 bg-[#FFB217] text-black text-sm rounded hover:bg-yellow-600 transition duration-300" onClick={openDialog} />
                                </div>
                            </div>
                        </Router>
                    </nav>
                    {selectedTab === 'components' ? <Components devices={devices} /> : <DeviceTable devices={devices} />}

                </div>
                <div style={{ flex: '40%', border: '2px solid white', padding: '1px' }}>
                    <EDMap />
                </div>
            </div>
            {/* Dialog for New Device */}
            <Dialog header="New Device" visible={showDialog} style={{ width: '40vw' }} onHide={closeDialog}>
                <div className="flex">
                    <div className="col-6">
                        <label htmlFor="type" className="block font-medium mb-2">
                            Type
                        </label>
                        <Dropdown
                            id="type"
                            value={type}
                            options={typeOptions}
                            onChange={(e) => setType(e.value)}
                            className="w-full border border-solid border-[#ccc] rounded text-sm"
                            placeholder="Select a Type"
                        />
                    </div>
                    <div className="col-6 mt-2" style={{ whiteSpace: "nowrap" }}>
                        <div className="card flex flex-wrap">
                            <div className="flex align-items-center">
                                <Checkbox
                                    sx={{ color: '#FFB217', '&.Mui-checked': { color: '#FFB217' } }}
                                    value="Billable"
                                    onChange={onOptionsChange}
                                    checked={selectedOptions.includes('Billable')}
                                />
                                <label htmlFor="option1" >Billable</label>
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox
                                    sx={{ color: '#FFB217', '&.Mui-checked': { color: '#FFB217' } }}
                                    value="Wind Only"
                                    onChange={onOptionsChange}
                                    checked={selectedOptions.includes('Wind Only')}
                                />
                                <label htmlFor="option2">Wind Only</label>
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox
                                    sx={{ color: '#FFB217', '&.Mui-checked': { color: '#FFB217' } }}
                                    value="Hotspot"
                                    onChange={onOptionsChange}
                                    checked={selectedOptions.includes('Hotspot')}
                                />
                                <label htmlFor="option3">Hotspot</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex'>
                    <div className='col-6'>
                        <label htmlFor="name" className="block font-medium mb-2">Name</label>
                        <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                    </div>
                    <div className="col-6">
                        <label htmlFor="deviceId" className="block font-medium mb-2">Device ID</label>
                        <InputText id="deviceId" value={deviceId} onChange={(e) => setDeviceId(e.target.value)} className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                    </div>
                </div>
                <div className='flex'>
                    <div className='col-6'>
                        <label htmlFor="activationDate" className="block font-medium mb-2">Activation Date</label>
                        <Calendar id="activationDate"
                            value={activationDate} onChange={(e) => setActivationDate(e.value ?? null)} showIcon
                            dateFormat="dd-MM-yy"
                            className="custom-calendar-icon text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                    </div>
                    <div className="col-6">
                        <label htmlFor="terminationDate" className="block font-medium mb-2">Termination Date</label>
                        <Calendar
                            id="terminationDate"
                            value={terminationDate}
                            onChange={(e) => setTerminationDate(e.value ?? null)}
                            showIcon
                            dateFormat="dd-MM-yy"
                            className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base"
                        />
                    </div>
                </div>
                <div className="flex">
                    <div className="col-5">
                        <label htmlFor="location" className="block font-medium mb-2" style={{ float: "left" }}>Location</label>
                        <InputText name="location" placeholder="Enter site coordinates" className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded" value={locationValue} onChange={(e) => setLocationValue(e.target.value)} />
                    </div>
                    <div className="col-1">
                        <div className='pt-4 cursor-pointer' onClick={() => setModalVisible(true)}                                                    >
                            <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="40" rx="6" fill="#F5B334" />
                                <path d="M23.3984 18.625C23.2812 18.8594 23.1641 19.0547 23.0078 19.25C22.8516 19.5234 22.6562 19.7969 22.5 20.0703V20.1094C21.7578 21.1641 21.0547 22.1016 20.5469 22.6875C20.2734 23.0781 19.6875 23.0781 19.4141 22.6875C18.9062 22.1016 18.2031 21.1641 17.5 20.1094C16.7578 19.0547 16.0547 17.8438 15.6641 16.7891C15.625 16.7109 15.5859 16.6328 15.5469 16.5547C15.3906 16.0469 15.3125 15.6172 15.3125 15.1875C15.3125 15.0312 15.3125 14.8359 15.3125 14.6797C15.5859 12.3359 17.5781 10.5 20 10.5C22.5781 10.5 24.6875 12.6094 24.6875 15.1875C24.6875 15.5391 24.6094 15.9297 24.4531 16.3203C24.2578 17.0234 23.8672 17.8438 23.3984 18.625ZM24.375 19.4844V28.2344L29.375 26.4375V16.5547L25.1172 18.0781C25.1953 17.9219 25.2734 17.7266 25.3516 17.5312C25.5859 16.9844 25.7812 16.3984 25.8594 15.8125L29.9609 14.3281C30.2734 14.2109 30.5859 14.25 30.8203 14.4453C31.0938 14.6016 31.25 14.9141 31.25 15.1875V27.0625C31.25 27.4922 30.9766 27.8438 30.625 27.9609L23.75 30.4609C23.5156 30.5391 23.3203 30.5391 23.0859 30.4609L16.5625 28.0781L10 30.4609C9.6875 30.5781 9.375 30.5391 9.14062 30.3438C8.86719 30.1875 8.75 29.875 8.75 29.5625V17.6875C8.75 17.2969 8.98438 16.9453 9.33594 16.8281L14.0625 15.1094C14.0625 15.1484 14.0625 15.1875 14.0625 15.1875C14.0625 15.8125 14.1797 16.3984 14.375 16.9844L10.625 18.3516V28.2344L15.625 26.4375V19.4844C15.6641 19.6016 15.7422 19.7188 15.8203 19.8359C16.3672 20.6953 16.9531 21.5547 17.5 22.2578V26.4375L22.5 28.2344V22.2578C23.0078 21.5547 23.5938 20.6953 24.1406 19.8359C24.2188 19.7188 24.2969 19.6016 24.375 19.4844Z" fill="#2F333E" />
                            </svg>
                        </div>
                        <MapModal
                            visible={modalVisible}
                            onHide={() => setModalVisible(false)}
                            onSave={handleSaveLocation}
                            initialLatLng={locationValue} />
                    </div>
                    <div className="col-2">
                        <label htmlFor="version" className="block font-medium mb-2" style={{ float: "left" }}>Version</label>
                        <InputText name="version" value={version} onChange={(e) => setVersion(e.target.value)} placeholder="Enter Version" className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded" />
                    </div>
                    <div className="col-4">
                        <label htmlFor="style" className="block font-medium mb-2" style={{ float: "left" }}>Pole Mount Style (optional)</label>
                        <Dropdown
                            name="style"
                            value={polemountstyle}
                            options={poleMountOptions}
                            onChange={(e) => setPolemountstyle(e.value)}
                            placeholder="- Select -"
                            className="w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-0 rounded text-sm"
                        />
                    </div>
                </div>
                <div className='flex'>
                    <div className='col-6'>
                        <label htmlFor="sku" className="block font-medium mb-2" style={{ float: "left" }}>SKU</label>
                        <InputText name="sku" placeholder="Optional" className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded" value={sku}
                            onChange={(e) => setSku(e.target.value)} />
                    </div>
                    <div className='col-6'>
                        <label htmlFor="dsn" className="block font-medium mb-2" style={{ float: "left" }}>Device Serial Number</label>
                        <InputText name="dsn" placeholder="Optional" className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded" value={dsn}
                            onChange={(e) => setDsn(e.target.value)} />
                    </div>
                </div>
                <div className='flex'>
                    <div className='col-12'>
                        <label htmlFor="component" className="block font-medium mb-2" style={{ float: "left" }}>Component category</label>
                        <Dropdown
                            id="component"
                            name="component"
                            value={componentCategory}
                            options={componentOptions}
                            onChange={(e) => setComponentCategory(e.value)}
                            placeholder="- Select -"
                            className="w-full border border-solid border-[#ccc] rounded text-xs"
                        />
                    </div>
                </div>
                <div className="flex justify-end mt-4">
                    <button
                        className="px-4 py-2 border border-solid border-[#CBCBC9] bg-white-300 text-sm rounded hover:bg-white-400 transition duration-300 mr-2"
                        onClick={closeDialog}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-4 py-2 bg-[#FFB217] text-black text-sm rounded transition duration-300 mr-2"
                        onClick={addDevice}
                    >
                        Add Device
                    </button>
                </div>
            </Dialog>
        </div>
    );
};

export default EquipmentAndDevices;
