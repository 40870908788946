
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuthToken } from './AuthToken';
import { apiUrls } from "./ApiConstants";

//let authToken = localStorage.getItem("at");
// TODO : Use refresh token

// getAuthToken().then((res) => {
//   localStorage.setItem('auth_token', res.access_token); // This will be enabled once CORS issue resolved!
//localStorage.setItem('auth_token',"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJjSmhhMSIsIlVzZXJJZCI6ImNKaGExIiwiVXNlck5hbWUiOiJDTUpIQUBFQ0MuQ09NIiwiQ3VzdG9tZXJJRCI6IjE0NmY0M2U3LTQwYjYtNDFmYy04ZTI4LWE5MmJhMzlkZjFkMiIsIkN1c3RvbWVyTmFtZSI6IkVtaXNzaW9uQ3VzdG9tZXJDIiwiSm9iVGl0bGUiOiJVc2VyIiwiQWNjZXNzTGV2ZWxOZXciOiJBZG1pbmlzdHJhdG9yIiwiR2xvYmFsQWRtaW4iOiJOIiwiU3VwZXJBZG1pbiI6Ik4iLCJDdXN0b21lcnMiOiIiLCJMYW5ndWFnZSI6ImVuIiwiVGltZVpvbmUiOiJBbWVyaWNhL0NoaWNhZ28iLCJHcmlkTGF5b3V0IjoiVGFibGUiLCJDdXJyZW50VU9NIjoiSW1wZXJpYWwiLCJBY2Nlc3NMZXZlbCI6IjkyZjA4NGQ5LWFkOWUtNDg0Ni1iNzEwLTFkNDY2ZmYwN2U4NiIsIklzRGVsZWdhdGVkIjoiIiwiU2VjdXJpdHlGZWF0dXJlcyI6IntcIklzQ3JlYXRlQWxhcm1zXCI6dHJ1ZSxcIklzU25vb3plQWxhcm1cIjp0cnVlLFwiSXNVc2VyTWFuYWdlbWVudFwiOnRydWUsXCJJc0Fzc2V0c01hbmFnZW1lbnRcIjpmYWxzZSxcIklzQ3JlYXRlR3JvdXBQbG90c1wiOnRydWV9IiwiQ3VzdG9tZXJEZWxlZ2F0aW9uIjoibnVsbCIsIkRlbGVnYXRpb24iOiJbe1wiQWNjZXNzTGV2ZWxJZFwiOlwiNWRmNzIyMmUtNTllMS00YjQyLTkyY2EtOGNmMWQ4NGQxZmE4XCIsXCJBY2Nlc3NMZXZlbE5hbWVcIjpcIkRzaXRlMlwiLFwiQ3VzdG9tZXJJZFwiOlwiZWViNGU0MDEtMDE0Yi00OTA3LWIyMzctNGU2NDZjOGJlM2RhXCIsXCJDdXN0b21lck5hbWVcIjpcIkVtaXNzaW9uQ3VzdG9tZXJEXCJ9XSIsInN1YiI6IkNoYW5kcmEgTW9oYW4gSmhhIiwianRpIjoiNjUxNzQ1ZGJkYTAzMTViNTczNTE2YWFjIiwiaWF0IjoiMTAvMzAvMjAyMyAxMjowNTowMCBQTSIsIm5iZiI6MTY5ODY2NzUwMCwiZXhwIjoxNjk4NjY5MzAwLCJpc3MiOiJBdXJhLldlYkFQSSIsImF1ZCI6IkF1cmEuV2ViQVBJIn0.MmDXDq1zG1Aprq3xUBuPw7lJyeTsbOnho7UOXrKxN0c");
//   /* To generate this token:
//       1. Run enterprise app in local -> Emission3.0-dev branch
//       2. Create a token through Postman -> http://localhost:53375/Account/Token
//       3. use the token here
//       4. This token valid only 30mins, after 30mins need to generate new token.
//       5. This manual work will be disabled once CORS isssue resolved
//   */
// })

// Create an Axios instance
//console.log("Debug log - process.env.NODE_ENV : ",process.env.NODE_ENV," | ",process.env.REACT_APP_API_ENDPOINT);
//console.log("REACT_APP_ENV : ",process.env.REACT_APP_ENV);

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});
// Request interceptor
//authToken = localStorage.getItem('auth_token'); // Comment this before commit
axiosInstance.interceptors.request.use(
  (config: any) => {
    // You can modify the request config here, such as adding headers
    config.headers['Authorization'] = `Bearer ${localStorage.getItem("at")}`;
    config.headers['Content-Type'] = "application/json";
    config.headers['Access-Control-Allow-Origin'] = "*";
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // You can perform actions on successful responses here
    return response;
  },
  (error: AxiosError) => {
    // You can handle errors here, e.g., logging or displaying error messages
    console.error('Request failed:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
