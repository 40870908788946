import React,{useState} from "react";
import logo from '../assets/img/logo_x.png';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import Modal from "../components/modalPopup/Modal";
import LogoutButton from "./LogoutButton";
import { hasPermission } from '../utils/permissions';
import { getActiveRole } from '../utils/role';

const Sidebar: React.FC = () => {
  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const location = useLocation();
  let url_split = location.pathname.split("/");
  
  return (
    <>
      <div className="bg-bgColor200 bg-opacity-100 text-white h-full flex flex-col">
        <div className="mb-2 mt-1">
          <img className="logo-x" src={logo} alt="" />
        </div>
        <NavLink
          onClick={() => (window.location.href = "/operations-overview")}
          to="/operations-overview"
        >
          <svg
            width="50"
            height="45"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isModalOpen ? (
              <>
                {location.pathname === "/operations-overview" ||
                location.pathname === "/emission-overview" ||
                location.pathname === "/AssetAlert" ? (
                  <rect width="50" height="50" rx="12" x="-1" fill="#000" />
                ) : (
                  ""
                )}
                <path
                  d="M34.7109 24.5C34.7109 25.2031 34.125 25.75 33.4609 25.75H32.2109L32.25 32C32.25 32.1172 32.25 32.2344 32.25 32.3125V32.9375C32.25 33.8359 31.5469 34.5 30.6875 34.5H30.0625C29.9844 34.5 29.9453 34.5 29.9062 34.5C29.8672 34.5 29.7891 34.5 29.75 34.5H28.5H27.5625C26.6641 34.5 26 33.8359 26 32.9375V32V29.5C26 28.8359 25.4141 28.25 24.75 28.25H22.25C21.5469 28.25 21 28.8359 21 29.5V32V32.9375C21 33.8359 20.2969 34.5 19.4375 34.5H18.5H17.25C17.1719 34.5 17.1328 34.5 17.0547 34.5C17.0156 34.5 16.9766 34.5 16.9375 34.5H16.3125C15.4141 34.5 14.75 33.8359 14.75 32.9375V28.5625C14.75 28.5625 14.75 28.5234 14.75 28.4844V25.75H13.5C12.7969 25.75 12.25 25.2031 12.25 24.5C12.25 24.1484 12.3672 23.8359 12.6406 23.5625L22.6406 14.8125C22.9141 14.5391 23.2266 14.5 23.5 14.5C23.7734 14.5 24.0859 14.5781 24.3203 14.7734L34.2812 23.5625C34.5938 23.8359 34.75 24.1484 34.7109 24.5Z"
                  fill={
                    isModalOpen ||
                    location.pathname === "/operations-overview" ||
                    location.pathname === "/emission-overview" ||
                    location.pathname === "/AssetAlert"
                      ? "#F1F4F5"
                      : "#000"
                  }
                />
              </>
            ) : (
              <>
                {location.pathname === "/operations-overview" ||
                location.pathname === "/emission-overview" ||
                location.pathname === "/AssetAlert" ? (
                  <rect width="50" height="50" rx="12" x="-1" fill="#ffd266" />
                ) : (
                  ""
                )}
                <path
                  d="M34.7109 24.5C34.7109 25.2031 34.125 25.75 33.4609 25.75H32.2109L32.25 32C32.25 32.1172 32.25 32.2344 32.25 32.3125V32.9375C32.25 33.8359 31.5469 34.5 30.6875 34.5H30.0625C29.9844 34.5 29.9453 34.5 29.9062 34.5C29.8672 34.5 29.7891 34.5 29.75 34.5H28.5H27.5625C26.6641 34.5 26 33.8359 26 32.9375V32V29.5C26 28.8359 25.4141 28.25 24.75 28.25H22.25C21.5469 28.25 21 28.8359 21 29.5V32V32.9375C21 33.8359 20.2969 34.5 19.4375 34.5H18.5H17.25C17.1719 34.5 17.1328 34.5 17.0547 34.5C17.0156 34.5 16.9766 34.5 16.9375 34.5H16.3125C15.4141 34.5 14.75 33.8359 14.75 32.9375V28.5625C14.75 28.5625 14.75 28.5234 14.75 28.4844V25.75H13.5C12.7969 25.75 12.25 25.2031 12.25 24.5C12.25 24.1484 12.3672 23.8359 12.6406 23.5625L22.6406 14.8125C22.9141 14.5391 23.2266 14.5 23.5 14.5C23.7734 14.5 24.0859 14.5781 24.3203 14.7734L34.2812 23.5625C34.5938 23.8359 34.75 24.1484 34.7109 24.5Z"
                  fill={
                    location.pathname === "/operations-overview" ||
                    location.pathname === "/emission-overview" ||
                    location.pathname === "/AssetAlert"
                      ? "#000"
                      : "#F1F4F5"
                  }
                />
              </>
            )}
          </svg>
        </NavLink>

        {hasPermission(getActiveRole(userPrefstate.data), "linkback") && (
          <>
            <NavLink
              to={isModalOpen ? "/customers" : "#"}
              onClick={toggleModal}
            >
              <svg
                width="50"
                height="45"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isModalOpen ||
                (url_split[1] === "CustomerDetails" && url_split[2] !== "") ? (
                  <rect
                    width="40"
                    height="40"
                    rx="8"
                    fill="#ffd266"
                    style={{ position: "relative", top: "30px" }}
                  />
                ) : (
                  ""
                )}
                <path
                  d="M14.375 10.5H25.625C26.6406 10.5 27.5 11.3594 27.5 12.375V28.625C27.5 29.6797 26.6406 30.5 25.625 30.5H21.875V27.375C21.875 26.3594 21.0156 25.5 20 25.5C18.9453 25.5 18.125 26.3594 18.125 27.375V30.5H14.375C13.3203 30.5 12.5 29.6797 12.5 28.625V12.375C12.5 11.3594 13.3203 10.5 14.375 10.5ZM15 19.875V21.125C15 21.4766 15.2734 21.75 15.625 21.75H16.875C17.1875 21.75 17.5 21.4766 17.5 21.125V19.875C17.5 19.5625 17.1875 19.25 16.875 19.25H15.625C15.2734 19.25 15 19.5625 15 19.875ZM19.375 19.25C19.0234 19.25 18.75 19.5625 18.75 19.875V21.125C18.75 21.4766 19.0234 21.75 19.375 21.75H20.625C20.9375 21.75 21.25 21.4766 21.25 21.125V19.875C21.25 19.5625 20.9375 19.25 20.625 19.25H19.375ZM22.5 19.875V21.125C22.5 21.4766 22.7734 21.75 23.125 21.75H24.375C24.6875 21.75 25 21.4766 25 21.125V19.875C25 19.5625 24.6875 19.25 24.375 19.25H23.125C22.7734 19.25 22.5 19.5625 22.5 19.875ZM15.625 14.25C15.2734 14.25 15 14.5625 15 14.875V16.125C15 16.4766 15.2734 16.75 15.625 16.75H16.875C17.1875 16.75 17.5 16.4766 17.5 16.125V14.875C17.5 14.5625 17.1875 14.25 16.875 14.25H15.625ZM18.75 14.875V16.125C18.75 16.4766 19.0234 16.75 19.375 16.75H20.625C20.9375 16.75 21.25 16.4766 21.25 16.125V14.875C21.25 14.5625 20.9375 14.25 20.625 14.25H19.375C19.0234 14.25 18.75 14.5625 18.75 14.875ZM23.125 14.25C22.7734 14.25 22.5 14.5625 22.5 14.875V16.125C22.5 16.4766 22.7734 16.75 23.125 16.75H24.375C24.6875 16.75 25 16.4766 25 16.125V14.875C25 14.5625 24.6875 14.25 24.375 14.25H23.125Z"
                  fill={
                    isModalOpen ||
                    (url_split[1] === "CustomerDetails" && url_split[2] !== "")
                      ? "#000"
                      : "#F1F4F5"
                  }
                />
              </svg>
            </NavLink>
          </>
        )}
        <Modal isOpen={isModalOpen} onClose={toggleModal} />
        {hasPermission(getActiveRole(userPrefstate.data), "note-view") && (
          <NavLink to="/analytics">
            <svg
              width="50"
              height="45"
              viewBox="5 0 40 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isModalOpen ? (
                <>
                  {location.pathname === "/analytics" ||
                  location.pathname === "/global" ? (
                    <rect
                      width="45"
                      height="45"
                      rx="7"
                      x="2"
                      y="2"
                      fill="#000"
                    />
                  ) : (
                    ""
                  )}
                  <path
                    d="M18.5 14.5H24.75V19.5C24.75 20.2031 25.2969 20.75 26 20.75H31V32C31 33.4062 29.8672 34.5 28.5 34.5H18.5C17.0938 34.5 16 33.4062 16 32V17C16 15.6328 17.0938 14.5 18.5 14.5ZM26 14.5L31 19.5H26V14.5ZM24.4375 24.1875C24.4375 23.6797 24.0078 23.25 23.5 23.25C22.9531 23.25 22.5625 23.6797 22.5625 24.1875V31.0625C22.5625 31.6094 22.9531 32 23.5 32C24.0078 32 24.4375 31.6094 24.4375 31.0625V24.1875ZM27.875 26.6875C27.875 26.1797 27.4453 25.75 26.9375 25.75C26.3906 25.75 26 26.1797 26 26.6875V31.0625C26 31.6094 26.3906 32 26.9375 32C27.4453 32 27.875 31.6094 27.875 31.0625V26.6875ZM21 29.1875C21 28.6797 20.5703 28.25 20.0625 28.25C19.5156 28.25 19.125 28.6797 19.125 29.1875V31.0625C19.125 31.6094 19.5156 32 20.0625 32C20.5703 32 21 31.6094 21 31.0625V29.1875Z"
                    fill={
                      isModalOpen ||
                      location.pathname === "/analytics" ||
                      location.pathname === "/global"
                        ? "#F1F4F5"
                        : "#000"
                    }
                  />
                </>
              ) : (
                <>
                  {location.pathname === "/analytics" ||
                  location.pathname === "/global" ? (
                    <rect
                      width="45"
                      height="45"
                      rx="7"
                      x="2"
                      y="2"
                      fill="#ffd266"
                    />
                  ) : (
                    ""
                  )}
                  <path
                    d="M18.5 14.5H24.75V19.5C24.75 20.2031 25.2969 20.75 26 20.75H31V32C31 33.4062 29.8672 34.5 28.5 34.5H18.5C17.0938 34.5 16 33.4062 16 32V17C16 15.6328 17.0938 14.5 18.5 14.5ZM26 14.5L31 19.5H26V14.5ZM24.4375 24.1875C24.4375 23.6797 24.0078 23.25 23.5 23.25C22.9531 23.25 22.5625 23.6797 22.5625 24.1875V31.0625C22.5625 31.6094 22.9531 32 23.5 32C24.0078 32 24.4375 31.6094 24.4375 31.0625V24.1875ZM27.875 26.6875C27.875 26.1797 27.4453 25.75 26.9375 25.75C26.3906 25.75 26 26.1797 26 26.6875V31.0625C26 31.6094 26.3906 32 26.9375 32C27.4453 32 27.875 31.6094 27.875 31.0625V26.6875ZM21 29.1875C21 28.6797 20.5703 28.25 20.0625 28.25C19.5156 28.25 19.125 28.6797 19.125 29.1875V31.0625C19.125 31.6094 19.5156 32 20.0625 32C20.5703 32 21 31.6094 21 31.0625V29.1875Z"
                    fill={
                      location.pathname === "/analytics" ||
                      location.pathname === "/global"
                        ? "#000"
                        : "#F1F4F5"
                    }
                  />
                </>
              )}
            </svg>
          </NavLink>
        )}
        {/* {hasPermission(getActiveRole(userPrefstate.data), 'help') &&
      <NavLink  to="#" >
      <svg width="50" height="53" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        {location.pathname === "#" ? <rect width="45" height="43" rx="7" fill="#ffd266" /> : ""}
        <path d="M11 17C11 15.6328 12.0938 14.5 13.5 14.5H24.75C26.1172 14.5 27.25 15.6328 27.25 17V24.5C27.25 25.9062 26.1172 27 24.75 27H18.8906L15.7266 29.3828C15.5312 29.5391 15.2969 29.5391 15.0625 29.4609C14.8672 29.3438 14.75 29.1484 14.75 28.875V27H13.5C12.0938 27 11 25.9062 11 24.5V17ZM21 28.25H24.75C26.8203 28.25 28.5 26.5703 28.5 24.5V19.5H33.5C34.8672 19.5 36 20.6328 36 22V29.5C36 30.9062 34.8672 32 33.5 32H32.25V33.875C32.25 34.1484 32.0938 34.3438 31.8984 34.4609C31.6641 34.5391 31.4297 34.5391 31.2344 34.3828L28.0703 32H23.5C22.0938 32 21 30.9062 21 29.5V28.25ZM16.3125 18.1328L16.2734 18.1719C16.1562 18.5625 16.3516 19.0312 16.7812 19.1484C17.1719 19.3047 17.6016 19.1094 17.7578 18.6797V18.6406C17.7969 18.5234 17.9141 18.4844 18.0312 18.4844H19.9062C20.1797 18.4844 20.4141 18.6797 20.4141 18.9531C20.4141 19.1484 20.2969 19.3047 20.1797 19.3828L18.7344 20.2422C18.4609 20.3594 18.3438 20.6328 18.3438 20.9062V21.3359C18.3438 21.7656 18.6953 22.1172 19.125 22.1172C19.5156 22.1172 19.8672 21.7656 19.9062 21.3359L20.9219 20.75C21.5859 20.3984 21.9766 19.6953 21.9766 18.9531C21.9766 17.8203 21.0391 16.9219 19.9062 16.9219H18.0312C17.25 16.9219 16.5469 17.3906 16.3125 18.1328ZM20.1406 24.1875C20.1406 23.6406 19.6719 23.1719 19.125 23.1719C18.5391 23.1719 18.0703 23.6406 18.0703 24.1875C18.0703 24.7734 18.5391 25.2422 19.125 25.2422C19.6719 25.2422 20.1406 24.7734 20.1406 24.1875Z"
          fill={location.pathname === "#" ? "#000" : '#F1F4F5'} />
      </svg>
      </NavLink>} */}

       {/* <NavLink to="/customers" >
        <svg width="50" height="45" viewBox="0 10 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          {location.pathname === "/customers" ? <rect width="45" height="50" rx="8" fill="#ffd266" /> : ""}
          <path d="M23.5 15.75C23.8125 15.75 24.125 15.8281 24.3984 15.9453L35.375 19.8906C35.7266 20.0078 36 20.3594 36 20.75C36 21.1797 35.7266 21.5312 35.375 21.6484L24.3984 25.5938C24.125 25.7109 23.8125 25.75 23.4609 25.75C23.1484 25.75 22.8359 25.7109 22.5625 25.5938L16.3125 23.3281C16.6641 22.9766 17.0938 22.7031 17.5625 22.5078L23.6953 20.0859C24.0469 19.9688 24.2031 19.6172 24.0469 19.2656C23.9297 18.9531 23.5781 18.7969 23.2656 18.9141L17.1328 21.3359C16.3516 21.6484 15.6875 22.1172 15.1797 22.7422C14.5156 23.6406 14.125 24.7344 14.125 25.9062V26.8828C14.3594 27.3906 14.5156 27.9766 14.6328 28.5234C14.8672 29.5781 15.0625 31.1016 14.7109 32.7812C14.6719 32.9375 14.5938 33.0938 14.4375 33.1719C14.2812 33.25 14.125 33.2891 13.9688 33.25L11.4688 32.625C11.2344 32.5859 11.0781 32.4297 11 32.2344C10.9609 32.0391 11 31.8047 11.1172 31.6484C11.4297 31.1797 11.7422 30.6719 11.9766 30.1641C12.4453 29.2656 12.875 28.1328 12.875 27V25.9062C12.875 24.6562 13.2266 23.4844 13.8516 22.4688L11.5859 21.6484C11.2344 21.5312 11 21.1797 11 20.75C11 20.3594 11.2344 20.0078 11.5859 19.8906L22.5625 15.9453C22.8359 15.8281 23.1484 15.75 23.5 15.75ZM16 30.4375L16.5859 24.7734L22.1328 26.7656C22.5625 26.9219 23.0312 27 23.5 27C23.9297 27 24.3984 26.9219 24.8281 26.7656L30.375 24.7734L31 30.4375C31 31.8438 27.6406 33.25 23.5 33.25C19.3594 33.25 16 31.8438 16 30.4375Z"
            fill={location.pathname === "/customers" ? "#424548" : '#F1F4F5'} />
        </svg>
      </NavLink> */}
        <div className="mt-auto ml-2 mb-4">
          {/* mt-auto pushes it to the bottom */}
          <LogoutButton />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
