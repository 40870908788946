import { NavLink, Route, Routes } from "react-router-dom";
import SiteEmissionAlert from "../AlertInfo/SiteEmissionAlert";
import AssetAlerts from "../AlertInfo/AssetAlerts";
import EmissionOverlay from "../EmissionOverview/EmissionOverlay";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { useEffect, useRef, useState } from "react";
import { getJsonStringify, getTimzeZoneForAPI, IsNullOrEmpty } from "../../utils/CommonUtils";
import { fetchEmissionAlerts } from "../../store/thunk/MapSliceThunk";

// const OperationsOverviewMenu = (props: any) => {
//     const { searchResults, startDate, endDate, searchText, emissionMarker, customerIdValue, alertTypeResult, selectedKeys,
// activeMarker, setActiveMarker, setAlertTypeTab, setShowOverlay, showOverlay} = props;
//     const dispatch = useDispatch<AppDispatch>();
//     const state = useSelector((state: any) => state.emissionAlertState);
//     const prevStartDateRef = useRef<any>({ startDate: "" });
//     const prevEndDateRef = useRef<any>({ endDate: "" });
//     const filteredData = searchResults.filter((item: any) => item.siteId);
//     const userPrefstate = useSelector((state: any) => state.userPreferenceState);
//     const timeZone = getTimzeZoneForAPI(userPrefstate.data.timeZone);
//     const arrayData = !IsNullOrEmpty(searchText)
//       ? filteredData
//       : !state.isLoading &&
//         (alertTypeResult.length ? alertTypeResult : state.data);
//     const [emissionCurrState, setEmissionCurrState] = useState(arrayData);
//     const [intCardLoading, setIntCardLoading] = useState(true);
//    useEffect(() => {
//      let isDateChange =
//        getJsonStringify(prevStartDateRef.current.startDate) !== getJsonStringify(startDate) &&
//        getJsonStringify(prevStartDateRef.current.endDate) !== getJsonStringify(endDate);
//        prevStartDateRef.current = { startDate };
//        prevEndDateRef.current = { endDate };
//      if (isDateChange) {
//        dispatch(fetchEmissionAlerts({ startDate, endDate, timeZone }));
//        setEmissionCurrState(state.data);
//        closeLoader();
//      }
//    }, [dispatch, startDate, endDate]);
//    const closeLoader = () => {
//      setTimeout(() => {
//        setIntCardLoading(false);
//      }, 2000);
//    };
//   return (
//     <>
//       <nav className="absolute top-5 right-[0rem] text-white p-1 flex flex-col w-44 z-10">
//         <NavLink
//           to="/operations-overview"
//           className={({ isActive }) =>
//             isActive
//               ? "text-orange bg-menuBgColor rounded-full flex items-center justify-between  m-2 py-1 px-3"
//               : "transition duration-300 bg-menuBgColor rounded-full flex items-center justify-between m-2 py-1 px-3"
//           }
//           onClick={() => {
//             setAlertTypeTab("site");
//             setActiveMarker(null);
//             setShowOverlay(true);
//           }}
//         >
//           <span>Emission Alert</span>
//         </NavLink>
//         <NavLink
//           to="/AssetAlert"
//           className={({ isActive }) =>
//             isActive
//               ? "text-orange bg-menuBgColor rounded-full flex items-center justify-between  m-2 py-1 px-3"
//               : "transition duration-300 bg-menuBgColor rounded-full flex items-center justify-between m-2 py-1 px-3"
//           }
//           onClick={() => {
//             setAlertTypeTab("asset");
//             setActiveMarker(null);
//             setShowOverlay(true);
//           }}
//         >
//           <span>Asset Alert</span>
//         </NavLink>
//       </nav>
//       <div>
//         <Routes>
//           <Route
//             path="/operations-overview"
//             element={
//               showOverlay && (
//                 <EmissionOverlay
//                   setShowOverlay={setShowOverlay}
//                   showOverlay={showOverlay}
//                 >
//                   <div className=" bg-bgColor600 text-textColor">
//                     <div
//                       className="rounded-md m-1 box-shadow"
//                       style={{ position: "relative" }}
//                     >
//                       <SiteEmissionAlert
//                         searchResults={searchResults}
//                         startDate={startDate}
//                         endDate={endDate}
//                         searchText={searchText}
//                         emissionMarker={emissionMarker}
//                         customerIdValue={customerIdValue}
//                         alertTypeResult={alertTypeResult}
//                         emissionCurrState={emissionCurrState}
//                         setEmissionCurrState={setEmissionCurrState}
//                       />
//                     </div>
//                   </div>
//                 </EmissionOverlay>
//               )
//             }
//           ></Route>
//           <Route
//             path="/AssetAlert"
//             element={
//               showOverlay && (
//                 <EmissionOverlay
//                   setShowOverlay={setShowOverlay}
//                   showOverlay={showOverlay}
//                 >
//                   <div className="rounded-md m-1 box-shadow bg-bgColor600 overflow-x-hidden">
//                     <AssetAlerts
//                       searchResults={searchResults}
//                       startDate={startDate}
//                       endDate={endDate}
//                       searchText={searchText}
//                       emissionMarker={emissionMarker}
//                       customerIdValue={customerIdValue}
//                       selectedKeys={selectedKeys}
//                       activeMarker={activeMarker}
//                     />
//                   </div>
//                 </EmissionOverlay>
//               )
//             }
//           ></Route>
//         </Routes>
//       </div>
//     </>
//   );
// };
const OperationsOverviewMenu = (props: any) => {
  const {
    searchResults,
    startDate,
    endDate,
    searchText,
    emissionMarker,
    customerIdValue,
    alertTypeResult,
    selectedKeys,
    activeMarker,
    setActiveMarker,
    setAlertTypeTab,
    setShowOverlay,
    showOverlay,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector((state: any) => state.emissionAlertState);
  const filteredData = searchResults.filter((item: any) => item.siteId);
  const arrayData = !IsNullOrEmpty(searchText) ? filteredData : (!state.isLoading && (alertTypeResult.length ? alertTypeResult : state.data));
  // const [emissionCurrState, setEmissionCurrState] = useState(arrayData);
  const emissionAlertState = useSelector(
    (state: any) => state.emissionAlertState
  ); // Get emission data from Redux
  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  const timeZone = getTimzeZoneForAPI(userPrefstate.data.timeZone);

  const prevStartDateRef = useRef<any>({ startDate: "" });
  const prevEndDateRef = useRef<any>({ endDate: "" });

  const [intCardLoading, setIntCardLoading] = useState(true);

  useEffect(() => {
    const isDateChange =
      getJsonStringify(prevStartDateRef.current.startDate) !==
        getJsonStringify(startDate) ||
      getJsonStringify(prevEndDateRef.current.endDate) !==
        getJsonStringify(endDate);

    prevStartDateRef.current = { startDate };
    prevEndDateRef.current = { endDate };

    if (isDateChange) {
      dispatch(fetchEmissionAlerts({ startDate, endDate, timeZone }));
      closeLoader();
    }
  }, [dispatch, startDate, endDate]);

  const closeLoader = () => {
    setTimeout(() => {
      setIntCardLoading(false);
    }, 2000);
  };

  return (
    <>
      <nav className="absolute top-5 right-[0rem] text-white p-1 flex flex-col w-44 z-10">
        <NavLink
          to="/operations-overview"
          className={({ isActive }) =>
            isActive
              ? "text-orange bg-bgLightRed rounded-full flex items-center justify-between m-2 py-1 px-3"
              : "transition duration-300 bg-bgLightRed rounded-full flex items-center justify-between m-2 py-1 px-3"
          }
          onClick={() => {
            setAlertTypeTab("site");
            setActiveMarker(null);
            setShowOverlay(true);
          }}
        >
          <span>Emission Alert</span>
        </NavLink>
        <NavLink
          to="/AssetAlert"
          className={({ isActive }) =>
            isActive
              ? "text-orange bg-bgYellow rounded-full flex items-center justify-between m-2 py-1 px-3 font-bold"
              : "transition duration-300 bg-bgYellow rounded-full flex items-center justify-between m-2 py-1 px-3 font-bold"
          }
          onClick={() => {
            setAlertTypeTab("asset");
            setActiveMarker(null);
            setShowOverlay(true);
          }}
        >
          <span>Asset Alert</span>
        </NavLink>
      </nav>

      <div>
        <Routes>
          <Route
            path="/operations-overview"
            element={
              showOverlay && (
                <EmissionOverlay
                  setShowOverlay={setShowOverlay}
                  showOverlay={showOverlay}
                >
                  <div className="bg-bgColor600 text-textColor rounded-md m-1 box-shadow">
                    <SiteEmissionAlert
                      searchResults={searchResults}
                      startDate={startDate}
                      endDate={endDate}
                      searchText={searchText}
                      emissionMarker={emissionMarker}
                      customerIdValue={customerIdValue}
                      alertTypeResult={alertTypeResult}
                      emissionCurrState={emissionAlertState.data}
                      // setEmissionCurrState={setEmissionCurrState}
                    />
                  </div>
                </EmissionOverlay>
              )
            }
          />

          <Route
            path="/AssetAlert"
            element={
              showOverlay && (
                <EmissionOverlay
                  setShowOverlay={setShowOverlay}
                  showOverlay={showOverlay}
                >
                  <div className="rounded-md m-1 box-shadow bg-bgColor600 overflow-x-hidden">
                    <AssetAlerts
                      searchResults={searchResults}
                      startDate={startDate}
                      endDate={endDate}
                      searchText={searchText}
                      emissionMarker={emissionMarker}
                      customerIdValue={customerIdValue}
                      selectedKeys={selectedKeys}
                      activeMarker={activeMarker}
                    />
                  </div>
                </EmissionOverlay>
              )
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default OperationsOverviewMenu;
