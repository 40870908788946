import React, { useEffect, useRef } from 'react';
import Select from 'react-select';
import './CustomSelect.css';
import selectArrow from "../../assets/img/select_arrow.png";

const CustomSelect = (props: any) => {

    const { options = [], onChange, value } = props;
    let selectedOption: any = {};
    if (options && options != null && options.length > 0 && value && value != null) {
        selectedOption = options.find((option: any) => {
            return option.value === value.value
        });
    }
    const selectRef = useRef<any>(null);
    const [menuOpen, setMenuOpen] = React.useState(false);

    const selectClickHandler = () => {
        if (selectRef.current && !props.disableClick) {
            setMenuOpen(!menuOpen);
        }
    };
    const handleOptionSelect = (selectedOption:any) => {
        setMenuOpen(false);
        onChange(selectedOption);
      };

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (menuOpen && selectRef.current && !selectRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        }
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [menuOpen]);

    const getPlaceholderValue = () => {
        let placeholder = "";
        if (selectedOption && selectedOption.label) {
            placeholder = selectedOption.label;
        } else if (props.placeholderStr) {
            placeholder = props.placeholderStr;
        } else {
            placeholder = 'Select an option';
        }
        return placeholder;
    }

    const getPlaceholderIcon = () => {
        let icon: any = "";
        if (selectedOption && selectedOption.icon) {
            icon = selectedOption.icon;
        } else if (props.placeholderStr) {
            icon = props.placeholderIcon;
        }
        return icon && <img src={icon} alt={selectedOption.label} className="selected-icon" />;
    }

    return (
      <div className="custom-select" ref={selectRef}>
        <div className="selected-option" onClick={selectClickHandler}>
          {getPlaceholderIcon()}
          <div className="select-ValueContainer">
            {/* <div className="pt-1 css-1dimb5e-singleValue"> */}
              {getPlaceholderValue()}
            {/* </div> */}
          </div>
          <div className="select-IndicatorsContainer">
            <span className="select-indicatorSeparator"></span>
            <div className="select-indicatorContainer">
              <i className="pi pi-chevron-down text-textColor text-sm"></i>
            </div>
          </div>
        </div>
        <Select
          menuIsOpen={menuOpen}
          value={value}
          options={options}
          onChange={handleOptionSelect}
          isSearchable={false}
          components={{
            Option: CustomOption,
          }}
        />
      </div>
    );
};

const CustomOption = ({ innerProps, label, data, onChange }: any) => (
    <div {...innerProps} className="custom-option" onChange={onchange}>
        <img src={data.icon} alt={label} className="selected-icon-right-arrow" /><span className="whitespace-nowrap">{label}</span>
    </div>
);

export default CustomSelect;
