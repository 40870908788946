import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken, getRefreshToken } from "../../config/services/ApiServices";

export const fetchToken = createAsyncThunk(
  'Token/fetch',
  async ({ UserName, Password }: { UserName: any, Password: any }, { rejectWithValue }) => {
    try {
      const response = await getToken({ UserName, Password });
      return response;
    } catch (error : any) {
      // Attempt to use error.response.data if it exists, otherwise pass a generic error message
      const errorPayload: any = error.response && error.response.data ? error.response.data : { message: 'An unexpected error occurred' };
      return (errorPayload);
    }
  }
);

  export const fetchRefreshToken = createAsyncThunk('RefreshToken/fetch', async ({ refreshtoken }: { refreshtoken: any }) => {
    const response = await getRefreshToken({ refreshtoken }); 
    return response;
  })
  