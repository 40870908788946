import ax from '../api/AxiosConfig';
import { formatDate, formatDateOnly, getTimzeZoneForAPI } from '../../utils/CommonUtils';

export const getAssetNotes = async (timeRange: any, urlData: any) => {
  try {
    const payload = {
      "AssetId": urlData.AssetId,
      "ProductType": "Soofie",
      "DateFilterStartDate": timeRange.StartDate,
      "DateFilterEndDate": timeRange.EndTime,
      "CustomerId": urlData.CustomerId,
    }
    const response = await ax.post('/Emission/GetAssetNotes', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};


// export const getAssetNotes = async (timeRange: any, urlData: any) => {
//   try {
//     const payload = {
//       "AssetId": [urlData.AssetId],
//       "AssetType": "Soofie",
//       "ProductType": "Soofie",
//       "DateFilterStartDate": timeRange.StartDate,
//       "DateFilterEndDate": timeRange.EndTime,
//       "CustomerId": urlData.CustomerId,
//       "Fill": "SoofieDetails"
//     }
//     const response = await ax.post('/Note/GetAssetNotes', payload);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };
export const getSiteDetails = async (timeRange: any, urlData: any, timeZone: string) => {
  try {
    const payload = {
      "AssetId": urlData.AssetId,
      "StartDate": timeRange.StartDate,
      "EndTime": timeRange.EndTime,
      "CustomerId": urlData.CustomerId,
      "TimeZone": timeZone,
    }
    const response = await ax.post('/Emission/GetSiteDetails', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getSliderDataPoints = async (timeRange: any, urlData: any, timeZone: string) => {
  try {
    /*Notes : This APi will call, on load of the page, to Get all alarms details.
     for a specific time range(24hr - 365 days) for time slider - 96 data points
    */
    // let siteId = "a3494a32-ec5b-4855-a3de-488c348621bc";
    // let customerId = "eeb4e401-014b-4907-b237-4e646c8be3da";
    let startDate = timeRange.StartDate;
    let endDate = timeRange.EndTime;
    let queryParam = `siteId=${urlData.AssetId}&customerId=${urlData.CustomerId}&startDate=${startDate}&endDate=${endDate}&timeZone=${timeZone}`;
    const response = await ax.get(`/Emission/GetSiteAlertStatus?${queryParam}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getDeviceMaintenanceInfo = async (urlData: any, timeZone: string) => {
  try {
    const payload = {
      "AssetId": urlData.AssetId,
      "Customerid": urlData.CustomerId,
      "TimeZone": timeZone
    }
    const response = await ax.post('/Emission/GetDeviceMaintenanceInfo', payload)
    return response.data;
  } catch (error) {
    console.error('Error in Axios request:', error);
    throw error;
  }
};
export const getAssetLatestStatus = async (urlData: any, timeZone: string) => {
  try {
    /*Notes : This API is used to bind Soofie Data where there is no alert and time interval is > 24hrs.
              There is some specific logic for “Last CH4 Emission” need to check User Story for the logic/requirement.
              If CH4 Emission “LastCh4” value is Null, Then we need to show lastCH4Error value.
    */
    // let siteId = "aa2893c4-1edc-48f7-b7d9-fc0271764b58";
    // let customerId = "eeb4e401-014b-4907-b237-4e646c8be3da";
    let queryParam = `siteId=${urlData.AssetId}&customerId=${urlData.CustomerId}&timeZone=${timeZone}`;
    const response = await ax.get(`/Emission/GetLatestSiteDetails?${queryParam}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllAlertsForEachSlide = async (curObj: any, alertTimeRange: any, urlData: any, timeZone: string) => {
  try {
    /*Notes : This APi will call, on load of the page, to Get all alarms details.
     for a specific time range(24hr - 365 days) for time slider - 96 data points
    */
    // let siteId = "aa2893c4-1edc-48f7-b7d9-fc0271764b58";
    // let customerId = "eeb4e401-014b-4907-b237-4e646c8be3da";
    let startDate = alertTimeRange.startDate;
    //let startDate = "2023-09-20 17:15:00";
    //let endDate = "2023-09-22 00:00:00";
    let interval = alertTimeRange.interval;
    let queryParam = `siteId=${urlData.AssetId}&customerId=${urlData.CustomerId}&startDate=${startDate}&timeZone=${timeZone}&interval=${interval}&alertSent=${curObj.alertSent}&emission=${curObj.emission}`;
    const response = await ax.get(`/Emission/GetAllAlerts?${queryParam}`);
    return response.data
  } catch (error) {
    throw error;
  }
};

export const getMethane = async (timeRange: any, interval: number, urlData: any, timeZone: string) => {
  try {
    const payload = {
      "CustomerID": urlData.CustomerId,
      "StartTime": timeRange.StartDate,
      "EndTime": timeRange.EndTime,
      "AssetId": urlData.AssetId,
      "Uom": "Metric",
      "Duration": interval,
      "TimeZone": timeZone
    }
    const response = await ax.post('/Emission/GetMethaneTableData', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetSoofieTimeseriesData = async (timeRange: any, urlData: any, timeZone: string) => {
  try {
    let data = {
      "AssetId": urlData.AssetId,
      "StartTime": timeRange.StartDate,
      "EndTime": timeRange.EndTime,
      "CustomerID": urlData.CustomerId,
      "TimeZone": timeZone
    };
    const response = await ax.post(`/Emission/GetSoofieTimeseriesData`, data);
    return response.data;
  } catch (error) {
    console.error('Error in Axios request:', error);
    throw error;
  }
};

/* Operation overview dashboard API start */
export const getOpSiteDetails = async ({ startDate, endDate, timeZone }: { startDate: Date, endDate: Date, timeZone: string }) => {
  try {
    /*
    Fake API used for UI functional flow validation, This will be removed while integrating real API.
    */
    //const url = "/Emission/GetOverviewSiteDetails"

    /*    const payload = {
       "StartTime" : "10-12-2023 08:00",
       "EndTime" : "10-13-2023 23:59",
       "ProductType": "Soofie"
     } */
    const startDateString = formatDate(startDate)
    const endDateString = formatDate(endDate)
    const payload = {
      "StartTime": startDateString,
      "EndTime": endDateString,
      "ProductType": "Soofie",
      "TimeZone": timeZone
    }
    const response = await ax.post("/Emission/GetOverviewSiteDetails", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmissionAlerts = async ({ startDate, endDate, timeZone }: { startDate: Date, endDate: Date, timeZone: string }) => {
  try {
    const startDateString = formatDate(startDate);
    const endDateString = formatDate(endDate);

    let queryParam = `startDate=${startDateString}&endDate=${endDateString}&timeZone=${timeZone}`;
    const response = await ax.get(`/Emission/SiteEmissionAlerts?${queryParam}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAssetAlerts = async ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
  try {

    const startDateString = formatDateOnly(startDate)
    const endDateString = formatDateOnly(endDate)

    let queryParam = `runDate=${startDateString}&endDate=${endDateString}`;
    const response = await ax.get(`/Emission/GetAssetAlerts?${queryParam}`);
    return response.data;

  } catch (error) {
    throw error;
  }
};

export const getAssetHierarchy = async () => {
  try {
    const response = await ax.get("/Emission/GetCustomers");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getUserPreferences = async () => {
  try {
    const response = await ax.get("/Emission/GetUserSettings");
    return response.data;
  } catch (error) {
    throw error;
  }
};


/* Emission performance dashboard API */

export const getTotalEmissionsData = async ({ startDate, endDate, customerId = "", timeZone, siteIds = [], query = "", orderBy }: { startDate: Date, endDate: Date, customerId: string, timeZone: string, siteIds?: any, query?: any, orderBy: number }) => {
  try {
    const startDateString = formatDate(startDate);
    const endDateString = formatDate(endDate);
    const payload = {
      SiteIds: siteIds,
      CustomerId: customerId,
      StartTime: startDateString,
      EndTime: endDateString,
      TimeZone: timeZone,
      Query: query,
      OrderBy: orderBy
    };

    const response = await ax.post('/Emission/GetTotalEmissionsData', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGlobalSummaryView = async ({ endDate, customerId = "", timeZone, siteIds = [] }: { endDate: Date, customerId: string, timeZone: string, siteIds?: any }) => {
  const endDateString = formatDate(endDate);
  try {
    const payloadForGlobalSummary = {
      siteIds: siteIds,
      CustomerId: customerId,
      EndTime: endDateString,
      TimeZone: timeZone
    };

    const response = await ax.post('/Emission/GetGlobalSummaryView', payloadForGlobalSummary);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserDetails = async () => {
  try {
    const response = await ax.post("/Employee/GetUserDetails");
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getHeatMapData = async ({ siteId, siteName, customerId = "", customerName, region, startTime, endTime, units, comparison, threshold }: { siteId: string, siteName: string, customerId: string, customerName: string, region: string, startTime: Date, endTime: Date, units: string, comparison: string, threshold: string }) => {
  try {
    //const startDateString = formatDate(startTime);
    //const endDateString = formatDate(endTime);
    const payload = {
      SiteId: siteId,
      SiteName: siteName,
      CustomerId: customerId,
      CustomerName: customerName,
      StartTime: startTime,
      EndTime: endTime,
      Units: units,
      Comparison: comparison,
      Threshold: threshold
    };
    const response = await ax.post('/Emission/GetHeatMapData', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getProbableSource = async ({ startDate, endDate, customerId = "", timeZone, assetId = "" }: { startDate: Date, endDate: Date, customerId: string, timeZone: string, assetId?: string }) => {
  try {
    const startDateString = startDate;
    const endDateString = endDate;
    const payload = {
      AssetId: assetId,
      CustomerId: customerId,
      StartTime: startDateString,
      EndTime: endDateString,
      TimeZone: timeZone,
    };

    const response = await ax.post('/Emission/GetProbableSource', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSiteConfigurationHistory = async ({ startDate, endDate, customerId = "", timeZone, assetId = "" }: { startDate: Date, endDate: Date, customerId: string, timeZone: string, assetId?: string }) => {
  try {
    const startDateString = startDate;
    const endDateString = endDate;
    const payload = {
      AssetId: assetId,
      CustomerId: customerId,
      StartTime: startDateString,
      EndTime: endDateString,
      TimeZone: timeZone,
    };

    const response = await ax.post('/Emission/SiteConfigurationHistory', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWorkorderHistory = async ({ startDate, endDate, customerId = "", timeZone, assetId = "" }: { startDate: Date, endDate: Date, customerId: string, timeZone: string, assetId?: string }) => {
  try {
    const startDateString = startDate;
    const endDateString = endDate;
    const payload = {
      AssetId: assetId,
      CustomerId: customerId,
      StartTime: startDateString,
      EndTime: endDateString,
      TimeZone: timeZone,
    };

    const response = await ax.post('/Emission/WorkorderHistory', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPeriodicSurveys = async ({ startDate, endDate, customerId = "", timeZone, assetId = "" }: { startDate: Date, endDate: Date, customerId: string, timeZone: string, assetId?: string }) => {
  try {
    const startDateString = startDate;
    const endDateString = endDate;
    const payload = {
      AssetId: assetId,
      CustomerId: customerId,
      StartTime: startDateString,
      EndTime: endDateString,
      TimeZone: timeZone,
    };

    // const response = await ax.post('/Emission/PeriodicSurveys', payload);
    const response = await ax.post('/Emission/PeriodicSurveys', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWindRoseData = async ({ startDate, endDate, customerId = "", assetId = "" }: { startDate: Date, endDate: Date, customerId: string, assetId?: string }) => {
  try {
    let queryParam = `siteId=${assetId}&customerId=${customerId}&startDate=${startDate}&endDate=${endDate}`;
    const response = await ax.get(`/Emission/GetWindRoseData?${queryParam}`);
    return response.data;

  } catch (error) {
    throw error;
  }
};

export const getConfigurationTable = async ({ customerId = "", assetId = "" }: { customerId: string, assetId?: string }) => {
  try {
    const url = `/Emission/GetSiteConfigurationHistory/${customerId}/${assetId}`;
    const response = await ax.get(url);
    //console.log("response ", response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSiteMaintenanceData = async ({ startDate, endDate, customerId = "", assetId = "" }: { startDate: Date, endDate: Date, customerId: string, assetId?: string }) => {
  try {

    const response = await ax.get(`/Emission/GetSiteMaintenanceHistory/${customerId}/${assetId}?startDate=${startDate}&endDate=${endDate}`);
    return response.data;

  } catch (error) {
    throw error;
  }
}

export const saveMaintenanceData = async ({ siteId, custId, date, userName, deviceName, activity }: { siteId: string, custId: string, date: string, userName: string, deviceName: string, activity: string }) => {
  try {
    const response = await ax.post('/Emission/SiteMaintenanceHistory', {
      SiteId: siteId,
      CustomerId: custId,
      Date: date,
      UserName: userName,
      DeviceName: deviceName,
      Activity: activity
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSiteHistoryUptimeReport = async ({ startTime, endTime, customerId = "", isPercent, siteId = "" }: { startTime: Date, endTime: Date, customerId: string, isPercent: Boolean, siteId?: string }) => {

  try {
    const payload: any = {
      customerId: customerId,
      siteId: siteId,
      startTime: startTime,
      endTime: endTime,
      isPercent: isPercent,
    };

    const response = await ax.get('/Emission/GetSoofieUptime', { params: payload });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetSoofieUptimeExport = async ({ StartTime, EndTime, CustomerId = "", SiteId = "" }: { StartTime: Date, EndTime: Date, CustomerId: any, SiteId?: string }) => {

  try {
    const payload: any = {
      CustomerId: CustomerId,
      SiteId: SiteId,
      StartTime: StartTime,
      EndTime: EndTime,
    };

    const response = await ax.get('/Emission/GetSoofieUptimeExport', { params: payload });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetSoofieUptimeGlobal = async ({ startTime, endTime, customerId = "", siteIds = [], isPercent }: { startTime: Date, endTime: Date, customerId: any, siteIds?: any, isPercent: Boolean }) => {

  try {
    const payload: any = {
      customerId: customerId,
      siteIds: siteIds,
      startTime: startTime,
      endTime: endTime,
      isPercent: isPercent
    };

    const response = await ax.post('/Emission/GetSoofieUptimeGlobal', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetGroundDataDetails = async ({ startTime, endTime, customerId = "", siteIds = [], deviceId }: { startTime: Date, endTime: Date, customerId: any, siteIds?: any, deviceId: any }) => {

  try {
    const payload: any = {
      //customerId: customerId,
      //siteIds: siteIds,
      startDate: startTime,
      endDate: endTime,
      //deviceId: deviceId
    };

    const response = await ax.get(`/Emission/GetGroundDataDetails/${customerId}/${siteIds}/${deviceId}?startDate=${startTime}&endDate=${endTime}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetAsset = async ({ customerId = "" }: { customerId: any }) => {

  try {
    const url = `/EmissionCustomer/GetAssets/${customerId}`;
    // const url = `http://localhost:53189/api/EmissionCustomer/GetAssets/${customerId}`;
    const response = await ax.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getToken = async ({ UserName,Password }: { UserName: any,Password:any }) => {

  try {
    const payload: any = {
      UserName: UserName,
      Password:Password
    };

    const response = await ax.post('/Emission/Token', payload);
    // const response = await ax.post('/Emission/Token', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRefreshToken = async ({ refreshtoken }: { refreshtoken: any, }) => {

  try {
    const payload: any = {
      refreshtoken: refreshtoken,
    };

    const response = await ax.post('/Emission/RefreshToken', payload);
    // const response = await ax.post('/Emission/RefreshToken', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSiteReprocess = async ({customerIds,siteIds,startDate,endDate,}: { customerIds: any[]; siteIds: any[]; startDate: string;endDate: string;
}) => {
  try {
    const payload : any = {
      customerIds: customerIds,
      siteIds: siteIds,
      startDate: startDate,
      endDate: endDate,
    };

    const response = await ax.post('/Emission/GetSiteReprocess', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRegenerateBilling = async ({ CustomerIds, Month }: { CustomerIds: any[]; Month: string; }) => {
  try {
    const payload: any = {
      CustomerIds: CustomerIds,
      Month: Month,
    };

    const response = await ax.post('/Emission/ReGenerateSoofieBill', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};