import { Slice, createSlice } from "@reduxjs/toolkit";
import { fetchCustomerAsset } from "../thunk/SitesOverviewThunk";

const initialData: any = [];

const CustomerAssetChartSlice: Slice<any, {}, "CustomerAssetChartSlice"> = createSlice({
    name: "CustomerAssetChartSlice",
    initialState: { data: initialData, isLoading: false, error: "" },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchCustomerAsset.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchCustomerAsset.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        });
        builder.addCase(fetchCustomerAsset.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message ?? ""
        });
    }
})

export default CustomerAssetChartSlice
