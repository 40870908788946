import React, { useState, useEffect } from 'react';

const SortIcon: React.FC = () => (
    <svg
        className="h-4 inline cursor-pointer"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
    >
        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
    </svg>
);

// Interface for the API data structure
interface ApiDataItem {
    assetID: string;
    assetName: string; 
    methaneSetPoint: number | null;
    activeEmission4hrs: number | null;
    activeEmissionAlert_24: number | null;
    activeStandardAlert_24: number | null;
    standardAlertCount_24: number | null;
    averageEmission_24: number;
    observedAverageEmission_24: number;
    cumulativeEmission_24: number;
    observedCumulativeEmission_24: number;
    emissionAlertCount_24: number | null;
    max4hEmissionRate_24: number | null;
    methaneAlarmSetpoint_24: number | null;
    standardAlarmSetpoint_24: number | null;
    equipmentAlertCount_24: number;
    activeEquipmentAlert_24: string;
    averageEmission_7: number;
    observedAverageEmission_7: number;
    cumulativeEmission_7: number;
    observedCumulativeEmission_7: number;
    emissionAlertCount_7: number | null;
    standardAlertCount_7: number | null;
    max4hEmissionRate_7: number | null;
    equipmentAlertCount_7: number;
    averageEmission_30: number;
    observedAverageEmission_30: number;
    cumulativeEmission_30: number;
    observedCumulativeEmission_30: number;
    emissionAlertCount_30: number | null;
    standardAlertCount_30: number | null;
    max4hEmissionRate_30: number | null;
    equipmentAlertCount_30: number;
    averageEmission_365: number;
    observedAverageEmission_365: number;
    cumulativeEmission_365: number;
    observedCumulativeEmission_365: number;
    emissionAlertCount_365: number;
    standardAlertCount_365: number | null;
    max4hEmissionRate_365: number | null;
    equipmentAlertCount_365: number;
}




const GlobalSummaryView: React.FC<{ apiData: ApiDataItem[] | null, isTableLoading: boolean; isChartLoading: boolean; uom: string, customerId:string }> = ({ apiData, isTableLoading, isChartLoading, uom, customerId }) => {

    const [data, setData] = useState<ApiDataItem[]>(apiData ? apiData : []);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [emissionUnit, setEmissionsUnit] = useState("");
    const [cummulativeUnit, setCummulativeUnit] = useState("");


    useEffect(() => {
        setData(apiData || []);
        if (uom === "Imperial") {
            setEmissionsUnit("(MCF/day)");
            setCummulativeUnit("(MCF)");
        } else {
            setEmissionsUnit("(kg/hr)");
            setCummulativeUnit("(kg)");
        }
    }, [apiData, uom]);

    function getUniqueAlerts(alertString: string | undefined): string {
        if (!alertString) return '-';

        const values = alertString.split(',');
        const uniqueValues = Array.from(new Set(values));
        const result = uniqueValues.join(', ');

        return result.replace(/,(\s*)$/, '$1');
    }


    const handleSort = (columnName: string) => {
        setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));

        if (apiData) {
            const sortedData = [...apiData].sort((a: any, b: any) => {
                const aValue = a[columnName];
                const bValue = b[columnName];

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    const extractNumeric = (value: string) => {
                        const match = value.match(/\d+/);
                        return match ? parseInt(match[0], 10) : 0;
                    };

                    const aNumericPart = extractNumeric(aValue);
                    const bNumericPart = extractNumeric(bValue);

                    if (aNumericPart !== bNumericPart) {
                        return sortOrder === 'asc' ? aNumericPart - bNumericPart : bNumericPart - aNumericPart;
                    }

                    return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                } else {
                    return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
                }
            }) as ApiDataItem[];

            setData(sortedData);
        }
    };

    const customStandard = (n1: any, n2: any) => {
        try {
            if (n1 != null && n1 !== "" && !isNaN(n1)) {
                n1 = parseInt(n1);
            } else {
                n1 = 0;
            }

            if (n2 != null && n2 !== "" && !isNaN(n2)) {
                n2 = parseInt(n2);
            } else {
                n2 = 0;
            }

            return n1 + n2;
        } catch (error) {
            console.log("An error occurred:", error);
            return 0;
        }
    };

   

    const renderDataRows = (rowData: ApiDataItem, index: number) => {
        const url = `/overview?AssetId=${rowData.assetID}&CustomerId=${customerId}`;
        return (
            <tr key={index} className='text-center font-normal border border-gray-400' style={{ fontSize: "12px" }}>
                <td className='border border-solid border-gray-300 text-gray-600'><a href={url}>{rowData.assetName}</a></td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.methaneSetPoint !== null ? rowData.methaneSetPoint.toFixed(2) : '-'}</td>
                <td
                    className='border border-solid border-gray-300 text-gray-600'
                    style={{
                        background:
                            (rowData.activeEmissionAlert_24 === null && rowData.activeStandardAlert_24 === null) ? '#66FF66'
                                : (rowData.activeEmissionAlert_24 && rowData.activeEmissionAlert_24 > 100) ||
                                    (rowData.activeStandardAlert_24 && rowData.activeStandardAlert_24 > 100) ? '#ff7a7a'
                                    : 'transparent',
                        color:
                            (rowData.activeEmissionAlert_24 && rowData.activeEmissionAlert_24 > 100) ||
                                (rowData.activeStandardAlert_24 && rowData.activeStandardAlert_24 > 100)
                                ? 'black'
                                : 'inherit',
                    }}
                >
                    {rowData.activeEmissionAlert_24 !== null &&
                        rowData.activeEmissionAlert_24 !== undefined &&
                        rowData.activeStandardAlert_24 !== null &&
                        rowData.activeStandardAlert_24 !== undefined ? (
                        <>
                            {rowData.activeEmissionAlert_24}, {rowData.activeStandardAlert_24}
                        </>
                    ) : rowData.activeEmissionAlert_24 !== null &&
                        rowData.activeEmissionAlert_24 !== undefined ? (
                        <>{rowData.activeEmissionAlert_24}</>
                    ) : rowData.activeStandardAlert_24 !== null &&
                        rowData.activeStandardAlert_24 !== undefined ? (
                        <>{rowData.activeStandardAlert_24}</>
                    ) : (
                        <>-</>
                    )}
                </td>
                <td className='border border-solid border-gray-300 text-gray-600'>{customStandard(rowData.emissionAlertCount_24, rowData.standardAlertCount_24)}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{customStandard(rowData.emissionAlertCount_7, rowData.standardAlertCount_7)}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{customStandard(rowData.emissionAlertCount_30, rowData.standardAlertCount_30)}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{customStandard(rowData.emissionAlertCount_365, rowData.standardAlertCount_365)}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.cumulativeEmission_24 !== null ? rowData.cumulativeEmission_24.toFixed(2) : 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.cumulativeEmission_7 !== null ? rowData.cumulativeEmission_7.toFixed(2) : 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.cumulativeEmission_30 !== null ? rowData.cumulativeEmission_30.toFixed(2) : 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.cumulativeEmission_365 !== null ? rowData.cumulativeEmission_365.toFixed(2) : 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.averageEmission_24 !== null ? rowData.averageEmission_24.toFixed(2) : 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.averageEmission_7 !== null ? rowData.averageEmission_7.toFixed(2) : 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.averageEmission_30 !== null ? rowData.averageEmission_30.toFixed(2) : 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.averageEmission_365 !== null ? rowData.averageEmission_365.toFixed(2) : 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'
                    style={{ background: rowData.activeEquipmentAlert_24 === null || rowData.activeEquipmentAlert_24 === undefined || rowData.activeEquipmentAlert_24 === "-" || rowData.activeEquipmentAlert_24 === "" ? '#66FF66' : 'transparent' }}
                >{getUniqueAlerts(rowData.activeEquipmentAlert_24)}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.equipmentAlertCount_24 ?? 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.equipmentAlertCount_7 ?? 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.equipmentAlertCount_30 ?? 0}</td>
                <td className='border border-solid border-gray-300 text-gray-600'>{rowData.equipmentAlertCount_365 ?? 0}</td>
            </tr>
        );
    };

    const calculateColumnSum = (columnNames: string[]) => {
        const totalSum = columnNames.reduce((sum, columnName) => {
            return sum + data.reduce((rowSum: any, row: any) => {
                const value = row[columnName];
                return typeof value === 'number' ? rowSum + value : rowSum;
            }, 0);
        }, 0);
        return totalSum.toLocaleString('en-US', { maximumFractionDigits: 2 });
    };

    const renderFooterRow = () => {
        return (
            <tr className='bg-gray-600 text-white font-semibold' style={{ border: "1px solid #dddddd", fontSize: "12px" }}>
                <td style={{ border: "1px solid #dddddd" }}></td>
                <td style={{ border: "1px solid #dddddd" }}></td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}>Total</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['emissionAlertCount_24'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['emissionAlertCount_7'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['emissionAlertCount_30'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['emissionAlertCount_365'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['cumulativeEmission_24'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['cumulativeEmission_7'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['cumulativeEmission_30'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['cumulativeEmission_365'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['averageEmission_24'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['averageEmission_7'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['averageEmission_30'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(['averageEmission_365'])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}>Total</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(["equipmentAlertCount_24"])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(["equipmentAlertCount_7"])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(["equipmentAlertCount_30"])}</td>
                <td className='text-center p-1' style={{ border: "1px solid #dddddd" }}> {calculateColumnSum(["equipmentAlertCount_365"])}</td>
            </tr>
        );
    };

    return (
        <div className='p-2'>
            {isChartLoading || isTableLoading ? (
                <>
                    <div className="flex justify-center items-center" style={{paddingLeft:"10%"}}>
                        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500" ></div>
                    </div>
                    <div className="flex justify-center items-center text-sm">
                        Loading Data...
                    </div>
                </>) : (
                <>
                    {apiData && apiData.length > 0 ? (
                        <div style={{ overflowY: "auto", height: "45vh", marginTop: "3%" }}>
                            <table className="table-bordered" style={{ border: "1px solid #dddddd" }}>
                                <colgroup span={4}></colgroup>
                                <thead className="text-white border border-gray-400 sticky top-0 z-2 border-collapse bg-cyan-900" style={{ border: "1px solid #dddddd", fontSize: "14px" }}>
                                    <tr>
                                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center', width: '10%', border: "1px solid #dddddd", padding: "5px" }} className='font-bold'>Site Name<div className='text-right mt-3' onClick={() => handleSort("assetName")}><SortIcon /></div></th>
                                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center', border: "1px solid #dddddd", padding: "5px" }} className='font-semibold'>Methane Alerts SetPoint<div className='text-right' onClick={() => handleSort("methaneSetPoint")}><SortIcon /></div></th>
                                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center', border: "1px solid #dddddd", padding: "5px" }} className='font-semibold'>Active Emission Alerts<div className='text-right' onClick={() => handleSort("activeEmission4hrs")}><SortIcon /></div></th>
                                        <th colSpan={4} style={{ textAlign: 'center', width: '20%', border: "1px solid #dddddd", padding: "5px" }} className='font-semibold'>Emission Alerts Count</th>
                                        <th colSpan={4} style={{ textAlign: 'center', width: '20%', border: "1px solid #dddddd", padding: "5px" }} className='font-semibold'>Cumulative Emission <span>{cummulativeUnit}</span></th>
                                        <th colSpan={4} style={{ textAlign: 'center', width: '20%', border: "1px solid #dddddd", padding: "5px" }} className='font-semibold'>Average Emission <span>{emissionUnit}</span></th>
                                        <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center', border: "1px solid #dddddd", padding: "5px" }} className='font-semibold'>Asset Alerts<div className='text-right' onClick={() => handleSort("activeEquipmentAlert_24")}><SortIcon /></div></th>
                                        <th colSpan={4} style={{ textAlign: 'center', width: '20%', border: "1px solid #dddddd", padding: "5px" }} className='font-semibold'>Equipment Alerts Count</th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>24 Hour<div className='text-right' onClick={() => handleSort("emissionAlertCount_24")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>7 Day <div className='text-right' onClick={() => handleSort("emissionAlertCount_7")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>30 Day<div className='text-right' onClick={() => handleSort("emissionAlertCount_30")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>365 Day<div className='text-right' onClick={() => handleSort("emissionAlertCount_365")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>24 Hour<div className='text-right' onClick={() => handleSort("cumulativeEmission_24")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>7 Day<div className='text-right' onClick={() => handleSort("cumulativeEmission_7")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>30 Day<div className='text-right' onClick={() => handleSort("cumulativeEmission_30")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>365 Day<div className='text-right' onClick={() => handleSort("cumulativeEmission_265")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>24 Hour<div className='text-right' onClick={() => handleSort("averageEmission_24")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>7 Day<div className='text-right' onClick={() => handleSort("averageEmission_7")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>30 Day<div className='text-right' onClick={() => handleSort("averageEmission_30")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>365 Day<div className='text-right' onClick={() => handleSort("averageEmission_365")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>24 Hour<div className='text-right' onClick={() => handleSort("equipmentAlertCount_24")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>7 Day<div className='text-right' onClick={() => handleSort("equipmentAlertCount_7")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>30 Day<div className='text-right' onClick={() => handleSort("equipmentAlertCount_30")}><SortIcon /></div></th>
                                        <th scope="col" className='font-normal border border-gray-400 border-collapse'>365 Day<div className='text-right' onClick={() => handleSort("equipmentAlertCount_365")}><SortIcon /></div></th>
                                    </tr>
                                </thead>
                                <tbody className='text-gray'>
                                    {apiData && data.map((rowData, index) => renderDataRows(rowData, index))}
                                </tbody>
                                <tfoot className='bg-gray-600 text-white' style={{ position: 'sticky', bottom: 0 }}>
                                    {renderFooterRow()}
                                </tfoot>
                            </table>
                        </div>
                    ) : (
                        <div style={{ height: "20vh" }}>
                            <div className="flex justify-center items-center text-sm" style={{paddingLeft:"12%"}}>
                                No data available.
                            </div>
                        </div>
                    )}
                </>
            )}


        </div>
    );
};

export default GlobalSummaryView;
