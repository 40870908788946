import SampleNotes from "./sample_data/sample_customer.json";
import ax from "../../api/AxiosConfig";
import axios from "axios";
const GetCustomers = async (timeRange: any) => {
    const StartDate = timeRange.StartDate;
    const EndTime = timeRange.EndTime;
    /*return await new Promise((resolve) => {
        resolve(SampleNotes);
    });*/
    return await ax.get(
        process.env.REACT_APP_API_ENDPOINT +
        "/Emission/GetCustomerList"
    )
        .then(async (res: any) => {
            return res?.data
        }).catch((err: any) => {
            console.log("GetCustomers", err)
        })
};


const PutCustomers = async (post_data: any) => {
    // return await ax
    //   .put(
    //     process.env.REACT_APP_AURA_ENDPOINT +
    //       "Sites/GetOperationSummaryDetails",post_data

    //   )
    //   .then(async (res: any) => {})
}
const DeleteCustomers = async (id: any) => {
    // return await ax
    //   .put(
    //     process.env.REACT_APP_AURA_ENDPOINT +
    //       "Sites/GetOperationSummaryDetails",post_data

    //   )
    //   .then(async (res: any) => {})
}

const GetCustomerByID = async (id: any) => {
    /*return await new Promise((resolve) => {
        var smp = SampleNotes?.filter((trm: any) => id == trm?.id)
        resolve(smp);
    });*/
    return await ax
        .get(
            process.env.REACT_APP_API_ENDPOINT +
            "/Emission/GetCustomerDetailById/" + id
        )
        .then(async (res: any) => {
            return res?.data
        })
        .catch((err: any) => {
            console.log(err)
        })
}

const CustomerAdd = (data: any) => {
    let customer_logo = data?.customer_logo;
    let customer_logo_name = data?.customer_logo_name;
    let customer_name = data?.customer_name;
    let domain_name = data?.domain_names;
    let sales_contact_name = data?.sales_contact_name;
    let customer_status = data?.customer_status;
    let emission_unit = data?.emission_unit;
    let uom = data?.uom;

    let post_data = {
        customer_logo: customer_logo,
        customer_logo_name: customer_logo_name,
        customer_name: customer_name,
        domain_name: domain_name,
        sales_contact_name: sales_contact_name,
        customer_status: customer_status,
        emission_unit: emission_unit,
        uom: uom,
    }
    return [];
    // return await ax
    //   .post(
    //     process.env.REACT_APP_AURA_ENDPOINT +
    //       "Sites/GetOperationSummaryDetails",post_data

    //   )
    //   .then(async (res: any) => {})

}
const CustomerServices = {
    GetCustomers,
    PutCustomers,
    GetCustomerByID
};
export default CustomerServices;
