// Example of a button using Tailwind CSS classes
import React,{useState} from 'react';
import logoutImage from '../assets/img/logout.png';
import { Dialog } from 'primereact/dialog';

const LogoutButton = () => {
  const [visible, setVisible] = useState(false);
    
  const handleClick = () => {
    setVisible(true);
   
  };

  const handleLogout = () => {
      // Remove local storage data
      localStorage.removeItem('isLoggedIn'); 
      localStorage.removeItem('refresh_token'); 
      localStorage.removeItem('access_token');      
      localStorage.removeItem('at'); 
      // Redirect to login page
      window.location.href = '/login';
    // Add your logout logic here
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  

  return (
    <>
    <button onClick={handleClick}>
      <img src={logoutImage} alt="Logout"/>
    </button>
    <Dialog header="Confirm Log out" visible={visible} onHide={handleCancel} className='w-4 '  headerStyle={{ color: '#D4D4D4'}}>
        <p className='mt-4 mb-4 text-base font-medium'>Are you sure you want to logout from the portal ?</p>
        <div className="flex justify-end space-x-2">
        <button
          onClick={handleLogout}
          className="bg-blue-900 text-base text-white px-4 py-2 rounded"
        >
          Logout
        </button>
        <button
          onClick={handleCancel}
          className="bg-white text-base text-slate-500 px-4 py-2 rounded  border border-slate-500 "
        >
          Cancel
        </button>
      </div>
      </Dialog>
    </>
  );
};

export default LogoutButton;
