// import React from "react";
// import {useLoadScript } from "@react-google-maps/api";
import { AlertTabMapProps, AssetAlertsMarkerType, OperationSite } from "../Types/OperationsSite";
// import { appInsights } from "../../utils/appInsights";
// import MapComponent from "./MapComponent";

export function assetToSite(asset: AssetAlertsMarkerType) {
  const site: any = {};
  site.id = asset.siteID;
  site.thingNo = asset.siteName;
  site.customerID = asset.customerId;
  site.customerName = asset.customerName;
  site.longitude = asset.longitude;
  site.latitude = asset.latitude;
  site.isAlert = false;
  site.isAssetAlert = true;
  site.siteName = asset.siteName;
  site.alertDetails = asset.alertDetails
  return site as OperationSite;
}
function OperartionOverviewGoogleMaps() {}
// function OperartionOverviewGoogleMaps(
//   { alertTypeTab, startDate, endDate, searchResults, emissionMarker, setEmissionMarker,
//     customerIdValue, setCustomerIdValue, selectedKeys, alertTypeResult, activeMarker, setActiveMarker, selectedAlertTypeOptions }: AlertTabMapProps & {
//       searchResults: OperationSite[], emissionMarker: string, setEmissionMarker: React.Dispatch<React.SetStateAction<any | null>>, customerIdValue: string,
//       setCustomerIdValue: React.Dispatch<React.SetStateAction<string>>, selectedKeys: any[] | null, alertTypeResult: any,
//       activeMarker: String | null, setActiveMarker: React.Dispatch<React.SetStateAction<string | null>>, selectedAlertTypeOptions:any | null
//     }) {
//   const { isLoaded } = useLoadScript({
//     googleMapsApiKey: "AIzaSyCdsXnMXcV_vTCPjTd5tHGCi2Oed3hVKEQ" // Add your API key
//   });

//   return isLoaded ? (
//     <MapComponent
//       alertTypeTab={alertTypeTab}
//       startDate={startDate}
//       endDate={endDate}
//       searchResults={searchResults}
//       emissionMarker={emissionMarker}
//       setEmissionMarker={setEmissionMarker}
//       customerIdValue={customerIdValue}
//       setCustomerIdValue={setCustomerIdValue}
//       selectedKeys={selectedKeys}
//       alertTypeResult={alertTypeResult}
//       activeMarker={activeMarker}
//       setActiveMarker={setActiveMarker}
//       selectedAlertTypeOptions={selectedAlertTypeOptions}
//     />
//   ) : null;
// }

// try {
//   const pageViewTelemetry = {
//     name: "operations-overview",
//     uri: window.location.href,
//   };

//   let currentUserDetail: any = localStorage.getItem('user');
//   appInsights.trackPageView(pageViewTelemetry);
//   appInsights.setAuthenticatedUserContext(currentUserDetail?.userName);
//   let startTime = Date.now();
//   // Log user spent time metric when the application is about to unload
//   window.addEventListener('beforeunload', () => {
//     const endTime = Date.now();
//     const durationInSeconds = Math.floor((endTime - startTime) / 1000);
//     appInsights.trackMetric({
//       name: 'operations-overview-UserSpentTime',
//       average: durationInSeconds,
//     });
//   });
// } catch (error) {
//   // console.error('Error with Azure AppInsights:', error);
// }

export default OperartionOverviewGoogleMaps