import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProbableSource, getSiteMaintenanceData, getWindRoseData, saveMaintenanceData, GetSoofieUptimeExport } from "../../config/services/ApiServices";
import { getSiteConfigurationHistory, getWorkorderHistory, getConfigurationTable, getSiteHistoryUptimeReport } from "../../config/services/ApiServices";
import { GetSoofieUptimeGlobal, GetAsset, GetGroundDataDetails,getSiteReprocess,getRegenerateBilling } from "../../config/services/ApiServices";

export const fetchProbableSourceChart = createAsyncThunk('probablesource-chart/fetch', async ({ startDate, endDate, customerId, timeZone, assetId }: { startDate: Date, endDate: Date, customerId: string, timeZone?: any, assetId?: string, }) => {
  const response = await getProbableSource({ startDate, endDate, customerId, timeZone, assetId });
  return response;
})

export const fetchSiteConfigurationHistory = createAsyncThunk('SiteConfigurationHistory-table/fetch', async ({ startDate, endDate, customerId, timeZone, assetId }: { startDate: Date, endDate: Date, customerId: string, timeZone?: any, assetId?: string, }) => {
  const response = await getSiteConfigurationHistory({ startDate, endDate, customerId, timeZone, assetId });
  return response;
})

export const fetchWorkorderHistory = createAsyncThunk('WorkorderHistory-table/fetch', async ({ startDate, endDate, customerId, timeZone, assetId }: { startDate: Date, endDate: Date, customerId: string, timeZone?: any, assetId?: string, }) => {
  const response = await getWorkorderHistory({ startDate, endDate, customerId, timeZone, assetId });
  return response;
})

export const fetchWindRoseData = createAsyncThunk('windRose-chart/fetch', async ({ startDate, endDate, customerId, assetId }: { startDate: Date, endDate: Date, customerId: string, assetId?: string, }) => {
  const response = await getWindRoseData({ startDate, endDate, customerId, assetId });
  return response;
})

export const fetchConfigurationTable = createAsyncThunk('ConfigurationTable-table/fetch', async ({ customerId, assetId }: { customerId: string, assetId?: string, }) => {
  const response = await getConfigurationTable({ customerId, assetId });
  // return jsonData;
  return response;
})

export const fetchSiteMaintenanceTable = createAsyncThunk('maintenace-table/fetch', async ({ startDate, endDate, customerId, assetId }: { startDate: Date, endDate: Date, customerId: string, assetId: string, }) => {
  const response = await getSiteMaintenanceData({ startDate, endDate, customerId, assetId });
  return response;
})

export const saveMaintenanceDataThunk = createAsyncThunk(
  'maintenance-table/saveMaintenanceData',
  async ({ siteId, custId, date, userName, deviceName, activity }: { siteId: string, custId: string, date: string, userName: string, deviceName: string, activity: string }) => {
    try {
      const response = await saveMaintenanceData({ siteId, custId, date, userName, deviceName, activity });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSiteHistoryUptimeReport = createAsyncThunk('siteHistoryUptimeReport-table/fetch', async ({ startTime, endTime, customerId, isPercent, siteId }: { startTime: Date, endTime: Date, customerId: string, isPercent?: any, siteId?: string, }) => {
  const response = await getSiteHistoryUptimeReport({ customerId, siteId, startTime, endTime, isPercent });
  return response;
})

export const fetchSoofieUptimeExport = createAsyncThunk('SoofieUptimeExport-download/fetch', async ({ StartTime, EndTime, CustomerId, SiteId }: { StartTime: Date, EndTime: Date, CustomerId: any, SiteId?: any, }) => {
  const response = await GetSoofieUptimeExport({ CustomerId, SiteId, StartTime, EndTime });
  return response;
})

export const fetchSoofieUptimeGlobal = createAsyncThunk('SoofieUptimeGlobal-table/fetch', async ({ startTime, endTime, customerId, isPercent, siteIds }: { startTime: Date, endTime: Date, customerId: string, isPercent?: any, siteIds?: any, }) => {
  const response = await GetSoofieUptimeGlobal({ customerId, siteIds, startTime, endTime, isPercent });

  return response;
})

export const fetchGroundDataDetail = createAsyncThunk('GroundDataDetail-table/fetch', async ({ startTime, endTime, customerId, deviceId, siteIds }: { startTime: Date, endTime: Date, customerId: string, deviceId?: any, siteIds?: any, }) => {
  const response = await GetGroundDataDetails({ customerId, siteIds, startTime, endTime, deviceId });

  return response;
})

export const fetchCustomerAsset = createAsyncThunk('GetAsset-table/fetch', async ({ customerId }: { customerId: string }) => {
  const response = await GetAsset({ customerId });
  return response;
})

export const fetchSiteReprocess = createAsyncThunk('SiteReprocess-table/fetch', async ({ customerIds,siteIds,startDate,endDate }: 
  { customerIds: any[]; siteIds: any[]; startDate: string;endDate: string; }) => {

  const response = await getSiteReprocess({ customerIds, siteIds, startDate, endDate });

  return response;
})

export const fetchRegenerateBilling = createAsyncThunk('RegenerateBilling-table/fetch', async ({ CustomerIds,Month }: 
  { CustomerIds: any[];  Month: string;}) => {

  const response = await getRegenerateBilling({ CustomerIds,Month });

  return response;
})