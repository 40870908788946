import { Slice, createSlice } from "@reduxjs/toolkit";
import { fetchEmissionChart } from "../thunk/EmissionperformanceThunk";

const initialData: any = [];

const BarchartSlice: Slice<any, {}, "BarchartSlice"> = createSlice({
    name: "BarchartSlice",
    initialState: { data: initialData, isLoading: false, error: "" },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchEmissionChart.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchEmissionChart.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        });
        builder.addCase(fetchEmissionChart.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message ?? ""
        });
    }
})

export default BarchartSlice
