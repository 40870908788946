// ArrowIcon.tsx
import React from "react";

const UpArrowIcon = () => {
    return (
        <div className="flex-shrink-0 cursor-pointer">
            <svg
                className=" h-4 inline "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
            >
                <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Z" />

            </svg>
        </div>
    );
};


const FilterIcon = () => {
    return (
        <div className="flex-shrink-0 cursor-pointer">
            <svg
                className="h-4 inline"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
            >
                <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
            </svg>
        </div>
    );
};

const DownloadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
        </svg>
    );
};

interface EditIconProps {
    onClick: () => void;
}

const EditIcon: React.FC<EditIconProps> = ({ onClick }) => {
    return (
        <svg
            className="feather feather-edit"
            fill="none" height="17" stroke="currentColor"
            strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="2" viewBox="0 0 24 24" width="24"
            xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={{ cursor: 'pointer' }}>
            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
    );
};

const PlusIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#000"
            className="bi bi-plus"
            viewBox="0 0 16 16"
        >
            <path
                fillRule="evenodd"
                d="M8 0a.75.75 0 0 1 .75.75V7h6.25a.75.75 0 1 1 0 1.5H8V15.25a.75.75 0 1 1-1.5 0V8H.75a.75.75 0 1 1 0-1.5H6V.75A.75.75 0 0 1 6 0z"
            />
        </svg>
    );
};

interface ToggleSwitchProps {
    isChecked: boolean;
    onChange: () => void;
    label?: string; // Add a label prop
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isChecked, onChange, label }) => {
    return (
        <label className="switch">
            <input type="checkbox" checked={isChecked} onChange={onChange} />
            <span className="slider"></span>
            {label && <span className="label">{label}</span>}
        </label>
    );
};

const SearchIcon = () => {
    return (
        <svg width="15" height="19" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                fill="#838588"
            />
        </svg>
    );
};




export { UpArrowIcon, FilterIcon, DownloadIcon, EditIcon, ToggleSwitch, PlusIcon, SearchIcon };
