import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Tree } from 'primereact/tree';
import moment from 'moment';
import { TabMenu } from 'primereact/tabmenu';
import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { getTimzeZoneForAPI, getValuesFromQueryString } from '../../utils/CommonUtils';
import TimecardDatepicker from './components/DateTimePicker';
import "./analytics.css";
import Notes from './Notes';
import NoteServices from '../../config/services/notes-services/notes-services';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import GobalUptimeReportTable from './reports/globalUptimeReport/GlobalUptimeReportTable';
import { hasPermission } from '../../utils/permissions';
import { getActiveRole } from '../../utils/role';

const Analytics = () => {
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [CustomerOption, SetCustomerOption] = useState<any>([]);
  const [HierOption, SetHierOption] = useState<any>([]);
  const [SiteOption, SetSiteOption] = useState<any>([]);
  const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>([]);
  const [HierOptionSelected, SetHierOptionSelected] = useState<any>([]);
  const [SiteOptionSelected, SetSiteOptionSelected] = useState<any>([]);
  const [enabFilterPopup, SetEnabFilterPopup] = useState(false);
  const [enableFilterStatus, SetEnableFilterStatus] = useState(false);
  const [advFilValue, setAdvFilValue] = useState<any>(null);
  const [advFilOption, setAdvFilOption] = useState<any>([]);
  const [currentData, SetCurrentData] = useState<any>([]);
  const [popUpFilterData, SetPopUpFilterData] = useState<any>([]);
  const [findLabels, SetFindLabels] = useState<any>([]);
  const [findLabels2, SetFindLabels2] = useState<any>([]);
  const [selectUptimeReport, setSelectUptimeReport] = useState<any>([]);
  const { search } = useLocation();

  const urlData = getValuesFromQueryString(search);
  const items = [
    { label: "Notes" },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  useEffect(() => {
    NoteServices.getCustomerHier().then((res) => {
      let temp_cust: any = [];
      if (res.length > 0) {
        //temp_cust.push(res[0])
        res.map((cust_val: any) => {
          temp_cust.push({
            value: cust_val.customerId
            , ...cust_val
          })
        })
      }

      // temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
      // const index = temp_cust.findIndex((item: any) => item.label === "All Customers");

      // // Move the object to the first position if found
      // if (index !== -1) {
      //   const allCustomerObject = temp_cust.splice(index, 1)[0];
      //   temp_cust.unshift(allCustomerObject);
      // }

      if (temp_cust?.length == 1) {
        SetCustomerOptionSelected({ 'label': temp_cust[0]?.label, "value": temp_cust[0]?.customerId })
      } else {
        temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
        const index = temp_cust.findIndex((item: any) => item?.label === "All Customers");
        //Move the object to the first position if found
        if (index !== -1) {
          const allCustomerObject = temp_cust.splice(index, 1)[0];
          temp_cust.unshift(allCustomerObject);
        }
      }
      SetCustomerOption(temp_cust)
    })
    SetCustomerOptionSelected({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
  }, [])

  let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
  interface DateTimeType {
    StartDate: String;
    EndTime: String;
  }
  const initialTimeRange: DateTimeType = {
    StartDate: moment
      .tz(timezoneForAPI)
      .subtract(1, "days")
      .format("MM-DD-YYYY HH:mm"),
    EndTime: moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm"),
  };

  const [timeRange, setTimeRange] = useState<DateTimeType>(initialTimeRange);
  const updatedSelectedDate = useCallback<Function>(
    (dt: any) => {
      setTimeRange(dt);
    },
    [timeRange]
  );
  const options = [
    { value: 'notes', label: 'Notes' },
    //{ value: 'reports', label: 'Reports' }
  ]

  if(hasPermission(getActiveRole(userPrefstate.data), 'uptime-report')){
    options.push({ value: 'reports', label: 'Reports' });
  }
  const handleOptionSelect = (e: any) => {
    if (e.value === 'notes') {
      return;
    }
    SetSiteOption([]);
    SetHierOptionSelected([]);
    SetSiteOptionSelected([]);
    SetHierOption([]);
    SetCustomerOptionSelected(e);
    SetFindLabels2([])
    setAdvFilValue([])
    SetEnableFilterStatus(false)
    setSelectUptimeReport(e);
    if (e?.children?.length > 0) {
      let temp_hier: any = []
      e?.children?.map((cust_val: any, index: any) => {
        temp_hier.push({
          value: index + 1
          , ...cust_val
        })
      });
      SetHierOption(temp_hier);
    }
  }
  const handleOptionSelectHier = (e: any) => {
    SetHierOptionSelected(e);
    if (e?.children?.length > 0) {
      let temp_hier: any = []
      e?.children?.map((cust_val: any) => {
        temp_hier.push({
          value: cust_val.customerId
          , ...cust_val
        })
      });
      SetSiteOption(temp_hier);
    }
  }

  const handleOptionSelectSite = (e: any) => {
    SetSiteOptionSelected(e);
  }
  const headerContent = (
    <>
      <div className='font-semibold' style={{ marginBottom: '15px', fontSize: '14px' }}>
        <i className='pi pi-align-left font-semibold' style={{ fontSize: '14px' }}></i>&nbsp;&nbsp;
        Advanced Filtering
      </div>
      <hr style={{ marginLeft: '-24px', marginRight: '-56px' }} />
      <div className='header_cus_bre_cmb mt-2'>
        <span style={{ textDecoration: 'underline', fontWeight: '500' }}>Customers</span> \ <span style={{ textDecoration: 'underline' }}>{CustomerOptionSelected?.label}</span>
      </div>
    </>
  )
  const footerContent = (
    <div>
      <button
        className=""
        onClick={() => {
          SetEnabFilterPopup(false)
          SetEnableFilterStatus(false)
          //setAdvFilValue([])
        }}
        style={{ textDecoration: 'underline' }}
      >
        Close
      </button>&nbsp;&nbsp;
      <button
        className="btn_yllw_blk font-bold py-2 px-4 rounded"
        onClick={() => {
          SetEnabFilterPopup(false)
          SetEnableFilterStatus(true)
          if (findLabels?.length > 0) {
            SetFindLabels2(findLabels)
          }
        }}
      >
        Apply
      </button>
    </div>
  );

  const handleShowPopup = () => {
    SetEnabFilterPopup(true)
    const filDAta = CustomerOption.filter((itm: any) => itm.customerId == CustomerOptionSelected.value)
    const flattenedData = flattenTree(filDAta[0], null, 0);
    setAdvFilOption([flattenedData])
  }

  const flattenTree = (node: any, parentId: any, index: any) => {
    const key: any = parentId ? `${parentId}-${index}` : `${index}`;
    const flattenedNode: any = {
      key,
      label: node?.label,
      children: [],
      data: node?.siteId,
    };

    if (node.children && node.children.length > 0) {
      node.children.forEach((child: any, i: any) => {
        const childNode: any = flattenTree(child, key, i);
        flattenedNode.children.push(childNode);
      });
    }
    return flattenedNode;
  };

  const handleFiterSelectionChange = (event: any) => {
    setAdvFilValue(event.value)
    SetEnableFilterStatus(false)
    const filteredResult = Object.keys(event?.value)?.length > 0 ? getCheckedNodes(advFilOption, event.value) : [];
    if (filteredResult?.length != 0) {
      // Extracting labels from the labels array
      let labelValues = filteredResult.map((item: any) => item.label);
      SetFindLabels(labelValues)
      // Filtering data based on labels
      let filteredData = currentData?.filter((item: any) => labelValues.includes(item.siteName));

      SetPopUpFilterData(filteredData)
    }
  };

  function getCheckedNodes(array: any, filterObject: any) {
    const checkedNodes: any = [];

    function recursiveCheck(node: any) {
      const nodeKey = node.key;
      const filterInfo = filterObject[nodeKey];

      if (filterInfo && filterInfo.checked) {
        checkedNodes.push({
          key: nodeKey,
          label: node.label,
          data: node.data
        });
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach((child: any) => {
          recursiveCheck(child);
        });
      }
    }

    array.forEach((rootNode: any) => {
      recursiveCheck(rootNode);
    });

    return checkedNodes;
  }

  useEffect(() => {
    if (selectUptimeReport?.value === "reports") {
      navigate('/global');
    }
  }, [selectUptimeReport]);

  return (
    <>
      <div className="bg-bgColor300 min-h-screen">
        <div className="header-layout-analytics pl-1 bg-bgColor300 text-textColor">
          <header className="body-font w-full">
            <div className="op-header-panel-analytics">
              <div className="flex gap-28">
                {/* Select OPtion for reports and notes START */}
                <div
                  className="custom-select custom_dropdown_notes"
                  style={{ marginTop: "10px" }}
                >
                  {/* Dropdown div  */}
                  <div className="dropdown-icon  bg-bgColor300 text-textColor">
                    <span className="aftericon">
                      <svg
                        width="40"
                        height="34"
                        viewBox="5 0 40 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="30"
                          height="30"
                          rx="7"
                          x="2"
                          y="2"
                          fill="#131F2E"
                        ></rect>
                        <path
                          d="M18.5 14.5H24.75V19.5C24.75 20.2031 25.2969 20.75 26 20.75H31V32C31 33.4062 29.8672 34.5 28.5 34.5H18.5C17.0938 34.5 16 33.4062 16 32V17C16 15.6328 17.0938 14.5 18.5 14.5ZM26 14.5L31 19.5H26V14.5ZM24.4375 24.1875C24.4375 23.6797 24.0078 23.25 23.5 23.25C22.9531 23.25 22.5625 23.6797 22.5625 24.1875V31.0625C22.5625 31.6094 22.9531 32 23.5 32C24.0078 32 24.4375 31.6094 24.4375 31.0625V24.1875ZM27.875 26.6875C27.875 26.1797 27.4453 25.75 26.9375 25.75C26.3906 25.75 26 26.1797 26 26.6875V31.0625C26 31.6094 26.3906 32 26.9375 32C27.4453 32 27.875 31.6094 27.875 31.0625V26.6875ZM21 29.1875C21 28.6797 20.5703 28.25 20.0625 28.25C19.5156 28.25 19.125 28.6797 19.125 29.1875V31.0625C19.125 31.6094 19.5156 32 20.0625 32C20.5703 32 21 31.6094 21 31.0625V29.1875Z"
                          fill="#4A90A8"
                        ></path>
                      </svg>
                    </span>
                    <Select
                      // menuIsOpen={menuOpen}
                      defaultValue={{ value: "notes", label: "Notes" }}
                      // value={CustomerOptionSelected}
                      options={options}
                      onChange={handleOptionSelect}
                      isSearchable={false}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                          color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                          zIndex: 10,
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="flex ">
                  {/* Calender div  */}
                  <TimecardDatepicker
                    pushSelectedDate={updatedSelectedDate}
                    timeSeriesRange={""}
                    timeRange={timeRange}
                  />
                  &nbsp; &nbsp;
                  {/* All Customer Dropdown  div  */}
                  <div className="custom_dropdown_notes dropdown-icon">
                    {/* style={{display:"none"}} */}
                    <span className="aftericon">
                      <i className="pi pi-map-marker" />
                    </span>
                    <Select
                      // menuIsOpen={menuOpen}
                      placeholder="Customers"
                      value={CustomerOptionSelected}
                      defaultValue={{ label: "All Customers", value: "0" }}
                      options={CustomerOption}
                      onChange={handleOptionSelect}
                      isSearchable={false}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                          color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                          zIndex: 10,
                        }),
                      }}
                    />
                  </div>
                  {/* Hierarchy dropdown div  */}
                  <div className="custom_dropdown_notes">
                    <button
                      type="button"
                      onClick={() => handleShowPopup()}
                      disabled={
                        CustomerOptionSelected?.label == "All Customers"
                          ? true
                          : false
                      }
                      className={
                        CustomerOptionSelected?.label == "All Customers"
                          ? "notes_adv_fil_disabled_btn py-1 px-2 rounded"
                          : "btn_yllw_blk py-1 px-2 rounded"
                      }
                    >
                      <i className="pi pi-align-left"></i>&nbsp;&nbsp; Advanced
                      Filtering
                    </button>
                  </div>
                  <div
                    className="custom_dropdown_notes dropdown-icon"
                    style={{ display: "none" }}
                  >
                    <span className="aftericon">
                      <i className="pi pi-map-marker" />
                    </span>
                    <Select
                      placeholder={"Hierarchy"}
                      // menuIsOpen={menuOpen}
                      // defaultValue={{ label: "Hierarchy", value: "hierarchy" }}
                      value={HierOptionSelected}
                      options={HierOption}
                      onChange={handleOptionSelectHier}
                      isSearchable={false}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                          color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                          zIndex: 10,
                        }),
                      }}
                    />
                  </div>
                  <div
                    className="custom_dropdown_notes dropdown-icon"
                    style={{ display: "none" }}
                  >
                    <span className="aftericon">
                      <i className="pi pi-map-marker" />
                    </span>
                    <Select
                      // menuIsOpen={menuOpen}
                      placeholder={"Sites"}
                      value={SiteOptionSelected}
                      defaultValue={{ label: "Sites", value: "sites" }}
                      options={SiteOption}
                      onChange={handleOptionSelectSite}
                      isSearchable={false}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                          color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                          zIndex: 10,
                        }),
                      }}
                    />
                  </div>
                  {/* Search filter div  */}
                  <div className="ml-3">
                    <div className="p-0.5 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center">
                      <div className="text-white p-1 hover:cursor-pointer">
                        <svg
                          width="15"
                          height="19"
                          viewBox="0 0 15 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                            fill="#838588"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        className="w-52 text-sm bg-transparent focus:outline-none"
                        placeholder="Search customers, sites, or issues"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        // onKeyUp={handleKeyUp}
                        // onFocus={handleFocus}
                      />
                    </div>
                  </div>
                </div>
                {/* Select OPtion for reports and notes END */}
              </div>
            </div>
          </header>
        </div>
        <div
          className="body-tab-selection-layout rounded-tl-lg rounded-tr-md rounded-bl-sm rounded-br-sm bg-bgColor200"
          style={{ width: "100%" }}
        ></div>
        <div className="pg-center-tabs-analytics bg-bgColor600">
          <TabMenu
            model={items}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          />
          {activeIndex == 0 && (
            <div style={{ padding: "0 20px" }}>
              <Notes
                setTimeRange={setTimeRange}
                timeRange={timeRange}
                chosenCustomer={findLabels2}
                customerOption={CustomerOptionSelected?.label}
                searchedCustomer={CustomerOptionSelected?.value}
                searchTerm={searchTerm}
                SiteOptionSelected={SiteOptionSelected}
              ></Notes>
            </div>
          )}
        </div>

        {selectUptimeReport?.value === "reports" && (
          <NavLink to="/analytics">
            <GobalUptimeReportTable timeRange={timeRange} />
          </NavLink>
        )}
      </div>

      <Dialog
        header={headerContent}
        visible={enabFilterPopup}
        style={{ width: "50vw" }}
        onHide={() => SetEnabFilterPopup(false)}
        footer={footerContent}
      >
        <p className="m-0">
          <Tree
            value={advFilOption}
            selectionMode="checkbox"
            selectionKeys={advFilValue}
            onSelectionChange={(e) => handleFiterSelectionChange(e)}
            className="w-full md:w-30rem"
          />
        </p>
      </Dialog>
    </>
  );
}


export default Analytics;