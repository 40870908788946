import { createSlice } from "@reduxjs/toolkit";
import { saveMaintenanceDataThunk } from "../thunk/SitesOverviewThunk";

const initialState = {
  isLoading: false,
  error: "",
};

const SaveSiteMaintenanceTable = createSlice({
  name: "SaveSiteMaintenanceTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveMaintenanceDataThunk.pending, (state) => {
      state.isLoading = true;
      state.error = ""; 
    });
    builder.addCase(saveMaintenanceDataThunk.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(saveMaintenanceDataThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "Failed to save maintenance data.";
    });
  },
});

export default SaveSiteMaintenanceTable;
