import React, { useEffect, useState } from 'react';
import championX_icon from "../assets/img/Logo.png";
import username_icon from "../assets/img/username_icon.png";
import password_icon from "../assets/img/password_icon.png";
import './LoginScreen.css';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from "../store";
import { fetchToken } from '../store/thunk/LoginThunk';
import { useNavigate } from "react-router-dom";
import detexImage from '../assets/img/Detex360.png';

const LoginScreen: React.FC = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [passwordError, setPasswordError] = useState('');

    const [remainingAttempts, setRemainingAttempts] = useState(10);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const loginState = useSelector((state: any) => state.loginState);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailInput = e.target.value;
        setEmail(emailInput);
        //console.log("emailInput", emailInput);
        if(emailInput === '') {
            setEmailError('Plesae Enter Email Address.');
        }else{
            setEmailError('');
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(fetchToken({ UserName: email, Password: password }));
    };

    useEffect(() => {
        if (loginState.data.isAuthenticated && loginState.data.token.access_token) {
            localStorage.setItem('access_token', loginState.data.token.access_token);
            localStorage.setItem('refresh_token', loginState.data.token.refresh_token);
            navigate(`/?at=${loginState.data.token.access_token}`);
            window.location.reload();
        }
    }, [loginState]);


    useEffect(() => {
        if (typeof loginState?.data === 'string') {
             // Reset errors at the beginning
        setEmailError('');
        setPasswordError('');
        if (loginState?.data === 'Invalid User.User Not Found.')
        {
            setEmailError('This User Id may be incorrect. Make sure you typed it correctly. Otherwise, contact your administrator.');
        }else if (loginState?.data === "Your Account Is Locked.")
        {
            setPasswordError('User Account Locked. Please contact your administrator.');
        } else if (loginState?.data?.startsWith('Your given password is incorrect and Invalid Login. You have remaining attempts left:' )) {
            // Extract the number of remaining attempts from the message
            const attemptsLeft = parseInt(loginState?.data.split(' ').pop(), 10);
            setRemainingAttempts(attemptsLeft);
    
            if (attemptsLeft < 0) {
                setPasswordError('User Account Locked. Please contact your administrator.');
            } else {
                setPasswordError(`Invalid login attempts.`);
            }
        }   
    }else {
        // If loginState.data is not a string, reset errors
        setEmailError('');
        setPasswordError('');
    }
    }, [loginState,remainingAttempts]);
   
    const disabledIcon = () => (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.35938 6.13281C8.11719 4.80469 10.3047 3.75 13 3.75C16.125 3.75 18.6641 5.19531 20.5 6.91406C22.3359 8.59375 23.5469 10.625 24.1328 12.0312C24.25 12.3438 24.25 12.6953 24.1328 13.0078C23.625 14.2578 22.5703 16.0547 21.0078 17.6172L25.1094 20.8594C25.5391 21.1719 25.6172 21.7578 25.2656 22.1484C24.9531 22.5781 24.3672 22.6562 23.9766 22.3047L0.851562 4.17969C0.421875 3.86719 0.34375 3.28125 0.695312 2.89062C1.00781 2.46094 1.59375 2.38281 1.98438 2.73438L6.35938 6.13281ZM7.88281 7.34375L9.67969 8.75C10.5781 7.96875 11.7109 7.5 13 7.5C15.7344 7.5 18 9.76562 18 12.5C18 13.3594 17.7656 14.1406 17.4141 14.8047L19.5234 16.4453C20.8516 15.1172 21.7891 13.5938 22.2969 12.5C21.75 11.3281 20.7344 9.6875 19.2109 8.28125C17.6094 6.79688 15.5391 5.625 13 5.625C11.0078 5.625 9.28906 6.32812 7.88281 7.34375ZM15.8906 13.6328C16.0469 13.2812 16.125 12.8906 16.125 12.5C16.125 10.7812 14.7188 9.375 13 9.375C12.9609 9.375 12.9219 9.375 12.8828 9.375C12.9609 9.60938 13 9.80469 13 10C13 10.4297 12.8828 10.7812 12.7266 11.1328L15.8906 13.6328ZM16.2812 18.7109L17.9219 20C16.4766 20.7812 14.8359 21.25 13 21.25C9.83594 21.25 7.29688 19.8438 5.46094 18.125C3.625 16.4062 2.41406 14.375 1.82812 13.0078C1.71094 12.6953 1.71094 12.3438 1.82812 12.0312C2.21875 11.1328 2.84375 10 3.74219 8.82812L5.1875 10C4.48438 10.8984 3.97656 11.7969 3.66406 12.5C4.21094 13.6719 5.22656 15.3516 6.75 16.7578C8.35156 18.2422 10.4219 19.375 13 19.375C14.1719 19.375 15.2656 19.1406 16.2812 18.7109ZM8 12.5C8 12.4219 8 12.3047 8 12.1875L10.1875 13.9062C10.5781 14.7266 11.3594 15.3516 12.2969 15.5469L14.4844 17.3047C14.0156 17.4219 13.5078 17.5 12.9609 17.5C10.2266 17.5 7.96094 15.2734 7.96094 12.5H8Z" fill="#424548"/>
        </svg>
      );
      
      const enabledIcon = () => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 12.5C7 9.76562 9.22656 7.5 12 7.5C14.7344 7.5 17 9.76562 17 12.5C17 15.2734 14.7344 17.5 12 17.5C9.22656 17.5 7 15.2734 7 12.5ZM12 15.625C13.7188 15.625 15.125 14.2578 15.125 12.5C15.125 10.7812 13.7188 9.375 12 9.375C11.9609 9.375 11.9219 9.375 11.8828 9.375C11.9609 9.60938 12 9.80469 12 10C12 11.4062 10.8672 12.5 9.5 12.5C9.26562 12.5 9.07031 12.5 8.875 12.4219C8.875 12.4609 8.875 12.5 8.875 12.5C8.875 14.2578 10.2422 15.625 12 15.625ZM4.46094 6.91406C6.29688 5.19531 8.83594 3.75 12 3.75C15.125 3.75 17.6641 5.19531 19.5 6.91406C21.3359 8.59375 22.5469 10.625 23.1328 12.0312C23.25 12.3438 23.25 12.6953 23.1328 13.0078C22.5469 14.375 21.3359 16.4062 19.5 18.125C17.6641 19.8438 15.125 21.25 12 21.25C8.83594 21.25 6.29688 19.8438 4.46094 18.125C2.625 16.4062 1.41406 14.375 0.828125 13.0078C0.710938 12.6953 0.710938 12.3438 0.828125 12.0312C1.41406 10.625 2.625 8.59375 4.46094 6.91406ZM12 5.625C9.42188 5.625 7.35156 6.79688 5.75 8.28125C4.22656 9.6875 3.21094 11.3281 2.66406 12.5C3.21094 13.6719 4.22656 15.3516 5.75 16.7578C7.35156 18.2422 9.42188 19.375 12 19.375C14.5391 19.375 16.6094 18.2422 18.2109 16.7578C19.7344 15.3516 20.75 13.6719 21.2969 12.5C20.75 11.3281 19.7344 9.6875 18.2109 8.28125C16.6094 6.79688 14.5391 5.625 12 5.625Z" fill="#424548"/>
        </svg>
      );
      

    return (
    <div className="flex h-screen"> 
        <div className="flex-1 flex items-center justify-center" style={{background: "#F9B505" }}>
            <img src={detexImage} alt="Logo" className="md:w-1/2 lg:w-1/3"/>
        </div>
    <div className="flex-1 flex flex-col justify-center items-start  " style={{background:"#1e293b"}}>
        <div className='pl-7'>
            <img src={championX_icon} width="150px" alt="Path" ></img>
            <h1 className="text-2xl font-bold mt-4 text-left text-white">Log in </h1>
            <h2 className="mt-2 text-left text-white">Welcome back! Please enter your details</h2>
            <form onSubmit={handleSubmit} className="w-full max-w-xs mt-4 text-left">

            <div className="mb-4 relative">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-white">User name</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                     <img src={username_icon} className="h-5 w-10 text-gray-500" alt="User" />
                    </div>
                    <input
                    className='w-full h-11 pl-10 pr-3 py-2 leading-tight text-white bg-gray-800 border border-solid border-gray-700 rounded'
                    type="email"
                    id="email"  
                    value={email}
                    placeholder='Enter user name'
                    onChange={handleEmailChange}  
                    data-testid = {`loginuser`} 
                    />     
                </div>
                {emailError && <p className="text-red-500 text-xs italic">{emailError}</p>}
            </div>
                {/* <div className="mb-4 relative">
                    <label 
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700 text-white">Password </label>
                    <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <img src={password_icon} className="h-5 w-10 text-gray-500" alt="User" />
                    </div>
                    <input
                     className='w-full h-11 pl-10 pr-3 py-2 leading-tight text-white bg-gray-800 border border-solid border-gray-700 border rounded '
                        type="password"
                        id="password"
                        value={password}
                        placeholder='Enter password'
                        onChange={handlePasswordChange}
                    />
                    </div>
                    {passwordError && <p className="text-red-500 text-xs italic">{passwordError}</p>}
                </div> */}
                <div className="mb-4 relative">
  <label 
  htmlFor="password"
  className="block text-sm font-medium text-gray-700 text-white">Password</label>
  <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <img src={password_icon} className="h-5 w-10 text-gray-500" alt="User" />
      </div>
      <input
        className='w-full h-11 pl-10 pr-3 py-2 leading-tight text-white bg-gray-800 border border-solid border-gray-700 border rounded'
        type={showPassword ? "text" : "password"}
        id="password"
        value={password}
        placeholder='Enter password'
        onChange={handlePasswordChange}        
        data-testid = {`loginpassword`} 
      />
      <div 
          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
      >
          {showPassword ? enabledIcon() : disabledIcon() }
      </div>
  </div>
  {passwordError && <p className="text-red-500 text-xs italic">{passwordError}</p>}
</div>

                <button 
                    type="submit"
                    className={`w-full font-bold py-2 px-4 rounded-[30px] ${email && password ? 'bg-[#F9B505] hover:bg-[#D99E04] text-black}' : 'bg-[#2D3748] text-gray-900'}`}      
                    data-testid = {`loginsubmit`} >Sign in </button>
            </form>
            </div>
      </div>
    </div>
    );
};

export default LoginScreen;