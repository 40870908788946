export const initTroublStatsTableOverview: any[] = [
    {
        "id": "101",
        "date": "31-Mar-2024",
        "daployed": "8293",
        "offline": "393",
        "failure": "66",
    },
    {
        "id": "102",
        "date": "31-Mar-2024",
        "daployed": "8296",
        "offline": "396",
        "failure": "66",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "8295",
        "offline": "395",
        "failure": "65",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "8294",
        "offline": "394",
        "failure": "64",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "8293",
        "offline": "393",
        "failure": "63",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "8292",
        "offline": "392",
        "failure": "62",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "8291",
        "offline": "391",
        "failure": "61",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "82912",
        "offline": "3912",
        "failure": "612",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "82913",
        "offline": "3913",
        "failure": "613",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "82914",
        "offline": "3914",
        "failure": "614",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "82915",
        "offline": "3915",
        "failure": "615",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "82916",
        "offline": "3916",
        "failure": "616",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "82917",
        "offline": "3917",
        "failure": "617",
    },
    {
        "id": "103",
        "date": "31-Mar-2024",
        "daployed": "82918",
        "offline": "3918",
        "failure": "618",
    }
];
export const initTroublStatsTableReports: any[] = [
    {
        "id": "101",
        "operator": "Alta",
        "region": "Midlan",
        "site": "Site1",
        "soofieDevice": "Device1",
        "issueType": "NO Polygon",
        "serialNumber": "6612345",
    },
    {
        "id": "102",
        "operator": "Alta2",
        "region": "Midlan2",
        "site": "Site2",
        "soofieDevice": "Device2",
        "issueType": "NO Polygon",
        "serialNumber": "6612312",
    },
    {
        "id": "103",
        "operator": "Alta3",
        "region": "Midlan3",
        "site": "Site3",
        "soofieDevice": "Device3",
        "issueType": "NO Polygon",
        "serialNumber": "6612333",
    },
    {
        "id": "104",
        "operator": "Alta4",
        "region": "Midlan4",
        "site": "Site4",
        "soofieDevice": "Device4",
        "issueType": "NO Polygon",
        "serialNumber": "6612344",
    },
];

export const initTroublStatsIssueTypes: any[] = [
    {
        "id": "101",
        "code": "no-wind",
        "name": "No Wind",
    },{
        "id": "102",
        "code": "no-data",
        "name": "No Data",
    },{
        "id": "103",
        "code": "bad-methane",
        "name": "Bad Methane",
    },{
        "id": "104",
        "code": "bad-temperature",
        "name": "Bad Temperature",
    },{
        "id": "105",
        "code": "fan-failed",
        "name": "Fan Failed",
    },{
        "id": "106",
        "code": "no-polygon-configured",
        "name": "No Polygon configured",
    },{
        "id": "107",
        "code": "no-alert-config",
        "name": "No Alert configured",
    },{
        "id": "108",
        "code": "soofie-tilted",
        "name": "Soofie Tilted",
    },{
        "id": "109",
        "code": "soofie-moved",
        "name": "Soofie Moved",
    },{
        "id": "110",
        "code": "cell-issue",
        "name": "Cell Issue",
    },{
        "id": "111",
        "code": "wind-blocked",
        "name": "Wind Blocked",
    }      
];

export const enterpriseRedirectUrl: any[] = [
    {
        "user-summary": "/User/Summary",
        "channel-support": "/Home/Channels",
        "asset-summary": "/Asset/Summary",
        "soofie-uptime": "/home/SOOFIEUptime",
        "alarm-summary": "/AlarmInfo/AlarmInfoSummary",
        "download-data": "/home/DownloadSoofie",
    }
];


export type Permission = 'Site-Emission-analysis' |'Site-Emission-alerts' |'uptime-report' |'troubleshooting-stats' |'billing-reports' |'emission-performance' | 'note-edit' | 'note-add' 
| 'note-delete' |'linkback' | 'note-view' | 'note-customerName' |'help' | 'download-data' |'soofie-uptime'  |'channels-support'  |'user-summary'  |'asset-summary'  |'alarm-summary' | 'ground-data';

export type Role = 'superAdmin' | 'globalAdmin' | 'readOnly' | 'Administrator' | 'Analyst' | 'Corporate' | 'Limited' | 'isDelegatedGlobalAdmin' | 'isDelegatedCustomerAdmin';

// Define roles and their permissions
export const rolePermissions = {    
    superAdmin: ['billing-reports', 'ground-data', 'uptime-report', 'note-add', 'alarm-summary', 'Site-Emission-alerts', 'asset-summary', 'channels-support', 'note-delete', 'download-data', 'note-edit', 'emission-performance', 'help', 'linkback', 'note-view', 'soofie-uptime', 'troubleshooting-stats', 'user-summary', 'Site-Emission-analysis', 'note-customerName'],
    globalAdmin: ['ground-data', 'uptime-report', 'note-add', 'alarm-summary', 'Site-Emission-alerts', 'asset-summary', 'channels-support', 'note-delete', 'download-data', 'note-edit', 'emission-performance', 'help', 'linkback', 'note-view', 'soofie-uptime', 'troubleshooting-stats', 'user-summary', 'Site-Emission-analysis', 'note-customerName'],
    readOnly: ['ground-data', 'uptime-report', 'alarm-summary', 'Site-Emission-alerts', 'asset-summary', 'channels-support', 'emission-performance', 'help', 'linkback', 'note-view', 'soofie-uptime', 'user-summary', 'Site-Emission-analysis'],
    isDelegatedGlobalAdmin: ['ground-data', 'uptime-report', 'note-add', 'alarm-summary', 'Site-Emission-alerts', 'asset-summary', 'channels-support', 'note-delete', 'download-data', 'note-edit', 'emission-performance', 'help', 'linkback', 'note-view', 'soofie-uptime', 'user-summary', 'Site-Emission-analysis'],    
    Administrator: ['uptime-report', 'note-add', 'alarm-summary', 'Site-Emission-alerts', 'asset-summary', 'channels-support', 'note-delete', 'download-data', 'note-edit', 'emission-performance', 'help', 'linkback', 'note-view', 'soofie-uptime', 'user-summary', 'Site-Emission-analysis'],
    Corporate: ['uptime-report', 'note-add', 'alarm-summary', 'Site-Emission-alerts', 'asset-summary', 'channels-support', 'emission-performance', 'help', 'linkback', 'note-view', 'soofie-uptime', 'Site-Emission-analysis'],
    Analyst: ['uptime-report', 'note-add', 'alarm-summary', 'Site-Emission-alerts', 'asset-summary', 'channels-support', 'note-delete', 'download-data', 'note-edit', 'emission-performance', 'help', 'linkback', 'note-view', 'soofie-uptime', 'Site-Emission-analysis'],
    Limited: ['alarm-summary', 'Site-Emission-alerts', 'asset-summary', 'channels-support', 'help', 'linkback', 'note-view', 'Site-Emission-analysis'],
    isDelegatedCustomerAdmin: ['uptime-report', 'note-add', 'alarm-summary', 'Site-Emission-alerts', 'asset-summary', 'channels-support', 'note-delete', 'download-data', 'note-edit', 'emission-performance', 'help', 'linkback', 'note-view', 'soofie-uptime', 'troubleshooting-stats', 'user-summary', 'Site-Emission-analysis'],
};
