import React,{useEffect, useState, useCallback} from "react";
import Select from 'react-select';
import moment from 'moment';
import NoteServices from '../../../../config/services/notes-services/notes-services';
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';
import { DownloadIcon ,PlusIcon ,RefreshIcon,FilterIcon,SearchIcon} from "../../../../assets/svgIcons/svgIcons";
import SiteReprocesstable from "./SiteReprocesstable";
import { getTimzeZoneForAPI, getValuesFromQueryString } from '../../../../utils/CommonUtils';
import { useSelector } from 'react-redux';
import SiteReprocessDateTimePicker from "../../components/SiteReprocessDateTimePicker";

const SiteReprocess = () => {

  const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>({ label: 'Select Customer', value: '' });
  const [CustomerOption, SetCustomerOption] = useState<any>([]);
  const [SiteOptionSelected, SetSiteOptionSelected] = useState<any>([]);
  const [SiteOption, SetSiteOption] = useState<any>([]);
  const [HierOption, SetHierOption] = useState<any>([]);
  const [HierOptionSelected, SetHierOptionSelected] = useState<any>([]);
  const [findLabels2, SetFindLabels2] = useState<any>([]);
  const [advFilValue, setAdvFilValue] = useState<any>(null);
  const [enableFilterStatus, SetEnableFilterStatus] = useState(false);
  const [enabFilterPopup, SetEnabFilterPopup] = useState(false);
  const [advFilOption, setAdvFilOption] = useState<any>([]);
  const [findSiteId, SetFindSiteId] = useState<any>([]);
  const [findLabels, SetFindLabels] = useState<any>([]);
  const [currentData, SetCurrentData] = useState<any>([]);
  
  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);

  interface DateTimeType {
    StartDate: String;
    EndTime: String;
  }
  const initialTimeRange: DateTimeType = {
    StartDate: moment
      .tz(timezoneForAPI)
      .subtract(1, "days")
      .format("MM-DD-YYYY HH:mm"),
    EndTime: moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm"),
  };

  const [timeRange, setTimeRange] = useState<DateTimeType>(initialTimeRange);
  const updatedSelectedDate = useCallback<Function>(
    (dt: any) => {
      setTimeRange(dt);
    },
    [timeRange]
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [downloadData, setDownloadData] = useState<any>([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const outerCustomerId = CustomerOptionSelected?.value || '';

  const [customerIds, setCustomerIds] = useState<string[]>(outerCustomerId ? [outerCustomerId] : []);

  useEffect(() => {
    setCustomerIds(outerCustomerId ? [outerCustomerId] : []);
}, [outerCustomerId]);

  const handleOptionSelect = (e: any) => {
    SetSiteOption([]);
    SetHierOptionSelected([]);
    SetSiteOptionSelected([]);
    SetHierOption([]);
    SetCustomerOptionSelected(e);
    SetFindLabels2([])
    setAdvFilValue([])
    SetEnableFilterStatus(false)
    if (e?.children?.length > 0) {
        let temp_hier: any = []
        e?.children?.map((cust_val: any, index: any) => {
            temp_hier.push({
                value: index + 1
                , ...cust_val
            })
        });
        SetHierOption(temp_hier);

    }
}


const handleShowPopup = () => {
  SetEnabFilterPopup(true)
  const filDAta = CustomerOption.filter((itm: any) => itm.customerId == CustomerOptionSelected.value)
  const flattenedData = flattenTree(filDAta[0], null, 0);
  setAdvFilOption([flattenedData])
}

const flattenTree = (node: any, parentId: any, index: any) => {
  const key: any = parentId ? `${parentId}-${index}` : `${index}`;
  const flattenedNode: any = {
      key,
      label: node?.label,
      children: [],
      data: node?.siteId,
  };

  if (node.children && node.children.length > 0) {
      node.children.forEach((child: any, i: any) => {
          const childNode: any = flattenTree(child, key, i);
          flattenedNode.children.push(childNode);
      });
  }
  return flattenedNode;
};


const handleFiterSelectionChange = (event: any) => {
  setAdvFilValue(event.value)
  SetEnableFilterStatus(false)
  const filteredResult = Object.keys(event?.value)?.length > 0 ? getCheckedNodes(advFilOption, event.value) : [];
  if (filteredResult?.length != 0) {
      // Extracting labels from the labels array
      let labelValues = filteredResult.map((item: any) => item.label);
      let SiteIdValues = filteredResult.map((item: any) => item.data);
      
      SetFindSiteId(SiteIdValues)
      SetFindLabels(labelValues)
      // Filtering data based on labels
      let filteredData = currentData?.filter((item: any) => labelValues.includes(item.siteName));
  }else{
      SetFindSiteId([]);
      SetFindLabels([]);
  }
};

function getCheckedNodes(array: any, filterObject: any) {
  const checkedNodes: any = [];

  function recursiveCheck(node: any) {
      const nodeKey = node.key;
      const filterInfo = filterObject[nodeKey];

      
      if (filterInfo && filterInfo.checked) {
          checkedNodes.push({
              key: nodeKey,
              label: node.label,
              data: node.data
          });
      }

      if (node.children && node.children.length > 0) {
          node.children.forEach((child: any) => {
              recursiveCheck(child);
          });
      }
  }

  array.forEach((rootNode: any) => {
      recursiveCheck(rootNode);
  });

  return checkedNodes;
}



const headerContent = (
  <>
      <div className='font-semibold' style={{ marginBottom: '15px', fontSize: '14px' }}>
          <i className='pi pi-align-left font-semibold' style={{ fontSize: '14px' }}></i>&nbsp;&nbsp;
          Advanced Filtering
      </div>
      <hr style={{ marginLeft: '-24px', marginRight: '-56px' }} />
      <div className='header_cus_bre_cmb mt-2'>
          <span style={{ textDecoration: 'underline', fontWeight: '500' }}>Customers</span> \ <span style={{ textDecoration: 'underline' }}>{CustomerOptionSelected?.label}</span>
      </div>
  </>
)

const footerContent = (
  <div>
      <button
          className=""
          onClick={() => {
              SetEnabFilterPopup(false)
              SetEnableFilterStatus(false)
              setAdvFilValue([])
              SetFindSiteId([])
              SetFindLabels([])
              
          }}
          style={{ textDecoration: 'underline' }}
      >
          Close
      </button>&nbsp;&nbsp;
      <button
          className="btn_yllw_blk font-bold py-2 px-4 rounded"
          onClick={() => {
              SetEnabFilterPopup(false)
              SetEnableFilterStatus(true)
              if (findLabels?.length > 0) {
                  SetFindLabels2(findLabels)
              }
          }}
      >
          Apply
      </button>
  </div>
);

  useEffect(() => {
    NoteServices.getCustomerHier().then((res: any) => {

        let temp_cust: any = []; //{ 'label': 'Select Customer', 'value': '' }
        if (res.length > 0) {
            //temp_cust.push(res[0])
            res.map((cust_val: any) => {
                temp_cust.push({
                    value: cust_val.customerId
                    , ...cust_val
                })
            })
        }      

        if (temp_cust?.length >= 1) {
            SetCustomerOptionSelected({ 'label': temp_cust[0]?.label, "value": temp_cust[0]?.customerId })
        } else {
            temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
            // Change for Select Cus ddl
            const index = temp_cust.findIndex((item: any) => item?.label === "Select Customer");
            //Move the object to the first position if found
            if (index !== -1) {
                const allCustomerObject = temp_cust.splice(index, 1)[0];
                temp_cust.unshift(allCustomerObject);
            }
        }

        SetCustomerOption(temp_cust)

    })
    SetCustomerOptionSelected({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'Select Customer', 'parentId': 0, 'siteId': "" })
   //SetCustomerOptionSelected({ label: 'Select Customer', value: '' });
}
, [])

const handleDownload = () => {
    const headers = ['Customer', 'Status', 'Site Count', 'From Date', 'To Date', 'Requested On', 'Requested By'];
    const csvRows = [
      headers.join(','),
      ...downloadData.map((item : any) => [
        item.customerName,
        item.status,
        item.sitesCount,
        item.fromDate,
        item.toDate,
        item.createdOn,
        item.createdBy
      ].join(','))
    ];

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'site-reprocess-data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleReprocess = () => {
    // Toggle the refreshTable state to trigger the table refresh in the child component
    setRefreshTable(prev => !prev);
  };

  
  return (
  <>
  <div className="tett table_card">
       <div className="flex justify-between items-center w-full mb-4">
                      <div className='flex place-items-start flex-wrap'>
                            <div className="relative mr-2 w-[121px] h-[40px] p-0.5  mt-1 rounded-md flex items-center">
                                <button type="button" className="btn_yllw_blk py-1 px-2 rounded flex items-center space-x-2" onClick={handleReprocess}>
                                Reprocess
                                <RefreshIcon className="relative -top-0" />
                                </button>
                              </div>
                              <div className="relative mr-2  w-[80px] h-[40px] p-0.5  mt-1 rounded-md flex items-center">
                              <button type="button" className=" py-1 px-2 rounded border" style={{ borderColor: 'hsl(0, 0%, 80%)' }}>     
                                <span className="mr-2">New</span>
                                  <PlusIcon />
                                </button>
                              </div>
                              <div className="relative w-[126px] h-[40px] p-0.5  mt-1 rounded-md flex items-center">
                                <div className='custom_dropdown_notes dropdown-icon' >
                                    <Select
                                        placeholder="Customers"
                                        value={CustomerOptionSelected}
                                        defaultValue={{ label: "All Customers", value: "0" }}
                                        options={CustomerOption}
                                        onChange={handleOptionSelect}
                                        isSearchable={false}
                                        styles={{
                                          option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                                            color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                                            zIndex: 10,
                                          }),
                                        }}
                                        />
                                    </div>
                              </div>
                                <div className="relative w-[184px] h-[36px] ml-[2.9rem] p-0.5  mt-1 rounded-md flex items-center" >
                                  <button type="button" 
                                    onClick={() => handleShowPopup()} 
                                    disabled={CustomerOptionSelected?.label == "All Customers" ? true : false}
                                    className={CustomerOptionSelected?.label == "All Customers" ? "notes_adv_fil_disabled_btn py-1 px-2 rounded" : "btn_white_blk py-1 px-2 rounded"}
                                    >     
                                      Advance filter
                                      <FilterIcon/>
                                    </button>
                                </div>                               
                        </div>
                        <div className="flex items-center">
                            <div  className="relative mr-4">
                            <SiteReprocessDateTimePicker
                             pushSelectedDate={updatedSelectedDate}
                             timeSeriesRange={""}
                             timeRange={timeRange}
                            />
                            </div>
                        <div className="relative w-[261px]">
                          <input
                            type="text"
                            className="w-full h-[35.08px] p-[10px_12px] pl-10 rounded-md border"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                          />
                          <SearchIcon />    
                        </div>
                        <div className="relative w-[184px] h-[36px] pl-[4.5rem] pr-2 ml-[-3.5rem]" >
                                      <button type="button" className="btn_white_blk py-1 px-2 rounded flex items-center "  onClick={handleDownload}>                                     
                                      <span className="mr-2"  > Download</span> 
                                        <DownloadIcon  /> 
                                      </button>
                        </div>  
                      </div>              
          </div>
        <div>
           <SiteReprocesstable timezoneForAPI={timezoneForAPI} timeRange={timeRange} findSiteId={findSiteId} customerIds={customerIds} refreshTable={refreshTable} searchTerm={searchTerm} setDownloadData={setDownloadData} />
      </div>
                                   
    </div>
    <Dialog header={headerContent} visible={enabFilterPopup} style={{ width: '50vw' }} onHide={() => SetEnabFilterPopup(false)} footer={footerContent}>
                <p className="m-0">
                    <Tree value={advFilOption} selectionMode="checkbox" selectionKeys={advFilValue}
                        onSelectionChange={(e) => handleFiterSelectionChange(e)} className="w-full md:w-30rem" />
                </p>
     </Dialog>
           
    </>
);
};



export default SiteReprocess;

