import React, { useState, useEffect } from 'react';
import EditIcon from '../../../../assets/img/edit-icon.png';
import trashIcon from '../../../../assets/img/trash-icon.png';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import MapModal from './MapModalPopup';

type Device = {
    type: string;
    name: string;
    deviceId: string;
    activationDate: Date | null;
    terminationDate: Date | null;
    locationValue: string;
    selectedOptions: string[];
    polemountstyle: string;
    componentCategory: string;
    sku: string;
    dsn: string;
    version: string;
};

const Components: React.FC<{ devices: Device[] }> = ({ devices }) => {

    const [data, setData] = useState<{ id: number; name: string; type: string }[]>([]);
    const totalRecords = data.length;

    const [showDialog, setShowDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [showAddCorner, setShowAddCorner] = useState(false);

    const handleEditClick = (item: any) => {
        setSelectedItem(item);
        setShowDialog(true);
    };

    const handleDialogClose = () => {
        setShowDialog(false);
        setSelectedItem(null);
    };

    const [tableData, setTableData] = useState<{ id: number; latitude: string; longitude: string }[]>([]);

    const [newLatitude, setNewLatitude] = useState('');
    const [newLongitude, setNewLongitude] = useState('');
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [editLatitude, setEditLatitude] = useState('');
    const [editLongitude, setEditLongitude] = useState('');
    const [locationValue, setLocationValue] = useState('');
    const [deviceData, setDeviceData] = useState({
        name: '',
        component: '',
        location: '',
        componentCategory: '',
    });

    useEffect(() => {
        const updatedData = devices.map((device, index) => ({
            id: index + 1,
            name: device.name,
            type: device.type,
            deviceId: device.deviceId,
            activationDate: device.activationDate,
            terminationDate: device.terminationDate,
            selectedOptions: device.selectedOptions,
            polemountstyle: device.polemountstyle,
            componentCategory: device.componentCategory,
            locationValue: device.locationValue,
            sku: device.sku,
            dsn: device.dsn,
            version: device.version,

        }));

        const updatedTableData = devices.map((device, index) => {
            const [latitude, longitude] = device.locationValue
                ? device.locationValue.split(',').map(coord => coord.trim())
                : ['0.000000', '0.000000'];

            return {
                id: index + 1,
                latitude,
                longitude,
            };
        });

        setData(updatedData);
        setTableData(updatedTableData);
    }, [devices]);


    const handleAddDevice = () => {
        // Collect values and save them
        const newDevice = {
            name: deviceData.name,
            component: deviceData.component,
            location: locationValue,
        };

        // Assuming addDeviceToTable is a function that adds the new device to the table
        addDeviceToTable(newDevice);

        // Clear the form or reset values if needed
        setDeviceData({ name: '', component: '', location: '', componentCategory: '' });
        setLocationValue('');

        // Close the dialog if desired
        handleDialogClose();
    };
    const addDeviceToTable = (device: any) => {
        setTableData((prevTableData) => [...prevTableData, device]);
        handleDialogClose();
    };
    const handleSaveLocation = (latLng: any) => {
        setLocationValue(`${latLng.lat}, ${latLng.lng}`);
        setModalVisible(false);
    };

    const handleEditLocation = (rowData: any) => {
        setSelectedRow(rowData);
        setEditLatitude(rowData.latitude);
        setEditLongitude(rowData.longitude);
        setEditDialogVisible(true);
    };

    const handleSaveEdit = () => {
        setTableData((prevData) =>
            prevData.map((row) =>
                row.id === selectedRow.id
                    ? { ...row, latitude: editLatitude, longitude: editLongitude }
                    : row
            )
        );
        setEditDialogVisible(false);
    };
    const deleteRow = (id: any) => {
        setTableData(tableData.filter(row => row.id !== id));
    };

    const addRow = () => {
        const newRow = {
            id: tableData.length + 1,
            latitude: newLatitude,
            longitude: newLongitude,
        };
        setTableData([...tableData, newRow]);
        setNewLatitude('');
        setNewLongitude('');
    };

    const actionsTemplate = (rowData: any) => (
        <div className='flex'>
            <img
                src={EditIcon}
                alt="Edit"
                style={{ cursor: 'pointer' }}
                onClick={() => handleEditLocation(rowData)}
            />
            <img
                src={trashIcon}
                alt="Delete"
                style={{ cursor: 'pointer' }}
                onClick={() => deleteRow(rowData.id)}
            />
        </div>
    );
    const path = tableData.map(({ latitude, longitude }) => ({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
    }));

    const pathCoordinates = tableData.map((point) => ({
        lat: parseFloat(point.latitude),
        lng: parseFloat(point.longitude),
    }));
    const toggleAddCorner = () => {
        setShowAddCorner(!showAddCorner);
    };
    const handleDeleteClick = (rowData: any) => {
        // Confirm deletion
        const confirmDelete = window.confirm(`Are you sure you want to delete ${rowData.name}?`);
        if (confirmDelete) {
            // Remove item from data
            const updatedData = data.filter((item) => item.id !== rowData.id);
            setData(updatedData);
        }
    };
    return (
        <div className="card">
            <DataTable value={data} paginator rows={5} className="p-2 p-datatable-striped" paginatorLeft={
                <span style={{ float: 'left', marginLeft: '10px' }}>
                    Showing {data.length > 0 ? 1 : 0} to {data.length} of {totalRecords} entries
                </span>
            }
                paginatorTemplate="FirstPageLink PrevPageLink NextPageLink LastPageLink">
                <Column field="name" header="Name" sortable />
                <Column field="type" header="Type" sortable />
                <Column
                    body={(rowData) => {
                        return (
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <img
                                    src={EditIcon}
                                    style={{ fontSize: '1.5rem', cursor: "pointer", marginLeft: "6px" }}
                                    onClick={() => handleEditClick(rowData)}
                                />
                                <img
                                    src={trashIcon}
                                    style={{ fontSize: '1.5rem', marginLeft: "15px", cursor: "pointer" }}
                                    onClick={() => handleDeleteClick(rowData)}
                                />
                            </div>
                        );
                    }}
                ></Column>
            </DataTable>

            {/* Dialog for editing additional details */}
            <Dialog
                visible={showDialog}
                onHide={handleDialogClose}
                header="Edit Site Details"
                footer={
                    <div>
                        <button
                            className="px-4 py-2 border border-solid border-[#CBCBC9] bg-white-300 text-sm rounded hover:bg-white-400 transition duration-300 mr-2"
                            onClick={handleDialogClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-4 py-2 bg-[#FFB217] text-black text-sm rounded transition duration-300 mr-2"
                            onClick={handleAddDevice}
                        >
                            Add Device
                        </button>
                    </div>
                }
                style={{ width: '40vw' }}
            >
                {selectedItem && (
                    <>
                        <div className='col-12'>
                            <label htmlFor="type">Type</label>
                            <input id="type" type="text" defaultValue={selectedItem.type} disabled={true} className="w-full h-[35px] bg-[#CBCBC9] border border-solid border-[#838588] px-2 rounded text-sm" />
                        </div>
                        <div className='flex'>
                            <div className='col-3'>
                                <label htmlFor="name" className="block font-medium mb-2" style={{ float: "left" }}>Name</label>
                                <InputText
                                    name="name"
                                    defaultValue={selectedItem.name}
                                    value={data[0].name}
                                    onChange={(e) => setDeviceData({ ...deviceData, name: e.target.value })}
                                    className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded"
                                />
                            </div>
                            <div className='col-3'>
                                <label htmlFor="name" className="block font-medium mb-2">Component category</label>
                                <Dropdown
                                    name="componentCategory"
                                    value={selectedItem.componentCategory}
                                    options={[
                                        { label: 'Option 1', value: 'option1' },
                                        { label: 'Option 2', value: 'option2' },
                                        { label: 'Option 3', value: 'option3' }
                                    ]}
                                    onChange={(e) => setDeviceData({ ...deviceData, componentCategory: e.value })}
                                    className="w-[100%] h-[35px] border border-solid border-[#ccc] rounded"
                                    placeholder="Select a category"
                                />
                            </div>
                            <div className="col-5">
                                <label htmlFor="location" className="block font-medium mb-2" style={{ float: "left" }}>Location</label>
                                <InputText
                                    name="location"
                                    placeholder="Enter site coordinates"
                                    defaultValue={selectedItem.locationValue}
                                    onChange={(e) => setLocationValue(e.target.value)}
                                    className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded"
                                />
                            </div>
                            <div className="col-1">
                                <div className='pt-4 cursor-pointer' onClick={() => setModalVisible(true)}                                                    >
                                    <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="40" height="40" rx="6" fill="#F5B334" />
                                        <path d="M23.3984 18.625C23.2812 18.8594 23.1641 19.0547 23.0078 19.25C22.8516 19.5234 22.6562 19.7969 22.5 20.0703V20.1094C21.7578 21.1641 21.0547 22.1016 20.5469 22.6875C20.2734 23.0781 19.6875 23.0781 19.4141 22.6875C18.9062 22.1016 18.2031 21.1641 17.5 20.1094C16.7578 19.0547 16.0547 17.8438 15.6641 16.7891C15.625 16.7109 15.5859 16.6328 15.5469 16.5547C15.3906 16.0469 15.3125 15.6172 15.3125 15.1875C15.3125 15.0312 15.3125 14.8359 15.3125 14.6797C15.5859 12.3359 17.5781 10.5 20 10.5C22.5781 10.5 24.6875 12.6094 24.6875 15.1875C24.6875 15.5391 24.6094 15.9297 24.4531 16.3203C24.2578 17.0234 23.8672 17.8438 23.3984 18.625ZM24.375 19.4844V28.2344L29.375 26.4375V16.5547L25.1172 18.0781C25.1953 17.9219 25.2734 17.7266 25.3516 17.5312C25.5859 16.9844 25.7812 16.3984 25.8594 15.8125L29.9609 14.3281C30.2734 14.2109 30.5859 14.25 30.8203 14.4453C31.0938 14.6016 31.25 14.9141 31.25 15.1875V27.0625C31.25 27.4922 30.9766 27.8438 30.625 27.9609L23.75 30.4609C23.5156 30.5391 23.3203 30.5391 23.0859 30.4609L16.5625 28.0781L10 30.4609C9.6875 30.5781 9.375 30.5391 9.14062 30.3438C8.86719 30.1875 8.75 29.875 8.75 29.5625V17.6875C8.75 17.2969 8.98438 16.9453 9.33594 16.8281L14.0625 15.1094C14.0625 15.1484 14.0625 15.1875 14.0625 15.1875C14.0625 15.8125 14.1797 16.3984 14.375 16.9844L10.625 18.3516V28.2344L15.625 26.4375V19.4844C15.6641 19.6016 15.7422 19.7188 15.8203 19.8359C16.3672 20.6953 16.9531 21.5547 17.5 22.2578V26.4375L22.5 28.2344V22.2578C23.0078 21.5547 23.5938 20.6953 24.1406 19.8359C24.2188 19.7188 24.2969 19.6016 24.375 19.4844Z" fill="#2F333E" />
                                    </svg>
                                </div>
                            </div>
                            <MapModal
                                visible={modalVisible}
                                onHide={() => setModalVisible(false)}
                                onSave={handleSaveLocation}
                                initialLatLng={locationValue} />

                        </div>
                        <div className='flex'>
                            <div className='col-6'>
                                <DataTable value={tableData} rows={5} className="p-datatable-striped">
                                    <Column field="latitude" header="Latitude" />
                                    <Column field="longitude" header="Longitude" />
                                    <Column body={actionsTemplate} header="Actions" style={{ whiteSpace: "nowrap" }} />
                                </DataTable>
                                <Dialog
                                    visible={editDialogVisible}
                                    onHide={() => setEditDialogVisible(false)}
                                    header="Edit Coordinates"
                                    footer={
                                        <div>
                                            <Button label="Cancel" onClick={() => setEditDialogVisible(false)} className="px-4 py-2 border border-solid border-[#CBCBC9] bg-white-300 text-sm mr-2" />
                                            <Button label="Save" onClick={handleSaveEdit} className="px-4 py-2 bg-[#FFB217] text-black text-sm rounded transition duration-300 mr-2" />
                                        </div>
                                    }
                                    style={{ width: '300px' }}
                                >
                                    <div className="field">
                                        <label htmlFor="latitude">Latitude</label>
                                        <InputText
                                            id="latitude"
                                            value={editLatitude}
                                            onChange={(e) => setEditLatitude(e.target.value)}
                                            className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded"
                                        />
                                    </div>
                                    <div className="field" style={{ marginTop: '1rem' }}>
                                        <label htmlFor="longitude">Longitude</label>
                                        <InputText
                                            id="longitude"
                                            value={editLongitude}
                                            onChange={(e) => setEditLongitude(e.target.value)}
                                            className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded"
                                        />
                                    </div>
                                </Dialog>
                                <div className="mt-3">
                                    <Button label="Add Corner" icon="pi pi-plus" className='border border-solid border-[#ccc] px-2 py-1 rounded' onClick={toggleAddCorner} />
                                    {showAddCorner && (
                                        <div className="p-fluid grid">
                                            <div className="col-4">
                                                <label htmlFor="latitude">Latitude</label>
                                                <input
                                                    type="text"
                                                    id="latitude"
                                                    value={newLatitude}
                                                    onChange={(e) => setNewLatitude(e.target.value)}
                                                    className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-2 rounded"
                                                />
                                            </div>
                                            <div className="col-4">
                                                <label htmlFor="longitude">Longitude</label>
                                                <input
                                                    type="text"
                                                    id="longitude"
                                                    value={newLongitude}
                                                    onChange={(e) => setNewLongitude(e.target.value)}
                                                    className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded"
                                                />
                                            </div>
                                            <Button label="Add" onClick={addRow} className="mt-2" />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-6">
                                <GoogleMap
                                    mapContainerStyle={{ width: "100%", height: "400px" }}
                                    center={path[0] || { lat: 0, lng: 0 }}
                                    zoom={5}
                                >
                                    {pathCoordinates.map((coord, index) => (
                                        <Marker key={index} position={coord}
                                            icon={{
                                                url: "data:image/svg+xml;base64," + btoa(`
                                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                   <circle cx="10" cy="10" r="5" fill="#F9B505" stroke="white" stroke-width="2"/>
                                                 </svg>
                                            `),
                                                scaledSize: new window.google.maps.Size(20, 20),
                                            }} />
                                    ))}

                                    <Polyline
                                        path={pathCoordinates}
                                        options={{
                                            strokeColor: "#F9B505",
                                            strokeOpacity: 0.8,
                                            strokeWeight: 3,
                                        }}
                                    />
                                </GoogleMap>
                            </div>
                        </div>
                    </>
                )}
            </Dialog>
        </div>
    );
};

export default Components;


