import React from "react";

function ToolTip({ title }: { title: string }) {
  return (
    <span className="em-font bg-gray-800/70 z-[100] px-1 w-max text-sm text-gray-100 rounded-md absolute left-1 translate-x-1/2 translate-y-0  m-4 mx-auto">
      {title}
    </span>
  );
}

export default ToolTip;
