import Select from 'react-select';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { getTimzeZoneForAPI } from '../../../utils/CommonUtils';
import { useSelector } from 'react-redux';
import NoteServices from '../../../config/services/notes-services/notes-services';
import { TabMenu } from 'primereact/tabmenu';
import GlobalUptimeReportTable from "./globalUptimeReport/GlobalUptimeReportTable";
import { NavLink, useNavigate } from 'react-router-dom';
import Analytics from "../index";
import TroubleshootingStats from "./troubleshooting-stats";
import BillingReport from "./billing-report";
import { getAuthUser } from "../../../config/services/global-services/global.service";
import { hasPermission } from '../../../utils/permissions';
import { getActiveRole } from './../../../utils/role';
import GroundDataReportTable from "./groundDataReport/groundDataReportTable";
import SiteReprocess from "./site-Reprocess";


const Reports = (props: any) => {
    const [HierOption, SetHierOption] = useState<any>([]);
    const [SiteOption, SetSiteOption] = useState<any>([]);
    const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>([]);
    const [HierOptionSelected, SetHierOptionSelected] = useState<any>([]);
    const [SiteOptionSelected, SetSiteOptionSelected] = useState<any>([]);
    const [advFilValue, setAdvFilValue] = useState<any>(null);
    const [enableFilterStatus, SetEnableFilterStatus] = useState(false);
    const [selectUptimeReport, setSelectUptimeReport] = useState<any>([]);
    const [CustomerOption, SetCustomerOption] = useState<any>([]);
    const [findSiteId, SetFindSiteId] = useState<any>([]);
    const [findLabels2, SetFindLabels2] = useState<any>([]);

    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
    interface DateTimeType {
        StartDate: String;
        EndTime: String;
    }
    const initialTimeRange: DateTimeType = {
        StartDate: moment
            .tz(timezoneForAPI)
            .subtract(1, "days")
            .format("MM-DD-YYYY HH:mm"),
        EndTime: moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm"),
    };

    const [timeRange, setTimeRange] = useState<DateTimeType>(initialTimeRange);

    const options = [
        { value: 'notes', label: 'Notes' },
        //{ value: 'reports', label: 'Reports' }
    ]

    if(hasPermission(getActiveRole(userPrefstate.data), 'uptime-report')){
        options.push({ value: 'reports', label: 'Reports' });
    }

    const [items, setItems] = useState<any>([]);

    const getAuthTabItems = async () => {
        const response = await getAuthUser();
        const result = [];
        if(hasPermission(getActiveRole(userPrefstate.data), 'uptime-report')){
            result.push({ label: "Uptime reports" });
        }
        if(hasPermission(getActiveRole(userPrefstate.data), 'troubleshooting-stats')){
            result.push({ label: "Troubleshooting Stats" });
        }
        if(hasPermission(getActiveRole(userPrefstate.data), 'billing-reports')){
            result.push({ label: "Billing Reports" });
        }

        if(hasPermission(getActiveRole(userPrefstate.data), 'ground-data')){
            result.push({ label: "Ground Data" });
            //result.push({ label: "Site(s) reprocess"});
        }
        
        return result;
    }

    useEffect(() => {
        const fetchData = async () => {
            const data: any = await getAuthTabItems();
            setItems([...data]);
        };
        fetchData();
    }, []);

    // const items = [
    //     { label: "Uptime reports" }
    // ];

    const [activeIndex, setActiveIndex] = useState(0);

    const navigate = useNavigate();

    const updatedSelectedDate = useCallback<Function>(
        (dt: any) => {
            setTimeRange(dt);
        },
        [timeRange]
    );

    const handleOptionSelect = (e: any) => {
        SetSiteOption([]);
        SetHierOptionSelected([]);
        SetSiteOptionSelected([]);
        SetHierOption([]);
        SetCustomerOptionSelected(e);
        SetFindLabels2([])
        setAdvFilValue([])
        SetEnableFilterStatus(false)
        setSelectUptimeReport(e);
        if (e?.children?.length > 0) {
            let temp_hier: any = []
            e?.children?.map((cust_val: any, index: any) => {
                temp_hier.push({
                    value: index + 1
                    , ...cust_val
                })
            });
            SetHierOption(temp_hier);

        }
    }


    useEffect(() => {
        NoteServices.getCustomerHier().then((res: any) => {

            let temp_cust: any = [];
            if (res.length > 0) {
                //temp_cust.push(res[0])
                res.map((cust_val: any) => {
                    temp_cust.push({
                        value: cust_val.customerId
                        , ...cust_val
                    })
                })
            }

            // temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
            // const index = temp_cust.findIndex((item: any) => item.label === "All Customers");

            // // Move the object to the first position if found
            // if (index !== -1) {
            //   const allCustomerObject = temp_cust.splice(index, 1)[0];
            //   temp_cust.unshift(allCustomerObject);
            // }

            if (temp_cust?.length === 1) {
                SetCustomerOptionSelected({ 'label': temp_cust[0]?.label, "value": temp_cust[0]?.customerId })
            } else {
                temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
                const index = temp_cust.findIndex((item: any) => item?.label === "All Customers");
                //Move the object to the first position if found
                if (index !== -1) {
                    const allCustomerObject = temp_cust.splice(index, 1)[0];
                    temp_cust.unshift(allCustomerObject);
                }
            }

            SetCustomerOption(temp_cust)

        })
        SetCustomerOptionSelected({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
    }, [])

    useEffect(() => {
        if (selectUptimeReport?.value === "notes") {
            navigate('/analytics');
        }
    }, [selectUptimeReport]);


    return (
      <>
        <header className="w-full bg-bgColor300 border-t border-borderColor text-textColor body-font">
          <div className="op-header-panel-analytics">
            <div className="w-52 op-select-dd-container cursor-pointer">
              {/* Select OPtion for reports and notes START */}
              <div
                className="custom-select custom_dropdown_notes"
                style={{ marginTop: "3px" }}
              >
                {/* Dropdown div  */}
                <div className="dropdown-icon bg-bgColor300 text-textColor">
                  <span className="aftericon">
                    <svg
                      width="40"
                      height="34"
                      viewBox="5 0 40 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="30"
                        height="30"
                        rx="7"
                        x="2"
                        y="2"
                        fill="#131F2E"
                      ></rect>
                      <path
                        d="M18.5 14.5H24.75V19.5C24.75 20.2031 25.2969 20.75 26 20.75H31V32C31 33.4062 29.8672 34.5 28.5 34.5H18.5C17.0938 34.5 16 33.4062 16 32V17C16 15.6328 17.0938 14.5 18.5 14.5ZM26 14.5L31 19.5H26V14.5ZM24.4375 24.1875C24.4375 23.6797 24.0078 23.25 23.5 23.25C22.9531 23.25 22.5625 23.6797 22.5625 24.1875V31.0625C22.5625 31.6094 22.9531 32 23.5 32C24.0078 32 24.4375 31.6094 24.4375 31.0625V24.1875ZM27.875 26.6875C27.875 26.1797 27.4453 25.75 26.9375 25.75C26.3906 25.75 26 26.1797 26 26.6875V31.0625C26 31.6094 26.3906 32 26.9375 32C27.4453 32 27.875 31.6094 27.875 31.0625V26.6875ZM21 29.1875C21 28.6797 20.5703 28.25 20.0625 28.25C19.5156 28.25 19.125 28.6797 19.125 29.1875V31.0625C19.125 31.6094 19.5156 32 20.0625 32C20.5703 32 21 31.6094 21 31.0625V29.1875Z"
                        fill="#4A90A8"
                      ></path>
                    </svg>
                  </span>
                  {/* <Select
                    // menuIsOpen={menuOpen}
                    defaultValue={{ value: "reports", label: "Reports" }}
                    // value={CustomerOptionSelected}
                    options={options}
                    onChange={handleOptionSelect}
                    isSearchable={false}
                    className="notes-custom-select"
                  /> */}
                  <Select
                      defaultValue={{ value: "reports", label: "Reports" }}
                      options={options}
                      onChange={handleOptionSelect}
                      isSearchable={false}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                          color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                          zIndex: 10,
                        }),
                      }}
                    />
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="pg-center-tabs m-0 p-0">
          <TabMenu
            className="my-tab-menu"
            model={items}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          />
          {activeIndex === 0 && (
              <div
                className="scrollable p-1 m-0 bg-bgColor400">
                <div className="overflow-x-auto">
                  <GlobalUptimeReportTable findSiteId={findSiteId} />
                </div>
              </div>
          )}

          {/* Troubleshooting Stats Tab Start */}
          {activeIndex === 1 && (
            <>
              <TroubleshootingStats />
            </>
          )}
          {/* Troubleshooting Stats Tab End */}

          {/* Billing Report Tab Start */}
          {activeIndex === 2 && (
            <>
              <BillingReport />
            </>
          )}
          {/* Billing Report Tab End */}
          {/* Ground Data Tab Start */}
          {activeIndex === 3 && (
            <div style={{ marginTop: "20px" }}>
                <GroundDataReportTable findSiteId={findSiteId} />
            </div>)}
          {/* Ground Data Tab End */}
          {activeIndex === 4 && (
            <div style={{ marginTop: "20px" }}>
                <SiteReprocess />
            </div>)}
          {/*  Site Reprocess Tab End */}
          {selectUptimeReport?.value === "notes" && (
            <NavLink to="/analytics">
              <Analytics />
            </NavLink>
          )}
        </div>
      </>
    );

};

export default Reports;