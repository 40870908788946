import React from 'react';
import { GoogleMap, Marker } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "65vh",
};

const EDMap: React.FC = () => {
    const center = { lat: 41.6729034275, lng: -75.98869840250 };
    const onClick = () => { };

    return (
        <div className="google-map" style={{ height: '100%', backgroundColor: 'lightgray' }}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={20}
                onClick={onClick}
            >
                <Marker position={center} />
            </GoogleMap>
        </div>
    );
};

export default EDMap;