import React, { useState } from 'react';
import { TreeSelect } from 'primereact/treeselect';
import './TreeSelectCustom.css';


const data = [
  {
    key: 'Select All',
    label: 'Select All',
    data: 'Select All',

  },
  {
    key: 'Flagged Assets',
    label: 'Flagged Assets',
    data: 'Flagged Assets',
    children: [
      { key: 'No Wind', label: 'No Wind', data: 'No Wind' },
      { key: 'No Data', label: 'No Data', data: 'No Data' },
      { key: 'Bad Methane', label: 'Bad Methane', data: 'Bad Methane' },
      { key: 'Bad Temperature', label: 'Bad Temperature', data: 'Bad Temperature' },
      { key: 'Fan Failed', label: 'Fan Failed', data: 'Fan Failed' },
      { key: 'No Polygon configured', label: 'No Polygon configured', data: 'No Polygon configured' },
      { key: 'No Alert configured', label: 'No Alert Configured', data: 'No Alert configured' },
    ]
  },
  {
    key: 'Healthy Assets',
    label: 'Healthy Assets',
    data: 'Healthy Assets',
  }
];

const HealthMultiSelect = (props: any) => {
  const [showTree, setShowTree] = useState<boolean>(false)
  const selectOption = (e: any) => {
    props.setSelectedKeys(e.value)
  }
  return (
    <div className="card flex justify-content-center" >
          <TreeSelect 
          value={props.selectedKeys}
          options={data}
          onChange={selectOption} 
          selectionMode="checkbox"
          placeholder={props.selectedKeysText}
          className="md:w-18rem w-full bg-bgColor200 text-textColor rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center custom-tree-select-height "
          />
        
     
    </div>
    
  );
};

export default React.memo(HealthMultiSelect);
