import {
    DataTable as PrimeDatatable,
    DataTableFilterMeta,
} from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { useEffect, useState, useRef, useCallback } from "react";
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { CSVLink } from "react-csv";
import "./assets/css/troubleshooting.css";
import "../../analytics.css";
import NoteServices from "../../../../config/services/notes-services/notes-services";
import { Toast } from 'primereact/toast';
import { useSelector } from "react-redux";
import { getTimzeZoneForAPI, getTimzeZoneUI, UTCToDesiredTimezone } from "../../../../utils/CommonUtils";
import { initTroublStatsIssueTypes, initTroublStatsTableOverview, initTroublStatsTableReports } from './assets/const';
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Accordion, AccordionTab } from 'primereact/accordion';
import sampleGraphImg from './assets/img/trbl_graph_mock.png';
import AnalysisGraph from "./components/AnalysisGraph";
import DatepickerTrouble from '../../../timeslider/DatepickerTrouble';
import Select from 'react-select';
import moment from "moment";
import TableOverview from "./components/TableOverview";
import TableReports from "./components/TableReports";


interface DateTimeType {
    StartDate: String;
    EndTime: String;
}
const TroubleshootingStats = (props: any) => {
    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    let timezoneForUI = getTimzeZoneUI(userPrefstate.data.timeZone);
    let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
    const initialTimeRange: DateTimeType = {
        StartDate: moment.tz(timezoneForAPI).subtract(6, "days").format("MM-DD-YYYY HH:mm"),
        EndTime: moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm"),
    };
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const toastBottomCenter = useRef<any>(null);
    //const searchTerm:any = props.searchTerm;
    //const timeRange = props.timeRange;
    //const SiteOptionSelected:any = props.SiteOptionSelected;
    const [tempLoading, SettempLoading] = useState<any>(true);
    const [SiteOptionSelected, SetSiteOptionSelected] = useState<any>([]);
    const [HierOption, SetHierOption] = useState<any>([]);
    const [SiteOption, SetSiteOption] = useState<any>([]);
    const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>([]);
    const [HierOptionSelected, SetHierOptionSelected] = useState<any>([]);
    const [advFilValue, setAdvFilValue] = useState<any>(null);
    const [enableFilterStatus, SetEnableFilterStatus] = useState(false);
    const [selectUptimeReport, setSelectUptimeReport] = useState<any>([]);
    const [CustomerOption, SetCustomerOption] = useState<any>([]);
    const [enabFilterPopup, SetEnabFilterPopup] = useState(false);
    const [advFilOption, setAdvFilOption] = useState<any>([]);
    const [timeRange, setTimeRange] = useState<DateTimeType>(initialTimeRange);
    const [searchTerm, setSearchTerm] = useState<any>("");
    const [findLabels, SetFindLabels] = useState<any>([]);
    const [findSiteId, SetFindSiteId] = useState<any>([]);
    const [findLabels2, SetFindLabels2] = useState<any>([]);
    const [currentData, SetCurrentData] = useState<any>([]);
    const [popUpFilterData, SetPopUpFilterData] = useState<any>([]);
    const updatedSelectedDate = useCallback<Function>((dt: any) => {
        setTimeRange(dt);
    }, [timeRange]);

    const handleOptionSelect = (e: any) => {
        SetSiteOption([]);
        SetHierOptionSelected([]);
        SetHierOption([]);
        SetCustomerOptionSelected(e);
        SetFindLabels2([])
        setAdvFilValue([])
        SetEnableFilterStatus(false)
        setSelectUptimeReport(e);
        if (e?.children?.length > 0) {
            let temp_hier: any = []
            e?.children?.map((cust_val: any, index: any) => {
                temp_hier.push({
                    value: index + 1
                    , ...cust_val
                })
            });
            SetHierOption(temp_hier);
        }
    }
    const handleShowPopup = () => {
        SetEnabFilterPopup(true)
        const filDAta = CustomerOption.filter((itm: any) => itm.customerId == CustomerOptionSelected.value)
        const flattenedData = flattenTree(filDAta[0], null, 0);
        setAdvFilOption([flattenedData])
    }
    const flattenTree = (node: any, parentId: any, index: any) => {
        const key: any = parentId ? `${parentId}-${index}` : `${index}`;
        const flattenedNode: any = {
            key,
            label: node?.label,
            children: [],
            data: node?.siteId,
        };
        if (node.children && node.children.length > 0) {
            node.children.forEach((child: any, i: any) => {
                const childNode: any = flattenTree(child, key, i);
                flattenedNode.children.push(childNode);
            });
        }
        return flattenedNode;
    };
    const handleOptionSelectHier = (e: any) => {
        SetHierOptionSelected(e);
        if (e?.children?.length > 0) {
            let temp_hier: any = []
            e?.children?.map((cust_val: any) => {
                temp_hier.push({
                    value: cust_val.customerId
                    , ...cust_val
                })
            });
            SetSiteOption(temp_hier);
        }
    }
    const handleOptionSelectSite = (e: any) => {
        SetSiteOptionSelected(e);
    }

    const headerContent = (
        <>
            <div className='font-semibold' style={{ marginBottom: '15px', fontSize: '14px' }}>
                <i className='pi pi-align-left font-semibold' style={{ fontSize: '14px' }}></i>&nbsp;&nbsp;
                Advanced Filtering
            </div>
            <hr style={{ marginLeft: '-24px', marginRight: '-56px' }} />
            <div className='header_cus_bre_cmb mt-2'>
                <span style={{ textDecoration: 'underline', fontWeight: '500' }}>Customers</span> \ <span style={{ textDecoration: 'underline' }}>{CustomerOptionSelected?.label}</span>
            </div>
        </>
    )

    const footerContent = (
        <div>
            <button
                className=""
                onClick={() => {
                    SetEnabFilterPopup(false)
                    SetEnableFilterStatus(false)
                    //setAdvFilValue([])
                }}
                style={{ textDecoration: 'underline' }}
            >
                Close
            </button>&nbsp;&nbsp;
            <button
                className="btn_yllw_blk font-bold py-2 px-4 rounded"
                onClick={() => {
                    SetEnabFilterPopup(false)
                    SetEnableFilterStatus(true)
                    if (findLabels?.length > 0) {
                        SetFindLabels2(findLabels)
                    }
                }}
            >
                Apply
            </button>
        </div>
    );

    function getCheckedNodes(array: any, filterObject: any) {
        const checkedNodes: any = [];
        function recursiveCheck(node: any) {
            const nodeKey = node.key;
            const filterInfo = filterObject[nodeKey];
            if (filterInfo && filterInfo.checked) {
                checkedNodes.push({
                    key: nodeKey,
                    label: node.label,
                    data: node.data
                });
            }
            if (node.children && node.children.length > 0) {
                node.children.forEach((child: any) => {
                    recursiveCheck(child);
                });
            }
        }
        array.forEach((rootNode: any) => {
            recursiveCheck(rootNode);
        });
        return checkedNodes;
    }
    const handleFiterSelectionChange = (event: any) => {
        setAdvFilValue(event.value)
        SetEnableFilterStatus(false)
        const filteredResult = Object.keys(event?.value)?.length > 0 ? getCheckedNodes(advFilOption, event.value) : [];
        if (filteredResult?.length != 0) {
            // Extracting labels from the labels array
            let labelValues = filteredResult.map((item: any) => item.label);
            let SiteIdValues = filteredResult.map((item: any) => item.data);
            SetFindSiteId(SiteIdValues)
            SetFindLabels(labelValues)
            // Filtering data based on labels
            let filteredData = currentData?.filter((item: any) => labelValues.includes(item.siteName));
            SetPopUpFilterData(filteredData)
        }
    };
    useEffect(() => {
        NoteServices.getCustomerHier().then((res: any) => {
            let temp_cust: any = [];
            if (res.length > 0) {
                res.map((cust_val: any) => {
                    temp_cust.push({
                        value: cust_val.customerId
                        , ...cust_val
                    })
                })
            }
            if (temp_cust?.length == 1) {
                SetCustomerOptionSelected({ 'label': temp_cust[0]?.label, "value": temp_cust[0]?.customerId })
            } else {
                temp_cust.push({
                    'children': [],
                    'customerID': 0,
                    'groupId': 0,
                    'hasAccess': false,
                    'isPrimaryCustomer': false,
                    'label': 'All Customers',
                    'parentId': 0,
                    'siteId': ""
                })
                const index = temp_cust.findIndex((item: any) => item?.label === "All Customers");
                //Move the object to the first position if found
                if (index !== -1) {
                    const allCustomerObject = temp_cust.splice(index, 1)[0];
                    temp_cust.unshift(allCustomerObject);
                }
            }
            SetCustomerOption(temp_cust)
        })
        SetCustomerOptionSelected({
            'children': [],
            'customerID': 0,
            'groupId': 0,
            'hasAccess': false,
            'isPrimaryCustomer': false,
            'label': 'All Customers',
            'parentId': 0,
            'siteId': ""
        })
    }, [])

    return <>
        <div className="card p-2 tett table_card trbl-stats-container bg-bgColor400">
            <Toast ref={toastBottomCenter} position="bottom-center" />
            <div className="flex flex-col">
                <div className="trbl-stats-top-header">
                    <div className="flex flex-rowp-4 m-2 float-right">
                        {/* Calender div  */}
                        <DatepickerTrouble
                            pushSelectedDate={updatedSelectedDate}
                            timeSeriesRange={""}
                        />
                    </div>
                </div>
                <div className="flex-1 m-2">
                    <Accordion activeIndex={activeIndex} onTabChange={(e: any) => setActiveIndex(e.index)}>
                        <AccordionTab header="Chart Overview">
                            <AnalysisGraph tempLoading={tempLoading} timeRange={timeRange} />
                        </AccordionTab>
                        <AccordionTab header="Table Overview">
                            <div className="trbl-table-overview">
                                <TableOverview tempLoading={tempLoading} timeRange={timeRange} />
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Table Reports">
                            <div className="trbl-table-reports">
                                <div className="customer-dd-container" style={{ right: "490px" }}>
                                    <div className='custom_dropdown_notes trbl-customer-dd dropdown-icon' >
                                        <span className="aftericon">
                                            <i className="pi pi-map-marker" />
                                        </span>
                                        <Select
                                            // menuIsOpen={menuOpen}
                                            placeholder="Customers"
                                            value={CustomerOptionSelected}
                                            defaultValue={{ label: "All Customers", value: "0" }}
                                            options={CustomerOption}
                                            onChange={handleOptionSelect}
                                            isSearchable={false}
                                            styles={{
                                                option: (provided, state) => ({
                                                  ...provided,
                                                  backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                                                  color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                                                  zIndex: 10,
                                                }),
                                              }}
                                        />
                                    </div>
                                    <div className='custom_dropdown_notes' >
                                        <button type="button" onClick={() => handleShowPopup()} disabled={CustomerOptionSelected?.label == "All Customers" ? true : false} className={CustomerOptionSelected?.label == "All Customers" ? "notes_adv_fil_disabled_btn_tr py-1 px-2 rounded" : "btn_yllw_blk_tr py-1 px-2 rounded"}>
                                            <i className='pi pi-align-left'></i>&nbsp;&nbsp;
                                            Advanced Filtering
                                        </button>
                                    </div>
                                    <div className='custom_dropdown_notes dropdown-icon' style={{ display: "none" }}>
                                        <span className="aftericon">
                                            <i className="pi pi-map-marker" />
                                        </span>
                                        <Select
                                            placeholder={'Hierarchy'}
                                            // menuIsOpen={menuOpen}
                                            // defaultValue={{ label: "Hierarchy", value: "hierarchy" }}
                                            value={HierOptionSelected}
                                            options={HierOption}
                                            onChange={handleOptionSelectHier}
                                            isSearchable={false}
                                            styles={{
                                                option: (provided, state) => ({
                                                  ...provided,
                                                  backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                                                  color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                                                  zIndex: 10,
                                                }),
                                              }}
                                        />
                                    </div>
                                    <div className='custom_dropdown_notes dropdown-icon' style={{ display: "none" }}>
                                        <span className="aftericon">
                                            <i className="pi pi-map-marker" />
                                        </span>
                                        <Select
                                            // menuIsOpen={menuOpen}
                                            placeholder={'Sites'}
                                            value={SiteOptionSelected}
                                            defaultValue={{ label: "Sites", value: "sites" }}
                                            options={SiteOption}
                                            onChange={handleOptionSelectSite}
                                            isSearchable={false}
                                            styles={{
                                                option: (provided, state) => ({
                                                  ...provided,
                                                  backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                                                  color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                                                  zIndex: 10,
                                                }),
                                              }}
                                        />
                                    </div>
                                </div>
                                <TableReports
                                    tempLoading={tempLoading}
                                    timeRange={timeRange}
                                    SelectedCustomer={CustomerOptionSelected.label}
                                    SelectedSites={findLabels2}
                                />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
            <Dialog header={headerContent} visible={enabFilterPopup} style={{ width: '50vw' }} onHide={() => SetEnabFilterPopup(false)} footer={footerContent}>
                <p className="m-0">
                    <Tree value={advFilOption} selectionMode="checkbox" selectionKeys={advFilValue}
                        onSelectionChange={(e) => handleFiterSelectionChange(e)} className="w-full md:w-30rem" />
                </p>
            </Dialog>
        </div>
    </>
}

export default TroubleshootingStats;