import React, { useState } from "react";
import Map, { Marker, NavigationControl } from "react-map-gl";
import { MAPBOX_TOKEN } from "../../utils/Constants";
import "mapbox-gl/dist/mapbox-gl.css";
import OperationsOverviewMenu from "../OperationsOverview/OperationsOverviewMenu";
import MapComponent from "./MapComponent";
import { appInsights } from "../../utils/appInsights";
import { getClientIP } from "../../utils/CommonUtils";

const OperartionOverviewMapboxMap = (props: any) => {
  const [viewport, setViewport] = useState({
    longitude: -100.0, // Initial longitude
    latitude: 40.0, // Initial latitude
    zoom: 3.5, // Initial zoom level
  });
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <>
      <OperationsOverviewMenu
        searchResults={props.searchResults}
        startDate={props.startDate}
        endDate={props.endDate}
        searchText={props.searchText}
        emissionMarker={props.emissionMarker}
        customerIdValue={props.customerIdValue}
        alertTypeResult={props.alertTypeResult}
        selectedKeys={props.selectedKeys}
        activeMarker={props.activeMarker}
        setActiveMarker={props.setActiveMarker}
        setAlertTypeTab={props.setAlertTypeTab}
        setShowOverlay={setShowOverlay}
        showOverlay={showOverlay}
      />
  
      <MapComponent
        alertTypeTab={props.alertTypeTab}
        startDate={props.startDate}
        endDate={props.endDate}
        searchResults={props.searchResults}
        emissionMarker={props.emissionMarker}
        setEmissionMarker={props.setEmissionMarker}
        customerIdValue={props.customerIdValue}
        setCustomerIdValue={props.setCustomerIdValue}
        selectedKeys={props.selectedKeys}
        alertTypeResult={props.alertTypeResult}
        setActiveMarker={props.setActiveMarker}
        activeMarker={props.activeMarker}
        selectedAlertTypeOptions={props.selectedAlertTypeOptions}
      />
    </>
  );
};

try {
  const pageViewTelemetry = {
    name: "operations-overview",
    uri: window.location.href,
  };

  let currentUserDetail: any = localStorage.getItem('user');
  appInsights.trackPageView(pageViewTelemetry);
  appInsights.setAuthenticatedUserContext(currentUserDetail?.userName);
  let startTime = Date.now();
  // Log user spent time metric when the application is about to unload
  window.addEventListener('beforeunload', () => {
    const endTime = Date.now();
    const durationInSeconds = Math.floor((endTime - startTime) / 1000);
    appInsights.trackMetric({
      name: 'operations-overview-UserSpentTime',
      average: durationInSeconds,
    });
  });
} catch (error) {
  // console.error('Error with Azure AppInsights:', error);
}
export default OperartionOverviewMapboxMap;
