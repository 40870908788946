import { Slice, createSlice } from "@reduxjs/toolkit";
import { fetchConfigurationTable } from "../thunk/SitesOverviewThunk";

const initialData: any = [];

const ConfigurationTableChartSlice: Slice<any, {}, "ConfigurationTableChartSlice"> = createSlice({
    name: "ConfigurationTableChartSlice",
    initialState: { data: initialData, isLoading: false, error: "" },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchConfigurationTable.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchConfigurationTable.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        });
        builder.addCase(fetchConfigurationTable.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message ?? ""
        });
    }
})

export default ConfigurationTableChartSlice
