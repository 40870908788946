import React, { useEffect, useState, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

interface BarChartProps {
  comparelastyear: boolean;
  sortBy: number;
  chartData: any;
  isLoading: boolean;
  uom: string;
  endDate: Date;
  customerId: string;
}

const BarChart: React.FC<BarChartProps> = ({ comparelastyear, sortBy, chartData, isLoading, uom, endDate, customerId }) => {
  const chartDivRef = useRef<HTMLDivElement | null>(null);
  const [emissionUnit, setEmissionsUnit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const currentYear = new Date(endDate).getFullYear();
  const previousYear = currentYear - 1;

  const sortedData = [...chartData];
  if (sortBy === 1) {
    sortedData.sort((a, b) => a.currentYearEmissions - b.currentYearEmissions);
  } else if (sortBy === 0) {
    sortedData.sort((a, b) => b.currentYearEmissions - a.currentYearEmissions);
  }

  const createChart = (data: any) => {
    const newRchart = am5.Root.new('barchart');

    newRchart._logo?.dispose();

    const newchart = newRchart.container.children.push(am5xy.XYChart.new(newRchart, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none',
      layout: newRchart.verticalLayout,
    }));

    const yAxis = newchart.yAxes.push(am5xy.CategoryAxis.new(newRchart, {
      categoryField: 'siteName',
      renderer: am5xy.AxisRendererY.new(newRchart, {
        inversed: true,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
        minGridDistance: 20,
      }),
    }));

    yAxis.get('renderer').grid.template.setAll({ location: 10 });
    yAxis.get('renderer').labels.template.set('fill', am5.color('#007994'));
    yAxis.get('renderer').labels.template.set('fontSize', 14);
    yAxis.get('renderer').labels.template.set('paddingLeft', -12);

    yAxis.get("renderer").labels.template.setAll({
      //oversizedBehavior: "wrap", // Wrap text if too long
      cursorOverStyle: "pointer", // Shows pointer cursor
      interactive: true,  // Ensure labels are interactive
    });

    yAxis.get("renderer").labels.template.setup = function(target) {
      target.set("background", am5.Rectangle.new(newRchart, {
        fill: am5.color(0xff0000),
         fillOpacity: 0
      }))
    }
    
    yAxis.get("renderer").labels.template.events.on("click", function(ev) {
      //console.log("Label", ev.target);
      const dataContext = ev.target.dataItem?.dataContext as any;
      const siteId = dataContext.siteId;

      var url = `overview?AssetId=${siteId}&CustomerId=${customerId}`; // Your dynamic link
      window.open(url, "_blank"); // Open in new tab
      //console.log("Label clicked:", category);
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = data.slice(startIndex, endIndex);

    yAxis.data.setAll(currentData);
    newchart.zoomOutButton.set('forceHidden', false);

    const xAxis = newchart.xAxes.push(am5xy.ValueAxis.new(newRchart, {
      renderer: am5xy.AxisRendererX.new(newRchart, {
        strokeOpacity: 0.1,
      }),
      min: 0,

    }));

    xAxis.get('renderer').labels.template.set('fontSize', 12);

    newchart.bottomAxesContainer.children.push(am5.Label.new(newRchart, {
      text: `[bold]UOM:[/] ${emissionUnit}`,
      fontSize: 14,
      x: am5.p0,
      centerX: am5.p50,
    }));

    function createSeries(field: any, name: any, color: any) {
      const series = newchart.series.push(am5xy.ColumnSeries.new(newRchart, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: 'siteName',
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(newRchart, {
          pointerOrientation: 'horizontal',
          getFillFromSprite: false,
          getStrokeFromSprite: true,
          autoTextColor: true,
          getLabelFillFromSprite: false,
          labelText: `[bold]{name}[/]\n{categoryY}: {valueX} ${emissionUnit}`,
        }),
      }));

      series.get("tooltip")?.get("background")?.adapters.add("fill", function (fill: any, target: any) {
        return am5.color(0xffffff);
      });

      series.columns.template.setAll({
        height: 14,
        strokeOpacity: 0.5,
        fill: color,
      });
      series.data.setAll(currentData);
      series.appear();
    }

    const legend = newchart.children.push(am5.Legend.new(newRchart, {
      centerX: am5.percent(43),
      x: am5.percent(43),
      y: am5.percent(100),
      centerY: am5.percent(100),
    }));
    const legend2 = newchart.children.push(am5.Legend.new(newRchart, {
      nameField: "name",
      fillField: "color",
      strokeField: "color",
      centerX: am5.percent(53),
      x: am5.percent(53),
    }));

    if (comparelastyear) {
      createSeries('previousYearEmissions', `${previousYear}`, '#D0D0CE');
      legend.data.setAll(newchart.series.values);
    }
    createSeries('currentYearEmissions', `${currentYear}`, '#007994');
    legend2.data.setAll([{ name: `${currentYear}`, color: am5.color('#007994') }]);

    const cursor = newchart.set('cursor', am5xy.XYCursor.new(newRchart, {
      behavior: 'zoomY',
    }));

    cursor.lineY.set('forceHidden', true);
    cursor.lineX.set('forceHidden', true);

    newchart.appear(1000, 100);

    return { chart: newchart, rchart: newRchart };
  };

  useEffect(() => {
    const chartDiv = chartDivRef.current;

    if (!chartDiv) {
      return;
    }

    chartDiv.innerHTML = '';

    if (isLoading) {
      chartDiv.innerHTML = '<div style="text-align: center; margin-top: 20px; font-size: 18px; color: #292B2E;">Loading Data...</div>';
    } else if (sortedData.length === 0) {
      chartDiv.innerHTML = '<div style="text-align: center; margin-top: 20px; font-size: 14px; color: #292B2E;">No data available.</div>';
    } else {
      const { chart, rchart } = createChart(sortedData);
      return () => {
        chart.dispose();
        if (rchart) {
          rchart.dispose();
        }
      };
    }
  }, [comparelastyear, sortBy, currentPage, isLoading, chartData]);

  const sortedDataRef = useRef(sortedData);
  useEffect(() => {
    sortedDataRef.current = sortedData;
  }, [sortedData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [chartData]);

  useEffect(() => {
    if (uom === "Imperial") {
      setEmissionsUnit("mcf");
    } else {
      setEmissionsUnit("kg");
    }
  }, [uom]);


  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  return (
    <div>
      <div id="barchart" ref={chartDivRef} style={{ width: '100%', height: '55.5vh' }}></div>
      {sortedData.length > 0 && (
        <div style={{ textAlign: 'center', marginTop: '10px' }} className='text-sm '>
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={`bg-gray-300 px-2 ${currentPage === 1 ? 'disabled:opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
          >
            {"<"}
          </button>
          <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className={`bg-gray-300 px-2 ${currentPage === totalPages ? 'disabled:opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
          >
            {">"}
          </button>
        </div>
      )}
    </div>
  );
};

export default BarChart;
