import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Sidebar from "./views/Sidebar";
import { Suspense, lazy, useEffect, useState } from "react";
import { getValuesFromQueryString } from "./utils/CommonUtils";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OperationsOverview from './views/OperationsOverview';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./store";
import { fetchUserPreferences } from "./store/thunk/OperationsOverviewThunk";
import { Overview } from "./components/Types/OperationsSite";
import EmissionOverview from "./views/EmissionPerformance";
import { getUserDetails } from "./config/services/ApiServices";
import Analytics from "./components/Analytics";
import Customers from "./components/Customers"
import CustomerDetails from "./components/Customers/CustomerDetails";
import CustomerAddEditIndex from "./components/Customers/CustomerAddEditIndex";
import Reports from "./components/Analytics/reports";
import LoginScreen from "./views/LoginScreen";
import { fetchRefreshToken } from "./store/thunk/LoginThunk";

const PageContianer = lazy(() => import('./views/PageContainer'));
//const OperationsOverview = lazy(() => import('./views/OperationsOverview'));

export default function App(): JSX.Element {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState("overview");
  const [isUserLoggedIn, setUserLoggedIn] = useState(localStorage.getItem('isLoggedIn'));
  const [overiewTypeTab, setOverviewTypeTab] = useState<Overview>("operation");
  const [settingApiLoading, setSettingApiLoading] = useState<boolean>(true);

  const refreshTokenState = useSelector((state: any) => state.refreshTokenState);

  // Update local storage when refreshTokenState changes
  useEffect(() => {
    
    if (refreshTokenState?.data?.token?.refresh_token) {
      localStorage.setItem('at', refreshTokenState?.data?.token?.access_token ?? '');
      localStorage.setItem('refresh_token', refreshTokenState?.data?.token?.refresh_token ?? '');
    }
  }, [refreshTokenState]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchRefreshToken({refreshtoken: localStorage.getItem("refresh_token") })); 
    }, 28 * 60 * 1000);
     
    return () => clearInterval(intervalId);
    
}, []);

  const updateOverviewTypeTab = (newTab: any) => {
    setOverviewTypeTab(newTab);
  };

  const dispatch = useDispatch<AppDispatch>();


  useEffect(() => {
    searchQueryObj(search);
    const urlData = getValuesFromQueryString(search);
    let isLoggedIn: any = localStorage.getItem('isLoggedIn');
    if(isLoggedIn!=="true"){
      setSettingApiLoading(false);
      navigate(`/login`);
    }else if (urlData.AssetId) {
      navigate(`/mapview?AssetId=${urlData.AssetId}&CustomerId=${urlData.CustomerId}`);
    } else {
        setPage("operations-overview");
        navigate('/operations-overview');      
    }
  }, []);


  useEffect(() => {
    let isLoggedIn: any = localStorage.getItem('isLoggedIn');
    if(isLoggedIn==="true"){
      dispatch(fetchUserPreferences())
    }
    let timerFunc = setTimeout(() => {
      const currentUrl =  window.location.host;
      window.location.href = window.location.protocol + "//" + currentUrl+"?action=login";
    }, 1000 * 60 * 30);

    return () => clearTimeout(timerFunc);
  }, []);

  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  let settingAPILoading = userPrefstate.successful;
  useEffect(() => {
    if (settingAPILoading)
      setSettingApiLoading(!settingAPILoading);
  }, [settingAPILoading])

  useEffect(() => {
    let isLoggedIn: any = localStorage.getItem('isLoggedIn');
      if(isLoggedIn==="true"){
        const fetchData = async () => {
          try {
            const userData = await getUserDetails();
            const userTempdata: any = {
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              userName: userData?.userName,
            }
            localStorage.setItem('user', JSON.stringify(userTempdata));
          } catch (error) {
            // console.error('Error fetching user details:', error);
          }
        };
        // console.log('%c TTT' + localStorage.getItem('user'), 'background: #222; color: #bada55');
        if (localStorage.getItem('user') == null || localStorage.getItem('user') === undefined) {
          fetchData();
        }
      }
  }, []);

  const searchQueryObj = (search: string) => {
    const s = search.replace("?", "");
    const searchArray = s.split("&");
    searchArray.forEach((se) => {
      const seArray = se.split("=");
      if(seArray[0]==="action" && seArray[1]==="login"){
        localStorage.setItem('isLoggedIn', 'false');
        setUserLoggedIn('false');
      }else{
        localStorage.setItem(seArray[0], seArray[1]);
        if(seArray[0]==="at"){
          localStorage.setItem('isLoggedIn', 'true');
          setUserLoggedIn('true');
        }
      }
    });
  };
  const location = useLocation();

  const renderPage = (pagePram: any) => {
    if (location.pathname === "/analytics") {

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Analytics />
        </Suspense>
      )
    }

    if (location.pathname === "/login") {

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <LoginScreen />
        </Suspense>
      )
    }

    let url_split = location.pathname.split("/");

    if (location.pathname === "/global") {

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Reports/>
        </Suspense>
      )
    }


    if (location.pathname === "/customers") {

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Customers />
        </Suspense>
      )
    }

    if (url_split[1] === "CustomerDetails" && url_split[2] !== "") {

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CustomerDetails CustomerId={url_split[2]} />
        </Suspense>
      )
    }
    if (url_split[1] === "add-customer" && url_split[2] !== "") {

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CustomerAddEditIndex />
        </Suspense>
      )
    }
    if (url_split[1] === "edit-customer" && url_split[2] !== "") {

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CustomerAddEditIndex CustomerId={url_split[2]} />
        </Suspense>
      )
    }
    switch (pagePram) {
      case "overview":
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <PageContianer />
          </Suspense>
        )
      default:
        return (
          <>
            {overiewTypeTab === "operation" ?
              <OperationsOverview updateOverviewTypeTab={updateOverviewTypeTab} />
              : <EmissionOverview updateOverviewTypeTab={updateOverviewTypeTab} />
            }

          </>
        )
    }
  }

  return (
    // <div>
    //   {isUserLoggedIn==="true" && <Sidebar />}
    //   {!settingApiLoading && renderPage(page)}
    //   {/* <Router>
    //     <div>
    //       <Routes>
    //         <Route path="/operations-overview" component={OperationsOverview} />
    //       </Routes>
    //     </div>
    //   </Router> */}
    // </div>

    <div className="flex bg-bgColor200 h-screen">
      {isUserLoggedIn === "true" && (
        <div className="flex-shrink-0 flex-grow-0 basis-[4.17%] flex flex-col items-center z-10 p-2 h-full">
          {/* Sidebar with full height */}
          <Sidebar />
        </div>
      )}
      <div
        className={`flex-grow ${
          settingApiLoading ? "opacity-50" : "opacity-100"
        } transition-opacity duration-300 main-content overflow-y-auto h-full`}
      >
        {/* Main content with scroll */}
        {renderPage(page)}
      </div>
    </div>
  );
}

