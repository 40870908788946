import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SiteHierarchy from '../hierarchy/SiteHierarchy';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import SiteDetails from './SiteDetails';
import EquipmentAndDevices from './Equipment&Devices';

interface NewSiteProps {
    onClose: () => void;
}
const steps = ['Site Details', 'Hierarchy', 'Equipment & Devices'];

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewSite: React.FC<NewSiteProps> = ({ onClose }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [stepData, setStepData] = React.useState([{}, {}, {}]);

    const isStepSkipped = (step: number) => skipped.has(step);

    const handleCancel = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (activeStep === 3) {
            setOpenSnackbar(true);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        setSkipped(newSkipped);
    };

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const handleDataChange = (stepIndex: number, data: any) => {
        const updatedData = [...stepData];
        updatedData[stepIndex] = data;
        setStepData(updatedData);
    };

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ width: '100%', paddingBottom: '60px' }}>
            <div className='tett table_card'>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        if (isStepSkipped(index)) stepProps.completed = false;
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you're finished</Typography>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography sx={{ mt: 1, mb: 1 }}></Typography>
                    {activeStep === 0 && (
                        <>
                            <SiteDetails
                                data={stepData[1]}

                                onDataChange={(data: any) => handleDataChange(0, data)}
                            />
                        </>
                    )}
                    <br />
                    {activeStep === 1 && (
                        <div>
                            <SiteHierarchy
                                data={stepData[1]}
                                onDataChange={(data: any) => handleDataChange(1, data)}
                            />
                        </div>
                    )}
                    {activeStep === 2 && (
                        <div>
                            <EquipmentAndDevices
                                data={stepData[2]}
                                onDataChange={(data: any) => handleDataChange(2, data)} />
                        </div>
                    )}

                </React.Fragment>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#ffffff',
                    padding: '10px 20px',
                    boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
                }}
            >
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleCancel} sx={{ mr: 1 }} style={{ color: "#292B2E", textDecoration: "underline" }}>
                    Cancel
                </Button>
                <Button onClick={handleBack} sx={{ mr: 1 }} style={{ color: "#292B2E", border: "1px solid #CBCBC9", borderRadius: "6" }}>
                    Back
                </Button>
                <Button onClick={handleNext} style={{ backgroundColor: "#F5B334", color: "#000" }}>
                    {activeStep === 3 ? 'Create Site' : 'Next Step'}
                </Button>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    Site created successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default NewSite;