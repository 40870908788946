import StaticJsonData from "./sample_data/trouble_sample_data.json";
import ax from "../../api/AxiosConfig";
import axios from "axios";
import moment from "moment";

const GetTableOverview = async ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
    try {
        const parsedStartDate = moment(startDate).format('MM-DD-YYYY');
        const parsedEndDate = moment(endDate).format('MM-DD-YYYY');
        let queryParam = `startDate=${parsedStartDate}&endDate=${parsedEndDate}`;
        const response = await ax.get(`/Emission/GetSoofieTroubleShootingStats?${queryParam}`);
        return response.data;
      } catch (error) {
        console.log("GetTableOverview", error)
        throw error;
      }
};

const GetTableOverviewRowDownload = async (fileName:string, date:string) => {
  try {
      const response = await ax.get(`/Emission/DownloadTroubleShootingStats/${fileName}/Troublesites_${date}.csv`);
      return response.data;
    } catch (error) {
      console.log("GetTableOverviewGlobalDownload", error)
      throw error;
    }
};

const GetTableReports = async ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
  try {
      const parsedStartDate = moment(startDate).format('MM-DD-YYYY');
      const parsedEndDate = moment(endDate).format('MM-DD-YYYY');
      let queryParam = `startDate=${parsedStartDate}&endDate=${parsedEndDate}`;
      const response = await ax.get(`/Emission/TroubleShootingStatsReport?${queryParam}`);
      return response.data;
    } catch (error) {
      console.log("GetTableOverview", error)
      throw error;
    }
};

const SoofieBillingReport = async ({ customerIds, timePeriod }: { customerIds: string[], timePeriod: string }) => {
  try {
      const requestBody = {
          CustomerIds: customerIds.length > 0 ? customerIds : [],
          TimePeriod: timePeriod
      };

      const response = await ax.post('/Emission/SoofieBillingReport', requestBody);
      return response.data;
  } catch (error) {
      console.log("SoofieBillingReport Error:", error);
      throw error;
  }
};

const DownloadBillingReport = async ({ customerId, fileName }: { customerId: string, fileName: string }) => {
  try {

    const response = await ax.get(`/Emission/DownloadBillingReport/${customerId}/${fileName}`, {
      responseType: 'blob' 
  });
  return response.data;
  } catch (error) {
      console.log("SoofieBillingReport Error:", error);
      throw error;
  }
};



const TroubleshootingStatsServices = {
    GetTableOverview,
    GetTableOverviewRowDownload,
    GetTableReports,
    SoofieBillingReport,
    DownloadBillingReport
};
export default TroubleshootingStatsServices;

