import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

type Device = {
    type: string;
    name: string;
    deviceId: string;
    activationDate: Date | null;
    terminationDate: Date | null;
    locationValue: string;
    selectedOptions: string[];
    polemountstyle: string;
    componentCategory: string;
    sku: string;
    dsn: string;
    version: string;
};

const DeviceTable: React.FC<{ devices: Device[] }> = ({ devices }) => {
    
    const totalRecords = devices.length;

    const actionBodyTemplate = () => {
        return (
            <Button icon="pi pi-ellipsis-v" className="p-button-text p-button-rounded text-textColor" onClick={() => alert("Menu opened")} />
        );
    };
    const formatDate = (date: Date) => {
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        });
    };

    return (
        <div className="card">
            <DataTable value={devices} paginator rows={5} className="p-2 p-datatable-striped" paginatorLeft={
                <span style={{ float: 'left', marginLeft: '10px' }}>
                    Showing {devices.length > 0 ? 1 : 0} to {devices.length} of {totalRecords} entries
                </span>
            }
                paginatorTemplate="FirstPageLink PrevPageLink NextPageLink LastPageLink" >
                <Column
                    field="status"
                    header="Status"
                    body={(rowData) => (
                        <span className={rowData.status === "Online" ? "online-status" : "offline-status"}>
                            {rowData.status}
                        </span>
                    )}
                    style={{ textAlign: 'center' }}
                    sortable
                />
                <Column field="deviceName" header="Device Name" style={{ textAlign: "left", whiteSpace: "nowrap" }} sortable />
                <Column field="version" header="Version" style={{ textAlign: "center" }} sortable />
                <Column field="deviceId" header="Device ID" style={{ textAlign: "center" }} sortable />
                <Column field="dsn" header="Serial no" style={{ textAlign: "center", whiteSpace: "nowrap" }} sortable />
                <Column
                    field="activationDate"
                    header="Activation Date"
                    body={(rowData) => formatDate(new Date(rowData.activationDate))}
                    style={{ textAlign: "center", whiteSpace: "nowrap" }}
                    sortable
                />
                <Column
                    field="terminationDate"
                    header="Termination Date"
                    body={(rowData) => formatDate(new Date(rowData.terminationDate))}
                    style={{ textAlign: "center", whiteSpace: "nowrap" }}
                    sortable
                />
                <Column header="Actions" body={actionBodyTemplate} style={{ textAlign: "center" }} />
            </DataTable>
        </div>
    );
};

export default DeviceTable;
