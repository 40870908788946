interface Roles {
    [key: string]: boolean | string;  // Roles can be boolean or string
  }
  
  export const getActiveRole = (roles: Roles): any => {

    // Check for isDelegatedCustomerAdmin and accessLevel
    if (roles.isDelegatedCustomerAdmin && roles.accessLevel) {
      return roles.accessLevel;
    }

    // Define the priority order
    const priorityOrder = ['superAdmin', 'readOnly', 'globalAdmin', 'isDelegatedGlobalAdmin', 'isDelegatedCustomerAdmin'];
  
    // Iterate through the priority order to find the first true role
    for (let role of priorityOrder) {
      if (roles[role] === true) {
        //console.log("role", role);
        return role;  // Return the first role with true value based on priority order
      }
    }
  
    // Return Acessrole if no roles are true
    return roles.accessLevel || null;
  };