import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { fetchAssetAlerts } from "../../store/thunk/MapSliceThunk";
import { IsNullOrEmpty } from '../../utils/CommonUtils';
import '../../assets/css/custom-op-style.css';

const AssetAlerts = (props: any) => {
    const [scrolledItem, setScrollItem] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>()
    const state = useSelector((state: any) => state.assetAlertState);
    const { searchResults, startDate, endDate, searchText, emissionMarker, customerIdValue, selectedKeys, activeMarker } = props;

    const filteredData = searchResults.filter((item: any) => item.siteID);

    const arrayData = !IsNullOrEmpty(searchText) ? filteredData : !state.isLoading && state.data;

    const containerRef = useRef<HTMLDivElement>(null);


    const cleanedSearchText = searchText.trim().replace(/["']/g, '');


    const renderLoading = (
        <div className="px-5 py-5 text-bold text-center text-textColor">
          <div className="flex justify-center items-center">
            <div className="loading">
              <h1>Loading Data...</h1>
            </div>
          </div>
        </div>
    );

    const renderNoData = (
        <div className="px-5 py-5 text-bold text-center text-textColor">
            <div className="flex justify-center items-center">
                <h1>No data available.</h1>
            </div>
        </div>
    );

    const renderNoDataFound = (
        <div className="px-5 py-5 text-bold text-center">
            <div className="flex justify-center items-center">
                <h1>No data found.</h1>
            </div>
        </div>
    );

    useEffect(() => {
        if (!emissionMarker && containerRef.current) {
            const firstElement = containerRef.current.querySelector('.rounded-md');
            if (firstElement) {
                firstElement.scrollIntoView({ behavior: "smooth", block: "center" });
                setScrollItem(null);
            }
        } else if (emissionMarker && customerIdValue && containerRef.current) {
            const element = containerRef.current.querySelector(`[data-site-id="${emissionMarker}"]`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
                setScrollItem(activeMarker === null ? null : emissionMarker);
            }
        }
    }, [emissionMarker, customerIdValue]);



    useEffect(() => {
        dispatch(fetchAssetAlerts({ startDate, endDate }))
    }, [dispatch, startDate, endDate])

    const isFlaggedAsset = ['No Data', 'Bad Methane', 'Bad Temperature', 'Fan Failed', 'No Polygon configured', 'No Alert configured', 'No Wind'];



    return (
      <>
        <div ref={containerRef}>
          <div className="text-md mb-1 ml-2 font-bold text-sky-300">
            Asset Alert
          </div>
          {state.isLoading ? (
            renderLoading
          ) : (
            <>
              {IsNullOrEmpty(state.data) ? (
                renderNoData
              ) : (
                <>
                  {IsNullOrEmpty(filteredData) && !IsNullOrEmpty(searchText) ? (
                    renderNoDataFound
                  ) : (
                    <>
                      {selectedKeys === null ||
                      Object.keys(selectedKeys).length === 0 ||
                      (selectedKeys["Select All"] &&
                        selectedKeys["Select All"].checked)
                        ? arrayData
                            .filter(
                              (item: any) =>
                                item.alertDetails.assetAlertCount > 0
                            )
                            .concat(
                              arrayData.filter(
                                (item: any) =>
                                  item.alertDetails.assetAlertCount === 0
                              )
                            )
                            .map(
                              (item: any, index: any) =>
                                (searchText === "" ||
                                  item.alertDetails.alertInfo.some(
                                    (alert: any) =>
                                      alert.alertType.toLowerCase() ===
                                      searchText.toLowerCase()
                                  ) ||
                                  item.siteName
                                    .toLowerCase()
                                    .includes(
                                      cleanedSearchText.toLowerCase()
                                    ) ||
                                  item.customerName
                                    .toLowerCase()
                                    .includes(
                                      cleanedSearchText.toLowerCase()
                                    )) && (
                                  <div
                                    {...(emissionMarker && customerIdValue
                                      ? {
                                          "data-site-id": item.siteID,
                                          "data-customer-id": item.customerId,
                                        }
                                      : {})}
                                    className="rounded-md mb-2 box-shadow"
                                    style={{
                                      width: "100%",
                                      height: "25%",
                                      opacity:
                                        scrolledItem === null
                                          ? 1
                                          : scrolledItem === item.siteID
                                          ? 1
                                          : 0.4,
                                    }}
                                    key={index}
                                  >
                                    <div className="flex items-center p-2">
                                      {/* <div className="pl-1">
                                                                    <div className="text-gray-800">
                                                                        <span>{item.customerName}/{item.siteName || item.thingNo}</span>
                                                                    </div>
                                                                </div> */}
                                      <div className="pl-1 text-textColor">
                                        <span
                                          onClick={() => {
                                            const url = `/mapview?AssetId=${item.siteID}&CustomerId=${item.customerId}`;
                                            window.location.href = url;
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {item.customerName}/
                                          {item.siteName || item.thingNo}
                                        </span>
                                      </div>

                                      <div
                                        className="flex items-center"
                                        style={{ flex: "1" }}
                                      >
                                        <label
                                          className={
                                            item.alertDetails
                                              .assetAlertCount === 0
                                              ? "assetalert-Greenhighlight"
                                              : "assetalert-Statushighlight"
                                          }
                                          style={{ marginLeft: "auto" }}
                                        >
                                          {item.alertDetails.assetAlertCount ===
                                          0
                                            ? "Active Asset"
                                            : "Flagged Assets"}
                                        </label>
                                      </div>
                                    </div>

                                    {item.alertDetails.assetAlertCount === 0 ? (
                                      <div className="flex items-center p-2">
                                        <div className="flex-grow pl-1 soofedata-first-item">
                                          <div className="text-textColor50 text-xs">
                                            Asset Name
                                          </div>
                                          <div
                                            className={`text-textColor100 text-sm p-1 alertdata-first-width`}
                                          >
                                            {item.siteName}
                                          </div>
                                        </div>
                                        <div className="soofedata-second-item">
                                          <div className="text-textColor50 text-xs mb-1">
                                            Alert Description
                                          </div>
                                          <div
                                            className={`text-textColor100 text-sm p-1 ${
                                              scrolledItem === item.siteID
                                                ? "assetalert-highlight"
                                                : ""
                                            } alertdata-first-width`}
                                          >
                                            Online
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      item.alertDetails.alertInfo
                                        .filter((alert: any) => {
                                          return (
                                            searchText === "" ||
                                            alert.alertType
                                              .toLowerCase()
                                              .includes(
                                                cleanedSearchText.toLowerCase()
                                              ) ||
                                            item.siteName
                                              .toLowerCase()
                                              .includes(
                                                cleanedSearchText.toLowerCase()
                                              ) ||
                                            item.customerName
                                              .toLowerCase()
                                              .includes(
                                                cleanedSearchText.toLowerCase()
                                              )
                                          );
                                        })

                                        .map(
                                          (
                                            filteredAlert: any,
                                            alertIndex: any
                                          ) => (
                                            <div key={alertIndex}>
                                              <div className="flex items-center p-2">
                                                <div className="flex-grow pl-1 soofedata-first-item">
                                                  <div className="text-textColor50 text-xs">
                                                    Asset Name
                                                  </div>
                                                  <div
                                                    className={`text-textColor100 text-sm p-1 alertdata-first-width`}
                                                  >
                                                    {filteredAlert.assetName}
                                                  </div>
                                                </div>
                                                <div className="soofedata-second-item">
                                                  <div className="text-textColor50 text-xs mb-1">
                                                    Alert Description
                                                  </div>
                                                  <div
                                                    className={`text-textColor100 text-sm p-1 ${
                                                      scrolledItem ===
                                                      item.siteID
                                                        ? "assetalert-highlight"
                                                        : ""
                                                    } alertdata-first-width`}
                                                  >
                                                    {filteredAlert.alertType}
                                                  </div>
                                                </div>
                                              </div>
                                              {alertIndex !==
                                                item.alertDetails.alertInfo
                                                  .length -
                                                  1 && (
                                                <div className="grey-line"></div>
                                              )}
                                            </div>
                                          )
                                        )
                                    )}
                                  </div>
                                )
                            )
                        : arrayData
                            .filter(
                              (item: any) =>
                                item.alertDetails.assetAlertCount > 0
                            )
                            .concat(
                              arrayData.filter(
                                (item: any) =>
                                  item.alertDetails.assetAlertCount === 0
                              )
                            )
                            .map((item: any, index: any) => {
                              const isOnline =
                                selectedKeys &&
                                selectedKeys["Healthy Assets"] &&
                                selectedKeys["Healthy Assets"].checked;

                              const isFlaggedAssets =
                                selectedKeys &&
                                isFlaggedAsset.some(
                                  (key) =>
                                    selectedKeys[key] &&
                                    selectedKeys[key].checked
                                );

                              const shouldDisplay =
                                (isOnline &&
                                  item.alertDetails.assetAlertCount === 0) ||
                                (!isOnline &&
                                  isFlaggedAssets &&
                                  item.alertDetails.alertInfo.length > 0 &&
                                  item.alertDetails.alertInfo.some(
                                    (alert: any) =>
                                      selectedKeys[alert.alertType] &&
                                      selectedKeys[alert.alertType].checked
                                  )) ||
                                (isOnline && isFlaggedAssets);

                              const isAlertType =
                                item.alertDetails.alertInfo.some(
                                  (alert: any) =>
                                    selectedKeys[alert.alertType] &&
                                    selectedKeys[alert.alertType].checked
                                ) ||
                                (selectedKeys["Healthy Assets"] &&
                                  selectedKeys["Healthy Assets"].checked &&
                                  item.alertDetails.alertInfo.length === 0);

                              return shouldDisplay
                                ? (searchText === "" ||
                                    item.alertDetails.alertInfo.some(
                                      (alert: any) =>
                                        alert.alertType.toLowerCase() ===
                                          searchText.toLowerCase() ||
                                        searchText.toLowerCase() !==
                                          alert.alertType.toLowerCase()
                                    ) ||
                                    item.siteName
                                      .toLowerCase()
                                      .includes(
                                        cleanedSearchText.toLowerCase()
                                      )) &&
                                    isAlertType && (
                                      <div
                                        {...(emissionMarker && customerIdValue
                                          ? {
                                              "data-site-id": item.siteID,
                                              "data-customer-id":
                                                item.customerId,
                                            }
                                          : {})}
                                        className="rounded-md mb-2 bg-bgContainer box-shadow"
                                        style={{
                                          width: "100%",
                                          height: "25%",
                                          opacity:
                                            scrolledItem === null
                                              ? 1
                                              : scrolledItem === item.siteID
                                              ? 1
                                              : 0.4,
                                        }}
                                        key={index}
                                      >
                                        <div className="flex items-center p-2">
                                          <div className="pl-1 text-textColor">
                                            <span
                                              onClick={() => {
                                                const url = `/mapview?AssetId=${item.siteID}&CustomerId=${item.customerId}`;
                                                window.location.href = url;
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              {item.customerName}/
                                              {item.siteName || item.thingNo}
                                            </span>
                                          </div>
                                          <div
                                            className="flex items-center"
                                            style={{
                                              flex: "1",
                                            }}
                                          >
                                            <label
                                              className={
                                                item.alertDetails
                                                  .assetAlertCount === 0
                                                  ? "assetalert-Greenhighlight"
                                                  : "assetalert-Statushighlight"
                                              }
                                              style={{
                                                marginLeft: "auto",
                                              }}
                                            >
                                              {item.alertDetails
                                                .assetAlertCount === 0
                                                ? "Active Asset"
                                                : "Flagged Assets"}
                                            </label>
                                          </div>
                                        </div>

                                        {item.alertDetails.assetAlertCount ===
                                        0 ? (
                                          <div className="flex items-center p-2">
                                            <div className="flex-grow pl-1 soofedata-first-item">
                                              <div className="text-textColor50 text-xs">
                                                Asset Name
                                              </div>
                                              <div
                                                className={`text-textColor50 text-sm p-1 alertdata-first-width`}
                                              >
                                                {item.siteName}
                                              </div>
                                            </div>
                                            <div className="soofedata-second-item">
                                              <div className="text-textColor50 text-xs mb-1">
                                                Alert Description
                                              </div>
                                              <div
                                                className={`text-textColor100 text-sm p-1 ${
                                                  scrolledItem === item.siteID
                                                    ? "assetalert-highlight"
                                                    : ""
                                                } alertdata-first-width`}
                                              >
                                                Online
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          item.alertDetails.alertInfo
                                            .filter((alert: any) => {
                                              const isAlertTypeSelected =
                                                selectedKeys &&
                                                selectedKeys[alert.alertType] &&
                                                selectedKeys[alert.alertType]
                                                  .checked;

                                              if (
                                                isAlertTypeSelected !== null
                                              ) {
                                                return (
                                                  (searchText === "" ||
                                                    alert.alertType
                                                      .toLowerCase()
                                                      .includes(
                                                        cleanedSearchText.toLowerCase()
                                                      ) ||
                                                    item.siteName
                                                      .toLowerCase()
                                                      .includes(
                                                        cleanedSearchText.toLowerCase()
                                                      ) ||
                                                    item.customerName
                                                      .toLowerCase()
                                                      .includes(
                                                        cleanedSearchText.toLowerCase()
                                                      )) &&
                                                  isAlertTypeSelected
                                                );
                                              }
                                            })

                                            .map(
                                              (
                                                filteredAlert: any,
                                                alertIndex: any
                                              ) => (
                                                <div key={alertIndex}>
                                                  <div className="flex items-center p-2">
                                                    <div className="flex-grow pl-1 soofedata-first-item">
                                                      <div className="text-textColor50 text-xs">
                                                        Asset Name
                                                      </div>
                                                      <div
                                                        className={`text-textColor100 text-sm p-1 alertdata-first-width`}
                                                      >
                                                        {
                                                          filteredAlert.assetName
                                                        }
                                                      </div>
                                                    </div>
                                                    <div className="soofedata-second-item">
                                                      <div className="text-textColor50 text-xs mb-1">
                                                        Alert Description
                                                      </div>
                                                      <div
                                                        className={`text-textColor100 text-sm p-1 ${
                                                          scrolledItem ===
                                                          item.siteID
                                                            ? "assetalert-highlight"
                                                            : ""
                                                        } alertdata-first-width`}
                                                      >
                                                        {
                                                          filteredAlert.alertType
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {alertIndex !==
                                                    item.alertDetails.alertInfo
                                                      .length -
                                                      1 && (
                                                    <div className="grey-line"></div>
                                                  )}
                                                </div>
                                              )
                                            )
                                        )}
                                      </div>
                                    )
                                : null;
                            })}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
}

export default AssetAlerts;