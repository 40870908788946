import { isValidUrl } from '../../utils/CommonUtils';
import EditIcon from '../Analytics/assets/edit.png';
import Customer_logo from './assets/imgs/sample_customer_logo.jpg'

const CustomerDetailsTab = (props: any) => {

    const renderLoading = (
        <div>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm">Loading Data...</div>
        </div>
    );

    function displayNotifyTo(NotifyTo: any) {
        let temp_return_div: any = "";

        if (NotifyTo?.length > 0) {
            temp_return_div = NotifyTo.map((notifyVal: any, notifyInd: any) => {
                return <div style={{ display: "flex", marginBottom: "0.5em" }}>
                    <div className='preffered-domain-badge'>{notifyInd + 1}</div>
                    <div> {notifyVal}<br></br></div>
                </div>
            })

        }
        return <>{temp_return_div}</>;
    }

    return <>
        {
            props?.tempLoading == false ?
                <>
                    <div style={{ display: "flex" }}>
                        <div className="tett table_card" style={{ flex: "40%", height: "14rem" }}>
                            <div style={{ display: "flex" }}>
                                <div className='customer_details_tab_card_heading'>
                                    Customer Name
                                </div>

                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginTop: "1.5rem", flex: "50%" }}>
                                    <img src={isValidUrl(props?.customerData?.customerLogo) ? props?.customerData?.customerLogo : Customer_logo} style={{ height: "8rem" }} />
                                </div>
                                <div style={{ marginTop: "1.5rem", flex: "50%", display: "flex", alignItems: "center", fontSize: "1.2em" }} className='text-textColor'>
                                    {props?.customerData?.customerName}
                                </div>
                            </div>
                        </div>
                        <div className="tett table_card" style={{ flex: "40%", marginLeft: "1em", height: "14rem" }}>

                            <div style={{ display: "flex" }}>
                                <div className='customer_details_tab_card_heading'>
                                    Preffered Email Domains
                                </div>

                            </div>
                            <div style={{ marginTop: "1em" }}>
                                {displayNotifyTo(props?.customerData?.preferredEmailDomains)}
                            </div>
                        </div>
                        <div style={{ flex: "15%" }}>

                        </div>

                        <div style={{ marginLeft: "auto", order: "2" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginLeft: "auto", order: "3", marginRight: "0.5rem" }}>
                                    <img src={EditIcon}></img>

                                </div>
                                <div style={{ marginLeft: "auto", order: "3" }}>
                                    <i className='pi pi-ellipsis-v text-textColor'></i>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div style={{ display: "flex" }}>
                        <div className="tett table_card" style={{ flex: "40%", marginTop: "1em", height: "14rem" }}>

                            <div style={{ display: "flex" }}>
                                <div className='customer_details_tab_card_heading'>
                                    Main Contacts
                                </div>

                            </div>

                            <div style={{ display: "flex" }} className='text-textColor'>
                                <div style={{ marginTop: "1.5rem", flex: "50%" }} >
                                    Contact Name :
                                </div>
                                <div style={{ marginTop: "1.5rem", flex: "50%", display: "flex", alignItems: "center" }}>
                                    {props?.customerData?.contactName}
                                </div>
                            </div>

                            <div style={{ display: "flex" }} className='text-textColor'>
                                <div style={{ marginTop: "1rem", flex: "50%" }} >
                                    Sales/Finance Contact Name:
                                </div>
                                <div style={{ marginTop: "1rem", flex: "50%", display: "flex", alignItems: "center" }}>
                                    {props?.customerData?.salesFinanceContactName}
                                </div>
                            </div>
                        </div>
                        <div className="tett table_card" style={{ flex: "40%", marginLeft: "1em", marginTop: "1em", height: "14rem" }}>

                            <div style={{ display: "flex" }} className='text-textColor'>
                                <div className='customer_details_tab_card_heading'>
                                    Setting
                                </div>

                            </div>

                            <div style={{ display: "flex" }} className='text-textColor'>
                                <div style={{ marginTop: "1rem", flex: "20%" }}>
                                    Customer Status:
                                </div>
                                <div style={{ marginTop: "1rem", flex: "50%", display: "flex", alignItems: "center" }}>
                                    {(props?.customerData?.customerStatus) ? 'true' : 'false'}
                                </div>
                            </div>



                            <div style={{ display: "flex" }} className='text-textColor'>
                                <div style={{ marginTop: "1rem", flex: "20%" }}>
                                    Unit of Measurement:
                                </div>
                                <div style={{ marginTop: "1rem", flex: "50%", display: "flex", alignItems: "center" }}>
                                    {props?.customerData?.unitOfMeasurement}
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: "15%" }}>

                        </div>

                        <div style={{ marginLeft: "auto", order: "2", visibility: "hidden" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginLeft: "auto", order: "3", marginRight: "0.5rem" }}>
                                    <img src={EditIcon}></img>

                                </div>
                                <div style={{ marginLeft: "auto", order: "3" }}>
                                    <i className='pi pi-ellipsis-v text-textColor'></i>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : (
                    (renderLoading)
                )
        }


    </>
}

export default CustomerDetailsTab;