import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "daterangepicker";
import "./../../timeslider/date_picker_style.css"
import $ from "jquery";
import { useSelector } from "react-redux";
import { getTimzeZoneForAPI, getTimzeZoneUI } from "../../../utils/CommonUtils";

const DateTimePicker = (props: any) => {
  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
  let timezoneForUI = getTimzeZoneUI(userPrefstate.data.timeZone);

  const pickerRef = useRef<HTMLInputElement | null>(null);
  const [startDate, setStartDate] = useState<Date>(new Date(moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm")));
  const [endDate, setEndDate] = useState<Date>(new Date(moment.tz(timezoneForAPI).format('MM-DD-YYYY HH:mm')));

  useEffect(() => {
    console.log("Date Time Picker props", props)
    setStartDate(props.timeSeriesRange.StartDate);
    setEndDate(props.timeSeriesRange.EndTime);

  }, [props.timeSeriesRange.StartDate])

  useEffect(() => {
    function cb(start: any, end: any) {
      $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }
    if (pickerRef.current) {
      const picker = $(pickerRef.current).daterangepicker({
        timePicker: true,
        timePicker24Hour: true,
        singleDatePicker: false,
        timePickerIncrement: 1,
        maxDate: moment.tz(timezoneForAPI).toDate(),
        startDate: startDate,
        endDate: endDate,
        ranges: {
          'Last 24 Hours': [moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm"), moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm")],
          'Last 7 Days': [moment.tz(timezoneForAPI).subtract(6, 'days'), moment.tz(timezoneForAPI)],
          'Last 30 Days': [moment.tz(timezoneForAPI).subtract(29, 'days'), moment.tz(timezoneForAPI)],
          'This Month': [moment.tz(timezoneForAPI).startOf('month'), moment.tz(timezoneForAPI).endOf('month')],
          'Last Month': [moment.tz(timezoneForAPI).subtract(1, 'month').startOf('month'), moment.tz(timezoneForAPI).subtract(1, 'month').endOf('month')]
        },
        locale: {
          format: "MM-DD-YYYY HH:mm"
        }
      }, cb);
      cb(moment(startDate), moment(endDate));
      picker.on("apply.daterangepicker", handleDateRangeChange);
      picker.on('showCalendar.daterangepicker', handleShowCalendar);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    props.pushSelectedDate({
      StartDate: moment(startDate).format("MM-DD-YYYY HH:mm"),
      EndTime: moment(endDate).format("MM-DD-YYYY HH:mm")
    });
  }, []);

    useEffect(() => {
    if (props.timeRange.StartDate && props.timeRange.EndTime) {
      setStartDate(new Date(props.timeRange.StartDate));
      setEndDate(new Date(props.timeRange.EndTime));
    }
  }, [props.timeRange]);

  const handleDateRangeChange = (event: any, picker: any) => {
    if (pickerRef.current) {
      const newStartDate = picker.startDate;
      const newEndDate = picker.endDate;
      props.pushSelectedDate({
        StartDate: newStartDate.format("MM-DD-YYYY HH:mm"),
        EndTime: newEndDate.format("MM-DD-YYYY HH:mm")
      });
      pickerRef.current.value = `${newStartDate.format("MM-DD-YYYY HH:mm")} - ${newEndDate.format("MM-DD-YYYY HH:mm")}`;

      setStartDate(newStartDate.format("MM-DD-YYYY HH:mm"));
      setEndDate(newEndDate.format("MM-DD-YYYY HH:mm"));

      const selectedRange = document.querySelector(".selected-range");
      if (selectedRange) {
        selectedRange.classList.remove("selected-range");
      }
      const startValue = newStartDate.format("MM-DD-YYYY HH:mm");
      const endValue = newEndDate.format("MM-DD-YYYY HH:mm");
      const rangeElements = document.querySelectorAll(`[data-range-key="${startValue}-${endValue}"]`);
      rangeElements.forEach((rangeElement) => {
        rangeElement.classList.add("selected-range");
      });
    }
  };
  const handleShowCalendar = (event: any, picker: any) => {
    const startTime = picker.startDate;
    const endTime = picker.endDate;
    const timeDifferenceMilliseconds = endTime - startTime;
    const timeDifference = endTime.diff(startTime, 'hours');
    const timeDifferenceDays = timeDifferenceMilliseconds / (1000 * 60 * 60 * 24);
    if ($("#dt-error-label")) {
      $("#dt-error-label").remove();
    }
    let err_label = $('<span>', {
      text: "Choose a date range of 24 hours to 365 days",
      id: 'dt-error-label'
    })[0];
    $(".show-calendar > .drp-buttons").prepend(err_label);
    setTimeout(() => {
      if (timeDifference >= 24 && timeDifferenceDays <= 365) {
        picker.container.find('.applyBtn').prop('disabled', false);
        $(err_label).css('display', 'none');
      } else {
        picker.container.find('.applyBtn').prop('disabled', true);
        $(err_label).css('display', 'inline-block');
      }
    }, 100);
  };
  return (
    <>
      <div className="flex mt-1 em-date-picker-contiainer">
      <div style={{ cursor: 'pointer' }} onClick={() => pickerRef.current && $(pickerRef.current).trigger('click')}>
         <svg width="15" height="18" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '2%' }}>
          <path d="M6.5 8C6.75 8 7 8.25 7 8.5V11.5C7 11.7812 6.75 12 6.5 12H3.5C3.21875 12 3 11.7812 3 11.5V8.5C3 8.25 3.21875 8 3.5 8H6.5ZM4 0C4.40625 0 4.75 0.34375 4.75 0.75V2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0ZM12.5 6H1.5V14C1.5 14.2812 1.71875 14.5 2 14.5H12C12.25 14.5 12.5 14.2812 12.5 14V6Z" fill="#4A90A8" />
        </svg> 
        </div>
        <div className="date-picker-txt-container">
          <div className="dt-input">
            <input type="text" className="h-5 pt-1 date-picker-textbox bg-bgColor300 text-textColor" ref={pickerRef} title="Select date and time range" placeholder="Select date and time range" />
          </div>
          <div className="dt-time-zone text-textColor">{timezoneForUI}</div>
        </div>
      </div>
    </>
  );
};

export default DateTimePicker;
