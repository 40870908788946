import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import Select from 'react-select';
import { getTimzeZoneForAPI, IsNullOrEmpty } from '../../../../utils/CommonUtils';
import { CSVLink } from "react-csv";
import UptimeReportDatePicker from '../../components/UptimeReportDatePicker';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from "../../../../store";
import { fetchGroundDataDetail } from "../../../../store/thunk/SitesOverviewThunk";
import { fetchSoofieUptimeExport } from '../../../../store/thunk/SitesOverviewThunk';
import NoteServices from '../../../../config/services/notes-services/notes-services';
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';
import "./assets/css/groundDataReport.css";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import TimecardDatepicker from '../../components/DateTimePicker';
import { Button } from 'primereact/button';

const DownloadIcon: React.FC = () => (
    <span className='inline-block text-center'>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-download"
            viewBox="0 0 16 16"
        >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
        </svg>
    </span>

);

const GroundDataReportTable = (props: any) => {
    const isValidDate = (date: string): boolean => {
        return moment(date, 'MM-DD-YYYY HH:mm', true).isValid();
    };
    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
    interface DateTimeType {
        StartDate: string;
        EndTime: string;
      }
      const initialTimeRange: DateTimeType = {
        StartDate: moment
          .tz(timezoneForAPI)
          .subtract(1, "days")
          .format("MM/DD/YYYY HH:mm"),
        EndTime: moment.tz(timezoneForAPI).format("MM/DD/YYYY HH:mm"),
      };
    
      const [timeRange, setTimeRange] = useState<DateTimeType>(initialTimeRange);
      const updatedSelectedDate = useCallback((dt: DateTimeType) => {
        setTimeRange(dt);
      }, [timeRange]);

    const [selectedValue, setSelectedValue] = useState('Percentage');
    const [columnData, setColumnData] = useState<string[]>([]);
    const [fromDate, setFromDate] = useState();
    const [siteNameSuggestions, setSiteNameSuggestions] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [toDate, setToDate] = useState();
    const [AlertMessage, setAlertMessage] = useState<any>();
    const startDateTime = fromDate !== undefined && isValidDate(fromDate) ? fromDate : moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm");
    const endDateTime = toDate !== undefined && isValidDate(toDate) ? toDate : moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm");
    const currentDate = moment();
    const currentMonth = currentDate.format("MMMM-YYYY");

    let startDate: any = moment(startDateTime);
    let endDate: any = moment(endDateTime);

    const dispatch = useDispatch<AppDispatch>();

    const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>({ label: 'Select Customer', value: '' });

    const [SiteOptionSelected, SetSiteOptionSelected] = useState<any>([]);
    const [DeviceOptionSelected, SetDeviceOptionSelected] = useState<any>([]);
    const [HierOption, SetHierOption] = useState<any>([]);
    const [SiteOption, SetSiteOption] = useState<any>([]);
    const [HierOptionSelected, SetHierOptionSelected] = useState<any>([]);
    const [CustomerOption, SetCustomerOption] = useState<any>([]);
    //const [SiteOption, SetSiteOption] = useState<any>([]);
    const [DeviceOption, SetDeviceOption] = useState<any>([]);
    const [inputDevice, setInputDevice] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<any>("");
    const [findLabels2, SetFindLabels2] = useState<any>([]);
    const [advFilValue, setAdvFilValue] = useState<any>(null);
    const [enableFilterStatus, SetEnableFilterStatus] = useState(false);
    const [enabFilterPopup, SetEnabFilterPopup] = useState(false);
    const [advFilOption, setAdvFilOption] = useState<any>([]);
    const [findSiteId, SetFindSiteId] = useState<any>([]);
    const [findLabels, SetFindLabels] = useState<any>([]);
    const [currentData, SetCurrentData] = useState<any>([]);
    const [popUpFilterData, SetPopUpFilterData] = useState<any>([]);

    const [rowDataState, setRowDataState] = useState<any>(null);
    const [startDateDescriptionState, setStartDateDescriptionState] = useState<string>('');
    const [lastRowDataState, setLastRowDataState] = useState<any>(null);

    const state = useSelector((state: any) => state.groundDataReport);

    
    const stateGlobalDownload = useSelector((state: any) => state.soofieUptimeExport);

    const outerCustomerId = CustomerOptionSelected?.customerId || '';

    const [customerId, setCustomerId] = useState<string>(outerCustomerId);
    let nonEmptySiteIds: any = [];

    let downloadCustomerName = CustomerOptionSelected?.label || '';


    nonEmptySiteIds = findSiteId?.filter((id: string) => id.trim() !== '');

    
    let isPercent: any = selectedValue === 'Percentage' ? true : false;
    const dts = useRef<any>(null);

    useEffect(() => {
        setCustomerId(outerCustomerId);
    }, [outerCustomerId]);


    const exportCSV = () => {
        if(showTable)
            dts.current?.exportCSV({ selectionOnly: false, filename: 'groundDataReport.csv' });
    };

    useEffect(() => {
        // const startTime = startDate.format('09/29/2024 02:43 a');
        // const endTime = endDate.format('10/01/2024 06:43 a');

        // nonEmptySiteIds = findSiteId?.filter((id: string) => id.trim() !== '');

        // const deviceId = 'e00fce6816ea74ad57b6b686';
        // const siteIds = '3a6f7e8d-670d-4730-a19d-3d38d70cc084';
        // const customerId = '146f43e7-40b6-41fc-8e28-a92ba39df1d2';
        

        // dispatch(fetchGroundDataDetail({ customerId, siteIds, startTime, endTime, deviceId }));
    }, [customerId, startDateTime, endDateTime, isPercent, findSiteId])

    useEffect(() => {
        const columnData: string[] = [];
        while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
            const formattedMonth = startDate.format("MMMM-YYYY");
            columnData.push(formattedMonth);
            startDate.add(1, 'month');
        }
        setColumnData(columnData);
    }, [props.timeRange, startDateTime, endDateTime]);
    
    // Group data by siteId
    /*const groupedData = state.data.reduce((acc: any, obj: any) => {
        
        const siteId = obj.siteId;
        if (!acc[siteId]) {
            acc[siteId] = [];
        }
        acc[siteId].push(obj);
        
        return acc;
    }, {});*/

    // Convert groupedData object to an array of arrays
    //const groupedArray = Object.values(groupedData);

    
    
    // const mappedData = groupedArray.map((items: any) => {
    //     return items.filter((item: any) => columnData.includes(item.time));
    // }).filter(items => items.length > 0);


    //const hasEmptyArrays = mappedData.some(array => array.length === 0);
    //const lastColumnHeader = columnData[columnData.length - 1];
    //sconst isCurrentMonthLastColumn = currentMonth === lastColumnHeader;

    const getAssetName = (rowData: any[]) => {
        const asset = rowData.find(obj => obj.siteName);
        return asset ? asset.siteName : 'N/A';
    };

    const downloadData = () => {
        if (stateGlobalDownload.data && typeof stateGlobalDownload.data === 'string') {
            const rows = stateGlobalDownload.data.split('\r\n');
            const headers = rows[0].split(',');
            let bodyData = rows.slice(1).map((row: any) => row.split(',').join(',')).join('\r\n');
            const csvData = headers.join(',') + '\r\n' + bodyData;
            const filename = `SOOFIE Uptime-${downloadCustomerName}_${lastRowDataState === undefined ? rowDataState.siteName : lastRowDataState.siteName}_${startDateDescriptionState}  .csv`;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const pseudoelement = document.createElement("a");
            pseudoelement.href = window.URL.createObjectURL(blob);
            pseudoelement.download = filename;
            document.body.appendChild(pseudoelement);
            pseudoelement.click();
            document.body.removeChild(pseudoelement);
        }
    };

    useEffect(() => {
        downloadData();
    }, [stateGlobalDownload.data]);

    const handleDownload = async (rowData: any) => {
        const lastRowData = rowData[rowData.length - 1];
        setLastRowDataState(lastRowData);

        const currentMonthYear = moment().format('MMMM-YYYY');
        const isCurrentMonthYear = moment(lastRowData?.time, 'MMMM-YYYY').isSame(currentMonthYear, 'month');

        let startDate, endDate;
        if (isCurrentMonthYear && lastRowData.time === currentMonthYear) {
            endDate = moment().format('MM-DD-YYYY');
            startDate = moment(endDate, 'MM-DD-YYYY').subtract(30, 'days').format('MM-DD-YYYY');
        } else if (!isCurrentMonthYear && lastRowData?.time !== currentMonthYear && lastRowData) {
            startDate = moment(lastRowData?.time, 'MMMM-YYYY').format('MM-DD-YYYY');
            endDate = moment(startDate, 'MM-DD-YYYY').add(1, 'month').subtract(1, 'day').format('MM-DD-YYYY');
        } else {
            startDate = moment(rowData.time, 'MMMM-YYYY').format('MM-DD-YYYY');
            endDate = moment(startDate, 'MM-DD-YYYY').add(1, 'month').subtract(1, 'day').format('MM-DD-YYYY');
        }
        const startDateFormatted = moment(startDate, 'MM-DD-YYYY').format('MMMM-YYYY');
        const startDateDescription = isCurrentMonthYear ? 'previous 30 days' : startDateFormatted;

        setRowDataState(rowData);
        setStartDateDescriptionState(startDateDescription);

        try {
            let CustomerId = customerId;
            let SiteId = lastRowData === undefined ? rowData.siteId : lastRowData?.siteId;
            let StartTime: any = startDate;
            let EndTime: any = endDate;

            dispatch(fetchSoofieUptimeExport({ CustomerId, SiteId, StartTime, EndTime }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleClick = (value: any) => {
        setSelectedValue(value);
    };
    const handleFromDateChange = (date: any) => {
        setFromDate(date);
    };
    const handleToDateChange = (date: any) => {
        setToDate(date);
    };

    const groundDataReport =state.data.data || [];

    const filteredData = groundDataReport.filter((item: { data: string; }) =>
        item.data.toLowerCase().includes(searchTerm.toLowerCase())
    );

    /*const csvData = [
        ["Site Name", ...columnData.flatMap(month => [month, "", ""])],
        ["", ...columnData.flatMap(() => ["Hardware", "Environmental", "Uptime"])],
        ...mappedData.map(row => {
            const rowData = columnData.flatMap(month => {
                const dataForMonth = row.find((obj: any) => obj.time === month);
                if (dataForMonth) {
                    return [`${Math.round(dataForMonth.hardware)} ${selectedValue === 'Percentage' ? '%' : ''}`, `${Math.round(dataForMonth.environmental)} ${selectedValue === 'Percentage' ? '%' : ''}`, `${Math.round(dataForMonth.uptime)} ${selectedValue === 'Percentage' ? '%' : ''}`];
                } else {
                    return ["N/A", "N/A", "N/A"];
                }
            });
            return [getAssetName(row), ...rowData];
        }),
    ];*/
    const renderLoading = (
        <div>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-borderColor"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm">Loading Data...</div>
        </div>
    );
    const renderNoData = (
        <div className="px-5 py-5 text-bold text-center">
            <div className="flex justify-center items-center">
                <h1>No data available.</h1>
            </div>
        </div>
    );

    const filterSelection = (
        <div className="px-5 py-5 text-bold text-center">
            <div className="flex justify-center items-center text-textColor">
                <h1>Please select Customer, Site & device</h1>
            </div>
        </div>
    );

    //console.log("CustomerOptionSelected", CustomerOptionSelected);

    const handleOptionSelect = (e: any) => {
        setInputDevice('');
        SetSiteOption([]);
        SetDeviceOption([]);
        SetHierOptionSelected([]);
        SetSiteOptionSelected([]);
        SetDeviceOptionSelected([]);
        SetHierOption([]);
        SetCustomerOptionSelected(e);
        SetFindLabels2([])
        setAdvFilValue([])
        SetEnableFilterStatus(false)
        setShowTable(false);
        if (e?.children?.length > 0) {
            let temp_hier: any = []
            e?.children?.map((cust_val: any, index: any) => {
                temp_hier.push({
                    value: index + 1
                    , ...cust_val
                })
            });
            SetHierOption(temp_hier);

        }
    }

    const handleSiteSelect = (e: any) => {
        
        SetSiteOptionSelected(e);
        SetDeviceOption([]);
        SetDeviceOptionSelected([]);
        setShowTable(false);
        
    }

    const handleDeviceSelect = (e: any) => {
        
        SetDeviceOptionSelected(e);
        
    }

    // const checkDeviceId = () => {
    //     const exists = devices.some(device => device.deviceId === deviceIdInput);
    //     setDeviceExists(exists);
    // };

    const handleShowTableData = () => {
        // SetEnabFilterPopup(true)
        // const filDAta = CustomerOption.filter((itm: any) => itm.customerId == CustomerOptionSelected.value)
        // const flattenedData = flattenTree(filDAta[0], null, 0);
        // setAdvFilOption([flattenedData])
        
        const startTime = startDate.format(timeRange.StartDate);
        const endTime = endDate.format(timeRange.EndTime);

        nonEmptySiteIds = findSiteId?.filter((id: string) => id.trim() !== '');

        // const deviceId = 'e00fce6816ea74ad57b6b686';
        // const siteIds = '3a6f7e8d-670d-4730-a19d-3d38d70cc084';
        // const customerId = '146f43e7-40b6-41fc-8e28-a92ba39df1d2';

        //CustomerOptionSelected.value==undefined || SiteOptionSelected.value==undefined || DeviceOptionSelected.value==undefined 

        let deviceId;
        if(inputDevice!=""){
            const exists = DeviceOption.some((device: { value: any; }) => device.value === inputDevice);
            if(!exists){
                setVisible(true);
                setAlertMessage("Device Id does not exist");
            }else{
                 deviceId = inputDevice;
            }
        }else{
             deviceId = DeviceOptionSelected.value;
        }

        const customerId = CustomerOptionSelected.value;
        const siteIds = SiteOptionSelected.value;
        //const deviceId = DeviceOptionSelected.value;
        
        if(deviceId){
            dispatch(fetchGroundDataDetail({ customerId, siteIds, startTime, endTime, deviceId }));
            setShowTable(true);
        }
    }


    const flattenTree = (node: any, parentId: any, index: any) => {
        const key: any = parentId ? `${parentId}-${index}` : `${index}`;
        const flattenedNode: any = {
            key,
            label: node?.label,
            children: [],
            data: node?.siteId,
        };

        if (node.children && node.children.length > 0) {
            node.children.forEach((child: any, i: any) => {
                const childNode: any = flattenTree(child, key, i);
                flattenedNode.children.push(childNode);
            });
        }
        return flattenedNode;
    };

    const handleFiterSelectionChange = (event: any) => {
        setAdvFilValue(event.value)
        SetEnableFilterStatus(false)
        const filteredResult = Object.keys(event?.value)?.length > 0 ? getCheckedNodes(advFilOption, event.value) : [];
        if (filteredResult?.length != 0) {
            // Extracting labels from the labels array
            let labelValues = filteredResult.map((item: any) => item.label);
            let SiteIdValues = filteredResult.map((item: any) => item.data);
            
            SetFindSiteId(SiteIdValues)
            SetFindLabels(labelValues)
            // Filtering data based on labels
            let filteredData = currentData?.filter((item: any) => labelValues.includes(item.siteName));
            SetPopUpFilterData(filteredData)
        }else{
            SetFindSiteId([]);
            SetFindLabels([]);
        }
    };

    function getCheckedNodes(array: any, filterObject: any) {
        const checkedNodes: any = [];

        function recursiveCheck(node: any) {
            const nodeKey = node.key;
            const filterInfo = filterObject[nodeKey];

            
            if (filterInfo && filterInfo.checked) {
                checkedNodes.push({
                    key: nodeKey,
                    label: node.label,
                    data: node.data
                });
            }

            if (node.children && node.children.length > 0) {
                node.children.forEach((child: any) => {
                    recursiveCheck(child);
                });
            }
        }

        array.forEach((rootNode: any) => {
            recursiveCheck(rootNode);
        });

        return checkedNodes;
    }

    const headerContent = (
        <>
            <div className='font-semibold' style={{ marginBottom: '15px', fontSize: '14px' }}>
                <i className='pi pi-align-left font-semibold' style={{ fontSize: '14px' }}></i>&nbsp;&nbsp;
                Advanced Filtering
            </div>
            <hr style={{ marginLeft: '-24px', marginRight: '-56px' }} />
            <div className='header_cus_bre_cmb mt-2'>
                <span style={{ textDecoration: 'underline', fontWeight: '500' }}>Customers</span> \ <span style={{ textDecoration: 'underline' }}>{CustomerOptionSelected?.label}</span>
            </div>
        </>
    )


    const footerContent = (
        <div>
            <button
                className=""
                onClick={() => {
                    SetEnabFilterPopup(false)
                    SetEnableFilterStatus(false)
                    setAdvFilValue([])
                    SetFindSiteId([])
                    SetFindLabels([])
                    
                }}
                style={{ textDecoration: 'underline' }}
            >
                Close
            </button>&nbsp;&nbsp;
            <button
                className="btn_yllw_blk font-bold py-2 px-4 rounded"
                onClick={() => {
                    SetEnabFilterPopup(false)
                    SetEnableFilterStatus(true)
                    if (findLabels?.length > 0) {
                        SetFindLabels2(findLabels)
                    }
                }}
            >
                Apply
            </button>
        </div>
    );

    useEffect(() => {
        NoteServices.getCustomerHier().then((res: any) => {

            let temp_cust: any = []; //{ 'label': 'Select Customer', 'value': '' }
            if (res.length > 0) {
                //temp_cust.push(res[0])
                res.map((cust_val: any) => {
                    temp_cust.push({
                        value: cust_val.customerId
                        , ...cust_val
                    })
                })
            }

            // temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
            // const index = temp_cust.findIndex((item: any) => item.label === "All Customers");

            // // Move the object to the first position if found
            // if (index !== -1) {
            //   const allCustomerObject = temp_cust.splice(index, 1)[0];
            //   temp_cust.unshift(allCustomerObject);
            // }

            if (temp_cust?.length >= 1) {
                SetCustomerOptionSelected({ 'label': temp_cust[0]?.label, "value": temp_cust[0]?.customerId })
            } else {
                temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
                // Change for Select Cus ddl
                const index = temp_cust.findIndex((item: any) => item?.label === "Select Customer");
                //Move the object to the first position if found
                if (index !== -1) {
                    const allCustomerObject = temp_cust.splice(index, 1)[0];
                    temp_cust.unshift(allCustomerObject);
                }
            }

            SetCustomerOption(temp_cust)

            console.log("temp_cust", temp_cust);

        })
        //SetCustomerOptionSelected({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'Select Customer', 'parentId': 0, 'siteId': "" })
       //SetCustomerOptionSelected({ label: 'Select Customer', value: '' });
    }
    , [])


    useEffect(() => {
        if (CustomerOptionSelected.value) {
            const filDAta = CustomerOption.filter((itm: any) => itm.customerId == CustomerOptionSelected.value)

            //SetSiteOptionSelected({ label: '111', value: '111' });
            if(filDAta.length>0){
            const extractSiteLabels = (options: any[]): string[] => {
                return options.flatMap((option: any) => [
                    option.siteId ? { label: option.label, value: option.siteId } : null,
                    ...option.children.flatMap((child: any) => [
                      child.siteId ? { label: child.label, value: child.siteId } : null,
                      ...child.children.map((grandchild: any) =>
                        grandchild.siteId ? { label: grandchild.label, value: grandchild.siteId } : null
                      )
                    ])
                  ]).filter(item => item !== null);
              };

              // Get all site label
                const siteLabels: any = extractSiteLabels(filDAta[0].children);
                //SetSiteOptionSelected(siteLabels);
                SetSiteOption(siteLabels);
                /*setSiteNameSuggestions(
                    siteLabels.filter((label: any) =>
                    label.toLowerCase()
                    )
                );*/

                }
        }
      }, [CustomerOptionSelected, CustomerOption]);


      useEffect(() => {
        if (CustomerOptionSelected.value && SiteOptionSelected.value) {
        NoteServices.getAllDevices(CustomerOptionSelected.value, SiteOptionSelected.value).then((res: any) => {

            let temp_cust: any = []; //{ 'label': 'Select Customer', 'value': '' }
            if (res.data.length > 0) {
                //temp_cust.push(res[0])
                res.data.map((cust_val: any) => {
                    temp_cust.push({
                        value: cust_val.deviceId,
                        label: cust_val.name
                    })
                })
            }



            SetDeviceOption(temp_cust);

           

        })

    }
        
    }
    , [CustomerOptionSelected, SiteOptionSelected])
    
    /*const filteredData = mappedData.filter((row) =>
        row.some((obj: any) =>
            Object.values(obj).some((value: any) =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        )
    );*/

    const [visible, setVisible] = useState(false);

    const handleCancel = () => {
        setVisible(false);
      };
    
    return (
        <>
            <div className="tett table_card trbl-stats-container">
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "1%", marginLeft: "3px" }}>
                        <div className="pl-2" style={{ position: "relative", height: "100%" }}>
                            <div className='flex items-center gap-2 flex-wrap'>
                                {/* All Customer Dropdown  div  */}
                                <div className='custom-select custom_dropdown_notes dropdown-icon z-0' >                                    
                                    <Select
                                        // menuIsOpen={menuOpen}
                                        placeholder="Customers"
                                        value={CustomerOptionSelected}
                                        defaultValue={{ label: "All Customers", value: "0" }}
                                        options={CustomerOption}
                                        onChange={handleOptionSelect}
                                        isSearchable={true}
                                        styles={{
                                            option: (provided, state) => ({
                                              ...provided,
                                              backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                                              color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                                              zIndex: 10,
                                            }),
                                          }}
                                    />
                                </div>
                                {/* All Customer Dropdown  div  */}
                                <div className='custom-select custom_dropdown_notes dropdown-icon text-textColor' >
                                    <Select
                                        // menuIsOpen={menuOpen}
                                        placeholder="Select Site"
                                        value={SiteOptionSelected}
                                        //defaultValue={{ label: "All Sites", value: "0" }}
                                        options={SiteOption}
                                        onChange={handleSiteSelect}
                                        isSearchable={true}
                                        styles={{
                                            option: (provided, state) => ({
                                              ...provided,
                                              backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                                              color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                                              zIndex: 10,
                                            }),
                                          }}
                                    />
                                </div>
                                {/* All Customer Dropdown  div  */}
                                <div className='custom-select custom_dropdown_notes dropdown-icon' >
                                    <Select
                                        // menuIsOpen={menuOpen}
                                        placeholder="Select Device"
                                        value={DeviceOptionSelected}
                                        //defaultValue={{ label: "All Devices", value: "0" }}
                                        options={DeviceOption}
                                        onChange={handleDeviceSelect}
                                        isSearchable={true}
                                        isDisabled={inputDevice!=""?true:false}
                                        styles={{
                                            option: (provided, state) => ({
                                              ...provided,
                                              backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                                              color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                                              zIndex: 10,
                                            }),
                                          }}
                                    />
                                </div>
                                <div className="vertical-line"></div>
                                {/* Search filter div  */}
                                <div className='ml-2'>
                                    <div className="p-0.5 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center">
                                        <div className="text-white p-1 hover:cursor-pointer" >
                                            <svg width="15" height="19" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                                                    fill="#D4D4D4"
                                                />
                                            </svg>
                                        </div>
                                        <input
                                            type="text"
                                            className="w-40 text-sm bg-transparent text-textColor focus:outline-none"
                                            placeholder="Enter Device Id"
                                            value={inputDevice}
                                            onChange={(e) => setInputDevice(e.target.value)}
                                            
                                        // onKeyDown={handleKeyPress}
                                        // onKeyUp={handleKeyUp}
                                        // onFocus={handleFocus}
                                        />
                                    </div>
                                </div>
                                &nbsp;
                                <div className='custom-select custom_dropdown_notes' >
                                    <button type="button" onClick={() => handleShowTableData()} disabled={CustomerOptionSelected.value==undefined || SiteOptionSelected.value==undefined || (DeviceOptionSelected.value==undefined && inputDevice=='') ? true : false} className={CustomerOptionSelected?.label == "All Customers" ? "notes_adv_fil_disabled_btn py-1 px-2 rounded" : "btn_yllw_blk py-1 px-2 rounded"}>
                                        <i className='pi pi-align-left'></i>&nbsp;&nbsp;
                                        View Ground Data
                                    </button>
                                </div>
                                
                                {/* {<div > {(getUptimereportHeader())} </div>} */}
                                <div className='ml-auto cursor-pointer'>
                                    {/* <div className="inline-flex rounded-md shadow-sm" role="group" style={{ height: "34px" }}>
                                        <button
                                            type="button"
                                            className={`px-2 py-1 text-sm font-medium bg-transparent border border-gray-300 rounded-s-md disabled:opacity-50 disabled:pointer-events-none ${selectedValue === 'Percentage' ? 'bg-gray-300 border border-gray-400' : 'hover:bg-gray-300 hover:text-black'} `}
                                            onClick={() => handleClick('Percentage')}
                                            disabled={hasEmptyArrays}
                                        >
                                            Percentage
                                        </button>
                                        <button
                                            type="button"
                                            className={`px-4 py-1 text-sm font-medium bg-transparent border border-gray-300 rounded-e-md  disabled:opacity-50 disabled:pointer-events-none ${selectedValue === 'Hours' ? 'bg-gray-300 border border-gray-400' : 'hover:bg-gray-300 hover:text-black'}`}
                                            onClick={() => handleClick('Hours')}
                                            disabled={hasEmptyArrays}
                                        >
                                            Hours
                                        </button>
                                    </div>
                                    &nbsp; */}
                                </div>
                                {/* Hierarchy dropdown div */}
                                {/* <div className='custom-select custom_dropdown_notes' >
                                    <button type="button" onClick={() => handleShowPopup()} disabled={CustomerOptionSelected?.label == "All Customers" ? true : false} className={CustomerOptionSelected?.label == "All Customers" ? "notes_adv_fil_disabled_btn py-1 px-2 rounded" : "btn_yllw_blk py-1 px-2 rounded"}>
                                        <i className='pi pi-align-left'></i>&nbsp;&nbsp;
                                        Advanced Filtering
                                    </button>
                                </div>
                                &nbsp; */}
                            </div>
                            <div className="flex flex-wrap mt-3 gap-6">
                                <div className="ml-2 mr-2">
                                        <TimecardDatepicker
                                            pushSelectedDate={updatedSelectedDate}
                                            timeSeriesRange={""}
                                            timeRange={timeRange}
                                            />
                                </div>
                                {/* Search filter div  */}
                                <div className='ml-2'>
                                    <div className="p-0.5 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center">
                                        <div className="text-white p-1 hover:cursor-pointer" >
                                            <svg width="15" height="19" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                                                    fill="#D4D4D4"
                                                />
                                            </svg>
                                        </div>
                                        <input
                                            type="text"
                                            className="w-40 text-sm bg-transparent text-textColor focus:outline-none"
                                            placeholder="Search Data"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        // onKeyDown={handleKeyPress}
                                        // onKeyUp={handleKeyUp}
                                        // onFocus={handleFocus}
                                        />
                                    </div>
                                </div>
                                <div className='pl-2 pr-2'>
                                    <div className="p-0.5 pr-1 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center" style={{ height: "34px" }} >
                                        <div className="p-1 ">
                                            {/* <button type="button" className={`ml-auto flex items-center space-x-1 disabled:opacity-50 disabled:pointer-events-none`} disabled={showTable?false:true}>
                                                <CSVLink className="ml-auto flex items-center space-x-1" data={csvData}
                                                    filename={`History_Uptime Report_${moment(startDateTime).format('MMM-YYYY')}_to_${moment(endDateTime).format('MMM-YYYY')}`}
                                                >
                                                    <span className="text-sm"> &nbsp;Download</span>&nbsp;
                                                </CSVLink>
                                                <DownloadIcon />
                                            </button> */}

                                            <Button label="Download CSV" icon="pi pi-download" className="p-mb-3 text-textColor" onClick={exportCSV} disabled={showTable?false:true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {state.isLoading ? (
                    renderLoading
                ) : (
                    <>
                       {showTable==false? (
                            filterSelection                            
                        ) : (
                            <>
                                {IsNullOrEmpty(state.data) ? (
                                    renderNoData
                                ):(
                                    <>
                                        <DataTable value={filteredData} paginator rows={8}  stripedRows
                                        exportFilename="groundDataReport"
                                            rowsPerPageOptions={[8, 20, 30, 40, 50]}  
                                            ref={dts} 
                                            selectionMode="single" 
                                            dataKey="id" 
                                            tableStyle={{ minWidth: '50rem' }}
                                            currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink">
                                            <Column field="time" sortable  header="Date"></Column>
                                            <Column field="data" sortable  header="Data"></Column>
                                            <Column field="isWindData" sortable header="Has Wind Data" style={{ width: '10%' }}
                                            body={(body_val) => {
                                                return (
                                                  <>
                                                    {body_val.isWindData==1?
                                                    (<div className='text-sm '>
                                                        <div className="custom_active_alert bg-green-100 text-green-800 text-sm font-medium mr-2 px-1.5 pt-2 rounded dark:bg-red-900 dark:text-green-300">
                                                            yes
                                                        </div>
                                                    </div>):

                                                    (<div className='text-sm '>
                                                        <div className="custom_active_alert bg-red-100 text-red-800 text-sm font-medium mr-2 px-1.5 pt-2 rounded dark:bg-red-900 dark:text-red-300">
                                                            No
                                                        </div>
                                                    </div>)
                                                    }
                                                  </>
                                                );
                                              }}></Column>
                                        </DataTable>
                                    </>

                               
                                )
                            }
                            </>
                        )}
                    </>
                )}
            </div>
            <Dialog header="Alert Message" visible={visible} onHide={handleCancel} className='w-4 '  headerStyle={{ color: 'rgba(0, 0, 0, .65) '}}>
                <p className='mt-4 mb-4 text-base font-medium'>{AlertMessage}</p>
                <div className="flex justify-end space-x-2">                
                    <button onClick={handleCancel} className="bg-white text-base text-slate-500 px-4 py-2 rounded  border border-slate-500 ">
                    Close
                    </button>
                </div>
            </Dialog>
            
        </>
    )

};

export default GroundDataReportTable;