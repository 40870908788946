import {
    DataTable as PrimeDatatable,
    DataTableFilterMeta,
} from "primereact/datatable";
import { useEffect, useState, useRef, useCallback } from "react";
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';
import { Button } from 'primereact/button';
import "./assets/css/billing.css";
import "../../analytics.css";
import NoteServices from "../../../../config/services/notes-services/notes-services";
import { Toast } from 'primereact/toast';
import { useSelector } from "react-redux";
import { getTimzeZoneForAPI, getTimzeZoneUI } from "../../../../utils/CommonUtils";
import Select from 'react-select';
import moment from "moment";
import BillingTable from "./components/BillingTable";
import regenerateIcon from './assets/img/circle_arrow_icon.png';
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import DeleteIcon from '../../assets/trash.png';

interface DateTimeType {
    StartDate: String;
    EndTime: String;
}
const BillingReport = (props: any) => {
    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    let timezoneForUI = getTimzeZoneUI(userPrefstate.data.timeZone);
    let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
    const initialTimeRange: DateTimeType = {
        StartDate: moment.tz(timezoneForAPI).subtract(6, "days").format("MM-DD-YYYY HH:mm"),
        EndTime: moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm"),
    };
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const toastBottomCenter = useRef<any>(null);
    const [tempLoading, SettempLoading] = useState<any>(true);
    const [SiteOptionSelected, SetSiteOptionSelected] = useState<any>([]);
    const [HierOption, SetHierOption] = useState<any>([]);
    const [SiteOption, SetSiteOption] = useState<any>([]);
    const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>([]);
    const [HierOptionSelected, SetHierOptionSelected] = useState<any>([]);
    const [advFilValue, setAdvFilValue] = useState<any>(null);
    const [enableFilterStatus, SetEnableFilterStatus] = useState(false);
    const [selectUptimeReport, setSelectUptimeReport] = useState<any>([]);
    const [CustomerOption, SetCustomerOption] = useState<any>([]);
    const [enabFilterPopup, SetEnabFilterPopup] = useState(false);
    const [advFilOption, setAdvFilOption] = useState<any>([]);
    const [timeRange, setTimeRange] = useState<DateTimeType>(initialTimeRange);
    const [findLabels, SetFindLabels] = useState<any>([]);
    const [findSiteId, SetFindSiteId] = useState<any>([]);
    const [findLabels2, SetFindLabels2] = useState<any>([]);
    const [currentData, SetCurrentData] = useState<any>([]);
    const [popUpFilterData, SetPopUpFilterData] = useState<any>([]);
    const [overviewData, setOverviewData] = useState<any>([]);
    const [SortField, setSortField] = useState("order");
    const [SortFieldSeq, setSortFieldSeq] = useState<any>(-1);
    const [chartNodata, setChartNodata] = useState(false);
    const updatedSelectedDate = useCallback<Function>((dt: any) => {
        setTimeRange(dt);
    }, [timeRange]);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const defaultToDate = moment().startOf('month');
    const defaultFromDate = moment(defaultToDate).subtract(1, 'month').startOf('month');
    const [fromDate, setFromDate] = useState<Nullable<Date>>(defaultFromDate.toDate());
    const [toDate, setToDate] = useState<Nullable<Date>>(defaultToDate.toDate());
    const maxDate = new Date(currentYear, currentMonth, 1);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayEdit, setDisplayEdit] = useState(false);
    const [downloadConfirm, setDownloadConfirm] = useState(false);

    // State Update for delete
    const [deleteId, setDeleteId] = useState<any>("");
    const [deleteAlarmId, setDeleteAlarmId] = useState<any>("");
    const [position, setPosition] = useState('center');

    const parsedDate = moment(fromDate);

    const year = parsedDate.year();
    const month = parsedDate.month() + 1; 

    const duration = `${String(year).slice(-2)}${String(month).padStart(2, '0')}`;

    const extractedYear = fromDate ? fromDate.getFullYear() : new Date().getFullYear();
    const extractedMonth = fromDate ? fromDate.getMonth() : new Date().getMonth(); 

    const monthNamesArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const formattedMonthYear = `${monthNamesArray[extractedMonth]}-${extractedYear}`;

    const billingTableRef = useRef<any>(null);
    const [dialogVisible, setDialogVisible] = useState(false);

    const handleOptionSelect = (e: any) => {
        SetSiteOption([]);
        SetHierOptionSelected([]);
        SetHierOption([]);
        SetCustomerOptionSelected(e);
        SetFindLabels2([])
        setAdvFilValue([])
        SetEnableFilterStatus(false)
        setSelectUptimeReport(e);
        if (e?.children?.length > 0) {
            let temp_hier: any = []
            e?.children?.map((cust_val: any, index: any) => {
                temp_hier.push({
                    value: index + 1
                    , ...cust_val
                })
            });
            SetHierOption(temp_hier);
        }
    }
    const handleShowPopup = () => {
        SetEnabFilterPopup(true)
        const filDAta = CustomerOption.filter((itm: any) => itm.customerId == CustomerOptionSelected.value)
        const flattenedData = flattenTree(filDAta[0], null, 0);
        setAdvFilOption([flattenedData])
    }
    const flattenTree = (node: any, parentId: any, index: any) => {
        const key: any = parentId ? `${parentId}-${index}` : `${index}`;
        const flattenedNode: any = {
            key,
            label: node?.label,
            children: [],
            data: node?.siteId,
        };
        if (node.children && node.children.length > 0) {
            node.children.forEach((child: any, i: any) => {
                const childNode: any = flattenTree(child, key, i);
                flattenedNode.children.push(childNode);
            });
        }
        return flattenedNode;
    };
    const handleOptionSelectHier = (e: any) => {
        SetHierOptionSelected(e);
        if (e?.children?.length > 0) {
            let temp_hier: any = []
            e?.children?.map((cust_val: any) => {
                temp_hier.push({
                    value: cust_val.customerId
                    , ...cust_val
                })
            });
            SetSiteOption(temp_hier);
        }
    }
    const handleOptionSelectSite = (e: any) => {
        SetSiteOptionSelected(e);
    }
    const headerContent = (
        <>
            <div className='font-semibold' style={{ marginBottom: '15px', fontSize: '14px' }}>
                <i className='pi pi-align-left font-semibold' style={{ fontSize: '14px' }}></i>&nbsp;&nbsp; Advanced Filtering
            </div>
            <hr style={{ marginLeft: '-24px', marginRight: '-56px' }} />
            <div className='header_cus_bre_cmb mt-2'>
                <span style={{ textDecoration: 'underline', fontWeight: '500' }}>Customers</span> \ <span style={{ textDecoration: 'underline' }}>{CustomerOptionSelected?.label}</span>
            </div>
        </>
    )
    const footerContent = (
        <div>
            <button
                className=""
                onClick={() => {
                    SetEnabFilterPopup(false)
                    SetEnableFilterStatus(false)
                    //setAdvFilValue([])
                }}
                style={{ textDecoration: 'underline' }}
            > Close </button>
            &nbsp;&nbsp;
            <button
                className="btn_yllw_blk font-bold py-2 px-4 rounded"
                onClick={() => {
                    SetEnabFilterPopup(false)
                    SetEnableFilterStatus(true)
                    if (findLabels?.length > 0) {
                        SetFindLabels2(findLabels)
                    }
                }}
            > Apply </button>
        </div>
    );
    function getCheckedNodes(array: any, filterObject: any) {
        const checkedNodes: any = [];
        function recursiveCheck(node: any) {
            const nodeKey = node.key;
            const filterInfo = filterObject[nodeKey];
            if (filterInfo && filterInfo.checked) {
                checkedNodes.push({
                    key: nodeKey,
                    label: node.label,
                    data: node.data
                });
            }
            if (node.children && node.children.length > 0) {
                node.children.forEach((child: any) => {
                    recursiveCheck(child);
                });
            }
        }
        array.forEach((rootNode: any) => {
            recursiveCheck(rootNode);
        });
        return checkedNodes;
    }
    const handleFiterSelectionChange = (event: any) => {
        setAdvFilValue(event.value)
        SetEnableFilterStatus(false)
        const filteredResult = Object.keys(event?.value)?.length > 0 ? getCheckedNodes(advFilOption, event.value) : [];
        if (filteredResult?.length != 0) {
            // Extracting labels from the labels array
            let labelValues = filteredResult.map((item: any) => item.label);
            let SiteIdValues = filteredResult.map((item: any) => item.data);
            SetFindSiteId(SiteIdValues)
            SetFindLabels(labelValues)
            // Filtering data based on labels
            let filteredData = currentData?.filter((item: any) => labelValues.includes(item.siteName));
            SetPopUpFilterData(filteredData)
        }
    };
    useEffect(() => {
        NoteServices.getCustomerHier().then((res: any) => {
            let temp_cust: any = [];
            if (res.length > 0) {
                res.map((cust_val: any) => {
                    temp_cust.push({
                        value: cust_val.customerId
                        , ...cust_val
                    })
                })
            }
            if (temp_cust?.length == 1) {
                SetCustomerOptionSelected({ 'label': temp_cust[0]?.label, "value": temp_cust[0]?.customerId })
            } else {
                // temp_cust.push({
                //     'children': [],
                //     'customerID': 0,
                //     'groupId': 0,
                //     'hasAccess': false,
                //     'isPrimaryCustomer': false,
                //     'label': 'All Customers',
                //     'parentId': 0,
                //     'siteId': ""
                // })
                const index = temp_cust.findIndex((item: any) => item?.label === "All Customers");
                //Move the object to the first position if found
                if (index !== -1) {
                    const allCustomerObject = temp_cust.splice(index, 1)[0];
                    temp_cust.unshift(allCustomerObject);
                }
            }
            SetCustomerOption(temp_cust)
        })
        SetCustomerOptionSelected({
            'children': [],
            'customerID': 0,
            'groupId': 0,
            'hasAccess': false,
            'isPrimaryCustomer': false,
            'label': 'Select Customer',
            'parentId': 0,
            'siteId': ""
        })
    }, [])
    useEffect(() => {
        // ReportServices.GetTableOverview({startDate:props.timeRange.StartDate, endDate:props.timeRange.EndTime}).then((res: any) => {
        //     SettempLoading(false);
        //     setOverviewData(res);
        // })
    }, [props.timeRange])
    const downloadOverviewCsv = async (fileName: string, date: string) => {
        try {
            //const response:any = await ReportServices.GetTableOverviewRowDownload(fileName,date);
            const response: any = [];
            const rows: any = response.split('\r\n');
            const headers: any = rows[0].split(',');
            let bodyData = rows.slice(1).map((row: any) => row.split(',').join(',')).join('\r\n');
            const csvData = headers.join(',') + '\r\n' + (!chartNodata ? bodyData : '');
            const formattedDate = moment(date, 'DD-MM-YYYY').format('DD-MMM-YYYY');
            const filename = `Troublesites_${formattedDate}.csv`;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const pseudoelement = document.createElement("a");
            pseudoelement.href = window.URL.createObjectURL(blob);
            pseudoelement.download = filename;
            document.body.appendChild(pseudoelement);
            pseudoelement.click();
            document.body.removeChild(pseudoelement);
        } catch (error) {
            console.error("Error Troubleshooting Stas Table Overview Sites CSV:", error);
        }
    };
    const renderLoading = (
        <div>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm">Loading Data...</div>
        </div>
    );
    const dialogFuncMap: any = {
        'displayBasic': setDisplayBasic,
        'displayEdit': setDisplayEdit,
        'downloadConfirm': setDownloadConfirm
    }
    const onHide = (name: any) => {
        dialogFuncMap[`${name}`](false);
    }
    const renderFooter = (name: any) => {
        return (
            <div onClick={() => onHide(name)} style={{ textDecoration: "underline", cursor: "pointer" }}>
                Close
            </div>
        );
    }
    const renderFooterDownload = (name: any) => {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>

                <div>
                    <Button onClick={() => { onHide(name) }} label="Cancel" className="dialog-cancel-btn"/>
                </div>
                <div>
                    <Button onClick={() => { handleDownloadSubmit(name) }} label="Ok" className="dialog-ok-btn"/>
                </div>
            </div>
        );
    }
    const handleDownloadSubmit = (name: any) => {
        onHide(name);
        // NoteServices.DeleteNote(deleteId, deleteAlarmId).then((res: any) => {
        //     onHide(name);
        //     if (res == "OK") {
        //         fetchNotes();
        //         toastBottomCenter.current.show({ severity: "success", summary: "Delete", detail: "Note Deleted Successfully", life: 3000 });
        //     } else {
        //         toastBottomCenter.current.show({ severity: "error", summary: "Delete", detail: "Something went wrong", life: 3000 });
        //     }
        // })
    }
    const onClickDelete = (name: any, position: any) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const openDialog = () => {
        setDialogVisible(true);
    };

    const closeDialog = () => {
        setDialogVisible(false);
    };

    const handleRegenerate = () => {
        billingTableRef.current.handleRegenerate();
        closeDialog();
    };

    const dialogFooter = (
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <button 
                onClick={handleRegenerate}
                style={{
                    backgroundColor: '#25476a',
                    color: '#ffffff',
                    padding: '8px 16px',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '4px'
                }}
            >
                Ok
            </button>
            <button 
                onClick={closeDialog} 
                style={{
                    border: '1px solid #c8c8c8',
                    backgroundColor: '#ffffff',
                    color: '#25476a',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    borderRadius: '4px'
                }}
            >
                Cancel
            </button>
        </div>
    );

    return (<>
        <div className="card p-10 tett table_card trbl-stats-container">
            <Toast ref={toastBottomCenter} position="bottom-center" />
            <div className="flex flex-col">
                <div className="trbl-stats-top-header">
                    <div className="flex flex-rowp-4 m-2 float-right">
                        <div className="card flex justify-content-centers pt-1">
                            <div className="pt-1 flex">
                                <span style={{ fontSize: '15px' }} className="text-textColor">Duration  : &nbsp;  </span><svg width="15" height="18" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.5 8C6.75 8 7 8.25 7 8.5V11.5C7 11.7812 6.75 12 6.5 12H3.5C3.21875 12 3 11.7812 3 11.5V8.5C3 8.25 3.21875 8 3.5 8H6.5ZM4 0C4.40625 0 4.75 0.34375 4.75 0.75V2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0ZM12.5 6H1.5V14C1.5 14.2812 1.71875 14.5 2 14.5H12C12.25 14.5 12.5 14.2812 12.5 14V6Z" fill="#D4D4D4" />
                                </svg>
                            </div>
                            &nbsp;&nbsp;
                            <div className="pt-1 p-grid p-fluid">
                                <div className="flex space-x-4">
                                    <div className="flex items-center">
                                        {/* API Integration on date change */}
                                        <Calendar
                                            value={fromDate}
                                            view="month"
                        
                                            dateFormat="M-yy"
                                            className="w-16 text-sm text-textColor"
                                            maxDate={maxDate}
                                            onChange={(e) => setFromDate(moment(e.value).toDate())}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        &nbsp;&nbsp;
                        <div className="billing-customer-dd-container">
                            <div className='custom_dropdown_notes trbl-customer-dd dropdown-icon' >
                                <span className="aftericon">
                                    <i className="pi pi-map-marker text-textColor" />
                                </span>
                                <Select
                                    placeholder="Customers"
                                    value={CustomerOptionSelected}
                                    //defaultValue={{ label: "All Customers", value: "0" }}
                                    options={CustomerOption}
                                    onChange={handleOptionSelect}
                                    isSearchable={false}
                                    styles={{
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                                          color: state.isFocused ? "#000000" : "#D4D4D4",
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          backgroundColor: "#131F2E", 
                                          color: "#D4D4D4",
                                          border: "1px solid #3C4F61"
                                        }),
                                        menuPortal: (provided) => ({
                                          ...provided,
                                          zIndex: 9999, // Ensures dropdown stays on top of all elements
                                        }),
                                      }}
                                      menuPortalTarget={document.body}
                                />
                            </div>
                            &nbsp;
                            {/* API Integration on click of Regenerate */}
                            <Button
                                className="p-0.5 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center"
                                style={{ height: "1em", color: "#D4D4D4", padding: "16px" }}
                                onClick={openDialog}
                            >
                                <img src={regenerateIcon} alt="Regenerate" className="selected-icon-right-arrow" /> Regenerate
                            </Button>
                            &nbsp;&nbsp;
                            {/* API Integration on click of Download all - Download all the customer billing report */}
                            <div className="p-0.5 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center"
                                style={{ height: "1em", padding: "16px 16px 16px 16px" }} onClick={() => {
                                    onClickDelete('downloadConfirm', 'center')
                                }} >
                                <i className="pi pi-download text-textColor" ></i><span className="text-sm text-textColor"> &nbsp;Download all</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 m-2">
                    <BillingTable
                        ref={billingTableRef}
                        formattedMonthYear={formattedMonthYear}
                        tempLoading={tempLoading}
                        timeRange={timeRange}
                        selectedCustomer={CustomerOptionSelected}
                        duration={duration}
                        fromDate={fromDate}
                    />
                </div>
            </div>
          </div>
          <Dialog
            header={headerContent}
            visible={enabFilterPopup}
            style={{ width: "50vw" }}
            onHide={() => SetEnabFilterPopup(false)}
            footer={footerContent}
          >
            <p className="m-0">
              <Tree
                value={advFilOption}
                selectionMode="checkbox"
                selectionKeys={advFilValue}
                onSelectionChange={(e) => handleFiterSelectionChange(e)}
                className="w-full md:w-30rem"
              />
            </p>
          </Dialog>
          {/* Start Download all Confirm */}
          <Dialog
            header={
              <>
                <div style={{ display: "flex" }}>
                  <img src={DeleteIcon}></img>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#25476a",
                      marginLeft: "8px",
                      fontWeight: "500",
                    }}
                  >
                    Download all
                  </div>
                </div>
            </>} visible={downloadConfirm} style={{ width: '20vw' }} footer={renderFooterDownload('downloadConfirm')} onHide={() => onHide('downloadConfirm')}>
                <div style={{ marginTop: "15px" }}>
                    Do you want to download monthly billing report for all customers for the selected month?
                </div>
            </Dialog>
            {/* End Download all Confirm */}
      </>
    );
}

export default BillingReport;