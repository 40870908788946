import React from "react";
import { Tree } from 'primereact/tree';

const SelectedHierarchy =({data}:{data: any[] })=>{

 // Function to convert flat data to nested structure
 const buildTree = (data: any[]) => {
    const tree: any = [];
    const lookup: any = {};

    data.forEach(item => {
        lookup[item.key] = { ...item, children: [] };
    });

    data.forEach(item => {
        if (item.key.includes('-')) {
            const parentKey = item.key.substring(0, item.key.lastIndexOf('-'));
            if (lookup[parentKey]) {
                lookup[parentKey].children.push(lookup[item.key]);
            } else {
                console.warn(`Parent key ${parentKey} not found for item ${item.key}`);
            }
        } else {
            tree.push(lookup[item.key]);
        }
    });

    return tree;
};

const treeData = buildTree(data);

    return (
        <div className="tett table_card relative w-[584px] min-h-[428px] min-w-[540px] top-[-20px] left-[53px] p-[24px_0px] rounded-tl-[8px]">
            <div className="pl-1" >  
                <div className="flex flex-col">
                    <div className="header flex justify-between items-center pb-2">
                        <span className="font-mulish text-[20px] font-medium text-left">Selected Hierarchy</span>
                    </div>
                    <div className="border-b-[1px] border-solid border-[#BEBEBC] w-[109%] ml-[-5%]" />
                    <Tree value={treeData} className="w-full md:w-30rem" />
                </div>
            </div>
        </div>
    )
};

export default SelectedHierarchy;