import moment from "moment";
import "moment-timezone";
export const formatDate = (date: any, timeZone?: string) => {
  let dt = (date && date !== null && date) || new Date();
  const day = String(dt.getDate()).padStart(2, '0');
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const hours = String(dt.getHours()).padStart(2, '0');
  const minutes = String(dt.getMinutes()).padStart(2, '0');
  if (!timeZone || timeZone === "" || timeZone == null) {
    return `${month}-${day}-${year} ${hours}:${minutes}`;
  }
  return `${month}-${day}-${year} ${hours}:${minutes} ${timeZone}`;
}

export const formatDateOnly = (date: any) => {
  let dt = (date && date !== null && date) || new Date();
  const day = String(dt.getDate()).padStart(2, "0");
  const month = String(dt.getMonth() + 1).padStart(2, "0");
  const year = dt.getFullYear();
  return `${month}-${day}-${year} ${"00"}:${"00"}`;
};
export const timeZone_api = moment.tz.guess(); // "Asia/Calcutt"
export const timeZone_ui = moment.tz(moment.tz.guess()).format("z"); // IST,CST...
export const getEmsDateTimeFormat = (date: any, tzStr: string) => {
  try {
    return `${moment(date).format('MM-DD-YYYY HH:mm')} ${getTimzeZoneUI(tzStr)}`;
  } catch (error) {
    console.log(" Error - getEmsDateTimeFormat :", error);
    return ""
  }
}
export const isNodata = (value: any) => {
  return value === undefined || value === "" || value == null;
};
export const getUrlWithQueryString = (obj: any, url: string) => {
  const queryString = Object.entries(obj)
    .map(
      (el: any) =>
        `${encodeURIComponent(el.key)}=${encodeURIComponent(el.value)}`
    )
    .join("&");
  const result = `${url}?${queryString}`;
  return result;
};
export const getValuesFromQueryString = (urlStr: string) => {
  const urlData: any = {};
  const s = urlStr.replace("?", "");
  const searchArray = s.split("&");
  return searchArray.reduce((acc: any, curr: any) => {
    const seArray = curr.split("=");
    acc[seArray[0]] = seArray[1];
    return acc;
  }, {});
};

export const IsNullOrEmpty = (obj: any) => {
  if (
    !obj ||
    (typeof obj === "object" &&
      Object.keys(obj).length === 0 &&
      !Array.isArray(obj)) ||
    (Array.isArray(obj) && obj && !obj.length)
  ) {
    return true;
  } else {
    return false;
  }
};
export const getTimzeZoneUI = (tzStr: string) => {
  let tz = "";
  if (tzStr && tzStr != null) {
    tz = moment.tz(tzStr).format("z");
  } else {
    tz = moment.tz("America/Chicago").format("z"); // Default value will be CDT
  }
  return tz;
};
export const getDateTimeTzByTimeZone = (time: string, tzStr: string) => {
  let dateTime = "";
  if (time && time != null && tzStr && tzStr != null) {
    dateTime = moment.tz(time, tzStr).format("MM-DD-YYYY HH:mm");
  } else {
    dateTime = moment.tz("America/Chicago").format("MM-DD-YYYY HH:mm");
  }
  return `${dateTime} ${getTimzeZoneUI(tzStr)}`;
};
export const getTimzeZoneForAPI = (tzStr: string) => {
  let tz = "";
  if (tzStr && tzStr != null) {
    tz = tzStr;
  } else {
    tz = moment.tz.guess();
  }
  return tz;
};
export const getEmissionMetric = (uom: string) => {
  let mtr = "";
  if (uom === "Imperial") {
    mtr = "MCF/day";
  } else {
    mtr = "kg/hr";
  }
  return mtr;
};
export const getWindSpeedMetric = (uom: string) => {
  let mtr = "";
  if (uom === "Imperial") {
    mtr = "MPH";
  } else {
    mtr = "m/s";
  }
  return mtr;
};

export const getClientIP = async () => {
  const ipServiceUrl = 'https://api.ipify.org?format=json';
  try {
    const response = await fetch(ipServiceUrl);
    const data = await response.json();
    const ipAddress = data.ip;
    return ipAddress;
  } catch (error) {
    // console.error('Error fetching IP address:', error);
    return null;
  }
};
export const getJsonStringify = (obj: any) => JSON.stringify(obj);
export const getJsonParse = (str: any) => JSON.parse(str);
export const getDateTimeDifference = (startDate: any, endDate: any) => {
  const startTime = new Date(startDate).getTime();
  const endTime = new Date(endDate).getTime();
  const timeDifference = endTime - startTime;
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  return {
    days,
    hours,
    minutes,
    seconds
  };
}

export const ConvertUtcToLocalReadableFullDate = (UTC_FormatDate: any) => {
  let date = new Date(UTC_FormatDate);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.toLocaleDateString("default", {
    // you can use undefined as first argument
    day: "2-digit",
  });
  const year = date.toLocaleDateString("default", {
    // you can use undefined as first argument
    year: "numeric",
  });

  let hrs_mins = date.toLocaleString("default", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    second: "numeric",
  });
  let return_format = month + " " + day + ", " + year + " " + hrs_mins;

  return return_format;
};

export const UniteConversion = (unit_pref: any, value: any, unit_type: any) => {
  if (unit_pref == "Imperial") {
    if (unit_type == "temperature") {
      let fahrenheit: any = value * 1.8 + 32;

      return `${fahrenheit?.toFixed(1)} ${"\u00b0"} F`;
    }
    if (unit_type == "wind_speed") {
      let mi: any = value * 0.621371;
      return `${mi?.toFixed(1)} mph`;
    }

    if (unit_type == "ground_range") {
      let inchs: any = value / 2.54;
      return `${inchs?.toFixed(0)} inchs`;
    }
  } else {
    if (unit_type == "temperature") {
      return `${value} ${"\u00b0"} C`;
    }
    if (unit_type == "wind_speed") {
      return `${value} km/h`;
    }

    if (unit_type == "ground_range") {
      let mi: any = value * 0.621371;
      return `${value} cm`;
    }
  }
};

// Generate random color array start
export function generateLowContrastHashColors(numColors: any) {
  const colors = [
    "#85c5e3",
    "#85a9e3",
    "#858de3",
    "#9985e3",
    "#b585e3",
    "#d285e3",
    "#e385d7",
    "#e385bb",
    "#e38785",
  ];
  const minLightness = 45; // Adjust as needed for your desired lightness
  const maxLightness = 50; // Adjust as needed for your desired lightness

  for (let i = 9; i < numColors; i++) {
    const lightness =
      Math.floor(Math.random() * (maxLightness - minLightness + 1)) +
      minLightness;
    const hue = Math.floor(Math.random() * 360); // Random hue value between 0 and 360
    const saturation = Math.floor(Math.random() * 31) + 30; // Adjust saturation as needed

    // Convert HSL color to hexadecimal color code
    const rgbColor = hslToRgb(hue / 360, saturation / 100, lightness / 100);
    const hexColor = rgbToHex(rgbColor);

    colors.push(hexColor);
  }

  return colors;
}

// Function to convert HSL color to RGB color
function hslToRgb(h: any, s: any, l: any) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // Achromatic
  } else {
    const hue2rgb = (p: any, q: any, t: any) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

// Function to convert RGB color to hexadecimal color code
function rgbToHex(rgb: any) {
  return `#${rgb
    .map((value: any) => value.toString(16).padStart(2, "0"))
    .join("")}`;
}

// Generate random color array end

export function areCoordinatesWithinDistance(
  lat1: any,
  lng1: any,
  lat2: any,
  lng2: any,
  distanceThreshold: any
) {
  const earthRadius = 6371e3; // Earth radius in meters

  // Convert latitude and longitude from degrees to radians
  const toRadians = (angle: any) => (angle * Math.PI) / 180;
  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lng2 - lng1);

  // Haversine formula
  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in meters
  const distance = earthRadius * c;

  // Check if the distance is within the threshold
  return distance <= distanceThreshold;
}

// convert to CST
export const conVerttToCST = (utcTimestamp: any) => {
  // Given UTC timestamp

  // Convert UTC timestamp to Date object
  const date = new Date(utcTimestamp);

  // Adjust time for CST timezone (UTC-5)
  date.setUTCHours(date.getUTCHours() - 5);

  // Format the date and time as "21:00 CST, 09/29/2023"
  const formattedDate = `${padWithZero(date.getUTCHours())}:${padWithZero(
    date.getUTCMinutes()
  )} CST, ${padWithZero(date.getUTCMonth() + 1)}/${padWithZero(
    date.getUTCDate()
  )}/${date.getUTCFullYear()}`;
  return formattedDate;
};

// Helper function to pad single digits with zero
function padWithZero(num: any) {
  return num < 10 ? `0${num}` : num;
}
function parseDate(dateString: any) {
  const parts: any = dateString.match(/(\d+)-(\w+)-(\d+) (\d+):(\d+) (AM|PM)/);
  const months: any = {
    Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5, Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
  };
  const [, day, month, year, hours, minutes, period] = parts;
  const monthIndex: any = months[month];

  let hour = parseInt(hours, 10);
  if (period === 'PM' && hour !== 12) {
    hour += 12;
  } else if (period === 'AM' && hour === 12) {
    hour = 0;
  }
  return new Date(year, monthIndex, day, hour, parseInt(minutes, 10));
}

export function isNearestInterval(timeString: any, utcTimeStamp: any, intervalInMinutes: any) {
  // Parse the time string manually
  const time: any = parseDate(timeString);
  // Get the difference in minutes
  let temp_datee: any = new Date(utcTimeStamp)
  const differenceInMinutes = Math.abs((time - temp_datee) / (1000 * 60));
  // Check if the time difference is within half of the specified interval
  return differenceInMinutes <= intervalInMinutes / 2;
}
// const result = isNearestInterval('16-Oct-2023 06:45 AM', 1697437579683, 180); // Check if within 3-hour interval
export function getMinuteDifference(dateString1: any, dateString2: any) {
  // Parse the date strings
  const date1: any = new Date(dateString1);
  const date2: any = new Date(dateString2);
  // Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(date2 - date1);
  // Convert milliseconds to minutes
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
  return differenceInMinutes;
}
export const emissionTypeTxt = (alertType: string) => {
  if (alertType.includes("concentration")) {
    return "(Concentration)"
  } else if (alertType.includes("LEL")) {
    return "(LEL)"
  } else {
    return "(Rate)"
  }
}
export const alertTypeMetricTxt = (alert: any) => {
  return (alert.type.includes("concentration") || alert.type.includes("LEL")) ? `${alert.constituent}` : "";
}

export const UTCToDesiredTimezone = (utc: any, timezone: any) => {
  if (utc == null || utc == "") {
    return "";
  } else {
    var dec = moment(utc);
    return dec.tz(timezone).format('DD MMM-Y hh:mmA') + " " + timezone;
  }
}

export const convertUTCToTimezone = (utc: any, timezone: any) => {
  if (utc == null || utc == "") {
    return "";
  } else {
    var dec = moment(utc);
    return dec.tz(timezone).format('DD MMM-Y HH:mm') + " " + timezone;
  }
}



export const filterByAlertType = (emissionData: any[], alertTypes: string[]) => {
  const result = emissionData.reduce((acc: any, curr: any) => {
    const alerts = curr.alerts;
    const newCurr = Object.assign({}, curr);
    if (alerts) {
      const filteredAlerts = alerts && alerts.filter((e: any) => {
        return alertTypes.includes(e.type)
      });
      newCurr.alerts = [...filteredAlerts];
      if (newCurr.alerts.length) {
        acc.push(newCurr)
      }
    }
    return acc;
  }, []);
  return result;
}

//To check if the given text is valid URL or not
export const isValidUrl = (urlString: any) => {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return regexp.test(urlString);
}
export const getDateStrDMY =(dt:any)=> moment(dt).format("DD-MM-YYYY"); //  18-04-2024
export const getDateStrMDY =(dt:any)=> moment(dt).format("MM-DD-YYYY"); //  04-18-2024