import { Slice, createSlice } from "@reduxjs/toolkit";
import { fetchWorkorderHistory } from "../thunk/SitesOverviewThunk";

const initialData: any = [];

const WorkorderHistoryChartSlice: Slice<any, {}, "WorkorderHistory"> = createSlice({
    name: "WorkorderHistory",
    initialState: { data: initialData, isLoading: false, error: "" },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchWorkorderHistory.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchWorkorderHistory.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        });
        builder.addCase(fetchWorkorderHistory.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message ?? ""
        });
    }
})

export default WorkorderHistoryChartSlice
