import SampleNotes from "./sample_data/sample_notes.json";
import ax from "../../api/AxiosConfig";
import axios from "axios";
const GetNotes = async (timeRange: any) => {
  const StartDate = timeRange.StartDate;
  const EndTime = timeRange.EndTime;
  //return []
  return await ax
    .post(`Emission/Notes`, {

      "StartTime": StartDate,
      "EndTime": EndTime
    })
    .then(async (res: any) => {
      if (res?.data?.length > 0) {
        return res?.data?.sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          let b_date: any = new Date(b.updatedOn);
          let a_date: any = new Date(a.updatedOn);
          return b_date - a_date;
        });
      } else {
        return []
      }
    })
  // return await new Promise((resolve) => {

  //   resolve(SampleNotes);
  // });




  // return await ax
  //   .get(
  //     process.env.REACT_APP_AURA_ENDPOINT +
  //       "Sites/GetOperationSummaryDetails"

  //   )
  //   .then(async (res: any) => {})
};


const PutNote = async (post_data: any, id: any) => {
  return await ax.put(process.env.REACT_APP_API_ENDPOINT + "/Emission/UpdateNote", post_data)
    .then(async (res: any) => {
      if (res?.status == 200) {
        return true
      } else {
        return false
      }
    })
    .catch((err: any) => {
      console.log(err)
      return false
    })
}
const DeleteNote = async (id: any, alaramId: any) => {
  let delete_url = ""
  if (alaramId == null) {
    delete_url = `Emission/DeleteNote/${id}`
  } else {
    delete_url = `Emission/DeleteNote/${alaramId}/${id}`
  }

  return await ax
    .delete(
      delete_url,
      // `Emission/DeleteNote/${id}/${alaramId}`,

    )
    .then(async (res: any) => {
      if (res.data == "Deleted Note Successfully") {
        return "OK"
      } else {
        return "Not_OK"
      }
    }).catch(() => {
      return "Not_OK"
    })
}

const getCustomerHier = async () => {
  //return [];
  return await ax
    .get(
      process.env.REACT_APP_API_ENDPOINT +
      "/Emission/GetAssetHierarchy"
    )
    .then(async (res: any) => {
      return res.data;
    })
}

const getAllDevices = async (customerId: any, siteId: any) => {
  //return [];
  return await ax
    .get(
      process.env.REACT_APP_API_ENDPOINT +
      `/Emission/GetDevicesBySiteId/${customerId}/${siteId}`
    )
    .then(async (res: any) => {
      return res.data;
    })
}

const AddNote = async (add_data: any) => {
  return await ax.post( process.env.REACT_APP_API_ENDPOINT + "/Emission/AddNote", add_data)
    .then(async (res: any) => {
      if (res?.status == 200) {
        return true
      } else {
        return false
      }
    })
    .catch((err: any) => {
      console.log(err)
      return false
    })
}

const NoteServices = {
  GetNotes,
  PutNote,
  getCustomerHier,
  getAllDevices,
  DeleteNote,
  AddNote
};
export default NoteServices;
