import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from 'react-select';
import { getTimzeZoneForAPI, IsNullOrEmpty } from '../../../../utils/CommonUtils';
import { CSVLink } from "react-csv";
import UptimeReportDatePicker from '../../../Analytics/components/UptimeReportDatePicker';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from "../../../../store";
import { fetchSoofieUptimeGlobal } from "../../../../store/thunk/SitesOverviewThunk";
import { fetchSoofieUptimeExport } from '../../../../store/thunk/SitesOverviewThunk';
import NoteServices from '../../../../config/services/notes-services/notes-services';
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';
import "./assets/css/globalUptimeReport.css";

const DownloadIcon: React.FC = () => (
    <span className='inline-block text-center'>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-download"
            viewBox="0 0 16 16"
        >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
        </svg>
    </span>

);

const GlobalUptimeReportTable = (props: any) => {
    const isValidDate = (date: string): boolean => {
        return moment(date, 'MM-DD-YYYY HH:mm', true).isValid();
    };
    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);

    const [selectedValue, setSelectedValue] = useState('Percentage');
    const [columnData, setColumnData] = useState<string[]>([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const startDateTime = fromDate !== undefined && isValidDate(fromDate) ? fromDate : moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm");
    const endDateTime = toDate !== undefined && isValidDate(toDate) ? toDate : moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm");
    const currentDate = moment();
    const currentMonth = currentDate.format("MMMM-YYYY");

    let startDate: any = moment(startDateTime);
    let endDate: any = moment(endDateTime);

    const dispatch = useDispatch<AppDispatch>();

    const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>({ label: 'Select Customer', value: '' });

    const [SiteOptionSelected, SetSiteOptionSelected] = useState<any>([]);
    const [HierOption, SetHierOption] = useState<any>([]);
    const [SiteOption, SetSiteOption] = useState<any>([]);
    const [HierOptionSelected, SetHierOptionSelected] = useState<any>([]);
    const [CustomerOption, SetCustomerOption] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<any>("");
    const [findLabels2, SetFindLabels2] = useState<any>([]);
    const [advFilValue, setAdvFilValue] = useState<any>(null);
    const [enableFilterStatus, SetEnableFilterStatus] = useState(false);
    const [enabFilterPopup, SetEnabFilterPopup] = useState(false);
    const [advFilOption, setAdvFilOption] = useState<any>([]);
    const [findSiteId, SetFindSiteId] = useState<any>([]);
    const [findLabels, SetFindLabels] = useState<any>([]);
    const [currentData, SetCurrentData] = useState<any>([]);
    const [popUpFilterData, SetPopUpFilterData] = useState<any>([]);

    const [rowDataState, setRowDataState] = useState<any>(null);
    const [startDateDescriptionState, setStartDateDescriptionState] = useState<string>('');
    const [lastRowDataState, setLastRowDataState] = useState<any>(null);

    const state = useSelector((state: any) => state.soofieUptimeGlobal);

    
    const stateGlobalDownload = useSelector((state: any) => state.soofieUptimeExport);

    const outerCustomerId = CustomerOptionSelected?.customerId || '';

    const [customerId, setCustomerId] = useState<string>(outerCustomerId);
    let nonEmptySiteIds: any = [];

    let downloadCustomerName = CustomerOptionSelected?.label || '';


    nonEmptySiteIds = findSiteId?.filter((id: string) => id.trim() !== '');

    
    let isPercent: any = selectedValue === 'Percentage' ? true : false;

    useEffect(() => {
        setCustomerId(outerCustomerId);
    }, [outerCustomerId]);


    useEffect(() => {
        const startTime = startDate.format('MM-DD-YYYY');
        const endTime = endDate.format('MM-DD-YYYY');

        nonEmptySiteIds = findSiteId?.filter((id: string) => id.trim() !== '');

        dispatch(fetchSoofieUptimeGlobal({ customerId, siteIds: nonEmptySiteIds, startTime, endTime, isPercent }));
    }, [customerId, startDateTime, endDateTime, isPercent, findSiteId])



    useEffect(() => {
        const columnData: string[] = [];
        while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
            const formattedMonth = startDate.format("MMMM-YYYY");
            columnData.push(formattedMonth);
            startDate.add(1, 'month');
        }
        setColumnData(columnData);
    }, [props.timeRange, startDateTime, endDateTime]);

    
    // Group data by siteId
    const groupedData = state.data.reduce((acc: any, obj: any) => {
        
        const siteId = obj.siteId;
        if (!acc[siteId]) {
            acc[siteId] = [];
        }
        acc[siteId].push(obj);
        
        return acc;
    }, {});

    // Convert groupedData object to an array of arrays
    const groupedArray = Object.values(groupedData);

    
    
    const mappedData = groupedArray.map((items: any) => {
        return items.filter((item: any) => columnData.includes(item.time));
    }).filter(items => items.length > 0);


    const hasEmptyArrays = mappedData.some(array => array.length === 0);
    const lastColumnHeader = columnData[columnData.length - 1];
    const isCurrentMonthLastColumn = currentMonth === lastColumnHeader;

    const getAssetName = (rowData: any[]) => {
        const asset = rowData.find(obj => obj.siteName);
        return asset ? asset.siteName : 'N/A';
    };

    const downloadData = () => {
        if (stateGlobalDownload.data && typeof stateGlobalDownload.data === 'string') {
            const rows = stateGlobalDownload.data.split('\r\n');
            const headers = rows[0].split(',');
            let bodyData = rows.slice(1).map((row: any) => row.split(',').join(',')).join('\r\n');
            const csvData = headers.join(',') + '\r\n' + bodyData;
            const filename = `SOOFIE Uptime-${downloadCustomerName}_${lastRowDataState === undefined ? rowDataState.siteName : lastRowDataState.siteName}_${startDateDescriptionState}  .csv`;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const pseudoelement = document.createElement("a");
            pseudoelement.href = window.URL.createObjectURL(blob);
            pseudoelement.download = filename;
            document.body.appendChild(pseudoelement);
            pseudoelement.click();
            document.body.removeChild(pseudoelement);
        }
    };

    useEffect(() => {
        downloadData();
    }, [stateGlobalDownload.data]);

    const handleDownload = async (rowData: any) => {
        const lastRowData = rowData[rowData.length - 1];
        setLastRowDataState(lastRowData);

        const currentMonthYear = moment().format('MMMM-YYYY');
        const isCurrentMonthYear = moment(lastRowData?.time, 'MMMM-YYYY').isSame(currentMonthYear, 'month');

        let startDate, endDate;
        if (isCurrentMonthYear && lastRowData.time === currentMonthYear) {
            endDate = moment().format('MM-DD-YYYY');
            startDate = moment(endDate, 'MM-DD-YYYY').subtract(30, 'days').format('MM-DD-YYYY');
        } else if (!isCurrentMonthYear && lastRowData?.time !== currentMonthYear && lastRowData) {
            startDate = moment(lastRowData?.time, 'MMMM-YYYY').format('MM-DD-YYYY');
            endDate = moment(startDate, 'MM-DD-YYYY').add(1, 'month').subtract(1, 'day').format('MM-DD-YYYY');
        } else {
            startDate = moment(rowData.time, 'MMMM-YYYY').format('MM-DD-YYYY');
            endDate = moment(startDate, 'MM-DD-YYYY').add(1, 'month').subtract(1, 'day').format('MM-DD-YYYY');
        }
        const startDateFormatted = moment(startDate, 'MM-DD-YYYY').format('MMMM-YYYY');
        const startDateDescription = isCurrentMonthYear ? 'previous 30 days' : startDateFormatted;

        setRowDataState(rowData);
        setStartDateDescriptionState(startDateDescription);

        try {
            let CustomerId = customerId;
            let SiteId = lastRowData === undefined ? rowData.siteId : lastRowData?.siteId;
            let StartTime: any = startDate;
            let EndTime: any = endDate;

            dispatch(fetchSoofieUptimeExport({ CustomerId, SiteId, StartTime, EndTime }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleClick = (value: any) => {
        setSelectedValue(value);
    };
    const handleFromDateChange = (date: any) => {
        setFromDate(date);
    };
    const handleToDateChange = (date: any) => {
        setToDate(date);
    };
    const csvData = [
        ["Site Name", ...columnData.flatMap(month => [month, "", ""])],
        ["", ...columnData.flatMap(() => ["Hardware", "Environmental", "Uptime"])],
        ...mappedData.map(row => {
            const rowData = columnData.flatMap(month => {
                const dataForMonth = row.find((obj: any) => obj.time === month);
                if (dataForMonth) {
                    return [`${Math.round(dataForMonth.hardware)} ${selectedValue === 'Percentage' ? '%' : ''}`, `${Math.round(dataForMonth.environmental)} ${selectedValue === 'Percentage' ? '%' : ''}`, `${Math.round(dataForMonth.uptime)} ${selectedValue === 'Percentage' ? '%' : ''}`];
                } else {
                    return ["N/A", "N/A", "N/A"];
                }
            });
            return [getAssetName(row), ...rowData];
        }),
    ];
    const renderLoading = (
        <div>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm text-textColor">Loading Data...</div>
        </div>
    );
    const renderNoData = (
        <div className="px-5 py-5 text-bold text-center">
            <div className="flex justify-center items-center text-textColor">
                <h1>No data available.</h1>
            </div>
        </div>
    );

    const handleOptionSelect = (e: any) => {
        SetSiteOption([]);
        SetHierOptionSelected([]);
        SetSiteOptionSelected([]);
        SetHierOption([]);
        SetCustomerOptionSelected(e);
        SetFindLabels2([])
        setAdvFilValue([])
        SetEnableFilterStatus(false)
        if (e?.children?.length > 0) {
            let temp_hier: any = []
            e?.children?.map((cust_val: any, index: any) => {
                temp_hier.push({
                    value: index + 1
                    , ...cust_val
                })
            });
            SetHierOption(temp_hier);

        }
    }


    const handleShowPopup = () => {
        SetEnabFilterPopup(true)
        const filDAta = CustomerOption.filter((itm: any) => itm.customerId == CustomerOptionSelected.value)
        const flattenedData = flattenTree(filDAta[0], null, 0);
        setAdvFilOption([flattenedData])
    }


    const flattenTree = (node: any, parentId: any, index: any) => {
        const key: any = parentId ? `${parentId}-${index}` : `${index}`;
        const flattenedNode: any = {
            key,
            label: node?.label,
            children: [],
            data: node?.siteId,
        };

        if (node.children && node.children.length > 0) {
            node.children.forEach((child: any, i: any) => {
                const childNode: any = flattenTree(child, key, i);
                flattenedNode.children.push(childNode);
            });
        }
        return flattenedNode;
    };

    const handleFiterSelectionChange = (event: any) => {
        setAdvFilValue(event.value)
        SetEnableFilterStatus(false)
        const filteredResult = Object.keys(event?.value)?.length > 0 ? getCheckedNodes(advFilOption, event.value) : [];
        if (filteredResult?.length != 0) {
            // Extracting labels from the labels array
            let labelValues = filteredResult.map((item: any) => item.label);
            let SiteIdValues = filteredResult.map((item: any) => item.data);
            
            SetFindSiteId(SiteIdValues)
            SetFindLabels(labelValues)
            // Filtering data based on labels
            let filteredData = currentData?.filter((item: any) => labelValues.includes(item.siteName));
            SetPopUpFilterData(filteredData)
        }else{
            SetFindSiteId([]);
            SetFindLabels([]);
        }
    };

    function getCheckedNodes(array: any, filterObject: any) {
        const checkedNodes: any = [];

        function recursiveCheck(node: any) {
            const nodeKey = node.key;
            const filterInfo = filterObject[nodeKey];

            
            if (filterInfo && filterInfo.checked) {
                checkedNodes.push({
                    key: nodeKey,
                    label: node.label,
                    data: node.data
                });
            }

            if (node.children && node.children.length > 0) {
                node.children.forEach((child: any) => {
                    recursiveCheck(child);
                });
            }
        }

        array.forEach((rootNode: any) => {
            recursiveCheck(rootNode);
        });

        return checkedNodes;
    }

    const headerContent = (
        <>
            <div className='font-semibold' style={{ marginBottom: '15px', fontSize: '14px' }}>
                <i className='pi pi-align-left font-semibold' style={{ fontSize: '14px' }}></i>&nbsp;&nbsp;
                Advanced Filtering
            </div>
            <hr style={{ marginLeft: '-24px', marginRight: '-56px' }} />
            <div className='header_cus_bre_cmb mt-2'>
                <span style={{ textDecoration: 'underline', fontWeight: '500' }}>Customers</span> \ <span style={{ textDecoration: 'underline' }}>{CustomerOptionSelected?.label}</span>
            </div>
        </>
    )


    const footerContent = (
        <div>
            <button
                className=""
                onClick={() => {
                    SetEnabFilterPopup(false)
                    SetEnableFilterStatus(false)
                    setAdvFilValue([])
                    SetFindSiteId([])
                    SetFindLabels([])
                    
                }}
                style={{ textDecoration: 'underline' }}
            >
                Close
            </button>&nbsp;&nbsp;
            <button
                className="btn_yllw_blk font-bold py-2 px-4 rounded"
                onClick={() => {
                    SetEnabFilterPopup(false)
                    SetEnableFilterStatus(true)
                    if (findLabels?.length > 0) {
                        SetFindLabels2(findLabels)
                    }
                }}
            >
                Apply
            </button>
        </div>
    );

    useEffect(() => {
        NoteServices.getCustomerHier().then((res: any) => {

            let temp_cust: any = []; //{ 'label': 'Select Customer', 'value': '' }
            if (res.length > 0) {
                //temp_cust.push(res[0])
                res.map((cust_val: any) => {
                    temp_cust.push({
                        value: cust_val.customerId
                        , ...cust_val
                    })
                })
            }

            // temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
            // const index = temp_cust.findIndex((item: any) => item.label === "All Customers");

            // // Move the object to the first position if found
            // if (index !== -1) {
            //   const allCustomerObject = temp_cust.splice(index, 1)[0];
            //   temp_cust.unshift(allCustomerObject);
            // }

            if (temp_cust?.length >= 1) {
                SetCustomerOptionSelected({ 'label': temp_cust[0]?.label, "value": temp_cust[0]?.customerId })
            } else {
                temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
                // Change for Select Cus ddl
                const index = temp_cust.findIndex((item: any) => item?.label === "Select Customer");
                //Move the object to the first position if found
                if (index !== -1) {
                    const allCustomerObject = temp_cust.splice(index, 1)[0];
                    temp_cust.unshift(allCustomerObject);
                }
            }

            SetCustomerOption(temp_cust)

        })
        SetCustomerOptionSelected({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'Select Customer', 'parentId': 0, 'siteId': "" })
       //SetCustomerOptionSelected({ label: 'Select Customer', value: '' });
    }
    , [])

    
    const filteredData = mappedData.filter((row) =>
        row.some((obj: any) =>
            Object.values(obj).some((value: any) =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        )
    );

    
    return (
      <>
        <div className="tett table_card trbl-stats-container">
          <div style={{ display: "flex" }}>
            <div style={{ flex: "1%", marginLeft: "3px" }}>
              <div
                className="pl-2"
                style={{ position: "relative", height: "100%" }}
              >
                <div className="flex place-items-start flex-wrap">
                  {/* Search filter div  */}
                  <div className="ml-2">
                    <div className="p-0.5 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center">
                      <div className="text-white p-1 hover:cursor-pointer">
                        <svg
                          width="15"
                          height="19"
                          viewBox="0 0 15 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                            fill="#D4D4D4"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        className="w-60 text-sm bg-transparent text-textColor focus:outline-none"
                        placeholder="Search sites"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        // onKeyUp={handleKeyUp}
                        // onFocus={handleFocus}
                      />
                    </div>
                  </div>
                  {/* {<div > {(getUptimereportHeader())} </div>} */}
                  <div className="ml-auto cursor-pointer">
                    <div
                      className="inline-flex rounded-md shadow-sm"
                      role="group"
                      style={{ height: "34px" }}
                    >
                      <button
                        type="button"
                        className={`px-2 py-1 text-sm font-medium border border-borderColor rounded-s-md disabled:opacity-50 disabled:pointer-events-none ${
                          selectedValue === "Percentage"
                            ? "bg-sky-300 text-black"
                            : "text-textColor hover:bg-gray-300 hover:text-black"
                        }`}
                        onClick={() => handleClick("Percentage")}
                        disabled={hasEmptyArrays}
                      >
                        Percentage
                      </button>
                      <button
                        type="button"
                        className={`px-4 py-1 text-sm font-medium border border-borderColor rounded-e-md disabled:opacity-50 disabled:pointer-events-none ${
                          selectedValue === "Hours"
                            ? "bg-sky-300 text-black"
                            : "text-textColor hover:bg-gray-300 hover:text-black"
                        }`}
                        onClick={() => handleClick("Hours")}
                        disabled={hasEmptyArrays}
                      >
                        Hours
                      </button>
                    </div>
                    &nbsp;
                  </div>
                  {/* All Customer Dropdown  div  */}
                  <div className="custom_dropdown_notes dropdown-icon">
                    <span className="aftericon">
                      <i className="pi pi-map-marker text-textColor" />
                    </span>
                    {/* <Select
                      // menuIsOpen={menuOpen}
                      placeholder="Customers"
                      value={CustomerOptionSelected}
                      defaultValue={{ label: "All Customers", value: "0" }}
                      options={CustomerOption}
                      onChange={handleOptionSelect}
                      isSearchable={false}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                          color: state.isFocused ? "#000000" : "#D4D4D4", // Adjust default text color
                          zIndex: 10,
                        }),
                      }}
                    /> */}
                      <Select
                          placeholder="Customers"
                          value={CustomerOptionSelected}
                          defaultValue={{ label: "All Customers", value: "0" }}
                          options={CustomerOption}
                          onChange={handleOptionSelect}
                          isSearchable={false}
                          styles={{
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? "#7DD3FC" : "transparent",
                              color: state.isFocused ? "#000000" : "#D4D4D4",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: "310px", // Limits height of dropdown
                              overflowY: "auto", // Ensures dropdown scrolls internally
                              zIndex: 999, // Prevents conflicts with other elements
                              backgroundColor: "#131F2E", 
                              color: "#D4D4D4",
                              border: "1px solid #3C4F61"
                            }),
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 9999, // Ensures dropdown stays on top of all elements
                            }),
                          }}
                          menuPortalTarget={document.body}
                        />

                  </div>
                  {/* Hierarchy dropdown div */}
                  <div className="custom_dropdown_notes">
                    <button
                      type="button"
                      onClick={() => handleShowPopup()}
                      disabled={
                        CustomerOptionSelected?.label == "All Customers"
                          ? true
                          : false
                      }
                      className={
                        CustomerOptionSelected?.label == "All Customers"
                          ? "notes_adv_fil_disabled_btn py-1 px-2 rounded"
                          : "btn_yllw_blk py-1 px-2 rounded"
                      }
                    >
                      <i className="pi pi-align-left"></i>&nbsp;&nbsp; Advanced
                      Filtering
                    </button>
                  </div>
                  &nbsp;
                  <div className="pl-2 pr-2">
                    <div
                      className="p-0.5 pr-1 rounded-md border border-gray-300 bg-gradient-to-r from-white-500 to-white-500 flex items-center"
                      style={{ height: "34px" }}
                    >
                      <div className="p-1 ">
                        <button
                          type="button"
                          className={`ml-auto flex items-center space-x-1 disabled:opacity-50 disabled:pointer-events-none text-textColor`}
                          disabled={hasEmptyArrays}
                        >
                          <CSVLink
                            className="ml-auto flex items-center space-x-1"
                            data={csvData}
                            filename={`History_Uptime Report_${moment(
                              startDateTime
                            ).format("MMM-YYYY")}_to_${moment(
                              endDateTime
                            ).format("MMM-YYYY")}`}
                          >
                            <span className="text-sm">
                              &nbsp;Download spreadsheet
                            </span>
                            &nbsp;
                          </CSVLink>
                          <DownloadIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-4 mt-2 text-textColor">
                    <UptimeReportDatePicker
                      onFromDateChange={handleFromDateChange}
                      onToDateChange={handleToDateChange}
                    />
                  </div>
          {state.isLoading ? (
            renderLoading
          ) : (
            <>
              {CustomerOptionSelected.label === "Select Customer" ? null : (
                <>
                  {IsNullOrEmpty(state.data) || hasEmptyArrays ? (
                    renderNoData
                  ) : (
                    <>
                      <div className="p-2 min-h-screen">
                        <table
                          className="table table-bordered dtr-inline table-responsive dataTable no-footer"
                          style={{ width: "100%" }}
                        >
                          <thead className="bg-bgColor400 border border-borderColor text-textColor sticky top-0 z-0 border-collapse">
                            <tr role="row" className="bg-bgColor400  text-textColor">
                              <th
                                colSpan={1}
                                rowSpan={2}
                                className="p-2 font-normal text-sm sorting"
                                style={{ width: "10%" }}
                              >
                                Site Name
                              </th>
                              {columnData.slice(0, -1).map((month, index) => (
                                <th
                                  key={index}
                                  id={`Month-${index}`}
                                  colSpan={1}
                                  rowSpan={2}
                                  className="p-2 font-normal  text-sm"
                                >
                                  {month}
                                </th>
                              ))}
                              <th
                                id="Previous-30-days"
                                colSpan={3}
                                rowSpan={1}
                                className="p-2 font-normal text-sm"
                              >
                                {isCurrentMonthLastColumn
                                  ? "Previous 30 Days"
                                  : columnData[columnData.length - 1]}
                              </th>
                            </tr>
                            <tr role="row" className="bg-bgColor600">
                              <React.Fragment>
                                <th
                                  className="p-2 font-normal text-sm "
                                  rowSpan={1}
                                  colSpan={1}
                                >
                                  Hardware
                                </th>
                                <th
                                  className="p-2 font-normal text-sm "
                                  rowSpan={1}
                                  colSpan={1}
                                >
                                  Environmental
                                </th>
                                <th
                                  className="p-2 font-normal  text-sm "
                                  rowSpan={1}
                                  colSpan={1}
                                >
                                  Uptime
                                </th>
                              </React.Fragment>
                            </tr>
                          </thead>
                          <tbody className="border border-borderColor">
                            {filteredData.length > 0 ? (
                              filteredData.map((row, rowIndex) => (
                                <tr
                                  key={rowIndex}
                                  className="bg-bgContainer300 text-textColor border border-borderColor my-2"
                                >
                                  <td className="text-center text-xs p-2">
                                    {getAssetName(row)}
                                  </td>
                                  {columnData
                                    .slice(0, -1)
                                    .map((month, columnIndex) => {
                                      const rowData = row.find((obj: any) =>
                                        obj.time.includes(month)
                                      );
                                      return (
                                        <td
                                          key={columnIndex}
                                          className="p-2 text-center text-xs P1"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {rowData ? (
                                            <>
                                              <span
                                                title={`Hardware: ${Math.round(
                                                  rowData.hardware
                                                )} ${
                                                  selectedValue === "Percentage"
                                                    ? "%"
                                                    : ""
                                                } `}
                                              >
                                                {Math.round(rowData.hardware)}{" "}
                                                {selectedValue === "Percentage"
                                                  ? "%"
                                                  : ""}
                                              </span>
                                              &nbsp;
                                              <span
                                                title={`Environmental: ${Math.round(
                                                  rowData.environmental
                                                )} ${
                                                  selectedValue === "Percentage"
                                                    ? "%"
                                                    : ""
                                                }`}
                                              >
                                                {Math.round(
                                                  rowData.environmental
                                                )}
                                                {selectedValue === "Percentage"
                                                  ? "%"
                                                  : ""}
                                              </span>
                                              &nbsp;
                                              <span
                                                className="font-bold"
                                                title={`Uptime: ${Math.round(
                                                  rowData.uptime
                                                )}${
                                                  selectedValue === "Percentage"
                                                    ? "%"
                                                    : ""
                                                }`}
                                              >
                                                {Math.round(rowData.uptime)}
                                                {selectedValue === "Percentage"
                                                  ? "%"
                                                  : ""}
                                              </span>
                                              &nbsp;
                                              <button
                                                onClick={() =>
                                                  handleDownload(rowData)
                                                }
                                                title="Download"
                                                className="pl-2"
                                              >
                                                <DownloadIcon />
                                              </button>
                                            </>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                      );
                                    })}

                                  {row[row.length - 1] ? (
                                    <>
                                      <td className="p-2 text-center text-xs">
                                        <span
                                          title={`Hardware: ${Math.round(
                                            row[row.length - 1].hardware
                                          )}${
                                            selectedValue === "Percentage"
                                              ? "%"
                                              : ""
                                          }`}
                                        >
                                          {Math.round(
                                            row[row.length - 1].hardware
                                          )}
                                          {selectedValue === "Percentage"
                                            ? "%"
                                            : ""}
                                        </span>
                                      </td>
                                      <td className="p-2 text-center text-xs">
                                        <span
                                          title={`Environmental: ${Math.round(
                                            row[row.length - 1].environmental
                                          )}${
                                            selectedValue === "Percentage"
                                              ? "%"
                                              : ""
                                          }`}
                                        >
                                          {Math.round(
                                            row[row.length - 1].environmental
                                          )}
                                          {selectedValue === "Percentage"
                                            ? "%"
                                            : ""}
                                        </span>
                                      </td>
                                      <td
                                        className="p-2 text-center text-xs"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <span
                                          className="font-bold"
                                          title={`Uptime: ${Math.round(
                                            row[row.length - 1].uptime
                                          )}${
                                            selectedValue === "Percentage"
                                              ? "%"
                                              : ""
                                          }`}
                                        >
                                          {Math.round(
                                            row[row.length - 1].uptime
                                          )}
                                          {selectedValue === "Percentage"
                                            ? "%"
                                            : ""}
                                        </span>
                                        <button
                                          className="pl-2"
                                          onClick={() => handleDownload(row)}
                                          title="Download"
                                        >
                                          <DownloadIcon />
                                        </button>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td className="text-center text-xs p-2">
                                        N/A
                                      </td>
                                      <td className="text-center text-xs p-2">
                                        N/A
                                      </td>
                                      <td className="text-center text-xs p-2">
                                        N/A
                                      </td>
                                    </>
                                  )}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={columnData.length + 1}
                                  className="text-center text-xs p-2"
                                >
                                  No matching records found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>

                    // )} : (
                    //     <div className="p-2 text-center m-14 text-gray-500">No data available</div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <Dialog
          header={headerContent}
          visible={enabFilterPopup}
          style={{ width: "50vw" }}
          onHide={() => SetEnabFilterPopup(false)}
          footer={footerContent}
        >
          <p className="m-0">
            <Tree
              value={advFilOption}
              selectionMode="checkbox"
              selectionKeys={advFilValue}
              onSelectionChange={(e) => handleFiterSelectionChange(e)}
              className="w-full md:w-30rem"
            />
          </p>
        </Dialog>
      </>
    );

};

export default GlobalUptimeReportTable;