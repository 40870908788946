import React,{useState,useEffect} from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from "moment";
import { fetchSiteReprocess } from "../../../../store/thunk/SitesOverviewThunk";
import { useDispatch ,useSelector} from "react-redux";
import { AppDispatch } from "../../../../store";

interface SiteReprocesstableProps {
    searchTerm: string;
    setDownloadData: any;
    refreshTable: boolean;
    customerIds: any[];
    findSiteId: any;
    timeRange : any;
    timezoneForAPI: any;
}
interface DataType {
  customerName: string;
  status: string;
  sitesCount: number;
  fromDate: string;
  toDate: string;
  createdOn: string;
  createdBy: string;
  }

const SiteReprocesstable: React.FC<SiteReprocesstableProps> = ({ searchTerm ,setDownloadData,refreshTable, customerIds,findSiteId,timeRange,timezoneForAPI }) => {
    
    const [data, setData] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState<DataType[]>([]);

    let nonEmptySiteIds: any = [];

    const isValidDate = (date: string): boolean => {
      return moment(date, 'MM-DD-YYYY HH:mm', true).isValid();
  };

  const startDateTime = timeRange.StartDate !== undefined && isValidDate(timeRange.StartDate) ? moment(timeRange.StartDate).format("MM-DD-YYYY HH:mm") : moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm");
  const endDateTime = timeRange.EndTime !== undefined && isValidDate(timeRange.EndTime) ? moment(timeRange.EndTime).format("MM-DD-YYYY HH:mm") : moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm");

    const dispatch = useDispatch<AppDispatch>();

    const state = useSelector((state: any) => state.siteReprocessState);
    console.log(" state.data", state.data);


    const renderLoading = (
        <div>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm">Loading Data...</div>
        </div>
      );

      const renderNoData = (
        <div className="px-5 py-5 text-bold text-center">
            <div className="flex justify-center items-center">
                <h1>No data available.</h1>
            </div>
        </div>
      );

      const statusBodyTemplate = (rowData: any) => {
        let statusStyle = {};
        switch (rowData.status) {
          case 'COMPLETED':
            statusStyle = { border: '1px solid #34A852', background: '#E7F5EA' };
            break;
          case 'INPROGRESS':
            statusStyle = { border: '1px solid #34A852', background: '#E7F5EA' };
            break;
          case 'OPEN':
            statusStyle = { border: '1px solid #FABB07', background: '#FEF7E1' };
            break;
          case 'Failed':
            statusStyle = { border: '1px solid red', background: '#FEE2E2' };
            break;
          default:
            break;
        }
        return (
          <span className="px-2 py-1 rounded" style={statusStyle}>
            {rowData.status}
          </span>
        );
      };
    
      const siteCountBodyTemplate = (rowData: any) => {
        const siteCountStyle = { border: '1px solid #FABB07', background: '#FEF7E1', fontSize: '12px' };
        return (
          <span className="px-2 py-1 rounded" style={siteCountStyle}>
            {rowData.sitesCount}
          </span>
        );
      };

      // Function to format date in dd-mm-yyyy format
        const formatDate = (dateString: string) => {
          return moment(dateString).format('DD-MM-YYYY');
        };

        // Function to format date in dd-mm-yyyy hh:mm AM/PM format
        const formatDateTime = (dateString: string) => {
          return moment(dateString).format('DD-MM-YYYY hh:mm A');
        };

      useEffect(() => {
        const filtered = Array.isArray(data) ? data.filter(item =>
          item.customerName.toLowerCase().includes(searchTerm.toLowerCase())
        ) : [];

        setFilteredData(filtered);
        setDownloadData(filtered); 
      }, [searchTerm, data, setDownloadData]);

      useEffect(() => {
        let startDate: any = moment(startDateTime,"MM-DD-YYYY HH:mm");
        let endDate: any = moment(endDateTime,"MM-DD-YYYY HH:mm");


        nonEmptySiteIds = findSiteId?.filter((id: string) => id.trim() !== '');

        dispatch(fetchSiteReprocess({ customerIds, siteIds: nonEmptySiteIds, startDate, endDate }));

        if (state.data && Array.isArray(state.data.data)) {
          setData(state.data.data);
          setLoading(false);       
           }else {
            setFilteredData([]); // If no data, ensure filteredData is empty
            setLoading(false);
          }

    }, [refreshTable,customerIds, startDateTime, endDateTime, findSiteId,dispatch])
      

    return (
        <>
         <div>
         {loading ? (
        renderLoading
      ) : (
            <DataTable 
            value={filteredData}
            paginator
            rows={12}
            rowsPerPageOptions={[12, 20, 30, 40, 50]} 
            sortMode="multiple"
            emptyMessage={renderNoData}
            className="notes_table_css cursor-default select-none"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            >
                <Column field="customerName" header="Customer"  sortable />
                <Column field="status" header="Status"  body={statusBodyTemplate}  sortable />
                <Column field="sitesCount" header="Site Count" body={siteCountBodyTemplate}   sortable />
                <Column field="fromDate" header="From Date"  body={(rowData) => formatDate(rowData.fromDate)} sortable />
                <Column field="toDate" header="To Date" body={(rowData) => formatDate(rowData.toDate)} sortable />
                <Column field="createdOn" header="Requested On" body={(rowData) => formatDateTime(rowData.createdOn)}  sortable />
                <Column field="createdBy" header="Requested By" sortable />
            </DataTable>
         )}
        </div>
        </>
    );

}
export default SiteReprocesstable;