
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CSVLink } from 'react-csv';
import { Button } from 'primereact/button';
import EditIcon from '../../../../assets/img/edit-icon.png';
import trashIcon from '../../../../assets/img/trash-icon.png';
import NewSite from './NewSite';

export default function SiteList() {

    const [isNewSiteVisible, setIsNewSiteVisible] = useState(false);

    interface siteDetails {
        Name : string,
        hierarchy: string
    }
    
    const openNewSite = () => {
        setIsNewSiteVisible(true); 
    }

    const editSite = (user: siteDetails) => {
    };

    const confirmDeleteSite = (user: siteDetails) => {
    };

    const DownloadIcon: React.FC = () => (
        <span className='inline-block text-center'>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-download"
                viewBox="0 0 16 16"
            >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
        </span>
    
    );

    return (
        <div>
            {isNewSiteVisible ? (
                <NewSite onClose={() => setIsNewSiteVisible(false)} />  // Pass a prop to handle closing the NewSite component
            ) : (
                <div className="tett table_card">
                    <div className="pl-1" style={{ position: "relative", height: "100%" }}>
                        <div className="flex">
                            <div className="ml-auto cursor-pointer">
                                <div className="p-0.5 pr-1 bg-gradient-to-r from-white-500 to-white-500 flex items-center shadow-sm" style={{ height: "34px" }}>
                                    <div className="p-1">
                                        <CSVLink data={''} filename={"users.csv"} className="font-semi-bold px-2 py-1 rounded">
                                            <button type="button" className={`ml-auto flex items-center space-x-1 disabled:opacity-50 disabled:pointer-events-none`} >
                                                <DownloadIcon />
                                                <span className="text-sm"> &nbsp;Download</span>&nbsp;
                                            </button>
                                        </CSVLink>
                                    </div>
                                </div>
                                &nbsp;
                            </div>
                            <div className="pl-2">
                                <Button className="px-2 py-2 rounded" style={{ background: "#F5B334" }} onClick={openNewSite} label="New site" icon="pi pi-plus" />
                            </div>
                        </div>
                    </div>
    
                    <DataTable paginator rows={8} stripedRows
                        exportFilename="groundDataReport"
                        rowsPerPageOptions={[8, 20, 30, 40, 50]}
                        selectionMode="single"
                        dataKey="id"
                        tableStyle={{ minWidth: '50rem' }}
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink">
                        <Column field="time" sortable header="Last Observation"></Column>
                        <Column field="data" sortable header="Name"></Column>
                        <Column field="data" sortable header="Hierarchy"></Column>
                        <Column field="data" sortable header="Type"></Column>
                        <Column field="data" sortable header="Serial No."></Column>
                        <Column
                            headerStyle={{ fontSize: "14px" }}
                            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                            showFilterMatchModes={false}
                            body={(rowData: siteDetails) => {
                                return (
                                    <>
                                        <div style={{ display: "flex" }}>
                                            <img src={EditIcon} onClick={() => editSite(rowData)} style={{ fontSize: '1.5rem', cursor: "pointer", marginLeft: "6px" }}></img>
                                            <img src={trashIcon} onClick={() => confirmDeleteSite(rowData)} style={{ fontSize: '1.5rem', marginLeft: "15px", cursor: "pointer" }}></img>
                                        </div>
                                    </>
                                );
                            }}
                            header="Actions"
                        ></Column>
                    </DataTable>
                </div>
            )}
        </div>
    );
    

}