import React, { useState, Fragment, useEffect, useCallback, useRef } from 'react';
import '../assets/css/custom-op-style.css';
import moment from "moment";
import eyeIcon from "../assets/img/eye_icon.png";
import chartIcon from "../assets/img/bar_icon.png";
import { Route, Routes, useNavigate } from "react-router-dom";
import BarChart from "../components/Graph/BarChartClickable";
import CustomMenu from "../components/form/CustomMenu";
import DatepickerCommon from "../components/DatepickerCommon";
import TimecardDatepicker from '../components/timeslider/TimecardDatepicker';
import CustomDatepicker from '../components/datepicker/EmissionPerformanceDP';
// import _ from 'lodash';
import DropdownTreeSelectCustom from '../components/form/dropdown/DropdownTreeSelectCustom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssetHierarchy } from '../store/thunk/MapSliceThunk';
import { AppDispatch } from '../store';
import GlobalSummaryView from '../components/Table/GlobalSummaryView';
import { fetchEmissionChart, fetchEmissionTable } from "../store/thunk/EmissionperformanceThunk";
import { getTimzeZoneForAPI, getValuesFromQueryString, formatDate, timeZone_api } from "../utils/CommonUtils";
import ax from "../config/api/AxiosConfig";
import LoaderIcon from "../svg/LoaderIcon";
import DownloadIcon from "../svg/DownloadIcon";
import championX_icon from "../assets/img/ChampionX_logo.png";
import { AutoComplete } from "primereact/autocomplete";
import { CSVLink } from 'react-csv';
interface DateTimeType {
    StartDate: String;
    EndTime: String;
}

// Create a new function that will execute the provided function after a delay
const useDebounce = (func: (...args: any[]) => any, delay: number) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    return (...args: any[]) => {
        clearTimeout(timeoutRef.current!);
        timeoutRef.current = setTimeout(() => func(...args), delay);
    };
};

const EmissionOverview: React.FC<any> = (props: any) => {
    const opOverviewoptions = [
        { value: 'Operations Overview', label: 'Operations Overview', icon: eyeIcon },
        { value: 'Emission Performance', label: 'Emission Performance', icon: chartIcon }
    ];
    const assetHierarchyState = useSelector((state: any) => state.assetHierarchyState);
    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
    const initialTimeRange: DateTimeType = {
        StartDate: moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm"),
        EndTime: moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm"),
    };
    const sortingOptions = [
        { value: 0, label: 'Most to Least' },
        { value: 1, label: 'Least to Most' }
    ];
    const assetSearchRef = useRef<any>(null);
    const [startDate, setStartDate] = useState<Date>(new Date(moment.tz(timezoneForAPI).subtract(1, "days").format("MM-DD-YYYY HH:mm")));
    const [endDate, setEndDate] = useState<Date>(new Date(moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm")));
    const [selectedLabel, setSelectedLabel] = useState<string | null>("day");
    const [comparelastyear, setComparelastyear] = useState(false);
    const [isCustomSelect, setIsCustomSelectar] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    let chartDetails = useSelector((state: any) => state.barChartState);
    let tableDetails = useSelector((state: any | null) => state.summaryViewTableState);
    let timeZone = getTimzeZoneForAPI(userPrefstate.data.timeZone);
    let metric = userPrefstate.data.currentUOM;

    const defaultCustomerOp = {
        "customerId": "",
        "customerName": "",
        "isPrimary": false,
        "assets": []
    }
    const customerOptions = assetHierarchyState.data.map((customerEle: any) => ({
        ...customerEle,
        value: customerEle.customerId,
        label: customerEle.customerName,
    }))
    let initialCustId: any = "";
    let initialCustObj: any;
    if (customerOptions.length) {
        let isPrimaryObj = customerOptions.find((obj: any) => obj.isPrimary);
        if (isPrimaryObj) {
            initialCustObj = isPrimaryObj
        } else {
            initialCustObj = customerOptions[0]
        }
        initialCustId = initialCustObj.customerId;
    }
    const [customerId, setCustomerId] = useState<string>(initialCustId);
    const [siteIds, setSiteIds] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [chartNodata, setChartNodata] = useState(false);
    const [tableNodata, setTableNodata] = useState(false);
    const [chartDataState, setChartDataState] = useState<any>([]);
    let processedChartData: any = [];
    if (!chartDetails.error && Object(chartDetails.data).constructor.name === "Array" && !chartNodata) {
        processedChartData = chartDetails.data.map((item: any) => ({
            ...item,
            currentYearEmissions: parseFloat(item.currentYearEmissions.toFixed(2)),
            previousYearEmissions: parseFloat(item.previousYearEmissions.toFixed(2)),
        }));
    }
    const navigate = useNavigate();
    const [selectedSortOption, setSelectedSortOption] = useState(sortingOptions[0]);
    const [selectedOption, setSelectedOption] = useState(opOverviewoptions[1]);
    const [timeRange, setTimeRange] = useState<DateTimeType>(initialTimeRange);
    const [selectedCustomerOption, setSelectedCustomerOption] = useState(initialCustObj || defaultCustomerOp);
    const [assets, setAssets] = useState<any>([]);
    const [selectedAsset, setSelectedAsset] = useState<any>(null);
    const [filteredAssets, setFilteredAssets] = useState<any>([]);
    const [query, setQuery] = useState<any>("");

    const customerName = selectedCustomerOption?.customerName || '';

    useEffect(() => {
        let extractedSiteIds: any = [];
        let astRefValue = assetSearchRef.current.props.value;
        if (chartDetails.data.length) {
            setChartDataState(chartDetails.data.map((item: any) => ({
                ...item,
                currentYearEmissions: parseFloat(item.currentYearEmissions.toFixed(2)),
                previousYearEmissions: parseFloat(item.previousYearEmissions.toFixed(2)),
            })));
            setChartNodata(false);
        } else {
            setChartNodata(true);
        }
        if (chartDetails.data && chartDetails.data.length > 0) {
            extractedSiteIds = chartDetails.data.map((item: any) => item.siteId);
            dispatch(fetchEmissionTable({ endDate, customerId, timeZone, siteIds: extractedSiteIds }));
        } else if (astRefValue && astRefValue != null && (Object(astRefValue).constructor.name === "Object")) {
            extractedSiteIds = [astRefValue.siteID];
        }
        //dispatch(fetchEmissionTable({ endDate, customerId, timeZone, siteIds: extractedSiteIds }));
    }, [chartDetails.data]);
    useEffect(() => {
        dispatch(fetchEmissionChart({ startDate, endDate, customerId, timeZone, siteIds, query, orderBy: selectedSortOption.value }));
    }, [timeRange, customerId, siteIds, selectedSortOption]);

    const updatedSelectedDate = useCallback<Function>((selectedTimeRange: any) => {
        setStartDate(new Date(selectedTimeRange.StartDate));
        setEndDate(new Date(selectedTimeRange.EndTime));
        setTimeRange(selectedTimeRange);
    }, [timeRange]);

    const pushCustomSelectFlag = useCallback<Function>((customFlag: boolean) => {
        setIsCustomSelectar(customFlag);
        setSelectedLabel("");
    }, [timeRange]);
    const handleSelectChange = (newValue: any) => {
        setSelectedOption(newValue);
        if (newValue.value === 'Emission Performance') {
            props.updateOverviewTypeTab("emission");
            navigate('/emission-overview');
        }
        if (newValue.value === 'Operations Overview') {
            props.updateOverviewTypeTab("operation");
            navigate('/operations-overview');
        }
    };
    const handleSortSelectChange = (newValue: any) => {
        setSelectedSortOption(newValue);
    };
    const downloadCSV = async () => {
        try {
            const payload = {
                "CustomerID": customerId,
                "StartTime": formatDate(startDate),
                "EndTime": formatDate(endDate),
                "timeZone": timeZone,
                "UnitOfMeasurement": metric
            };

            setLoading(true);
            const response = await ax.post("/Emission/TotalEmissionExport", payload);
            setLoading(false);
            const rows = response.data.split('\r\n');
            const headers = rows[0].split(',');
            let bodyData = rows.slice(1).map((row: any) => row.split(',').join(',')).join('\r\n');
            const csvData = headers.join(',') + '\r\n' + (!chartNodata ? bodyData : '');

            const filename = `TotalEmissions_${customerName}_(${formatDate(startDate)} - ${formatDate(endDate)}).csv`;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const pseudoelement = document.createElement("a");
            pseudoelement.href = window.URL.createObjectURL(blob);
            pseudoelement.download = filename;
            document.body.appendChild(pseudoelement);
            pseudoelement.click();

            document.body.removeChild(pseudoelement);
        } catch (error) {
            setLoading(false);
            console.error("Error downloading CSV:", error);
        }
    };

    const csvData = [
        ["Site Name", "Current Year", "Previous Year"],
        ...chartDetails.data.map((item: any) => {
            return [item.siteName, item.currentYearEmissions, item.previousYearEmissions];
        }),
    ];

    const handleCustomerSelectChange = (newValue: any) => {
        setSiteIds([]);
        setSelectedAsset("");
        setSelectedCustomerOption(newValue);
        setCustomerId(newValue.customerId);
        if (newValue.assets && newValue.assets.length) {
            setAssets([...newValue.assets.map((e: any) => {
                return {
                    ...e,
                    code: e.siteID,
                    name: e.siteName
                }
            })]);
        }
    };
    const searchComplete = (event: any) => {
        let _filteredAssets;
        if (event.query) {
            if (!event.query.trim().length) {
                _filteredAssets = [...assets];
            }
            else {
                _filteredAssets = assets.filter((ast: any) => {
                    return ast.name.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredAssets(_filteredAssets);
        }
    }
    const searchTypingHandler = (event: any) => {
        setSelectedAsset(event.value);
        if (assetSearchRef.current.props.value) {
            if (Object(event.value).constructor.name === "String") {
                //setQuery(event.value);
            } else if (Object(event.value).constructor.name === "Object") {
                setSiteIds([event.value.siteID]);
            }
        }
    }
    const onBlurSearch = (e: any) => {
        //updateSearchStates();
    }
    const updateSearchStates = () => {
        let searchValue = assetSearchRef.current.props.value;
        let searchStrLwc: any = "";
        if (Object(searchValue).constructor.name === "String") {
            searchStrLwc = searchValue.toLowerCase()
        } else if (Object(searchValue).constructor.name === "Object") {
            searchStrLwc = searchValue.siteID.toLowerCase()
        }
        const _filtered_ids = assets.filter((ast: any) => ast.name.toLowerCase().includes(searchStrLwc)).map((el: any) => el.siteID);
        if (_filtered_ids.length) {
            setChartNodata(false);
            setTableNodata(false);
            setSiteIds(_filtered_ids);
        } else {
            setChartNodata(true);
            setTableNodata(true);
        }
    }

    const debouncedUpdateSearchStates = useDebounce(updateSearchStates, 500);

    const handleKeyDown = (evt: any) => {
        if (evt.key === 'Enter') {
            debouncedUpdateSearchStates();
        } else if ((evt.key === "Delete" || evt.key === "Backspace")) {
            setTimeout(() => {
                if (assetSearchRef.current.props.value === "") {
                    debouncedUpdateSearchStates();
                }
            }, 500)
        }
    };
    const onSearchClick = () => {
        updateSearchStates();
    };

    const onLoadAction = () => {
        dispatch(fetchAssetHierarchy());
        if (selectedCustomerOption.assets && selectedCustomerOption.assets.length) {
            setAssets([...selectedCustomerOption.assets.map((e: any) => {
                return {
                    ...e,
                    code: e.siteID,
                    name: e.siteName
                }
            })]);
        }
    }
    useEffect(() => {
        setTimeout(() => { onLoadAction() }, 1000);
    }, [selectedCustomerOption]);


    const calculateColumnSum = (columnNames: string[]) => {
        const totalSum = columnNames.reduce((sum, columnName) => {
            return sum + tableDetails.data.reduce((rowSum: any, row: any) => {
                const value = row[columnName];
                return typeof value === 'number' ? rowSum + value : rowSum;
            }, 0);
        }, 0);
        return totalSum.toLocaleString('en-US', { maximumFractionDigits: 2 });
    };

    function getUniqueAlerts(alertString: string | undefined): string {
        if (!alertString) return '-';

        const values = alertString.split(',');
        const uniqueValues = Array.from(new Set(values));
        const result = uniqueValues.join(', ');

        return result.replace(/,(\s*)$/, '$1');
    }

    const customStandard = (n1: any, n2: any) => {
        try {
            if (n1 != null && n1 !== "" && !isNaN(n1)) {
                n1 = parseInt(n1);
            } else {
                n1 = 0;
            }

            if (n2 != null && n2 !== "" && !isNaN(n2)) {
                n2 = parseInt(n2);
            } else {
                n2 = 0;
            }

            return n1 + n2;
        } catch (error) {
            console.log("An error occurred:", error);
            return 0;
        }
    };


    const createCsvDataFromResponse = (data: any) => {
        let mainHeaders: string = "Site Name,Methane Alerts SetPoint,Active Emission Alerts,Emission Alerts Count, ,  , ,Cumulative Emission (kg), , , ,Average Emission (kg/hr), , , ,Asset Alerts,Equipment Alerts Count, , , , "

        let subHeaders: string = ",,,24 Hour,7 Day,30 Day,365 Day,24 Hour,7 Day,30 Day,365 Day,24 Hour,7 Day,30 Day,365 Day, ,24 Hour,7 Day,30 Day,365 Day";



        let rowData = data.map((item: any) => {
            let activeAlerts = '-';
            if (item.activeEmissionAlert_24 !== null && item.activeEmissionAlert_24 !== undefined && item.activeStandardAlert_24 !== null && item.activeStandardAlert_24 !== undefined) {
                activeAlerts = `"${item.activeEmissionAlert_24} ,${item.activeStandardAlert_24}"`;
            } else if (item.activeEmissionAlert_24 !== null && item.activeEmissionAlert_24 !== undefined) {
                activeAlerts = item.activeEmissionAlert_24;
            } else if (item.activeStandardAlert_24 !== null && item.activeStandardAlert_24 !== undefined) {
                activeAlerts = item.activeStandardAlert_24;
            }

            return `${item.assetName},${item.methaneSetPoint !== null ? item.methaneSetPoint.toFixed(2) : '-'},${activeAlerts},${customStandard(item.emissionAlertCount_24, item.standardAlertCount_24)},${customStandard(item.emissionAlertCount_7, item.standardAlertCount_7)},${customStandard(item.emissionAlertCount_30, item.standardAlertCount_30)},${customStandard(item.emissionAlertCount_365, item.standardAlertCount_365)},${item.cumulativeEmission_24 !== null ? item.cumulativeEmission_24.toFixed(2) : 0},${item.cumulativeEmission_7 !== null ? item.cumulativeEmission_7.toFixed(2) : 0},${item.cumulativeEmission_30 !== null ? item.cumulativeEmission_30.toFixed(2) : 0},${item.cumulativeEmission_365 !== null ? item.cumulativeEmission_365.toFixed(2) : 0},${item.averageEmission_24 !== null ? item.averageEmission_24.toFixed(2) : 0},${item.averageEmission_7 !== null ? item.averageEmission_7.toFixed(2) : 0},${item.averageEmission_30 !== null ? item.averageEmission_30.toFixed(2) : 0},${item.averageEmission_365 !== null ? item.averageEmission_365.toFixed(2) : 0},"${getUniqueAlerts(item.activeEquipmentAlert_24)}",${item.equipmentAlertCount_24 ?? 0},${item.equipmentAlertCount_7 ?? 0},${item.equipmentAlertCount_30 ?? 0},${item.equipmentAlertCount_365 ?? 0}`;

        }).join('\n');

        let lastRow = `,,Total ,"${calculateColumnSum(['emissionAlertCount_24'])}","${calculateColumnSum(['emissionAlertCount_7'])}","${calculateColumnSum(['emissionAlertCount_30'])}","${calculateColumnSum(['emissionAlertCount_365'])}","${calculateColumnSum(['cumulativeEmission_24'])}","${calculateColumnSum(['cumulativeEmission_7'])}","${calculateColumnSum(['cumulativeEmission_30'])}","${calculateColumnSum(['cumulativeEmission_365'])}","${calculateColumnSum(['averageEmission_24'])}","${calculateColumnSum(['averageEmission_7'])}","${calculateColumnSum(['averageEmission_30'])}","${calculateColumnSum(['averageEmission_365'])}",Total,"${calculateColumnSum(["equipmentAlertCount_24"])}","${calculateColumnSum(["equipmentAlertCount_7"])}","${calculateColumnSum(["equipmentAlertCount_30"])}","${calculateColumnSum(["equipmentAlertCount_365"])}"`;

        return `${mainHeaders} \n 
        ${subHeaders} \n 
        ${rowData}
        ${lastRow}
        `;
    }

    const downloadGlobalSummaryTableCSV = async (data: any) => {
        try {
            let rowResp = createCsvDataFromResponse(data);
            const rows: any = rowResp.split('\r\n');
            const headers: any = rows[0].split(',');
            let bodyData = rows.slice(1).map((row: any) => row.split(',').join(',')).join('\r\n');
            const csvData = headers.join(',') + '\r\n' + (!chartNodata ? bodyData : '');
            const filename = `${customerName}_(${formatDate(startDate)} - ${formatDate(endDate)}).csv`;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const pseudoelement = document.createElement("a");
            pseudoelement.href = window.URL.createObjectURL(blob);
            pseudoelement.download = filename;
            document.body.appendChild(pseudoelement);
            pseudoelement.click();
            document.body.removeChild(pseudoelement);
        } catch (error) {
            console.error("Error Configuration Table row download CSV:", error);
        }
    };

    return (
      <>
        <div
          style={{ zIndex: 2, fontSize: "14px" }}
          className="bg-bgColor300"
        >
          <div className="op-page-container">
            <div className="op-header-panel" style={{ height: "50px" }}>
              <div
                className="header-layout"
                style={{
                  height: "23px",
                  paddingTop: "5px",
                  marginBottom: "10px",
                }}
              >
                <div className="min-width-200px op-select-dd-container cursor-pointer z-2">
                  <CustomMenu
                    options={opOverviewoptions}
                    value={selectedOption}
                    onChange={handleSelectChange}
                  />
                </div>
                <div className="hd-right-elements pr-0 cursor-pointer">
                  <div className="ml-9 group flex text-sm">
                    <span className="ml-2 mt-2">
                      <CustomDatepicker
                        pushSelectedDate={updatedSelectedDate}
                        pushCustomSelectFlag={pushCustomSelectFlag}
                        outerSelectedTimeRange={timeRange}
                      />
                    </span>
                    <div className="hierarchy-dd-customize mt-1 mr-2 ml-2">
                      <CustomMenu
                        options={customerOptions}
                        value={selectedCustomerOption}
                        onChange={handleCustomerSelectChange}
                      />
                    </div>
                    <div
                      style={{ height: "32px" }}
                      className="p-0.5 mt-1 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center"
                    >
                      <div className="card flex justify-content-center asset-search-container">
                        <div
                          className="text-textColor p-1 hover:cursor-pointer"
                          onClick={onSearchClick}
                        >
                          <svg
                            width="15"
                            height="19"
                            viewBox="0 0 15 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                              fill="#838588"
                            />
                          </svg>
                        </div>
                        <div className="bg-bgColor300 text-textColor">
                          <AutoComplete
                            field="name"
                            ref={assetSearchRef}
                            //multiple
                            //dropdown
                            placeholder="Search sites"
                            value={selectedAsset}
                            suggestions={filteredAssets}
                            completeMethod={searchComplete}
                            virtualScrollerOptions={{ itemSize: 50 }}
                            onChange={(e: any) => searchTypingHandler(e)}
                            onBlur={onBlurSearch}
                            onKeyDown={handleKeyDown}
                          />
                        </div>

                        {/* <div className="text-white p-1 hover:cursor-pointer" onClick={assetSearchBtnClick}>
                                                <svg width="15" height="19" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                                                        fill="#838588"
                                                    />
                                                </svg>
                                            </div> */}
                      </div>
                      {/* <div className="text-white p-1 hover:cursor-pointer" onClick={handleSearch}>
                                            <svg width="15" height="19" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                                                    fill="#838588"
                                                />
                                            </svg>
                                        </div>
                                        <input
                                            type="text"
                                            className="w-60 text-sm bg-transparent focus:outline-none"
                                            placeholder="Search sites"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            onKeyDown={handleKeyPress}
                                        /> */}
                    </div>
                    &nbsp;&nbsp;
                    <img
                      src={championX_icon}
                      width="150px"
                      alt="Path"
                      style={{
                        marginBottom: "0px",
                        height: "30px",
                        marginTop: "1px",
                      }}
                    ></img>
                  </div>
                  {/* <span className='pl-2'>
                                        <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="48" height="32" rx="4" fill="white" />
                                            <path d="M26.0078 10.1719C26.4766 10.6406 26.75 11.2656 26.75 11.9297V20.25C26.75 21.6562 25.6172 22.75 24.25 22.75H15.5C14.0938 22.75 13 21.6562 13 20.25V9C13 7.63281 14.0938 6.5 15.5 6.5H21.3203C21.9844 6.5 22.6094 6.77344 23.0781 7.24219L26.0078 10.1719ZM24.875 20.25H24.8359V12.75H21.75C21.0469 12.75 20.5 12.2031 20.5 11.5V8.41406H15.5C15.1484 8.41406 14.875 8.6875 14.875 9.03906V20.25C14.875 20.6016 15.1484 20.875 15.5 20.875H24.25C24.5625 20.875 24.875 20.6016 24.875 20.25ZM22.0625 26.5C22.5703 26.5 23 26.1094 23 25.5625C23 25.0547 22.5703 24.625 22.0625 24.625H13.9375C12.375 24.625 11.125 23.375 11.125 21.8125V11.1875C11.125 10.6797 10.6953 10.25 10.1875 10.25C9.64062 10.25 9.25 10.6797 9.25 11.1875V21.8125C9.25 24.4297 11.3203 26.5 13.9375 26.5H22.0625Z" fill="#424548" />
                                            <path d="M34.3711 19.0039L30.8711 15.5039C30.625 15.2578 30.543 14.875 30.6797 14.5469C30.8164 14.2188 31.1445 14 31.5 14H38.5C38.8555 14 39.1562 14.2188 39.293 14.5469C39.4297 14.875 39.3477 15.2578 39.1016 15.5039L35.6016 19.0039C35.2734 19.3594 34.6992 19.3594 34.3711 19.0039Z" fill="#424548" />
                                        </svg>
                                    </span>
                                    <span className='pl-2'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 20.875C17.0156 20.875 17.875 21.7344 17.875 22.75C17.875 23.8047 17.0156 24.625 16 24.625C14.9453 24.625 14.125 23.8047 14.125 22.75C14.125 21.7344 14.9453 20.875 16 20.875ZM16 14.625C17.0156 14.625 17.875 15.4844 17.875 16.5C17.875 17.5547 17.0156 18.375 16 18.375C14.9453 18.375 14.125 17.5547 14.125 16.5C14.125 15.4844 14.9453 14.625 16 14.625ZM16 12.125C14.9453 12.125 14.125 11.3047 14.125 10.25C14.125 9.23438 14.9453 8.375 16 8.375C17.0156 8.375 17.875 9.23438 17.875 10.25C17.875 11.3047 17.0156 12.125 16 12.125Z" fill="#424548" />
                                        </svg>
                                    </span> */}
                </div>
              </div>
            </div>
            <div className="p-1">
              <div>
                <Routes>
                  <Route
                    path="/emission-overview"
                    element={
                      <>
                        <div
                          style={{ display: "flex" }}
                          className="bg-bgColor300"
                        >
                          <div style={{ flex: "1%", marginLeft: "3px" }}>
                            <div
                              className="pl-2 rounded-md border border-borderColor m-1 bg-bgColor500 text-textColor"
                              style={{ position: "relative", height: "100%" }}
                            >
                              <div className="pt-2 flex">
                                <label className="pl-2 font-semibold mt-1">
                                  Top 50 Sites - Cumulative Emissions
                                </label>
                                <label className="ml-auto font-semibold mt-1">
                                  Sorting&nbsp;
                                </label>
                                <div className="cursor-pointer z-2 text-black">
                                  <CustomMenu
                                    options={sortingOptions}
                                    value={selectedSortOption}
                                    onChange={handleSortSelectChange}
                                  />
                                </div>
                                <span className="font-semibold ml-auto mt-1">
                                  Compare to Last Year&nbsp;
                                </span>
                                <div className="cursor-pointer mt-1">
                                  <div
                                    className={`h-6 rounded-full p-1 duration-300 ease-in-out`}
                                    onClick={() =>
                                      setComparelastyear(!comparelastyear)
                                    }
                                    style={{
                                      width: "45px",
                                      backgroundColor: comparelastyear
                                        ? "#007994"
                                        : "#D0D0CE",
                                    }}
                                  >
                                    <div
                                      className={`h-4 w-5 bg-white rounded-full shadow-md transform transition-transform duration-400 ease-in-out ${
                                        comparelastyear ? "translate-x-6" : ""
                                      }`}
                                    ></div>
                                  </div>
                                </div>
                                <button
                                  className="ml-2 mt-2 flex items-center space-x-1 mb-2 mr-3 font-semibold"
                                  title="Download"
                                  onClick={() => {
                                    downloadCSV();
                                  }}
                                  style={{ width: "30px", height: "17px" }}
                                >
                                  {loading ? <LoaderIcon /> : <DownloadIcon />}
                                </button>
                              </div>
                              <div className="mt-2 mr-2">
                                <BarChart
                                  comparelastyear={comparelastyear}
                                  sortBy={selectedSortOption.value}
                                  chartData={chartNodata ? [] : chartDataState}
                                  isLoading={chartDetails.isLoading}
                                  uom={metric}
                                  endDate={endDate}
                                  customerId = {customerId}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginTop: "10px" }}
                          className="bg-bgColor300"
                        >
                          <div style={{ flex: "1%", marginLeft: "2px" }}>
                            <div
                              className="rounded-md border border-borderColor m-1 mt-1 box-shadow bg-bgColor500 text-textColor"
                              style={{ position: "relative", height: "100%" }}
                            >
                              <div
                                className="p-0.5 rounded-md border border-borderColor mt-2 bg-gradient-to-r from-white-500 to-white-500 flex items-center"
                                style={{
                                  height: "1em",
                                  padding: "11px 16px 20px 16px",
                                  float: "right",
                                  marginRight: "1%",
                                }}
                              >
                                <div
                                  className="xl_download_btn"
                                  onClick={() => {
                                    downloadGlobalSummaryTableCSV(
                                      tableDetails.data
                                    );
                                  }}
                                >
                                  <span>
                                    <i className="pi pi-download"></i>
                                    <span className="text-sm">
                                      {" "}
                                      &nbsp;Download Spreadsheet
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <GlobalSummaryView
                                apiData={chartNodata ? [] : tableDetails.data}
                                isTableLoading={tableDetails.isLoading}
                                isChartLoading={chartDetails.isLoading}
                                uom={metric}
                                customerId= {customerId}/>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    key="emission-overview"
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default EmissionOverview;
