import React, { useState , useEffect , useRef} from 'react';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import moment from 'moment';


interface NotesCalendarProps {
    date: Date | null;
    setDate: (date: Date | null) => void;
  }

const NotesCalendar: React.FC<NotesCalendarProps> = ({date,setDate}) => {
    const calendarRef = useRef<any>(null);
    const currentDate = moment().toDate();

    
    useEffect(() => {
        if (calendarRef.current) {
            // Use a timeout to ensure the component is fully mounted before focusing
            setTimeout(() => {
                const inputElement = calendarRef.current.inputElement;
                if (inputElement) {
                    inputElement.focus();
                }
            }, 0);
        }
    }, []);



    return (
        <Calendar 
        ref={calendarRef}
        value={date} 
        onChange={(e : any) => setDate(e.value)}
        maxDate={currentDate}
        className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font"  />
    );
};

export default NotesCalendar;