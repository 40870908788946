import { useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import { NavLink, useLocation } from "react-router-dom";
import "./assets/css/customers.css";
import AddCustomer from "./AddCustomer";
import EditCustomer from "./EditCustomer";

const CustomerAddEditIndex = (props: any) => {

  const location = useLocation();
  const [CustomerDetailsByID, SetCustomerDetailsByID] = useState<any>([]);
  const items = [
    { label: "Customer Details" },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  let url_split = location.pathname.split("/");
  return (
    <>
      <div className="bg-bgColor300">
        <div className="bg-bgColor300 text-textColor">
          <header className="w-full body-font">
            <div
              className="bg-bgColor500 p-2"
            >
              <div className="my-1" >
                <div className="min-width-200px op-select-dd-container cursor-pointer">
                  {/* Select OPtion for reports and notes START */}
                  <div className="all_customers_breadcrum">
                    <span style={{ fontWeight: "400" }}>
                      <NavLink to="/customers">
                        <span
                          style={{
                            textDecoration: "underline",
                            textDecorationThickness: "from-font",
                          }}
                        >
                          Customers
                        </span>
                      </NavLink>
                      \
                    </span>
                    <span
                      className="all_customers_breadcrum"
                      style={{
                        textDecoration: "underline",
                        textDecorationThickness: "from-font",
                      }}
                    >
                      {(() => {
                        if (url_split && url_split[1] == "add-customer") {
                          return "Create New Customer";
                        } else if (
                          url_split &&
                          url_split[1] == "edit-customer"
                        ) {
                          return "Edit Customer";
                        }
                      })()}
                    </span>
                  </div>
                  {/* Select OPtion for reports and notes END */}
                </div>
              </div>
            </div>
          </header>
        </div>
        <div
          className="body-tab-selection-layout rounded-tl-lg rounded-tr-md rounded-bl-sm rounded-br-sm bg-bgColor300"
          style={{ width: "100%" }}
        >
          <div className="mr-2">{""}</div>
        </div>
        <div className="mt-2 bg-bgColor400">
          <TabMenu
            model={items}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          />

          {(() => {
            if (url_split && url_split[1] == "add-customer") {
              return <AddCustomer />;
            } else if (url_split && url_split[1] == "edit-customer") {
              return <EditCustomer />;
            }
          })()}
        </div>
      </div>
    </>
  );
}


export default CustomerAddEditIndex;