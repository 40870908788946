import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import moment from 'moment';
import { TabMenu } from 'primereact/tabmenu';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { getTimzeZoneForAPI } from '../../utils/CommonUtils';
import "./assets/css/customers.css";
import AllCustomers from './AllCustomers';
import NoteServices from '../../config/services/notes-services/notes-services';
import AssetsTable from './components/customer-assets/AssetTable';
import CustomerAssets from './components/customer-assets';
import { hasPermission } from '../../utils/permissions';
import { getActiveRole } from '../../utils/role';
import CustomerUsers from './components/customer-assets/users/UsersList';
import SiteList from './components/sites/siteList';

const Customers = () => {
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [CustomerOption, SetCustomerOption] = useState<any>([]);
  const [HierOption, SetHierOption] = useState<any>([]);
  const [SiteOption, SetSiteOption] = useState<any>([]);
  const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>([]);
  const [HierOptionSelected, SetHierOptionSelected] = useState<any>([]);
  const [SiteOptionSelected, SetSiteOptionSelected] = useState<any>([]);
  const items = [
    { label: "Notes" },

  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  useEffect(() => {
    NoteServices.getCustomerHier().then((res) => {
      // let temp_cust = [{
      //   value: "0"
      //   , label: "All Customers"
      // }];
      let temp_cust:any = [];
      if (res.length > 0) {
        res.map((cust_val: any) => {
          temp_cust.push({
            value: cust_val.customerId
            , ...cust_val
          })
        })
      }
      SetCustomerOption(temp_cust)
    })
  }, [])
  let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
  interface DateTimeType {
    StartDate: String;
    EndTime: String;
  }
  const initialTimeRange: DateTimeType = {
    StartDate: moment
      .tz(timezoneForAPI)
      .subtract(1, "days")
      .format("MM-DD-YYYY HH:mm"),
    EndTime: moment.tz(timezoneForAPI).format("MM-DD-YYYY HH:mm"),
  };

  const [timeRange, setTimeRange] = useState<DateTimeType>(initialTimeRange);
  const updatedSelectedDate = useCallback<Function>(
    (dt: any) => {
      setTimeRange(dt);
    },
    [timeRange]
  );

  const [item, setItems] = useState<any>([]);

  const getAuthTabItems = async () => {
      
      const result = [];

      if(hasPermission(getActiveRole(userPrefstate.data), 'ground-data')){
          result.push({ label: "Customer Details" });
      }
      if(hasPermission(getActiveRole(userPrefstate.data), 'ground-data')){
          result.push({ label: "Sites" });
      }
      if(hasPermission(getActiveRole(userPrefstate.data), 'ground-data')){
          result.push({ label: "Users" });
      }

      if(hasPermission(getActiveRole(userPrefstate.data), 'ground-data')){
          result.push({ label: "Assets" });
          result.push({ label: "Delegations"});
          result.push({ label: "Alarms"});
          result.push({ label: "Notification Group"});
      }
      
      return result;
  }

  useEffect(() => {
      const fetchData = async () => {
          const data: any = await getAuthTabItems();
          setItems([...data]);
      };
      fetchData();
  }, []);

  const handleOptionSelect = (e: any) => {
    SetSiteOption([]);
    SetHierOptionSelected([]);
    SetSiteOptionSelected([]);
    SetHierOption([]);
    SetCustomerOptionSelected(e);
    if (e?.children?.length > 0) {
      let temp_hier: any = []
      e?.children?.map((cust_val: any, index: any) => {
        temp_hier.push({
          value: index + 1
          , ...cust_val
        })
      });
      SetHierOption(temp_hier);

    }
  }

  const handleOptionSelectHier = (e: any) => {

    SetHierOptionSelected(e);
    if (e?.children?.length > 0) {
      let temp_hier: any = []
      e?.children?.map((cust_val: any) => {
        temp_hier.push({
          value: cust_val.customerId
          , ...cust_val
        })
      });
      SetSiteOption(temp_hier);
    }
  }

  const handleOptionSelectSite = (e: any) => {

    SetSiteOptionSelected(e);
    
  }
  return (
    <>
      <div className="bg-bgColor400">
        <header className="w-full bg-bgColor400 px-3 text-textColor py-3 body-font">
          <div className="">
            <div className="">
              <div className="min-width-200px op-select-dd-container cursor-pointer">
                {/* Select OPtion for reports and notes START */}
                <div className="custom-select custom_dropdown_notes">
                  <div className='all_customers_breadcrum'>
                    Customers \ All Customers
                  </div>
                  <div className="flex absolute top-1 right-1">
                  </div>
                </div>
                {/* Select OPtion for reports and notes END */}
              </div>
            </div>
          </div>
        </header>
      </div>
      <div
        className="body-tab-selection-layout rounded-tl-lg rounded-tr-md rounded-bl-sm rounded-br-sm"
        style={{ width: "100%" }}
      >
      </div>
      <div className="bg-bgColor400">
        <TabMenu
            className="my-tab-menu"
            model={item}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
        />
        {activeIndex == 0 && (
        <div className="p-1">
          <AllCustomers searchTerm={searchTerm} timeRange={timeRange} SiteOptionSelected={SiteOptionSelected}></AllCustomers>
        </div>)}
        {activeIndex == 1 && (
        <div className="p-1">
          <SiteList/>
        </div>)}
        {activeIndex == 2 && (
        <div className="p-1">
          <CustomerUsers/>
        </div>)}
      </div>
  </>
)}


export default Customers;