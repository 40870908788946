import React, { useEffect, useState, useRef } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import ReportServices from "../../../../../config/services/report-services/troubleshooting-stats.service";
import { CSVLink } from "react-csv";

const DownloadIcon: React.FC = () => (
  <span className='inline-block text-center'>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-download"
      viewBox="0 0 16 16"
    >
      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
    </svg>
  </span>

);

const AnalysisGraph = (props: any) => {
  const chartDivRef = useRef<HTMLDivElement | null>(null);

  const [overviewData, setOverviewData] = useState<any>([]);
  const [tempLoading, settempLoading] = useState<any>(true);

  useEffect(() => {
    ReportServices.GetTableOverview({ startDate: props.timeRange.StartDate, endDate: props.timeRange.EndTime }).then((res: any) => {
      setOverviewData(res);
      settempLoading(false);
    })
  }, [props.timeRange])

  const createChart = (data: any) => {

    var root = am5.Root.new("analysisChart");
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root._logo?.dispose();

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        wheelY: "none",
        layout: root.verticalLayout,
        maxTooltipDistance: 0
      })
    );

    data.forEach((item: any) => {
      item.time = new Date(item.time).getTime();
    });
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 2,
    });

    xRenderer.grid.template.setAll({
      forceHidden: false
    });

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: xRenderer
      })
    );
    xAxis.set("dateFormats", {
      day: "dd",
      month: "MM",
    });


    function createSeries(name: any, field: any, color: any, opposite: boolean = false, text: string, rotation: number, align: number) {

      const yRenderer = am5xy.AxisRendererY.new(root, { opposite: opposite, });

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: yRenderer,

        })
      );
      var series: any = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "time",
          calculateAggregates: true,
          tooltip: am5.Tooltip.new(root, {}),
          legendLabelText: name === "Failure" ? "[{stroke}]{name} : {valueY.formatNumber('#.##')} %" : "[{stroke}]{name} : {valueY.formatNumber('#.##')} ",
          legendRangeLabelText: "[{stroke}]{name}",
          fill: am5.color(color),
          stroke: am5.color(color)
        })
      );
      yAxis.children.moveValue(am5.Label.new(root, { text: text, rotation: rotation, y: am5.p50, centerX: am5.p50 }), align);
      yAxis.get("renderer").labels.template.set("fill", am5.color(color));
      yAxis.set("tooltip", am5.Tooltip.new(root, {
        themeTags: ["axis"]
      }));

      series.bullets.push(function (root: any) {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 4,
            fill: series.get("fill")
          })
        });
      });

      if (name === "Failure") {
        series.get("tooltip").label.set("text", `{valueX.formatDate('dd-MMM')}: [bold]{valueY.formatNumber('#.##')}% ({name})`);
      }
      else {
        series.get("tooltip").label.set("text", "{valueX.formatDate('dd-MMM')}: [bold]{valueY.formatNumber('#.##')} ({name})")
      }
      series.get("tooltip")?.get("label")?.set("fill", am5.color("#FFFFFF"));

      series.data.setAll(data);
    }

    createSeries(
      "Deployed",
      "deployed",
      "#FFD566",
      false,
      `[bold ${"#D4D4D4"}]Unit(s)[/]`,
      -90,
      0
    );
    createSeries("Offline", "offline", "#4ABDAC", false, "", 0, 0);
    createSeries(
      "Failure",
      "failure",
      "#E0E0E0",
      true,
      `[bold ${"#D4D4D4"}]% Failure`,
      90,
      1
    );

    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomXY",
      xAxis: xAxis
    }));

    xAxis.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    var legend = chart.children.push(am5.Legend.new(root, {
      x: am5.percent(40),
      y: am5.percent(92),
    }));
    legend.data.setAll(chart.series.values);


    chart.bottomAxesContainer.children.push(
      am5.Label.new(root, {
        text: `[bold ${"#D4D4D4"}]Day(s)[/]`,
        fontSize: 14,
        x: am5.percent(50),
        centerX: am5.percent(50),
        y: am5.percent(135),
      })
    );
    return { chart: chart, rchart: root };

  }

  useEffect(() => {
    const chartDiv = chartDivRef.current;

    if (!chartDiv) {
      return;
    }

    chartDiv.innerHTML = '';

    if (tempLoading === true) {
      chartDiv.innerHTML = '<div style="text-align: center; margin-top: 20px; font-size: 14px; color: #292B2E;">Loading Data...</div>';
    } else if (overviewData.length === 0) {
      chartDiv.innerHTML = '<div style="text-align: center; margin-top: 20px; font-size: 14px; color: #292B2E;">No data available.</div>';
    } else {
      const { chart, rchart } = createChart(overviewData);
      return () => {
        chart.dispose();
        if (rchart) {
          rchart.dispose();
        }
      };
    }
  }, [tempLoading, overviewData]);

  const csvData = [
    ['Time', 'Deployed', 'Offline', 'Failure %'],
    ...overviewData.map((item: any) => [
      new Date(item.time).toLocaleDateString('en-US'),
      item.deployed,
      item.offline,
      item.failure,
    ]),
  ];

  const csvFilename = `Analysis_graph_(${props.timeRange.StartDate} - ${props.timeRange.EndTime})`;

  return (
    <>
      <div style={{ flex: "1%", marginLeft: "3px" }}>
        <div className="pl-2" style={{ position: "relative", height: "100%" }}>
          <div className='flex'>
            <div className='ml-auto cursor-pointer'>
              &nbsp;
            </div>
            <div className='pl-2 pr-2'>
              <div className="p-0.5 pr-1 rounded-md border border-gray-500 bg-gradient-to-r from-white-500 to-white-500 flex items-center" style={{ height: "34px" }} >
                <div className="p-1 ">
                  <button type="button" className={`${overviewData.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`} >
                    <CSVLink className="ml-auto flex items-center space-x-1" data={csvData} filename={csvFilename}>
                      <div className={`${overviewData.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`} >
                        <span className="text-sm" > &nbsp;Download chart</span>&nbsp;
                        <DownloadIcon />
                      </div>
                    </CSVLink>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="analysisChart" ref={chartDivRef} style={{ width: "100%", height: "550px" }}></div>
      </div>
    </>
  );
};


export default AnalysisGraph