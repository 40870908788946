import statusIcon from "../assets/img/status_icon.png";
import eyeIcon from "../assets/img/eye_icon.png";
import { DateTimeType, SliderDataType, SoofieAssetType } from "./Models";
import moment from "moment";

export const mtr_wind_speed = "m/s";
export const mtr_wind_std = "°";
export const mtr_wind_std_str = "deg";
export const mtr_wind_dir = "°";
export const mtr_wind_dir_str = "deg";
export const mtr_emission = "kg/hr @ 4hr CH4";
export const kghr = "kg/hr";
export const noData = "No Data";
export const ppm = "ppm";
export const percentage = "%";
export const emissionDetails1 = {
  data: [
    {
      raisedOn: "2024-01-04T12:15:00Z",
      time: "04-Jan-2024 06:15 AM",
      customerName: "EmissionCustomerB",
      customerId: "38c6cdf5-3190-4b81-a69b-8fe8b4b13702",
      siteId: "b9103afa-d901-4e3a-b7eb-3dfb1a6e0df2",
      siteName: "Asset 30",
      emission: null,
      activeAlert: false,
      alerts: [
        {
          name: "Linked asset not visible while updating alarm.",
          type: "concentration-CH4",
          alertEmission: 26.269999999999996,
          alertingSource: "Device 584",
          alertThreshold: 1.0,
          alertInterval: 15,
          uom: "Metric",
          constituent: "CH4",
          unit: "ppm",
        },
        {
          name: "Interval 3 threshold 10",
          type: "Custom",
          alertEmission: 91.6389057853628,
          alertingSource: "Device 1787",
          alertThreshold: 10.0,
          alertInterval: 45,
          uom: "Metric",
          constituent: "CH4",
          unit: "kg/hr",
        },
        {
          name: "LEL with delay 4",
          type: "LEL",
          alertEmission: 0.4,
          alertingSource: "Device 1384",
          alertThreshold: 0.2,
          alertInterval: 15,
          uom: "Metric",
          constituent: "LEL",
          unit: "%",
        },
      ],
    },
    {
      raisedOn: "2024-01-04T12:15:00Z",
      time: "04-Jan-2024 06:15 AM",
      customerName: "EmissionCustomerD",
      customerId: "eeb4e401-014b-4907-b237-4e646c8be3da",
      siteId: "43c08b8e-e349-45c1-8ea8-6ed524c3a54f",
      siteName: "Asset 67",
      emission: null,
      activeAlert: false,
      alerts: [
        {
          name: "Test Alarm - 01",
          type: "Custom",
          alertEmission: 16.6660776870417,
          alertingSource: "Device 528",
          alertThreshold: 1.66666666666667,
          alertInterval: 15,
          uom: "Metric",
          constituent: "CH4",
          unit: "kg/hr",
        },
      ],
    },
  ],
};
export const defaultSelecetedKeys = {
  "Flagged Assets": {
    checked: true,
    partialChecked: false,
  },
  "No Wind": {
    checked: true,
    partialChecked: false,
  },
  "No Data": {
    checked: true,
    partialChecked: false,
  },
  "Bad Methane": {
    checked: true,
    partialChecked: false,
  },
  "Bad Temperature": {
    checked: true,
    partialChecked: false,
  },
  "Fan Failed": {
    checked: true,
    partialChecked: false,
  },
  "No Polygon configured": {
    checked: true,
    partialChecked: false,
  },
  "No Alert configured": {
    checked: true,
    partialChecked: false,
  },
};
export const selectedKeysData = {
  "Select All": {
    checked: true,
    partialChecked: false,
  },
  "Flagged Assets": {
    checked: true,
    partialChecked: false,
  },
  "No Wind": {
    checked: true,
    partialChecked: false,
  },
  "No Data": {
    checked: true,
    partialChecked: false,
  },
  "Bad Methane": {
    checked: true,
    partialChecked: false,
  },
  "Bad Temperature": {
    checked: true,
    partialChecked: false,
  },
  "Fan Failed": {
    checked: true,
    partialChecked: false,
  },
  "No Polygon configured": {
    checked: true,
    partialChecked: false,
  },
  "No Alert configured": {
    checked: true,
    partialChecked: false,
  },
  "Healthy Assets": {
    checked: true,
    partialChecked: false,
  },
};
export const workOrderHistory = [
  {
    woNumber: "12345",
    date: "09-11-2023",
    user: "User 1",
    status: "closed",
    description: "Description of work performed or work order notes...",
  },
  {
    woNumber: "12346",
    date: "09-11-2023",
    user: "User 2",
    status: "closed",
    description: "Description of work performed or work order notes...",
  },
  {
    woNumber: "12347",
    date: "09-11-2023",
    user: "User 3",
    status: "closed",
    description: "Description of work performed or work order notes...",
  },
];

export const statusOptions = [
  { value: "Emission Alerts", label: "Emission Alerts", icon: statusIcon },
  { value: "Asset Alerts", label: "Asset Alerts", icon: statusIcon },
];
export const alertTypeOptions = [
  { value: "Select All", label: "Select All", icon: statusIcon },
  {
    value: "Emission Rate Alerts",
    label: "Emission Rate Alerts",
    icon: statusIcon,
  },
  {
    value: "Emission Concentration Alerts",
    label: "Emission Concentration Alerts",
    icon: statusIcon,
  },
];
export const opOverviewoptions = [
  { value: "Operations Overview", label: "Operations Overview", icon: eyeIcon },
  //{ value: 'Emission Performance', label: 'Emission Performance', icon: chartIcon }
];
export const infoTootltip =
  "Operations View intended to view only 24 hours at a time.";
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZGV2aXBpbm5hbWFuZW5pIiwiYSI6ImNtMTh0a2pleDEzOHYycnF2NXI1MTFrdnEifQ.1O8kT2rTUJ7jAi08y4PlPA";
export const heatMapArrowTootltip = "Click here to expand/collapse the Heat map options.";
export const heatMapInfoTootltip = "Heat Map only shown for time range between 30 mins to 2 hours.";

interface WindDirectionMapping {
  range: [number, number];
  angle?: number;
  label?: string;
}

// Define mappings for icons and text
export const directionMappings: {
  [key: string]: WindDirectionMapping[];
} = {
  icon: [
    { range: [337.5, 360], angle: 180 },
    { range: [0, 22.5], angle: 180 },
    { range: [22.5, 67.5], angle: 225 },
    { range: [67.5, 112.5], angle: 270 },
    { range: [112.5, 157.5], angle: 315 },
    { range: [157.5, 202.5], angle: 0 },
    { range: [202.5, 247.5], angle: 45 },
    { range: [247.5, 292.5], angle: 90 },
    { range: [292.5, 337.5], angle: 135 },
  ],
  text: [
    { range: [348.75, 360], label: "N" },
    { range: [0, 11.25], label: "N" },
    { range: [11.25, 33.75], label: "NNE" },
    { range: [33.75, 56.25], label: "NE" },
    { range: [56.25, 78.75], label: "ENE" },
    { range: [78.75, 101.25], label: "E" },
    { range: [101.25, 123.75], label: "ESE" },
    { range: [123.75, 146.25], label: "SE" },
    { range: [146.25, 168.75], label: "SSE" },
    { range: [168.75, 191.25], label: "S" },
    { range: [191.25, 213.75], label: "SSW" },
    { range: [213.75, 236.25], label: "SW" },
    { range: [236.25, 258.75], label: "WSW" },
    { range: [258.75, 281.25], label: "W" },
    { range: [281.25, 303.75], label: "WNW" },
    { range: [303.75, 326.25], label: "NW" },
    { range: [326.25, 348.75], label: "NNW" },
  ],
};
export const initialSliderObj = {
  dateTime: "",
  alertSent: false,
  windSpeed: 0,
  windDirection: 0,
  windVar: 0,
};
export const initialTimeSliderData: SliderDataType = {
  interval: 15,
  dataPoints: [],
};
export const initialSoofieAsset: SoofieAssetType = {
  Id: "",
  siteName: "",
  Inactive: false,
  lastSyncTime: "",
  lastCh4: 0,
  lastCH4Error: "",
  last24HourAlertCount: 0,
  lastWindDirection: undefined,
  lastWindSpeed: undefined,
  lastWindVar: undefined,
};
export const initialSoofieAlerts = {
  alertSent: "",
  alerts: [],
  windtriangle: {},
};
export const initialSiteInfo: any = {
  components: [],
  devices: [],
  region: "",
  location: "",
};
export const iniSoofieAlertList = {
  list: [],
};