const DownloadIcon: React.FC<{ className?: string }> = ({ className }) => (
    <span className='inline-block text-center'>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={`bi bi-download`}
            viewBox="0 0 16 16"
        >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
        </svg>
    </span>
  
  );

  const PlusIcon: React.FC<{ className?: string }> = ({ className }) => (
    <span className='inline-block text-center'>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={`bi bi-plus ${className}`}
            viewBox="0 0 16 16"
        >
            <path
                fillRule="evenodd"
                d="M8 1a.5.5 0 0 1 .5.5v6.5h6a.5.5 0 0 1 0 1h-6v6.5a.5.5 0 0 1-1 0V8.5H1a.5.5 0 0 1 0-1h6V1.5A.5.5 0 0 1 8 1z"
            />
        </svg>
    </span>
    );

    const FilterIcon: React.FC = () => (
        <span className='inline-block text-center'>
           <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.38589 3.66687C1.62955 2.82155 1.25138 2.39889 1.23712 2.03968C1.22473 1.72764 1.35882 1.42772 1.59963 1.22889C1.87684 1 2.44399 1 3.57828 1H18.4212C19.5555 1 20.1227 1 20.3999 1.22889C20.6407 1.42772 20.7748 1.72764 20.7624 2.03968C20.7481 2.39889 20.3699 2.82155 19.6136 3.66687L13.9074 10.0444C13.7566 10.2129 13.6812 10.2972 13.6275 10.3931C13.5798 10.4781 13.5448 10.5697 13.5236 10.6648C13.4997 10.7721 13.4997 10.8852 13.4997 11.1113V16.4584C13.4997 16.6539 13.4997 16.7517 13.4682 16.8363C13.4403 16.911 13.395 16.9779 13.336 17.0315C13.2692 17.0922 13.1784 17.1285 12.9969 17.2012L9.59686 18.5612C9.22931 18.7082 9.04554 18.7817 8.89802 18.751C8.76901 18.7242 8.6558 18.6476 8.583 18.5377C8.49975 18.4122 8.49975 18.2142 8.49975 17.8184V11.1113C8.49975 10.8852 8.49975 10.7721 8.47587 10.6648C8.45469 10.5697 8.41971 10.4781 8.37204 10.3931C8.31828 10.2972 8.2429 10.2129 8.09213 10.0444L2.38589 3.66687Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
        );
    


    interface RefreshIconProps {
        className?: string;
    }
    
    const RefreshIcon: React.FC<RefreshIconProps> = ({ className }) => (
        <span className='inline-block text-center'>
            <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.0532 19.2687C11.4523 20.3331 14.3098 20.2753 16.7568 18.8625C20.8223 16.5153 22.2152 11.3168 19.868 7.2513L19.618 6.81829M5.1451 15.7514C2.79789 11.6859 4.19083 6.48741 8.25631 4.1402C10.7033 2.72743 13.5608 2.66963 15.9599 3.73399M3 15.8351L5.73205 16.5671L6.4641 13.8351M18.5492 9.16712L19.2813 6.43507L22.0133 7.16712" stroke="#292B2E" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
    );


    const SearchIcon: React.FC = () => (
        <svg  className="absolute left-3 top-1/2 transform -translate-y-1/2" width="15" height="19" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
              fill="black"  />
            </svg>
    );

    const SearchHierarchy: React.FC = () => (
        <svg width="15" height="19" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
        fill="#424548" />
    </svg>
    );

    const SortIcon: React.FC = () => (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.42383 1.3125C7.79883 0.90625 8.45508 0.90625 8.83008 1.3125L12.8301 5.3125C13.1113 5.59375 13.2051 6.03125 13.0488 6.40625C12.8926 6.78125 12.5488 7.03125 12.1426 7.03125H4.14258C3.73633 7.03125 3.36133 6.78125 3.20508 6.40625C3.04883 6.03125 3.14258 5.59375 3.42383 5.3125L7.42383 1.3125ZM7.42383 14.7188L3.42383 10.7188C3.14258 10.4375 3.04883 10 3.20508 9.625C3.36133 9.25 3.73633 9 4.14258 9H12.1426C12.5176 9 12.8926 9.25 13.0488 9.625C13.2051 10 13.1113 10.4375 12.8301 10.7188L8.83008 14.7188C8.45508 15.125 7.79883 15.125 7.42383 14.7188Z" fill="#424548"/>
</svg>
    );

export { DownloadIcon, PlusIcon , RefreshIcon ,FilterIcon,SearchIcon,SortIcon ,SearchHierarchy};