import react, { useState, useEffect } from 'react';
import { SearchHierarchy } from '../../../../assets/svgIcons/svgIcons';
import NoteServices from '../../../../config/services/notes-services/notes-services';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import { Tree } from 'primereact/tree';
import SelectedHierarchy from './SelectedHierarchy';
import { PlusIcon } from '../../../../assets/svgIcons/svgIcons';

interface SiteHierarchyProps {
    data: any;
    onDataChange: (data: any) => void;
}
const SiteHierarchy: React.FC<SiteHierarchyProps> = ({ data, onDataChange }) => {
    const [CustomerOptionSelected, SetCustomerOptionSelected] = useState<any>({ label: 'Select Customer', value: '' });
    const [CustomerOption, SetCustomerOption] = useState<any>([]);

    const [advFilValue, setAdvFilValue] = useState<any>(null);
    const [advFilOption, setAdvFilOption] = useState<any>([]);
    const [enableFilterStatus, SetEnableFilterStatus] = useState(false);
    const [findSiteId, SetFindSiteId] = useState<any>([]);
    const [findLabels, SetFindLabels] = useState<any>([]);
    const [currentData, SetCurrentData] = useState<any>([]);
    const [popUpFilterData, SetPopUpFilterData] = useState<any>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [filteredDatas, setFilteredDatas] = useState([]);

    const handleDataChange = () => {
        const data = {
           filteredDatas
        };
        onDataChange(data);
    };
    useEffect(() => {
        handleDataChange();
    }, [filteredDatas]);

    useEffect(() => {
        NoteServices.getCustomerHier().then((res: any) => {

            let temp_cust: any = []; //{ 'label': 'Select Customer', 'value': '' }
            if (res.length > 0) {
                //temp_cust.push(res[0])
                res.map((cust_val: any) => {
                    temp_cust.push({
                        value: cust_val.customerId
                        , ...cust_val
                    })
                })
            }

            if (temp_cust?.length >= 1) {
                SetCustomerOptionSelected({ 'label': temp_cust[0]?.label, "value": temp_cust[0]?.customerId })
            } else {
                temp_cust.push({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'All Customers', 'parentId': 0, 'siteId': "" })
                // Change for Select Cus ddl
                const index = temp_cust.findIndex((item: any) => item?.label === "Select Customer");
                //Move the object to the first position if found
                if (index !== -1) {
                    const allCustomerObject = temp_cust.splice(index, 1)[0];
                    temp_cust.unshift(allCustomerObject);
                }
            }

            SetCustomerOption(temp_cust)

        })
        SetCustomerOptionSelected({ 'children': [], 'customerID': 0, 'groupId': 0, 'hasAccess': false, 'isPrimaryCustomer': false, 'label': 'Select Customer', 'parentId': 0, 'siteId': "" })
        //SetCustomerOptionSelected({ label: 'Select Customer', value: '' });
    }
        , [])

    const renderLoading = (
        <div>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm">Loading Data...</div>
        </div>
    );


    useEffect(() => {
        const filDAta = CustomerOption;
        const flattenedData = filDAta.map((node: any, index: any) => flattenTree(node, null, index));
        setAdvFilOption(flattenedData)
    }, [CustomerOption])

    const flattenTree = (node: any, parentId: any, index: any) => {
        const key: any = parentId ? `${parentId}-${index}` : `${index}`;
        const flattenedNode: any = {
            key,
            label: node?.label,
            children: [],
            data: node?.siteId,
        };


        if (node.children && node.children.length > 0) {
            node.children.forEach((child: any, i: any) => {
                const childNode: any = flattenTree(child, key, i);
                flattenedNode.children.push(childNode);
            });
        }
        return flattenedNode;
    };


    const handleFiterSelectionChange = (event: any) => {
        setAdvFilValue(event.value)
        SetEnableFilterStatus(false)
        const filteredResult: any = Object.keys(event?.value)?.length > 0 ? getCheckedNodes(advFilOption, event.value) : [];

        if (filteredResult?.length != 0) {
            // Extracting labels from the labels array
            let labelValues = filteredResult.map((item: any) => item.label);
            let SiteIdValues = filteredResult.map((item: any) => item.data);

            SetFindSiteId(SiteIdValues)
            SetFindLabels(labelValues)
            // Filtering data based on labels
            let filteredData = currentData?.filter((item: any) => labelValues.includes(item.siteName));
            SetPopUpFilterData(filteredData)
        } else {
            SetFindSiteId([]);
            SetFindLabels([]);
        }
        setFilteredDatas(filteredResult);
    };

    function getCheckedNodes(array: any, filterObject: any) {
        const checkedNodes: any = [];

        function recursiveCheck(node: any) {
            const nodeKey = node.key;
            const filterInfo = filterObject[nodeKey];


            if (filterInfo && filterInfo.checked) {
                checkedNodes.push({
                    key: nodeKey,
                    label: node.label,
                    data: node.data
                });
            }

            if (node.children && node.children.length > 0) {
                node.children.forEach((child: any) => {
                    recursiveCheck(child);
                });
            }
        }

        array.forEach((rootNode: any) => {
            recursiveCheck(rootNode);
        });

        return checkedNodes;
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filterTree = (nodes: any[], query: string) => {
        if (!query) {
            return nodes;
        }

        return nodes
            .map((node: any) => {
                if (node.label.toLowerCase().includes(query.toLowerCase())) {
                    return node;
                }

                if (node.children) {
                    const filteredChildren: any = filterTree(node.children, query);
                    if (filteredChildren.length > 0) {
                        return { ...node, children: filteredChildren };
                    }
                }

                return null;
            })
            .filter((node) => node !== null);
    };

    const filteredData = filterTree(advFilOption, searchQuery);


    return (
        <>
            <div className="tett table_card relative w-[584px] min-h-[876px] min-w-[540px] top-[5px] left-[-2px] p-[24px_0px] rounded-tl-[8px]">
                <div className="flex">
                    <div className="pl-1" >
                        <div className="flex flex-col">
                            <div className="header flex justify-between items-center pb-2">
                                <span className="font-mulish text-[20px] font-medium text-left">Choose Site Hierarchy</span>
                            </div>
                            <div className="border-b-[1px] border-solid border-[#BEBEBC] w-[109%] ml-[-5%]" />
                            {/*  Search input start  */}
                            <div className="flex gap-[8px] mt-4">
                                <div className="p-0.5 rounded-md border border-gray-400 bg-gradient-to-r from-white-500 to-white-500 flex items-center">
                                    <div className="text-white p-1 hover:cursor-pointer" >
                                        <SearchHierarchy />
                                    </div>
                                    <input
                                        type="text"
                                        className="w-[511px] text-sm bg-transparent focus:outline-none"
                                        placeholder="Search site hierarchies"
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            {/*  Search input end  */}
                            {/*  Site hierarchy list start  */}
                            {filteredData.length > 0 ? (
                                <>
                                    <div >
                                        <Tree value={filteredData} selectionMode="checkbox" selectionKeys={advFilValue}
                                            onSelectionChange={(e) => handleFiterSelectionChange(e)} className="w-full md:w-30rem" />
                                    </div>
                                    <div className="flex justify-start mt-3">
                                        <button className="bg-white text-black border border-[#CBCBC9] rounded-md hover:cursor-pointer w-[146px] h-[40px] py-2">
                                            <PlusIcon className="mr-2" />
                                            Add Hierarchy
                                        </button>
                                    </div>
                                </>
                            ) : (
                                renderLoading
                            )}
                        </div>
                    </div>
                    <div>
                        {filteredDatas.length > 0 && (
                            <SelectedHierarchy data={filteredDatas} />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
};

export default SiteHierarchy;