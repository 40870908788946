import { configureStore, combineReducers } from '@reduxjs/toolkit';
import MapSiteDetailsSlice from './operations-overview/MapSiteDetailsSlice';
import EmissionAlertSlice from './operations-overview/EmissionAlertSlice';
import AssetHierarchySlice from './operations-overview/AssetHierarchySlice';
import AssetAlertSlice from './operations-overview/AssetAlertSlice';
import UserPreferenceSlice from './operations-overview/UserPreferenceSlice';
import BarchartSlice from './emission-performance/BarchartSlice';
import SummaryViewSlice from './emission-performance/SummaryViewSlice';
import HeatMapSlice from './heatmap/HeatMapSlice';
import ProbableSourceChartSlice from './site_emission-overview/ProbableSourceChartSlice';
import SiteConfigurationChartSlice from './site_emission-overview/SiteConfigurationChartSlice';
import WorkorderHistoryChartSlice from './site_emission-overview/WorkorderHistoryChartSlice';
import PeriodicSurveysSlice from './history/PeriodicSurveusSlice';
import WindRoseChartSlice from './site_emission-overview/WindRoseChartSlice';
import ConfigurationTableChartSlice from './site_emission-overview/ConfigurationTableChartSlice';
import SiteMaintenanceTableSlice from './site_emission-overview/SiteMaintenanceTableSlice';
import SaveSiteMaintenanceTable from './site_emission-overview/SaveSiteMaintenanceTableSlice';
import SiteHistoryUptimeReportChartSlice from './site_emission-overview/SiteHistoryUptimeReportChartSlice';
import SoofieUptimeExportChartSlice from './site_emission-overview/SoofieUptimeExportChartSlice';
import SoofieUptimeGlobalChartSlice from './site_emission-overview/SoofieUptimeGlobalChartSlice';
import GroundDataReportSlice from './site_emission-overview/GroundDataReportSlice';
import CustomerAssetChartSlice from './customer-overview/CustomerAssetChartSlice';
import LoginChartSlice from './login-slice/LoginChartSlice';
import RefreshTokenChartSlice from './login-slice/RefreshTokenChartSlice';
import SiteReprocessChartSlice from './site_emission-overview/SiteReprocessChartSlice';
import RegenerateBillingChartSlice from './site_emission-overview/RegenerateBillingChartSlice';


const rootReducer = combineReducers({
    mapSiteState: MapSiteDetailsSlice.reducer,
    emissionAlertState: EmissionAlertSlice.reducer,
    assetAlertState: AssetAlertSlice.reducer,
    assetHierarchyState: AssetHierarchySlice.reducer,
    userPreferenceState: UserPreferenceSlice.reducer,
    barChartState: BarchartSlice.reducer,
    summaryViewTableState: SummaryViewSlice.reducer,
    heatmapState: HeatMapSlice.reducer,
    probableSourceChart: ProbableSourceChartSlice.reducer,
    siteConfigurationHistory: SiteConfigurationChartSlice.reducer,
    workOrderHistory: WorkorderHistoryChartSlice.reducer,
    periodicSurveys: PeriodicSurveysSlice.reducer,
    windRoseChart: WindRoseChartSlice.reducer,
    configurationTable: ConfigurationTableChartSlice.reducer,
    maintenanceTable: SiteMaintenanceTableSlice.reducer,
    saveMaintenance: SaveSiteMaintenanceTable.reducer,
    siteHistoryUptimeReport: SiteHistoryUptimeReportChartSlice.reducer,
    soofieUptimeExport: SoofieUptimeExportChartSlice.reducer,
    soofieUptimeGlobal: SoofieUptimeGlobalChartSlice.reducer,
    groundDataReport: GroundDataReportSlice.reducer,
    customerAsset: CustomerAssetChartSlice.reducer,
    loginState: LoginChartSlice.reducer,
    refreshTokenState: RefreshTokenChartSlice.reducer,
    siteReprocessState: SiteReprocessChartSlice.reducer,
    regenerateBillingState: RegenerateBillingChartSlice.reducer
});

const store = configureStore({
    reducer: rootReducer
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;

export default store;

