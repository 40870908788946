import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

interface MapComponentProps {
  onClick: (event: google.maps.MapMouseEvent) => void;
  center?: { lat: number; lng: number };
}

const containerStyle = {
  width: "100%",
  height: "50vh",
};

const MapComponent: React.FC<MapComponentProps> = ({ onClick, center }) => {
  return (
    <>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center || { lat: 0, lng: 0 }}
          zoom={10}
          onClick={onClick}
        >
          {center && <Marker position={center} />}
        </GoogleMap>
    </>
  );
};

export default MapComponent;
