export const UserService = {

    getUsersData() {
        return [
            {
                id: 1,
                userStatusActive: 'Active',
                firstName: 'John',
                lastName: 'Doe',
                smsEmail: 'john.doe@example.com',
                email: 'john@example.com',
                mobileContact: '123-456-7890',
                alternateNumber: '987-654-3210',
                selectedOption: 'Mobile',
                jobTitle: 'Software Engineer',
                selectedAccessLevel: 'Admin',
                cameraOperator: 'Yes',
                ssoUser: true,
                region: 'Region 1, Region 2',
                siteVisibility: 'All Site'
            },
            {
                id: 2,
                userStatusActive: 'Inactive',
                firstName: 'Jane',
                lastName: 'Smith',
                smsEmail: 'jane.smith@example.com',
                email: 'jane@example.com',
                mobileContact: '555-555-5555',
                alternateNumber: '999-999-9999',
                selectedOption: 'Office',
                jobTitle: 'UX Designer',
                selectedAccessLevel: 'User',
                cameraOperator: 'No',
                ssoUser: false,
                region: 'Region 1',
                siteVisibility: 'Multiple Sites'
            },
            {
                id: 3,
                userStatusActive: 'Active',
                firstName: 'Michael',
                lastName: 'Johnson',
                smsEmail: 'michael.johnson@example.com',
                email: 'michael@example.com',
                mobileContact: '987-654-3210',
                alternateNumber: '123-456-7890',
                selectedOption: 'Office',
                jobTitle: 'Product Manager',
                selectedAccessLevel: 'Admin',
                cameraOperator: 'No',
                ssoUser: true,
                region: 'Region 2',
                siteVisibility: '1 and 6'
            },
            {
                id: 4,
                userStatusActive: 'Active',
                firstName: 'Sarah',
                lastName: 'Connor',
                smsEmail: 'sarah.connor@example.com',
                email: 'sarah@example.com',
                mobileContact: '111-222-3333',
                alternateNumber: '444-555-6666',
                selectedOption: 'Other',
                jobTitle: 'Operations Manager',
                selectedAccessLevel: 'User',
                cameraOperator: 'Yes',
                ssoUser: true,
                region: 'Region 3',
                siteVisibility: 'All Site'
            },
            {
                id: 5,
                userStatusActive: 'Inactive',
                firstName: 'David',
                lastName: 'Beckham',
                smsEmail: 'david.beckham@example.com',
                email: 'david@example.com',
                mobileContact: '777-888-9999',
                alternateNumber: '000-111-2222',
                selectedOption: 'Mobile',
                jobTitle: 'Marketing Specialist',
                selectedAccessLevel: 'User',
                cameraOperator: 'No',
                ssoUser: false,
                region: 'Region 4',
                siteVisibility: 'Multiple Sites'
            }
        ];
    },

    getUser() {
        return Promise.resolve(this.getUsersData());
    },

};
