import { useEffect, useState, useRef, useCallback } from "react";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import AssetTable from "./AssetTable";
import "./assets/css/asset-style.css";
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from "../../../../store";
import { fetchCustomerAsset } from "../../../../store/thunk/SitesOverviewThunk";
import { CSVLink } from "react-csv";
import { SearchIcon } from "../../../../components/History/Icons/SiteArrowIcon";

const CustomerAssets = (props: any) => {
    const dispatch = useDispatch<AppDispatch>();

    const state = useSelector((state: any) => state.customerAsset);
    const [customerId, setCustomerId] = useState<any>("");
    const [searchTerm, setSearchTerm] = useState<any>("");

    useEffect(() => {
        if (props.CustomerDetailsByID && props.CustomerDetailsByID?.customerId) {
            setCustomerId(props.CustomerDetailsByID?.customerId);
        }
    }, [props.CustomerDetailsByID]);


    useEffect(() => {
        if (customerId) {
            dispatch(fetchCustomerAsset({ customerId }));
        }
    }, [customerId])

    const prepareDataForCSV = (data: any) => {
        return data.map((item: any) => ({
            'Asset Name': item.name, // replace with actual property names
            'Asset Type': item.assetType,
            'Model': item.model,
            'Serial No': item.serialNumber,
            'First Activation Date': item.activationDate,
            'Warranty Exp Date': item.warrantyExpirationDate,
            'Status': item.status
        }))
    };


    return (<>
        <div className="card p-3 tett table_card trbl-stats-container">
            <Toast position="bottom-center" />
            <div className="flex flex-col">
                <div className="trbl-stats-top-header">
                    <div className="flex flex-rowp-4 m-2 float-right">
                        <div className="billing-customer-dd-container">
                            <div className="p-0.5 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center"
                                style={{ height: "1em", padding: "11px 16px 20px 16px" }}>
                                <div className="xl_download_btn" >
                                    <CSVLink
                                        data={prepareDataForCSV(state.data)} // replace state with your data
                                        filename={"Customer_Assets.csv"}
                                        className="xl_download_btn"
                                    >
                                        <span>
                                            <i className="pi pi-download text-textColor" ></i><span className="text-sm text-textColor"> &nbsp;Download Spreadsheet</span>
                                        </span>
                                    </CSVLink>
                                </div>
                            </div>
                            <div>
                                <Button className="btn-yellow" ><span>+</span> New </Button>
                            </div>
                            {/* Search filter div  */}
                            <div className='ml-2'>
                                <div className="p-0.5 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center">
                                    <div className="text-white p-1 hover:cursor-pointer" >
                                        <SearchIcon />
                                    </div>
                                    <input
                                        type="text"
                                        className="w-48 text-sm bg-transparent focus:outline-none"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    // onKeyDown={handleKeyPress}
                                    // onKeyUp={handleKeyUp}
                                    // onFocus={handleFocus}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 m-2">
                    <AssetTable state={state} searchTerm={searchTerm} timeRange={props.timeRange} />
                </div>
            </div>

        </div>
    </>)
}

export default CustomerAssets;