
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserService } from './UserService';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EditIcon from '../../../../../assets/img/edit-icon.png';
import trashIcon from '../../../../../assets/img/trash-icon.png';
import CustomMenu from '../../../../form/CustomMenu';
import { CSVLink } from 'react-csv';
import "./usersList.css";
import { Button } from 'primereact/button';

interface UserDetails {
    id: string | null;
    firstName: string;
    lastName: string;
    smsEmail: string;
    email: string;
    mobileContact: string;
    alternateNumber: string;
    selectedOption: string;
    jobTitle: string;
    selectedAccessLevel: string | { label: string };
    userStatusActive: boolean;
    cameraOperator: boolean;
    ssoUser: boolean;
    region: string;
    siteVisibility: string;
    productType: string;
    [key: string]: string | boolean | null | { label: string };
}

const DownloadIcon: React.FC = () => (
    <span className='inline-block text-center'>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#D4D4D4"
            className="bi bi-download"
            viewBox="0 0 16 16"
        >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
        </svg>
    </span>

);

export default function CustomerUsers() {

    const emptyUser: UserDetails = {
        id: null,
        firstName: '',
        lastName: '',
        smsEmail: '',
        email: '',
        mobileContact: '',
        alternateNumber: '',
        selectedOption: '',
        jobTitle: '',
        selectedAccessLevel: '',
        userStatusActive: false,
        cameraOperator: false,
        ssoUser: false,
        region: '',
        siteVisibility: '',
        productType: ''
    };

    const [users, setUsers] = useState<UserDetails[]>([]);
    const [userDialog, setUserDialog] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>('Mobile');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState<boolean>(false);

    const [user, setUser] = useState<UserDetails>(emptyUser);
    const [selectedUsers, setSelectedUsers] = useState<UserDetails[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const toast = useRef<Toast>(null);

    const toggleSwitch = () => {
        setUser({ ...user, cameraOperator: !user.cameraOperator });
    };
    const toggleUserStatus = () => {
        setUser({ ...user, userStatusActive: !user.userStatusActive });
    };

    useEffect(() => {
        UserService.getUser().then((data: any) => setUsers(data));
    }, []);


    const openNew = () => {
        setIsEditing(false);
        setUser(emptyUser);
        setSubmitted(false);
        setUserDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUserDialog(false);
    };

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    };
    const saveUser = () => {
        setSubmitted(true);

        if (!user.firstName.trim() || !user.lastName.trim() || !user.email.trim()) {
            setShowErrorMessage(true);
            return;
        }

        setShowErrorMessage(false);
        if (user.firstName.trim()) {
            let _users = [...users];
            let _user = { ...user };

            if (user.id) {
                const index = findIndexById(user.id);
                _users[index] = _user;
                toast.current?.show({ severity: 'success', summary: 'Successful', detail: `The user ${user.firstName} ${user.lastName} was successfully updated!`, life: 3000 });
            } else {
                _user.id = createId();
                _users.push(_user);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: `The new user ${user.firstName} ${user.lastName} was successfully added!`,
                    life: 3000,
                });
            }
            setUsers(_users);
            setUserDialog(false);
            setUser(emptyUser);
        }
    };

    const editUser = (user: UserDetails) => {
        setIsEditing(true);
        setUser({ ...user });
        setUserDialog(true);
    };

    const confirmDeleteUser = (user: UserDetails) => {
        setUser(user);
        setDeleteUserDialog(true);
    };

    const deleteUser = () => {
        let _users = users.filter((val) => val.id !== user.id);

        setUsers(_users);
        setDeleteUserDialog(false);
        setUser(emptyUser);
        toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
    };

    const findIndexById = (id: string) => {
        let index = -1;

        for (let i = 0; i < users.length; i++) {
            if (users[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = (): string => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const onUserInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const val = e.target.value;
        let _user = { ...user };

        switch (name) {
            case 'firstName':
            case 'lastName':
            case 'jobTitle':
                if (/^[a-zA-Z]+$/.test(val) || val === '') {
                    _user[name] = val;
                }
                break;
            case 'mobileContact':
            case 'alternateNumber':
                if (/^\d*$/.test(val) || val === '') {
                    _user[name] = val;
                }
                break;
            default:
                _user[name] = val;
                break;
        }
        setUser(_user);
    };

    const deleteUserDialogFooter = (
        <React.Fragment>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={hideDeleteUserDialog} label="Cancel" style={{ backgroundColor: "white", border: "1px solid black", color: "black", height: "30px", marginLeft: "10px", padding: "8px" }} />
                <Button onClick={deleteUser} label="Delete" style={{ backgroundColor: "#f5b334", border: "0px solid #f5b334", color: "black", height: "30px", marginLeft: "10px", padding: "8px" }} />
            </div>
        </React.Fragment>
    );

    const handleDialogHide = () => {
        setUserDialog(false);
    };

    const renderDialogFooter = () => {
        return (
            <>
                {showErrorMessage && submitted && (
                    <div className="completed-status" style={{ display: "flex", float: "left" }}>
                        <i className="me-2 pi pi-info-circle" style={{ color: '#D33504' }}></i>
                        <label style={{ fontSize: "14px" }}>Please ensure all fields are correctly entered and try again.</label>
                    </div>
                )}
                <div style={{ display: "flex", float: "right" }}>
                    <button style={{ textDecoration: "underline", cursor: "pointer" }} onClick={hideDialog} >Cancel</button>&nbsp;&nbsp;
                    <button
                        className="text-black font-semi-bold px-3 py-2 rounded"
                        onClick={saveUser}
                        style={{ background: "#F5B334" }}
                    >
                        Save
                    </button>
                </div>
            </>
        );
    };

    const accessLevelOptions = [
        { value: 0, label: 'Admin' },
        { value: 1, label: 'User' },
        { value: 2, label: 'Read only' },

    ];

    const userTypeOptions = [
        { value: 0, label: 'Super admin' },
        { value: 1, label: 'Global admin' },
        { value: 2, label: 'Read only admin' },
        { value: 3, label: 'customer global admin' },
    ];

    const filteredUsers = users.filter(user => {
        const values = Object.values(user).map((value: any) => value.toString().toLowerCase());
        return values.some(value => value.includes(searchQuery.toLowerCase()));
    });

    const csvHeaders = [
        "Status",
        "User Name",
        "Email ID",
        "Access Level",
        "Region",
        "Site Visibility",
        "Product Type",
        "Camera Operator",
        "SSO",
    ];

    const csvData = [
        csvHeaders,
        ...filteredUsers.map(row => [
            row.userStatusActive ? "Completed" : "InCompleted",
            `${row.firstName} ${row.lastName}`,
            row.email,
            typeof row.selectedAccessLevel === 'object' && 'label' in row.selectedAccessLevel ? row.selectedAccessLevel.label : row.selectedAccessLevel,
            row.region,
            row.siteVisibility,
            row.productType,
            row.cameraOperator ? "Yes" : "No",
            row.ssoUser ? "Yes" : "No",
        ])
    ];

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(e.target.value);
    };

    // Available page size options
    const rowsPerPageOptions = [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 }
    ];

    // Example data to feed into the table
    const totalRecords = filteredUsers.length;

    return (
        <div>
            <Toast ref={toast} position="top-center" />
            <div className="tett table_card">
                <div className="pl-1" style={{ position: "relative", height: "100%" }}>
                    <div className='flex'>
                        <div>
                            <div className="mr-2 h-8 p-0.5  mt-1 rounded-md border border-borderColor bg-gradient-to-r from-white-500 to-white-500 flex items-center">
                                <input
                                    type="text"
                                    className="p-1 text-sm bg-transparent focus:outline-none"
                                    placeholder="Search "
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    style={{ width: "250px" }}
                                />
                                <div className="text-white p-1 hover:cursor-pointer" >
                                    <svg width="15" height="15" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                                            fill="#838588"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className='ml-auto cursor-pointer'>
                            <div className="p-0.5 pr-1 bg-gradient-to-r from-white-500 to-white-500 flex items-center shadow-sm" style={{ height: "34px" }} >
                                <div className="p-1 ">
                                    <CSVLink data={csvData} filename={"users.csv"} className="font-semi-bold px-2 py-1 rounded">
                                        <button type="button" className={`ml-auto flex items-center space-x-1 disabled:opacity-50 disabled:pointer-events-none`} >
                                            <DownloadIcon />
                                            <span className="text-sm text-textColor"> &nbsp;Download</span>&nbsp;
                                        </button>
                                    </CSVLink>
                                </div>
                            </div>
                            &nbsp;
                        </div>
                        <div className='pl-2'>
                            <Button className="px-2 py-2 rounded" style={{ background: "#F5B334" }} onClick={openNew} label="New user " icon="pi pi-plus" />
                        </div>
                    </div>
                </div>
                <DataTable
                    value={filteredUsers}
                    selection={selectedUsers}
                    onSelectionChange={(e) => setSelectedUsers(e.value)}
                    stripedRows
                    className="notes_table_css"
                    selectionMode="multiple"
                    paginator
                    rows={12}
                    paginatorLeft={
                        <span style={{ float: 'left', marginLeft: '10px' }}>
                            Showing {filteredUsers.length > 0 ? 1 : 0} to {filteredUsers.length} of {totalRecords} entries
                        </span>
                    }
                    paginatorTemplate="FirstPageLink PrevPageLink NextPageLink LastPageLink"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                >
                    <Column
                        field="userStatusActive"
                        header="Status"
                        sortable
                        body={(rowData: UserDetails) => {
                            const status = rowData.userStatusActive ? "Completed" : "InCompleted";
                            const isCompleted = status;
                            return (
                                <span className={isCompleted ? "completed-status" : ""}>
                                    {status}
                                </span>
                            );
                        }}
                        
                    ></Column>
                    <Column
                        header="User Name"
                        body={(rowData: UserDetails) => `${rowData.firstName} ${rowData.lastName}`}
                        sortable
                    ></Column>
                    <Column field="email" header="Email ID" sortable ></Column>
                    <Column
                        header="Access Level"
                        body={(rowData: UserDetails) => {
                            if (typeof rowData.selectedAccessLevel === 'object' && 'label' in rowData.selectedAccessLevel) {
                                return rowData.selectedAccessLevel.label;
                            } else {
                                return rowData.selectedAccessLevel;
                            }
                        }}
                        sortable
                    ></Column>
                    <Column field="region" header="Region" sortable ></Column>
                    <Column field="siteVisibility" header="Site Visibility" sortable ></Column>
                    <Column field="productType" header="Product Type" sortable ></Column>
                    <Column field="cameraOperator" header="Camera Operator"
                        body={(rowData: UserDetails) => rowData.cameraOperator ? "Yes" : "No"}
                        sortable
                    ></Column>
                    <Column field="ssoUser" header="SSO" sortable ></Column>
                    <Column
                        headerStyle={{ fontSize: "14px" }}
                        style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                        showFilterMatchModes={false}
                        body={(rowData: UserDetails) => {
                            return (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <img src={EditIcon} onClick={() => editUser(rowData)} style={{ fontSize: '1.5rem', cursor: "pointer", marginLeft: "6px" }}></img>
                                        <img src={trashIcon} onClick={() => confirmDeleteUser(rowData)} style={{ fontSize: '1.5rem', marginLeft: "15px", cursor: "pointer" }}></img>
                                    </div>
                                </>
                            );
                        }}
                        header={`Actions`}
                    ></Column>
                </DataTable>
            </div>

            <Dialog visible={userDialog}
                style={{ width: "600px" }}
                header={isEditing ? "Edit User Details" : "Add User Details"}
                modal onHide={handleDialogHide} footer={renderDialogFooter} >
                <div className="p-grid p-fluid">
                    <div className="flex">
                        <div className="col-6">
                            <label htmlFor="firstName" className='text-base'>First Name {user.firstName.trim() === '' && <span>*</span>}
                                {submitted && !user.firstName && <small className="p-error">First Name is required.</small>}
                            </label>
                            <div className="w-[100%] pr-2">
                                <InputText name="firstName" value={user.firstName} onChange={(e) => onUserInputChange(e, 'firstName')} required autoFocus className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                            </div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="lastName" className='text-base'>Last Name{user.lastName.trim() === '' && <span>*</span>}
                                {submitted && !user.lastName && <small className="p-error"> Last Name is required.</small>}
                            </label>
                            <InputText name="lastName" value={user.lastName} onChange={(e) => onUserInputChange(e, 'lastName')} className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="col-12">
                            <label htmlFor="email" className='text-base'>Email ID{user.email.trim() === '' && <span>*</span>}
                                {submitted && !user.email && <small className="p-error"> Add Email ID.</small>}</label>
                            <InputText name="email" value={user.email} onChange={(e: any) => onUserInputChange(e, 'email')} className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="col-6">
                            <label htmlFor="mobileContact" className='text-base'>Mobile Contact Number</label>
                            <div className="w-[100%] pr-2">
                                <InputText name="mobileContact" value={user.mobileContact} onChange={(e: any) => onUserInputChange(e, 'mobileContact')} className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                            </div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="alternateNumber" className='text-base'>Alternate Number</label>
                            <InputText name="alternateNumber" value={user.alternateNumber} onChange={(e: any) => onUserInputChange(e, 'alternateNumber')} className="text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font text-base" />
                            <label className="radio-button-container">
                                Mobile
                                <input
                                    type="radio"
                                    name="radio"
                                    value="Mobile"
                                    checked={selectedOption === 'Mobile'}
                                    onChange={handleRadioChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                            <label className="radio-button-container">
                                Office
                                <input
                                    type="radio"
                                    name="radio"
                                    value="Office"
                                    checked={selectedOption === 'Office'}
                                    onChange={handleRadioChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                            <label className="radio-button-container">
                                Other
                                <input
                                    type="radio"
                                    name="radio"
                                    value="Other"
                                    checked={selectedOption === 'Other'}
                                    onChange={handleRadioChange}
                                />
                                <span className="checkmark"></span>
                            </label>

                        </div>
                    </div>

                    <div className="flex">
                        <div className="col-6">
                            <label htmlFor="jobTitle" className='text-base'>Job Title</label>
                            <div className="w-[100%] pr-2">
                                <InputText name="jobTitle" value={user.jobTitle} onChange={(e) => onUserInputChange(e, 'jobTitle')} className="text-base text-left w-[100%] h-[35px] border border-solid border-[#ccc] px-2 py-1 rounded notes_form_text_font" placeholder="Type here..." />
                            </div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="accessLevel" className='text-base'>Access Level</label>
                            <div className='cursor-pointer'>
                                <CustomMenu
                                    className="w-[100%] pr-2 cursor-pointer text-base"
                                    options={accessLevelOptions}
                                    value={user?.selectedAccessLevel}
                                    placeholderStr="Please select"
                                    onChange={(value: any) => setUser({ ...user, selectedAccessLevel: value })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="col-12">
                            <label className='text-base'>User Type </label>
                            <div className='cursor-pointer'>
                                <CustomMenu
                                    className="w-[100%] pr-2 cursor-pointer"
                                    options={userTypeOptions}
                                    value={user.userType}
                                    onChange={(value: any) => setUser({ ...user, userType: value })}
                                    placeholderStr="Please select"
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="flex col-6">
                        <div style={{ flex: '1 1 0', fontSize: 16 }}>User Status (Active)</div>
                        <div style={{ width: 48, height: 24, position: 'relative', cursor: "pointer" }}>
                            <div
                                className={`h-6.5 rounded-full p-1 duration-300 ease-in-out`}
                                onClick={toggleUserStatus}
                                style={{ width: "50px", backgroundColor: user.userStatusActive ? "#F5B334" : "gray" }}
                            >
                                <div className={`h-5 w-6 bg-white rounded-full shadow-md transform transition-transform duration-400 ease-in-out ${user.userStatusActive ? "translate-x-5" : ""}`}></div>
                            </div>

                        </div>
                    </div>
                    <div className="flex col-6">
                        <div style={{ flex: '1 1 0', fontSize: 16, }}>Camera Operator</div>
                        <div style={{ width: 48, height: 24, position: 'relative', cursor: "pointer" }}>
                            <div
                                className={`h-6.5 rounded-full p-1 duration-300 ease-in-out`}
                                onClick={toggleSwitch}
                                style={{ width: "50px", backgroundColor: user.cameraOperator ? "#F5B334" : "gray" }}
                            >
                                <div className={`h-5 w-6 bg-white rounded-full shadow-md transform transition-transform duration-400 ease-in-out ${user.cameraOperator ? "translate-x-5" : ""}`}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog header={<>
                <div style={{ display: "flex" }}>
                    <img src={trashIcon} alt="Delete Icon"></img>
                    <div style={{ fontSize: "16px", color: "#25476a", marginLeft: "8px", fontWeight: "500" }}>Delete User</div>
                </div>
            </>}
                visible={deleteUserDialog}
                style={{ width: '20vw' }}
                footer={deleteUserDialogFooter}
                onHide={hideDeleteUserDialog}>
                <div style={{ marginTop: "15px" }}>
                    Are you sure you want to delete this user?
                </div>
            </Dialog>
        </div>
    );
}
