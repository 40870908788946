import {
    DataTable as PrimeDatatable,
    DataTableFilterMeta,
} from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { useEffect, useState, useRef } from "react";
import { CSVLink } from "react-csv";
import "../assets/css/troubleshooting.css";
import NoteServices from "../../../../../config/services/notes-services/notes-services";
import { useSelector } from "react-redux";
import { initTroublStatsIssueTypes, initTroublStatsTableOverview, initTroublStatsTableReports } from '../assets/const';
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import ReportServices from "../../../../../config/services/report-services/troubleshooting-stats.service";

const TableReports = (props: any) => {
    const { SelectedCustomer, SelectedSites } = props;
    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    const [SortField, setSortField] = useState("order");
    const [SortFieldSeq, setSortFieldSeq] = useState<any>(-1);
    const [tempLoading, SetTempLoading] = useState<any>(true);
    const [reportsData, setReportsData] = useState<any>([]);
    const [selectedIssue, setSelectedIssue] = useState<any>([]);
    const [filteredReportsData, setFilteredReportsData] = useState<any>([]);
    const customerName = props.SelectedCustomer || "";
    const startDate = props.timeRange.StartDate || "";
    const endDate = props.timeRange.EndTime || "";

    const renderLoading = (
        <div style={{padding:"5%"}}>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm">Loading Data...</div>
        </div>
    );
    useEffect(() => {
        SetTempLoading(props.tempLoading);
    }, [])
    useEffect(() => {
        ReportServices.GetTableReports({ startDate: props.timeRange.StartDate, endDate: props.timeRange.EndTime }).then((res: any) => {
            SetTempLoading(false);
            setReportsData(res);
            setFilteredReportsData(res);
        })
    }, [props.timeRange])
    useEffect(() => {
        let filteredReports: any = reportsData;
        //1. Issue Type filter
        if (selectedIssue.length) {
            filteredReports = reportsData.filter((el: any) => {
                return selectedIssue.some((e: any) => e.name.includes(el.issue));
            })
        }
        // 2. Customer level filter
        if (SelectedCustomer && SelectedCustomer !== "All Customers") {
            filteredReports = filteredReports.filter((el: any) => SelectedCustomer === el.operator)
        }
        // 3. Hierarchy/Region level filter
        // 4. Sites level filter
        if (SelectedSites.length) {
            filteredReports = filteredReports.filter((el: any) => {
                return SelectedSites.some((site: string) => (site === el.operator || site === el.region || site === el.site));
            })
        }
        setFilteredReportsData(filteredReports);
    }, [selectedIssue, SelectedCustomer, SelectedSites])

    const csvData = [
        ["Operator", "Region", "Site", "Soofie Device", "Issue Type", "Serial Number"],
        ...filteredReportsData?.map((item: any) => {
            return [item.operator, item.region, item.site, item.device, item.issue, item.serialNo];
        }),
    ];
    return (
        <div className="tett">
            {
                tempLoading == false ?
                    <div className="flex md:flex md:flex-grow flex-row justify-end space-x-1 top_btns">
                        <MultiSelect
                            value={selectedIssue}
                            onChange={(e) => setSelectedIssue(e.value)}
                            options={initTroublStatsIssueTypes}
                            optionLabel="name"
                            placeholder="Select Issue Type"
                            maxSelectedLabels={2}
                            className="cus_multi_select_notes_TableReport w-full md:w-15rem"
                        //selectedItemsLabel={selectedItemsLabel}
                        />

                        <CSVLink data={csvData}
                            filename={`TableReports_${customerName}_(${startDate} - ${endDate}).csv`}
                            style={{
                                border: "1px solid #3C4F61",
                                padding: "4px 5px",
                                borderRadius: "4px",
                                cursor: filteredReportsData.length > 0 ? "pointer" : "not-allowed",
                            }}>
                            <span>
                                <i className="pi pi-download" ></i><span className="text-sm"> &nbsp;Download Spreadsheet</span>
                            </span>
                        </CSVLink>
                    </div>
                    : <></>
            }
            {
                tempLoading == false ? <PrimeDatatable
                    stripedRows
                    sortField={SortField}
                    sortOrder={SortFieldSeq}
                    value={filteredReportsData}
                    paginator
                    className="trbl_stats_table_reports_css notes_table_css"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    rows={12}
                    rowsPerPageOptions={[12, 20, 30, 40, 50]}
                >
                    <Column
                        headerStyle={{ fontSize: "14px" }}
                        style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                        showFilterMatchModes={false}
                        field="operator"
                        header={`Operator`}
                        body={(body_val) => {
                            return (
                                <>
                                    {body_val.operator}
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        headerStyle={{ fontSize: "14px" }}
                        style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                        showFilterMatchModes={false}
                        field="region"
                        header={`Region`}
                        body={(body_val) => {
                            return (
                                <>
                                    {body_val.region}
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        headerStyle={{ fontSize: "14px" }}
                        style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                        showFilterMatchModes={false}
                        field="site"
                        header={`Site`}
                        body={(body_val) => {
                            return (
                                <>
                                    {body_val.site}
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        headerStyle={{ fontSize: "14px" }}
                        style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                        showFilterMatchModes={false}
                        field="device"
                        header={`Soofie Device`}
                        body={(body_val) => {
                            return (
                                <>
                                    {body_val.device}
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        headerStyle={{ fontSize: "14px" }}
                        style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                        showFilterMatchModes={false}
                        field="issue"
                        header={`Issue Type`}
                        body={(body_val) => {
                            return (
                                <>
                                    {body_val.issue}
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        headerStyle={{ fontSize: "14px" }}
                        style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                        showFilterMatchModes={false}
                        field="serialNo"
                        header={`Serial Number`}
                        body={(body_val) => {
                            return (
                                <>
                                    {body_val.serialNo}
                                </>
                            );
                        }}
                    ></Column>
                </PrimeDatatable> : ((renderLoading))
            }
        </div>
    )
}
export default TableReports;