import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import MapComponent from "./ModalGoogleMap";

interface MapModalProps {
  visible: boolean;
  onHide:any;
  onSave: any;
  initialLatLng: string; 
}

const MapModal: React.FC<any> = (props:any) => {
  const { visible, onHide, onSave, initialLatLng }:MapModalProps = props;
  const [latLng, setLatLng] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [coordinatesInput, setCoordinatesInput] = useState(initialLatLng); 
  const [radioCheck, setRadioCheck] = useState<string>("cordinates");
  const [error, setError] = useState<string | null>(null); 

  useEffect(() => {
    if (initialLatLng) {
      const [lat, lng] = initialLatLng.split(",").map(Number);
      setLatLng({ lat, lng });
      setCoordinatesInput(initialLatLng);
    }
  }, [initialLatLng]);

  const handleCoordinatesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoordinatesInput(e.target.value);
  };

  const updateMap = () => {
    const parts = coordinatesInput.split(",");
    if (parts.length !== 2 || isNaN(Number(parts[0])) || isNaN(Number(parts[1]))) {
      setError("Please enter comma separated Lat/long"); 
      return;
    }
    
    setError(null); 
    const [lat, lng] = parts.map(Number);
    setLatLng({ lat, lng });
  };
  const handleMapClick = (event: any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setLatLng({ lat, lng });
    setCoordinatesInput(`${lat},${lng}`); 
  };

  const clearSelection = () => {
    setLatLng({ lat: 0, lng: 0 });
    setCoordinatesInput("");
  };

  return (
    <Dialog
      header="Select Location"
      visible={visible}
      onHide={onHide}
      style={{ width: "40vw" }}
      footer={
        <div>
          <button className="p-2 font-semibold"style={{background:"#F5B334",color:"black",borderRadius:3}} onClick={clearSelection}>Clear Selection</button>&nbsp;
          <button className="p-2 font-semibold" style={{background:"#F5B334",color:"black",borderRadius:3}} onClick={() => onSave(latLng)}>Save</button>
          <button
            className="p-2 text-black font-bold underline"
            onClick={onHide}
            style={{ backgroundColor: "#fff", color: "#000" }}
          >
            Cancel
          </button>
        </div>
      }
    >
      <div style={{ height: "500px", width: "100%" }}>
        <div className="p-1">
          <label className="pt-2">
            <input
              type="radio"
              name="switchfield"
              checked={radioCheck === "cordinates"}
              className="pl-2 form-check-input"
              onChange={() => setRadioCheck("cordinates")}
            />&nbsp; Search by Coordinates
          </label>
        </div>
          <div className="input-group">
          <input
            type="text"
            value={coordinatesInput} 
            name="location"
            placeholder="Latitude,Longitude"
            className="form-control"
            autoComplete="new-password"
            spellCheck={false}
            onChange={handleCoordinatesChange}
          />
          <button className="btn btn-warning text-black" type="button" onClick={updateMap}>
          Update map
          </button>
        </div>
        <br />
        {error && <p style={{ color: "red" }}>{error}</p>} 
        <MapComponent onClick={handleMapClick} center={latLng} />
      </div>
      <br />
    </Dialog>
  );
};

export default MapModal;
