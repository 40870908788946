import { createRoot } from 'react-dom/client';
import { BrowserRouter} from "react-router-dom";
import './assets/css/custom-style.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from './store';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
<Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
</Provider>);
