import React from 'react'
import { AlertInfoType } from './Types/OperationsSite'
import DeviceIcon from "../assets/img/device.svg"

function SensorDetailsPopupItem({ alertInfo }: { alertInfo: AlertInfoType }) {
    return (
        <div>
            <div className="flex flex-row  mb-3">
                <div className="flex items-center ">
                    <div className="flex-grow pl-1 soofedata-first-item">
                        <div className="text-gray-600 text-xs">Asset Name</div>
                        <div className={`text-gray-800 text-sm alertdata-first-width`}>
                            {alertInfo.assetName}
                        </div>
                    </div>
                    <div className='soofedata-second-item ml-4'>
                        <div className="text-gray-600 text-xs mb-1">Alert Type</div>
                        <div className={`text-gray-800 text-sm p-1 pr-0 assetalert-highlight alertdata-first-width`} style={{ backgroundColor: "#FEEBB5" }}>
                            {alertInfo.alertType ? alertInfo.alertType : "Online"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SensorDetailsPopupItem