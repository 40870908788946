import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { createBrowserHistory } from 'history';
import { getClientIP } from './CommonUtils';

let currentUser: any = localStorage.getItem('user');
currentUser = JSON.parse(currentUser)
const browserHistory = null;
// const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'c1d1d737-4e0e-4f4c-bb24-509a487f517e',
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: browserHistory, userEmail: currentUser?.userName, userName: currentUser?.firstName + ' ' + currentUser?.lastName, authenticateUserId: currentUser?.userName, url: window.location.href, clientIP: (async () => {
          return await getClientIP();
        })()
      },
    },
    accountId: currentUser?.userName,
    appId: 'Emission Site',
  },
});

let startTime = Date.now();
try {
  appInsights.loadAppInsights(); // Track the current page view
  appInsights.trackEvent({
    name: 'User Auth Details',
    properties: {
      userEmail: currentUser?.userName,
      userName: currentUser?.lastName + ' ' + currentUser?.firstName,
      authenticateUserId: currentUser?.userName,
      currentTime: new Date().toISOString(),
      clientIP: (async () => {
        return await getClientIP();
      })(),
      url: window.location.href,
    },
  });
  appInsights.trackPageView();
  appInsights.setAuthenticatedUserContext(currentUser?.userName); // Set authenticated user context

  // Log user spent time metric when the application is about to unload
  window.addEventListener('beforeunload', () => {
    const endTime = Date.now();
    const durationInSeconds = Math.floor((endTime - startTime) / 1000);
    appInsights.trackMetric({
      name: 'OverAllUserSpentTime',
      average: durationInSeconds,
    });
  });

} catch (error) {
  // console.error('Error with Azure AppInsights:', error);
}

export { reactPlugin, appInsights };