import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { fetchEmissionAlerts } from "../../store/thunk/MapSliceThunk";
import { isNodata, IsNullOrEmpty, getTimzeZoneForAPI, getTimzeZoneUI, getEmissionMetric, getJsonStringify, emissionTypeTxt, alertTypeMetricTxt } from '../../utils/CommonUtils';
import { noData } from '../../utils/Constants';
import auraEverseIcon from './../../assets/img/aura_everse_icon.png';
import auraGalleryIcon from './../../assets/img/aura_gallery_icon.png';


const SiteEmissionAlert = (props: any) => {
    const [scrolledItem, setScrollItem] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>()
    const state = useSelector((state: any) => state.emissionAlertState);
    // const { data: emissionCurrState, isLoading } = useSelector((state: any) => state.emissionAlertState);
    const {
      searchResults,
      startDate,
      endDate,
      searchText,
      emissionMarker,
      customerIdValue,
      alertTypeResult,
    } = props;
    const filteredData = searchResults.filter((item: any) => item.siteId);
    const arrayData = !IsNullOrEmpty(searchText) ? filteredData : (!state.isLoading && (alertTypeResult.length ? alertTypeResult : state.data));
    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    let timeZone = getTimzeZoneForAPI(userPrefstate.data.timeZone);
    //let emissionMetric = getEmissionMetric(userPrefstate.data.currentUOM);
    const containerRef = useRef<HTMLDivElement>(null);
    const prevStartDateRef = useRef<any>({ startDate: "" });
    const prevEndDateRef = useRef<any>({ endDate: "" });
    const cleanedSearchText = searchText.trim().replace(/["']/g, '').trim();
    const [emissionCurrState, setEmissionCurrState] = useState(arrayData);
    const [intCardLoading, setIntCardLoading] = useState(true);
    useEffect(() => {
        setEmissionCurrState(alertTypeResult);
        closeLoader();
    }, [alertTypeResult]);
    useEffect(() => {
        if (!emissionMarker && containerRef.current) {
            const firstElement = containerRef.current.querySelector('.rounded-md');
            if (firstElement) {
                firstElement.scrollIntoView({ behavior: "smooth", block: "center" });
                setScrollItem(null);
            }
        } else if (emissionMarker && customerIdValue && containerRef.current) {
            const element = containerRef.current.querySelector(`[data-site-id="${emissionMarker}"]`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
                setScrollItem(emissionMarker);
            }
        }
    }, [emissionMarker, customerIdValue]);
    const atMatricHr = (alert: any) => `${(alert.alertInterval / 60).toFixed(2)}hr`;
    const getAlertThreshold = (alert: any) => {
        if (alert.type.includes("concentration") || alert.type.includes("LEL")) {
            return (`${(alert.alertThreshold).toFixed(1)} ${alert.unit} ${alert.constituent}`)
        } else {
            return (`${(alert.alertThreshold).toFixed(1)} ${alert.unit} @ ${atMatricHr(alert)}`)
        }
    }
    const renderLoading = (
        <div className="p-5 text-bold text-center">
            <div className="flex justify-center items-center">
                <h1>Loading Data...</h1>
            </div>
        </div>
    );
    const renderNoData = (
        <div className="p-5 text-bold text-center">
            <div className="flex justify-center items-center">
                <h1>No data available.</h1>
            </div>
        </div>
    );
    useEffect(() => {
        let isDateChange = (getJsonStringify(prevStartDateRef.current.startDate) !== getJsonStringify(startDate) && getJsonStringify(prevStartDateRef.current.endDate) !== getJsonStringify(endDate));
        prevStartDateRef.current = { startDate };
        prevEndDateRef.current = { endDate };
        if (isDateChange) {
            dispatch(fetchEmissionAlerts({ startDate, endDate, timeZone }))
            setEmissionCurrState(state.data);
            closeLoader();
        }
    }, [dispatch, startDate, endDate]);
    useEffect(() => {
        // if (searchResults.length && !IsNullOrEmpty(searchText)) {
        //     setEmissionCurrState(searchResults.filter((item: any) => item.siteId));
        //     closeLoader();
        // }
        if (searchResults.length) {
          setEmissionCurrState(searchResults.filter((item: any) => item.siteId));
        }
        closeLoader();
    }, [searchResults, startDate, endDate, state.data]);
    const closeLoader = () => {
      setTimeout(() => {
          setIntCardLoading(false);
      }, 2000);
    }
    return (
      <>
        <div ref={containerRef}>
          <div className="text-md mb-1 ml-2 font-bold text-sky-300">
            Emission Alert
          </div>
          {/* AURA Emission Alert start */}
          {/* <div className="rounded-md mb-2 bg-white box-shadow emissiondata-reset-background">
                    <div className="flex items-center p-2">
                        <div className="pl-1">
                            <img src={auraEverseIcon} width={25} height={25} />
                        </div>
                        <div className="flex items-center" style={{ flex: "1" }}>
                            <label className="" style={{ marginLeft: "auto" }}>
                                <img src={auraGalleryIcon} width={20} height={20} />
                            </label>
                        </div>
                    </div>
                    <div className="flex items-center p-2">
                        <div className="pl-1">
                            <span style={{ cursor: 'pointer' }} >Site Name</span>
                        </div>
                        <div className="flex items-center" style={{ flex: "1" }}>
                            <label className="highlight-Emissiontext" style={{ marginLeft: "auto" }}>Active Alert</label>
                        </div>
                    </div>
                    <div className="flex items-center p-2">
                        <div className="flex-grow pl-1 soofedata-first-item">
                            <div className="text-gray-600 text-xs">Alert Sent</div>
                            <div className={`text-gray-800 text-sm p-1 soofedata-highlight soofedata-first-width`}>
                                9.45 pm CT, 6/6/2023
                            </div>
                        </div>
                        <div className='soofedata-second-item'>
                            <div className="text-gray-600 text-xs mb-1 whitespace-nowrap">Total Number of Leaks Found</div>
                            <div className={`text-gray-800 text-sm p-1  soofedata-highlight soofedata-first-width`}>
                                3
                            </div>
                        </div>
                    </div>

                    <div className={`flex items-center p-2`}>
                        <div className="flex-grow pl-1 soofedata-first-item">
                            <div className="text-gray-600 text-xs">Evaluated Component</div>
                            <div className={`text-gray-800 text-sm p-1 soofedata-highlight soofedata-first-width`}>
                                2
                            </div>
                        </div>
                        <div className='soofedata-second-item'>
                            <div className="text-gray-600 text-xs mb-1 whitespace-nowrap">Survey Date</div>
                            <div className={`text-gray-800 text-sm p-1 soofedata-highlight soofedata-first-width`}>
                                10.00 pm CT, 6/26/2023
                            </div>
                        </div>
                    </div>
                </div> */}
          {/* AURA Emission Alert end */}

          {state.isLoading || intCardLoading ? (
            renderLoading
          ) : (
            <>
              {IsNullOrEmpty(state.data) ? (
                renderNoData
              ) : (
                <>
                  {IsNullOrEmpty(filteredData) && !IsNullOrEmpty(searchText) ? (
                    <div className="px-5 py-5 text-bold text-center">
                      <div className="flex justify-center items-center">
                        <h1>No data found.</h1>
                      </div>
                    </div>
                  ) : (
                    <>
                      {emissionCurrState &&
                        emissionCurrState.length > 0 &&
                        emissionCurrState.map(
                          (item: any, index: any) =>
                            (searchText === "" ||
                              item.customerName
                                .toLowerCase()
                                .includes(searchText.toLowerCase()) ||
                              item.siteName
                                .toLowerCase()
                                .includes(searchText.toLowerCase()) ||
                              item.alerts.some(
                                (alert: any) =>
                                  alert.type
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase()) ||
                                  searchText.toLowerCase() !==
                                    alert.type.toLowerCase()
                              )) && (
                              <div
                                key={index}
                                {...(emissionMarker && customerIdValue
                                  ? {
                                      "data-site-id": item.siteId,
                                      "data-customer-id": item.customerId,
                                    }
                                  : {})}
                                className={`rounded-md mb-2 ${
                                  scrolledItem === null
                                    ? "bg-bgColor300"
                                    : scrolledItem === item.siteId
                                    ? "bgInnerContainer"
                                    : "bg-bgColor text-textColor100"
                                } box-shadow emissiondata-reset-background `}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  //opacity: scrolledItem === null ? 1 : scrolledItem === item.siteId ? 1 : 0.4
                                }}
                              >
                                <div className="flex items-center p-2">
                                  <div className="pl-1">
                                    <span
                                      onClick={() => {
                                        const url = `/mapview?AssetId=${item.siteId}&CustomerId=${item.customerId}`;
                                        window.location.href = url;
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item.customerName}/
                                      {item.siteName || item.thingNo}
                                    </span>
                                  </div>
                                  <div
                                    className="flex items-center"
                                    style={{ flex: "1" }}
                                  >
                                    <label
                                      className={`highlight-Emissiontext ${
                                        scrolledItem === item.siteId
                                          ? "emissiondata-highlight text-red-800 font-medium"
                                          : ""
                                      }`}
                                      style={{ marginLeft: "auto" }}
                                    >
                                      Active Alert
                                    </label>
                                  </div>
                                </div>

                                {item.alerts
                                  .filter((alert: any) => {
                                    const types =
                                      item.alerts?.map((info: any) =>
                                        info.type.toLowerCase()
                                      ) || [];
                                    return (
                                      alert.type
                                        .toLowerCase()
                                        .includes(
                                          cleanedSearchText.toLowerCase()
                                        ) ||
                                      // || searchText.toLowerCase() !== alert.type.toLowerCase()
                                      // || types.map((type: any) => type.toLowerCase()).includes('custom')
                                      item.customerName
                                        .toLowerCase()
                                        .includes(
                                          cleanedSearchText.toLowerCase()
                                        ) ||
                                      item.siteName
                                        .toLowerCase()
                                        .includes(
                                          cleanedSearchText.toLowerCase()
                                        )
                                    );
                                  })
                                  .map((alert: any, alertIndex: any) => (
                                    <div key={alertIndex}>
                                      <div className="flex items-center p-2">
                                        <div className="flex-grow pl-1 soofedata-first-item">
                                          <div className="text-textColor50 text-xs">
                                            Alert Sent
                                          </div>
                                          <div
                                            className={`text-textColor100 text-sm p-1 ${
                                              scrolledItem === item.siteId
                                                ? "emissiondata-highlight"
                                                : ""
                                            } alertdata-first-width`}
                                          >
                                            {isNodata(item.time)
                                              ? noData
                                              : `${item.time} ${getTimzeZoneUI(
                                                  timeZone
                                                )}`}
                                          </div>
                                        </div>
                                        <div className="soofedata-second-item">
                                          <div className="text-textColor50 text-xs mb-1 whitespace-nowrap">
                                            Alert Emission{" "}
                                            {emissionTypeTxt(alert.type)}
                                          </div>
                                          <div
                                            className={`text-textColor100 text-sm p-1 ${
                                              scrolledItem === item.siteId
                                                ? "emissiondata-highlight"
                                                : ""
                                            } alertdata-first-width`}
                                          >
                                            {isNodata(alert.alertEmission)
                                              ? noData
                                              : `${parseFloat(
                                                  alert.alertEmission.toFixed(1)
                                                )} ${
                                                  alert.unit
                                                } ${alertTypeMetricTxt(alert)}`}
                                          </div>
                                        </div>
                                      </div>

                                      <div className={`flex items-center p-2`}>
                                        <div className="flex-grow pl-1 soofedata-first-item">
                                          <div className="text-textColor50 text-xs">
                                            Alerting Source
                                          </div>
                                          <div
                                            className={`text-textColor100 text-sm p-1 ${
                                              scrolledItem === item.siteId
                                                ? "emissiondata-highlight"
                                                : ""
                                            } alertdata-first-width`}
                                          >
                                            {isNodata(alert.alertingSource)
                                              ? noData
                                              : `${alert.alertingSource}`}
                                          </div>
                                        </div>
                                        <div className="soofedata-second-item">
                                          <div className="text-textColor50 text-xs mb-1 whitespace-nowrap">
                                            Alert Threshold{" "}
                                            {emissionTypeTxt(alert.type)}
                                          </div>
                                          <div
                                            className={`text-textColor100 text-sm p-1 ${
                                              scrolledItem === item.siteId
                                                ? "emissiondata-highlight"
                                                : ""
                                            } alertdata-first-width`}
                                          >
                                            {isNodata(alert.alertThreshold)
                                              ? noData
                                              : getAlertThreshold(alert)}
                                          </div>
                                        </div>
                                      </div>

                                      {item.alerts.length > 1 &&
                                        alertIndex !==
                                          item.alerts.length - 1 && (
                                          <div className="grey-line"></div>
                                        )}
                                    </div>
                                  ))}
                              </div>
                            )
                        )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
};

export default SiteEmissionAlert;
