import { DataTable as PrimeDatatable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import ReportServices from "../../../../../config/services/report-services/troubleshooting-stats.service";
import { useDispatch,useSelector } from "react-redux";
import { getTimzeZoneUI } from "../../../../../utils/CommonUtils";
import moment from "moment";
import regenerateIcon from '../assets/img/circle_arrow_icon.png';
import { fetchRegenerateBilling } from "../../../../../store/thunk/SitesOverviewThunk";
import {AppDispatch} from "../../../../../store/index";
import { Dialog } from 'primereact/dialog';

const BillingTable = forwardRef((props: any, ref) => {
    const userPrefstate = useSelector((state: any) => state.userPreferenceState);
    let timezoneForUI = getTimzeZoneUI(userPrefstate.data.timeZone);
    const dispatch = useDispatch<AppDispatch>();

    const [tempLoading, SettempLoading] = useState<any>(true);
    const [reportData, setReportData] = useState<any[]>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);

    const state = useSelector((state: any) => state.regenerateBillingState);
    const CustomerIds = [props.selectedCustomer.customerId];   
    const Month : any = props.formattedMonthYear;


    console.log("Regenerate Billing State", state.data);

    const fetchBillingReport = async () => {
        try {
            const customerIds = [props.selectedCustomer.customerId];
            const timePeriod = props.duration;
            const billingReportData = await ReportServices.SoofieBillingReport({ customerIds, timePeriod });
            SettempLoading(false);
            setReportData(billingReportData.data.reverse());
        } catch (error) {
            console.error("Error fetching Soofie billing report:", error);
        }
    };

    useEffect(() => {      
        fetchBillingReport();
    }, [props.selectedCustomer, props.duration]);

    const downloadBillingReport = async (customerId: string, fileName: string, customerName:string) => {
        try {
            const yearMonth = moment(props.fromDate).format("YYYY-MMM");
            const fullFileName = `${yearMonth}_${fileName}`;
            const response = await ReportServices.DownloadBillingReport({ customerId, fileName: fullFileName });

            if (response instanceof Blob) {
                const pseudoElement = document.createElement("a");
                pseudoElement.href = window.URL.createObjectURL(response);
                pseudoElement.download = `${customerName}_${yearMonth}_${fileName}`;
                document.body.appendChild(pseudoElement);
                pseudoElement.click();
                document.body.removeChild(pseudoElement);
            } else {
                console.error("Unexpected response type, expected Blob.");
            }

        } catch (error) {
            console.error("Error downloading billing report:", error);
        }
    };


    const renderLoading = (
        <div>
            <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
            </div>
            <div className="flex flex-wrap align-items-center justify-content-center text-sm">Loading Data...</div>
        </div>
    );
    const getStatusStyle = (status: string) => {
        return <div className={(status === "COMPLETE") ? "green-status" : "gray-status"}> {status} </div>
    }

    const openDialog = (reportData: any) => {
        setSelectedReport(reportData); // Store report data if needed
        setDialogVisible(true); // Open the dialog
    };

    const closeDialog = () => {
        setDialogVisible(false); // Close the dialog
    };

    const handleRegenerate =async ()=> {
        try {
            SettempLoading(true);
            closeDialog();
            const response = await dispatch(fetchRegenerateBilling({ CustomerIds, Month })).unwrap();
              // Extract the operator data from the response
             const operator = response.data?.[0]?.operator || '';

             const date = moment().tz(timezoneForUI);
             const formattedDate = date.format('DD-MMM-YYYY');
             const formattedTime = date.format('hh:mm A');

            const newRow = {
            time: `${formattedDate} ${formattedTime}`,
            customerName: operator,
            fileName: `${props.formattedMonthYear}.xlsx`,
            status: 'COMPLETE', // or appropriate initial status
            regenerateIcon: regenerateIcon
        };
            setReportData((prevData: any[]) => [newRow, ...prevData]); // Add new row to the top of the list
            await fetchBillingReport();
        } catch (error) {
            console.error("Error regenerating billing report:", error);
        } finally {
            SettempLoading(false);
        }
    };

    useImperativeHandle(ref, () => ({
        handleRegenerate
    }));

    const dialogFooter = (
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
             <button 
                onClick={handleRegenerate}
                style={{
                    backgroundColor: '#25476a',
                    color: '#ffffff',
                    padding: '8px 16px',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '4px'
                }}
            >
                Ok
            </button>
            <button 
                onClick={closeDialog} 
                style={{
                    border: '1px solid #c8c8c8',
                    backgroundColor: '#ffffff',
                    color: '#25476a',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    borderRadius: '4px'
                }}
            >
                Cancel
            </button>
           
        </div>
    );

    return (
        <div className="tett">
            {tempLoading == false ? <PrimeDatatable
                stripedRows
                value={reportData}
                paginator
                className="notes_table_css"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                rows={12}
                rowsPerPageOptions={[12, 20, 30, 40, 50]}
            >
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    field="time"
                    header={`Time (${timezoneForUI})`}
                    sortable
                    body={(body_val) => {
                        return (
                            <>
                                {body_val.time}
                            </>
                        );
                    }}
                ></Column>
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    field="customerName"
                    header={`Customer`}
                    sortable

                ></Column>
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    field="fileName"
                    header={`Billing Report`}
                    sortable
                    body={(body_val) => (<>
                        {body_val.fileName}&nbsp;&nbsp;<i className="pi pi-download" onClick={() => downloadBillingReport(body_val.customerId, body_val.fileName,body_val.customerName)}></i>
                    </>
                    )}
                ></Column>
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    field="status"
                    header={`Status`}
                    sortable
                    body={(body_val) => {
                        return (
                            <>
                                {getStatusStyle(body_val.status)}
                            </>
                        );
                    }}
                ></Column>
                <Column
                    headerStyle={{ fontSize: "14px" }}
                    style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
                    showFilterMatchModes={false}
                    header={`Regenerate`}
                    body={(body_val) => {
                        return (<img  onClick={() => openDialog(body_val)}  src={regenerateIcon} alt="Regenerate" className="selected-icon-right-arrow" />);
                    }}
                ></Column>
            </PrimeDatatable> : (
                (renderLoading)
            )}
             {/* Dialog component */}
             <Dialog
                visible={dialogVisible}
                onHide={closeDialog}
                style={{ width: '30vw', borderRadius: '8px' }}
                footer={dialogFooter}
                 headerClassName="custom-dialog-header"
                header={
                    <div style={{ fontSize: '16px', color: '#25476a', fontWeight: '500' }}>
                        Regenerate monthly billing report ?
                    </div>
                }
            >
               
            </Dialog>
        </div>
    )
})
export default BillingTable;