import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import "./AlertSensorPopup.css";
import "primeicons/primeicons.css";
import { OperationSite } from "./Types/OperationsSite";
import {
  getEmissionMetric,
  getTimzeZoneForAPI,
  getTimzeZoneUI,
  isNodata,
  emissionTypeTxt,
  alertTypeMetricTxt,
} from "../utils/CommonUtils";
import { kghr, noData, percentage, ppm } from "../utils/Constants";
const AlertDetailsPopup = ({ site }: { site: OperationSite }) => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector((state: any) => state.emissionAlertState);

  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  let timezoneForAPI = getTimzeZoneForAPI(userPrefstate.data.timeZone);
  //let emissionMetric = getEmissionMetric(userPrefstate.data.currentUOM);

  let currArr: any = "";
  const siteData = state.data?.filter(
    (s: any) => s.siteId === site.id && s.customerId === site.customerID
  );
  if (siteData && siteData?.length > 0) {
    currArr = siteData[0];
    // currArr = siteData[siteData.length - 1];
  }
  const atMatricHr = (alert: any) => `${alert.alertInterval / 60}hr`;
  const getAlertThreshold = (alert: any) => {
    if (alert.type.includes("concentration") || alert.type.includes("LEL")) {
      return `${alert.alertThreshold.toFixed(1)} ${alert.unit} ${
        alert.constituent
      }`;
    } else {
      return `${alert.alertThreshold.toFixed(1)} ${alert.unit} @ ${atMatricHr(
        alert
      )}`;
    }
  };

  return (
    <>
      <div style={{ padding: 0, margin: 0 }}>
        <div className="block p-2 bg-bgColor600 text-textColor rounded-lg shadow">
          <div className="flex flex mb-2 items-center">
            <div className=" mr-4 site-name-style">{site.thingNo}</div>
            <div className="text-sm ">
              <div className="custom_active_alert bg-red-100 text-red-800 text-sm font-medium mr-2 px-1.5 pt-2 rounded dark:bg-red-900 dark:text-red-300">
                Active Alert
              </div>
            </div>
          </div>

          {/* <div className='flex align-center mb-2'>
            <div className='mr-1 alert-details-style'>
              Alert Details
            </div>
          </div> */}

          <div className="flex items-center">
            <div className=" flex-grow pl-1 soofedata-first-item">
              <p className="mt-1 text-gray-600 text-xs pb-1">Alert Sent</p>
              <h2 className="text-gray-800 text-sm p-1 emissiondata-highlight  alertdata-first-width">
                {isNodata(currArr?.time)
                  ? noData
                  : `${currArr?.time} ${getTimzeZoneUI(timezoneForAPI)} `}
              </h2>
            </div>
            <div className="soofedata-second-item">
              <p className="mt-1 text-gray-600 text-xs pb-1">
                Alert Emission {emissionTypeTxt(currArr?.alerts[0]?.type)}
              </p>
              <h2 className="text-gray-800 text-sm p-1 emissiondata-highlight  alertdata-first-width">
                {isNodata(currArr?.alerts)
                  ? noData
                  : `${parseFloat(
                      currArr?.alerts[0]?.alertEmission.toFixed(1)
                    )} ${currArr?.alerts[0]?.unit} ${alertTypeMetricTxt(
                      currArr?.alerts[0]
                    )}`}
              </h2>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex-grow pl-1 soofedata-first-item">
              <p className="mt-1 text-gray-600 text-xs pb-1">Alerting Source</p>
              <h2 className="text-gray-800 text-sm p-1 emissiondata-highlight  alertdata-first-width">
                {isNodata(currArr?.alerts)
                  ? noData
                  : currArr?.alerts[0]?.alertingSource}
              </h2>
            </div>
            <div className="soofedata-second-item">
              <p className="mt-1 text-gray-600 text-xs pb-1">
                Alert Threshold {emissionTypeTxt(currArr?.alerts[0].type)}
              </p>
              <h2 className="text-gray-800 text-sm p-1 emissiondata-highlight  alertdata-first-width">
                {isNodata(currArr?.alerts)
                  ? noData
                  : getAlertThreshold(currArr?.alerts[0])}
              </h2>
            </div>
          </div>
          <div className="flex mt-4">
            <button
              type="button"
              className="font-medium border border-borderColor rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 w-full btn-site-details"
              onClick={() => {
                const url = `/mapview?AssetId=${site.id}&CustomerId=${site.customerID}`;
                window.location.href = url;
              }}
            >
              View Site Emissions
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertDetailsPopup;
