import { useEffect, useState } from 'react';
import { Chips } from "primereact/chips";
import { Tag } from 'primereact/tag';
import { InputSwitch } from 'primereact/inputswitch';
import Select from 'react-select'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Customer_logo from './assets/imgs/sample_customer_logo.jpg'
import { NavLink } from 'react-router-dom';
import CustomerServices from '../../config/services/customer-services/customer.service';

const EditCustomer = (props: any) => {
  const url = window.location.href;
  const customerId = url.substring(url.lastIndexOf('/') + 1);
  const [currentCustomer, setCurrentCustomer] = useState<any>([]);
  const [domainName, setDomainName] = useState([]);
  const [uomDropDown, setUomDropDown] = useState<any>("");
  const [customerActive, setCustomerActive] = useState(false);
  const [emissionUnitShow, setEmissionUnitShow] = useState(false);
  const [UpdateDomainName, setUpdateDomainName] = useState("");
  const [domainTags, setDomainTags] = useState<any>([]);
  const options = [
    { value: 'Value 1', label: 'Emission' },
    { value: 'Value 2', label: 'Methane' },
    { value: 'Value 3', label: 'Ethane' }
  ]
  useEffect(() => {
    CustomerServices.GetCustomerByID(customerId).then((res: any) => {
      setCurrentCustomer(res[0]);
    })
  }, [])

  useEffect(() => {
    formik.setValues({
      "customer_name": currentCustomer?.customer_name,
      "contact_name": currentCustomer?.contact_name,
      "sales_finance_name": currentCustomer?.sales_finance_name,
      "domain_name": currentCustomer?.domain_name,
      "customer_status": currentCustomer?.customer_status,
      "emission_unit": currentCustomer?.emission_unit,
      "uom": currentCustomer?.uom?.[0]?.value,
    })
    setUomDropDown(currentCustomer?.uom)
    setCustomerActive(currentCustomer?.customer_status)
    setEmissionUnitShow(currentCustomer?.emission_unit)
    setDomainTags(currentCustomer?.domain_name)
  }, [currentCustomer])


  const formik: any = useFormik({
    initialValues: {
      logoFile: '',
      logo: '',
      customer_name: '',
      contact_name: '',
      sales_finance_name: '',
      domain_name: '',
      customer_status: customerActive,
      emission_unit: emissionUnitShow,
      uom: '',
    },
    validationSchema: Yup.object().shape({
      customer_name: Yup.string().required('This field is required'),
      contact_name: Yup.string().required('This field is required'),
      sales_finance_name: Yup.string().required('This field is required'),
      domain_name: Yup.array().required('This field is required'),
      customer_status: Yup.string().required('This field is required'),
      emission_unit: Yup.string().required('This field is required'),
      uom: Yup.string().required('This field is required'),
    }),
    onSubmit: async (values: any) => {
      //const formData_redux = {
      //_id: prop?.id ? prop?.id : prop?.data?.id,
      //};
      //var formData = new FormData();
      //formData.append("aogiSiteID", prop?.id ? prop?.id : prop?.data?.id);
    },
  });

  const handleLogoChange = (e: any) => {
    let pictureFile = e.target.files[0];
    formik.setFieldValue('logoFile', pictureFile);
    formik.setFieldValue('logo', pictureFile?.name);
  }

  const handleDomainName = (e: any) => {
    var currTxt = UpdateDomainName?.split(",");

    if (UpdateDomainName.length >= 1) {
      setDomainTags((prevState: Set<string>) => {
        const updatedTags = new Set([...prevState, ...currTxt]);
        return [...updatedTags];
      });
      setUpdateDomainName("")
    }
    formik.setFieldValue('domain_name', domainTags);
  }

  const handleUomChange = (e: any) => {
    formik.setFieldValue('uom', e?.value);
    setUomDropDown({ 'value': e?.value, 'label': e?.label })
  }
  const deleteTags = (tid: any) => {
    const filteredTag = domainTags?.filter((tag: any, index: any) => index !== tid);
    setDomainTags(filteredTag);
    if (filteredTag?.length == 0) {
      formik.setFieldValue('domain_name', "");
    } else {
      formik.setFieldValue('domain_name', filteredTag);
    }
  }

  return <>
    <form className="" onSubmit={formik.handleSubmit} encType={'multipart/form-data'}>
      <div style={{ padding: '0px 20px', marginTop: '20px' }}>
        <div style={{ display: "flex" }}>
          <div className="tett table_card" style={{ flex: "40%" }}>
            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "1.5rem", flex: "50%" }}>
                <label htmlFor="choose-file">
                  <img src={Customer_logo} style={{ height: "8rem" }} />
                </label>
                <input style={{ display: "none" }} accept="image/gif, image/jpeg, image/png" id="choose-file" type="file" name={'logo'} onChange={(e) => handleLogoChange(e)} />
              </div>

            </div>
            <div style={{ display: "flex", paddingTop: '10px' }}>
              <div className='customer_details_tab_card_heading'>
                Customer Name
              </div>
            </div>
            <div>
              <div className=''>
                <input autoComplete="off" name='customer_name' onChange={formik.handleChange} value={formik.values.customer_name} onBlur={formik.handleBlur} className="border border-gray-500 pl-[5px] border-solid rounded w-96 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="customer_name" type="text" placeholder="Customer Name" style={{ height: '35px' }} />
              </div>
              {formik.touched.customer_name && formik.errors.customer_name ? <div className="text-red-400 text-sm">{formik.errors.customer_name}</div> : null}
            </div>
          </div>
          <div className="tett table_card" style={{ flex: "40%", marginLeft: "1em" }}>

            <div style={{ display: "flex" }}>
              <div className='customer_details_tab_card_heading'>
                Preffered Email Domains
              </div>

            </div>
            <div>
              <div style={{ display: "flex", marginTop: "1em", alignItems: "center" }}>
                <div className='customer_details_tab_card_heading'>
                  Domain Name
                </div>
              </div>
              <div style={{ marginTop: "0.5em" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input autoComplete="off" value={UpdateDomainName} name='customer_name' className="border border-gray-500 pl-[5px] border-solid rounded w-96 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="customer_name" type="text" onChange={(e: any) => setUpdateDomainName(e?.target.value)} placeholder="Customer Name" style={{ height: '35px' }} />

                  <button type='button' className='btn_yllw_blk py-1 px-3 rounded' style={{ height: '40px', marginLeft: '1em' }} onClick={(e: any) => handleDomainName(e)}>
                    <b><i className="pi pi-plus"></i></b>
                  </button>
                </div>
                {formik.touched.domain_name && formik.errors.domain_name ? <div className="text-red-400 text-sm">{formik.errors.domain_name}</div> : null}
                <div className='text-gray-400 text-sm'>
                  You can enter several domains seperated by comma.
                </div>
                <div className='mt-5'>
                  {
                    domainTags?.map((arr: any, idx: any) => {
                      return (
                        <Tag key={idx} className={'ml-2'} style={{ background: '#F1F1F0' }}>
                          <div className="flex align-items-center gap-2">
                            <span style={{ color: '#565656' }}>{arr.trim()}</span>
                            <div className='' onClick={() => deleteTags(idx)}>
                              <i className="pi pi-times text-xs"></i>
                            </div>
                          </div>
                        </Tag>
                      )
                    })
                  }

                </div>
              </div>
            </div>

          </div>
          <div style={{ flex: "15%" }}>

          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div className="tett table_card" style={{ flex: "40%", marginTop: "1em" }}>

            <div style={{ display: "flex" }}>
              <div className='customer_details_tab_card_heading'>
                Main Contacts
              </div>

            </div>

            <div>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "1.5rem", flex: "50%" }}   >
                  Contact Name :
                </div>
              </div>
              <div className=''>
                <input autoComplete="off" onChange={formik.handleChange} value={formik.values.contact_name} onBlur={formik.handleBlur} name="contact_name" className="border border-gray-500 pl-[5px] border-solid rounded w-96 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="contact_name" type="text" placeholder="Contact Name" style={{ height: '35px' }} />
              </div>
              {formik.touched.contact_name && formik.errors.contact_name ? <div className="text-red-400 text-sm">{formik.errors.contact_name}</div> : null}
            </div>

            <div>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "1rem", flex: "50%" }} >
                  Sales/Finance Contact Name:
                </div>

              </div>
              <div className=''>
                <input autoComplete="off" onChange={formik.handleChange} value={formik.values.sales_finance_name} onBlur={formik.handleBlur} name="sales_finance_name" className="border border-gray-500 pl-[5px] border-solid rounded w-96 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="sales_finance_name" type="text" placeholder="Sales/Finance Contact Name" style={{ height: '35px' }} />
              </div>
              {formik.touched.sales_finance_name && formik.errors.sales_finance_name ? <div className="text-red-400 text-sm">{formik.errors.sales_finance_name}</div> : null}
            </div>
          </div>

          <div className="tett table_card" style={{ flex: "40%", marginLeft: "1em", marginTop: "1em" }}>

            <div style={{ display: "flex" }}>
              <div className='customer_details_tab_card_heading'>
                Settings
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "1rem", flex: "5%" }}>
                Customer Status:
              </div>
              <div style={{ marginTop: "1rem", flex: "2%", display: "flex", alignItems: "center" }}>
                <label htmlFor="customer_status">
                  {
                    customerActive == true ? "Active" : "Inactive"
                  }
                </label>
              </div>
              <div style={{ marginTop: "1rem", display: "flex", flex: "20%", alignItems: "center" }}>
                <InputSwitch
                  name='customer_status'
                  value={formik.values.customer_status}
                  onBlur={formik.handleBlur}
                  id='customer_status'
                  checked={customerActive}
                  onChange={(e) => {
                    setCustomerActive(e.value)
                    formik.setFieldValue('customer_status', e?.value)
                  }
                  } />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "1rem", flex: "5%" }}>
                Emission Unit:
              </div>
              <div style={{ marginTop: "1rem", flex: "2%", display: "flex", alignItems: "center" }}>
                <label htmlFor="emission_unit">
                  {
                    emissionUnitShow == true ? "Show" : "Hide"
                  }
                </label>
              </div>
              <div style={{ marginTop: "1rem", display: "flex", flex: "20%", alignItems: "center" }}>
                <InputSwitch
                  name='emission_unit'
                  value={formik.values.emission_unit}
                  onBlur={formik.handleBlur}
                  id='emission_unit'
                  checked={emissionUnitShow}
                  onChange={(e) => {
                    setEmissionUnitShow(e.value)
                    formik.setFieldValue('emission_unit', e?.value)
                  }
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "1rem", flex: "5%" }}>
                UOM:
              </div>
              <div style={{ marginTop: "1rem", flex: "46%", display: "flex", alignItems: "center" }}>
                <Select
                  name="uom"
                  id="uom"
                  placeholder={'Select....'}
                  options={options}
                  className='customer_uom'
                  value={uomDropDown}
                  onChange={(e) => handleUomChange(e)}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            {formik.touched.uom && formik.errors.uom ? <div style={{ marginLeft: '160px', marginTop: '1px' }} className="text-red-400 text-sm">{formik.errors.uom}</div> : null}
          </div>
          <div style={{ flex: "15%" }}>

          </div>
        </div>

      </div>
      <div style={{ display: "flex", marginTop: '5rem' }}>
        <div className="table_card" style={{ flex: "40%" }}>
          <div className='float-right'>
            <NavLink style={{ textDecoration: 'underline from-font' }} to={'/customers'} title='Cancel'>Cancel</NavLink>
            &nbsp;&nbsp;&nbsp;&nbsp;

            <button
              type='submit'
              // disabled={!(formik.isValid && formik.dirty)}
              className={!(formik.isValid && formik.dirty) ? 'btn_yllw_blk opacity-50 font-bold py-2 px-4 rounded' : ' btn_yllw_blk font-bold py-2 px-4 rounded'}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </>
}

export default EditCustomer;