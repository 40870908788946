import {
  DataTable as PrimeDatatable,
  DataTableFilterMeta,
} from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { useEffect, useState, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import EyeEnabled from "../../assets/img/eye_enabled.svg";
import { CSVLink } from "react-csv";
import NoteServices from "../../config/services/notes-services/notes-services";
import moment from 'moment';
import EditIcon from './assets/edit.png';
import DeleteIcon from './assets/trash.png';
import { Toast } from 'primereact/toast';
import { useSelector } from "react-redux";
import { getTimzeZoneUI, UTCToDesiredTimezone,convertUTCToTimezone } from "../../utils/CommonUtils";
import { Diversity1Outlined } from "@mui/icons-material";
import { getAuthUser } from "../../config/services/global-services/global.service";
import { hasPermission } from '../../utils/permissions';
import { getActiveRole } from '../../utils/role';
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from 'primereact/dropdown';
import NotesCalendar from "./components/NotesCalendar";
import { Tooltip } from 'primereact/tooltip';

const Notes = (props: any) => {

  const userPrefstate = useSelector((state: any) => state.userPreferenceState);

  let timezoneForUI = getTimzeZoneUI(userPrefstate.data.timeZone);

  /*console.log("Role", getActiveRole({
    "timeZone": "Etc/UTC",
    "currentUOM": "Metric",
    "language": "en",
    "superAdmin": false,
    "globalAdmin": false,
    "readOnly": false,
    "accessLevel": "admin",
    "isDelegatedGlobalAdmin": false,
    "isDelegatedCustomerAdmin": false
}))*/

  const toastBottomCenter = useRef<any>(null);
  const searchTerm = props.searchTerm;
  const timeRange = props.timeRange;
  const setTimeRange = props.setTimeRange;
  const SiteOptionSelected = props.SiteOptionSelected;
  const [csvData, setCsvData] = useState<any>([])
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayAdd, setDisplayAdd] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [SortField, setSortField] = useState("order");
  const [SortFieldSeq, setSortFieldSeq] = useState<any>(-1);
  const [position, setPosition] = useState('center');
  const [readOnlySiteName, SetreadOnlySiteName] = useState('');
  const [readOnlyCustomerName, SetreadOnlyCustomerName] = useState('');
  const [readOnlyCustomerId, setReadOnlyCustomerId] = useState<string>('');
  const [readOnlyID, SetreadOnlyID] = useState('');
  const [readNoteCat, SetreadNoteCat] = useState('');
  const [readNoteTag, SetreadNoteTag] =  useState<string[]>([]);
  const [readDetails, SetreadDetails] = useState('');
  const [readLoggedBy, SetreadLoggedBy] = useState('');
  const [readLoggedDate, SetreadLoggedDate] = useState('');
  const [readLastUpdated, SetreadLastUpdated] = useState('');
  const [filteredValues, SetfilteredValues] = useState<any>();
  const [originalValues, SetOriginalValues] = useState<any>([]);
  const [noteCategory, setNoteCategory] = useState<any>([]);
  const [checkedNoteCat, setCheckedNoteCat] = useState<any>(null);
  const [getAlmId, setGetAlmId] = useState<any>(null);
  const [checkedNoteCatLength, setCheckedNoteCatLength] = useState<any>("All Note Category");
  const [tempLoading, SettempLoading] = useState<any>(true);

  // Ritesh State Update for delete
  const [deleteId, setDeleteId] = useState<any>("");
  const [deleteAlarmId, setDeleteAlarmId] = useState<any>("");

  const [updateLoadingNotes, setUpdateLoadingNotes] = useState<any>(false);
  const [customerNameSuggestions, setCustomerNameSuggestions] = useState([]);
  const [siteNameSuggestions, setSiteNameSuggestions] = useState([]);
  const [customerHierarchy, setCustomerHierarchy] = useState<any[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');
  const [selectedSiteId, setselectedSiteId] = useState<string>('');
  const [workPerformedDate, setWorkPerformedDate] = useState<Date | null>(null);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const noteCategoryOptions = [
    { label: 'Alert - Emission', value: 'Alert - Emission' },
    { label: 'Alert - Assets', value: 'Alert - Assets' },
    { label: 'Site - Maintenance', value: 'Site - Maintenance' }
];

const noteTagOptions = [
  { label: 'Emission Alerts', value: 'Emission Alerts' },
  { label: 'Asset Alert', value: 'Asset Alert' },
  { label: 'Site Maintenance', value: 'Site Maintenance' }
];

  useEffect(() => {
    if (originalValues?.length > 0) {
      let filteredData = [...originalValues];
      //let filteredData = props?.enableFilterStatus == true ? (props?.currentData?.length > 0 ? [...props.currentData] : [...originalValues]) : [...originalValues];
      //Advance filter to choose children customer
      if (props?.chosenCustomer?.length > 0) {
        filteredData = filteredData?.filter((item: any) => props?.chosenCustomer?.includes(item.siteName));
      }
      //Advance filter reset to all customers
      if (props?.customerOption == "All Customers") {
        filteredData = originalValues;
      }

      //Search Customer
      if (searchTerm) {
        filteredData = filteredData.filter((item: any) => {
          
          return (
            item.siteName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.details?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.loggedBy?.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
      }

      //Note Category filter
      if (checkedNoteCat?.length > 0) {
        const uniqueIds = [...new Set(checkedNoteCat?.map((r: any) => r.category || "Others"))];
        filteredData = filteredData.filter((item: any) => uniqueIds.includes(item.category || "Others"));
      }

      //Customer Dropdown filter
      if (props?.searchedCustomer) {

        filteredData = filteredData.filter((itm: any) => {

          // console.log(props?.searchedCustomer,"props?.searchedCustomer");
          return itm.customerID == props?.searchedCustomer;
        });
      }
      SetfilteredValues(filteredData);
      // props?.SetCurrentData(originalValues)
      setCsvData([
        ["Site Name", "Note Category", "Details", "Tags", "Logged By", "Logged Date", "Last Updated"," Work Performed"],
        ...filteredData.map((item: any) => {

          return [item.siteName, item.category, item.details, item.tag, item.loggedBy, item.loggedOn, item.updatedOn,item.workPerformed,
             // Convert loggedOn timestamp to desired timezone
            //  UTCToDesiredTimezone(item.loggedOn, timezoneForUI),
             // Convert updatedOn timestamp to desired timezone
              // UTCToDesiredTimezone(item.updatedOn, timezoneForUI)
            ];
        }),
      ]);
    } else {
      SetfilteredValues([]);
      // props?.SetCurrentData([])
      setCsvData([]);
    }
  }, [searchTerm, SiteOptionSelected, checkedNoteCat, props?.searchedCustomer, originalValues, props?.customerOption, props?.chosenCustomer/*, props?.enableFilterStatus*/]);


  useEffect(() => {
    SettempLoading(true)
    NoteServices.GetNotes(timeRange).then((res: any) => {
      if (res?.length > 0) {
        SetOriginalValues(res)
        SetfilteredValues(res)
        // props?.SetCurrentData(res)
        setCsvData([
          [`Site Name`, "Note Category", "Details", `Tags`, "Logged By", `Logged Date`, "Last Updated"," Work Performed"],
          ...res.map((item: any, index: number) => {
            return [
              item.siteName,
              item.category,
              item.details,
              item.tag,
              item.loggedBy,
              item.loggedOn,
              item.updatedOn,
              item.workPerformed,
              // Convert loggedOn timestamp to desired timezone
              // UTCToDesiredTimezone(item.loggedOn, timezoneForUI),
              // Convert updatedOn timestamp to desired timezone
              //  UTCToDesiredTimezone(item.updatedOn, timezoneForUI)
            ];
          }),
        ])

        //Creating Unique Alert Type Starts
        const uniqueAlertTypes: any = {};
        res.forEach((item: any) => {
          const category = item.category || "Others"; // Set category to "Others" if null or empty
          uniqueAlertTypes[category] = { id: item.id, category: category };
        });
        const finalAlertType = Object.values(uniqueAlertTypes);
        setNoteCategory(finalAlertType)
        //Creating Unique Alert Type Ends
      } else {
        SetfilteredValues([])
        SetOriginalValues([])
        setCsvData([])

      }
      setTimeout(() => {
        SettempLoading(false)
      }, 2000)
    }).catch(() => {
      setTimeout(() => {
        SettempLoading(false)
      }, 2000)
    })
  }, [timeRange])

  useEffect(() => {
    if (noteCategory?.length > 0) {
        // Preselect all categories on initial load
        setCheckedNoteCat(noteCategory);
        setCheckedNoteCatLength("All Note Category");
    }
}, [noteCategory]);

  useEffect(() => {
    const fetchCustomerHierarchy = async () => {
      try {
        const data = await NoteServices.getCustomerHier();
        setCustomerHierarchy(data);
      } catch (error) {
        console.error('Error fetching customer hierarchy:', error);
      }
    };

    fetchCustomerHierarchy();
  }, []);

  const dialogFuncMap: any = {
    'displayBasic': setDisplayBasic,
    'displayEdit': setDisplayEdit,
    'deleteConfirm': setDeleteConfirm,
    'displayAdd': setDisplayAdd
  }


  const onClickDelete = (name: any, position: any, data: any) => {

    setDeleteId(data.id)
    setDeleteAlarmId(data.alarmNoteId);
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  }

  const onClick = (name: any, position: any, data: any) => {
    SetreadOnlySiteName(data.siteName);
    SetreadOnlyID(data.id);
    SetreadNoteCat(data.category);
    SetreadNoteTag(data.tag);
    SetreadDetails(data.details);
    SetreadLoggedBy(data.loggedBy);
    SetreadLoggedDate(data.loggedOn);
    SetreadLastUpdated(data.updatedOn);
    setGetAlmId(data.alarmNoteId);
    dialogFuncMap[`${name}`](true);
    if (position) {
      setPosition(position);
    }
  }

  // Define the resetForm function
const resetForm = () => {
  SetreadOnlyCustomerName('');
  setReadOnlyCustomerId('');
  setWorkPerformedDate(null);
};
 
  const onHide = (name: any) => {
    resetForm();
    dialogFuncMap[`${name}`](false);
  }

  const renderFooter = (name: any) => {
    return (
      <div onClick={() => onHide(name)} style={{ textDecoration: "underline", cursor: "pointer" }}>
        Close
      </div>
    );
  }

  const renderFooterDelete = (name: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>

        <div>
          <Button onClick={() => { onHide(name) }} label="Cancel" className="bg-white border border-borderColor text-black py-2 px-3 m-2" />
        </div>
        <div>
          <Button onClick={() => { handleDeleteSubmit(name) }} label="Delete" className="bg-bgColor300 border border-borderColor text-textColor py-2 px-3 m-2" />
        </div>
      </div>
    );
  }

  const handleDeleteSubmit = (name: any) => {
    NoteServices.DeleteNote(deleteId, deleteAlarmId).then((res: any) => {
      onHide(name);
      if (res == "OK") {
        fetchNotes();
        toastBottomCenter.current.show({ severity: "success", summary: "Delete", detail: "Note Deleted Successfully", life: 3000 });
      } else {
        toastBottomCenter.current.show({ severity: "error", summary: "Delete", detail: "Something went wrong", life: 3000 });
      }
    })
  }
  const fetchNotes = () => {
    NoteServices.GetNotes(timeRange).then((res: any) => {
      if (res) {
        SetOriginalValues(res);
        if (filteredValues == "") {
          SetfilteredValues(res)
          // props?.SetCurrentData(res)
        }
        setCsvData([[`Site Name`, "Note Category", "Details", `Tags`, "Logged By", `Logged Date`, "Last Updated"," Work Performed"],
        ...res.map((item: any, index: number) => {
          return [item.siteName, item.category, item.details, item.tag, item.loggedBy,item.workPerformed, 
             // Convert loggedOn timestamp to desired timezone
            //  UTCToDesiredTimezone(item.loggedOn, timezoneForUI),
             // Convert updatedOn timestamp to desired timezone
              // UTCToDesiredTimezone(item.updatedOn, timezoneForUI)
          ];
        }),
        ])
      }
    });
  }

  const handleEditSubmit = async (name: any) => {
    setUpdateLoadingNotes(true);
    let post_data = {
      //id: readOnlyID,
      //SiteName: readOnlySiteName,
      //category: readNoteCat,
      //Tags: readNoteTag,
      NoteId: readOnlyID,
      Description: readDetails,
      //AlaramId: getAlmId,
      //LoggedBy: readLoggedBy,
      //LoggedDate: readLoggedDate,
      //LastUpdated: moment(new Date()).format('D MMM-YYYY')
    }
    await NoteServices.PutNote(post_data, readOnlyID).then((res: any) => {
      onHide(name);
      SettempLoading(true)
      if (res == true) {
        fetchNotes();
        toastBottomCenter.current.show({ severity: "success", summary: "Update", detail: "Notes updated", life: 3000 });
      } else {
        //onHide(name);
        toastBottomCenter.current.show({ severity: "error", summary: "Update", detail: "Something went wrong", life: 3000 });
      }
    })
    setUpdateLoadingNotes(false);

    setTimeout(() => {
      SettempLoading(false)
    }, 2000)
  }
  
  const handleAddSubmit = async (name: any) => {
    setUpdateLoadingNotes(true);
    setTriggerEffect(true);

   const tags = readNoteTag || [];

    const newNote = {
    Description:  readDetails,
    ThingNo: selectedSiteId,
    CustomerID: selectedCustomerId,
    ProductType: "Soofie",
    Category: readNoteCat,
    Tags: tags.length > 0 ? readNoteTag.join(',') : '', 
    WorkPerformed: workPerformedDate ? moment(workPerformedDate).format('MM-DD-YYYY HH:mm') : null,
    Type: "C"// Constant Value 
    };
  
    await NoteServices.AddNote(newNote).then((res: any) =>{
    onHide(name);
    SettempLoading(true)
    if (res == true) {
    // fetchNotes();
      toastBottomCenter.current.show({ severity: "success", summary: "Add", detail: "Note added", life: 3000 });
    } else {
      toastBottomCenter.current.show({ severity: "error", summary: "Add", detail: "Something went wrong", life: 3000 });
    }
  })
  setUpdateLoadingNotes(false);
  setTimeout(() => {
    SettempLoading(false)
  }, 2000)
};

  useEffect(() => {
    if (triggerEffect && workPerformedDate && moment(workPerformedDate).isSame(moment(), 'day')) {
      // Get current date and time for EndTime and one day less for StartDate
      const currentDateTime = moment().format('MM-DD-YYYY HH:mm');
      const previousDateTime = moment().subtract(1, 'days').format('MM-DD-YYYY HH:mm');
      
      setTimeRange({
        StartDate: previousDateTime,
        EndTime: currentDateTime,
      });
      setTriggerEffect(false);
    }
  }, [triggerEffect, workPerformedDate, setTimeRange]); 

  const renderFooterEdit = (name: any) => {
    return (
      <div style={{ display: "flex", float: "right" }}>
        <button onClick={() => onHide(name)} style={{ textDecoration: "underline", cursor: "pointer" }}>Close</button>&nbsp;&nbsp;
        <button disabled={updateLoadingNotes === true ? true : false} className="btn_yllw_blk font-bold py-2 px-4 rounded" onClick={() => { handleEditSubmit(name) }}>{updateLoadingNotes === true ? "Loading.. " : "Submit"}</button>
      </div>

    );
  }

  const renderFooterAdd = (name: any) => {
    return (
      <div style={{ display: "flex", float: "right" }}>
        <button onClick={() => onHide(name)} style={{ textDecoration: "underline", cursor: "pointer" }}>Close</button>&nbsp;&nbsp;
        <button
          disabled={!isFormValid || updateLoadingNotes}
          className={`${isFormValid ? 'btn_yllw_blk' : 'btn_grey_blk'} font-bold py-2 px-4 rounded`}
          onClick={() => { handleAddSubmit(name) }}
        >
          {updateLoadingNotes ? "Loading.." : "Submit"}
        </button>
      </div>
    );
  };

  const renderLoading = (
    <div>
      <div className="flex justify-center items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-500"></div>
      </div>
      <div className="flex flex-wrap align-items-center justify-content-center text-sm text-textColor">Loading Data...</div>
    </div>
  );

  const handleNoteCategoryChange = (e: any) => {
    setCheckedNoteCat(e.value)
    if (e.value.length == noteCategory.length) {
      setCheckedNoteCatLength("All Note Category");
    } else {
      setCheckedNoteCatLength(`${e.value.length} Note Categories Selected`)
    }
  }

  const searchCustomerName = (event : any, type : any) => {
    if (type === 'customer') {
      // Extract outer labels from getcustomerHierarchy data
       const extractOuterLabels = (options: any[]): string[]=>{
        return options.map((option: any) => option.label);
       }
       const outerLabels : any = extractOuterLabels(customerHierarchy);
       setCustomerNameSuggestions(
        outerLabels.filter((label : any) =>
            label.toLowerCase().includes(event.query.toLowerCase())
        )
    );
    // Extract customerId for the selected customer
    const selectedCustomer = customerHierarchy.find(
      (customer: any) => customer.label.toLowerCase().includes(event.query.toLowerCase())
    );
    if (selectedCustomer) {
      setSelectedCustomerId(selectedCustomer.customerId);
    }
           
    } else if (type === 'site') {
       // Finding the selected customer by ID
     const selectedCustomer = customerHierarchy.find((customer: any) => customer.customerId === readOnlyCustomerId);
     if(selectedCustomer){
      // Extract site labels from the selected customer's children
      const extractSiteLabels = (options: any[]): string[] => {
        return options.flatMap((option: any) => [
         option.siteId ? option.label : null ,
          ...option.children.flatMap((child: any) => [
            child.siteId ? child.label : null,
            ...child.children.map((grandchild: any) => grandchild.siteId ? grandchild.label : null)
          ])
        ]).filter(label => label !== null);
      };
      // Get all site label
      const siteLabels: any = extractSiteLabels(selectedCustomer.children);
        setSiteNameSuggestions(
          siteLabels.filter((label: any) =>
            label.toLowerCase().includes(event.query.toLowerCase())
          )
        );
    }     
    }
  };

  const handleSiteSelect = (e: any) => {
    const selectedCustomer = customerHierarchy.find((customer: any) => customer.customerId === readOnlyCustomerId);
    if (selectedCustomer) {
      const findSiteId = (options: any[], query: string): string | null => {
        for (const option of options) {
          if (option.label.toLowerCase().includes(query.toLowerCase()) && option.siteId) {
            return option.siteId;
          }
          if (option.children) {
            const foundInChildren = findSiteId(option.children, query);
            if (foundInChildren) {
              return foundInChildren;
            }
          }
        }
        return null;
      };
  
      const selectedSiteId = findSiteId(selectedCustomer.children, e.value);
      if (selectedSiteId) {
        setselectedSiteId(selectedSiteId);
      }
    }
  }

  const handleCustomerChange = (e: any) => {
   // Finding the selected customer by name
  const selectedCustomer = customerHierarchy.find(
    (customer: any) => customer.label === e.value
  );
  if (selectedCustomer) {
     // Set the selected customer's ID
    setReadOnlyCustomerId(selectedCustomer.customerId);
    setSelectedCustomerId(selectedCustomer.customerId);
  }
   // Set the selected customername
  SetreadOnlyCustomerName(e.value);
   // Clear the site name if the customer name is empty
   if (!e.value) {
    SetreadOnlySiteName('');
    setselectedSiteId('');
    setSelectedCustomerId('');
  }
  };


  useEffect(() => {
    const isWorkPerformedRequired = readNoteCat === 'Site - Maintenance';
    setIsFormValid(
     !!readOnlyCustomerName &&
      !!readOnlySiteName &&
      !!readNoteCat &&
      !!readDetails &&
      (!isWorkPerformedRequired || !!workPerformedDate) // Only mandatory if "Site - Maintenance" is selected
    );
  }, [readOnlyCustomerName, readOnlySiteName, readNoteCat, readDetails, workPerformedDate]);

  return(
   <>
    <Toast ref={toastBottomCenter} position="bottom-center" />

    <div className="tett table_card">
      {/* Start Delete */}
      <Dialog header={<>
        <div style={{ display: "flex" }}>
          {/* <img src={DeleteIcon} alt="Delete Icon"></img> */}
          <i className="pi pi-trash text-textColor" style={{fontSize:"20px"}}></i>
          <div style={{ fontSize: "16px", color: "#D4D4D4", marginLeft: "8px", fontWeight: "500" }}>Delete Note</div>

        </div>
      </>} visible={deleteConfirm} style={{ width: '20vw' }} footer={renderFooterDelete('deleteConfirm')} onHide={() => onHide('deleteConfirm')}>
        <div style={{ marginTop: "15px" }}>
          Please confirm that you want to delete this note. Once deleted,the note would be irretrievable
        </div>
      </Dialog>
      {/* deleteConfirm */} {/* Delete End */}{/* Edit Start */}
      <Dialog header={<>
        <div style={{ display: "flex" }}>
          {/* <img src={EditIcon} alt="Edit Icon"></img> */}
          <i className="pi pi-file-edit text-textColor" style={{fontSize:"20px"}}></i>
          <div style={{ fontSize: "16px", color: "#D4D4D4", marginLeft: "8px", fontWeight: "500" }}>Edit Note</div>

        </div>
      </>} visible={displayEdit} style={{ width: '40vw' }} footer={renderFooterEdit('displayEdit')} onHide={() => onHide('displayEdit')}>

        <div className="flex mb-2" style={{ marginTop: "1.5rem" }}>
          <div className="pt-2 w-[30%] notes_form_text_font">
            Site Name
          </div>

          <div className="w-[90%]">
            <InputText value={readOnlySiteName} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
        </div>
        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Note Category
          </div>
          <div className="w-[90%]">
            <InputText value={readNoteCat} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
        </div>
        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Note Tags
          </div>
          <div className="w-[90%]">
            <InputText 
             value={Array.isArray(readNoteTag) ? readNoteTag.join(',') : readNoteTag} 
             disabled
              className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
        </div>
        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Details
          </div>
          <div className="w-[90%]">
            <InputTextarea value={readDetails} rows={4} className="text-left w-[100%] h-[70px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" onChange={(e) => { SetreadDetails(e.target.value) }} />
          </div>
        </div>
        <div className="flex">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Logged By
          </div>
        </div>
        <div className="flex mb-3">
          <div className="w-[50%] pr-2">
            <InputText value={readLoggedBy} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>

        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "50%" }} className={'notes_form_text_font'}>Logged Date/Time</div>
          <div style={{ flex: "50%", marginLeft: "1em" }} className={'notes_form_text_font'}>Last Updated</div>
        </div>

        <div className="flex mb-2">
          <div className="w-[50%] pr-2">

            <InputText value={UTCToDesiredTimezone(readLoggedDate, timezoneForUI)} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
          <div className="w-[50%] pl-2">
            <InputText value={UTCToDesiredTimezone(readLastUpdated, timezoneForUI)} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
        </div>
      </Dialog>

      {/* Edit End */}


      {/* Read only Start */}

      <Dialog header={<>
        <div style={{ display: "flex" }}>
          <img

            // src={EyeDisabled}
            src={EyeEnabled}
            alt=""

          />
          <div style={{ fontSize: "16px", color: "#D4D4D4", marginLeft: "8px", fontWeight: "500" }}>View Note</div>

        </div>
      </>} visible={displayBasic} style={{ width: '40vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
        <div className="flex mb-2" style={{ marginTop: "1.5rem" }}>
          <div className="pt-2 w-[30%] notes_form_text_font">
            Site Name
          </div>

          <div className="w-[90%]">
            <InputText value={readOnlySiteName} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
        </div>


        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Note Category
          </div>

          <div className="w-[90%]">
            <InputText value={readNoteCat} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
        </div>

        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Note Tags
          </div>

          <div className="w-[90%]">
            <InputText 
            value={Array.isArray(readNoteTag) ? readNoteTag.join(',') : readNoteTag} 
             disabled 
             className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
        </div>

        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Details
          </div>

          <div className="w-[90%]">

            <InputTextarea value={readDetails} disabled rows={4} className="text-left w-[100%] h-[70px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />

          </div>
        </div>

        <div className="flex">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Logged By
          </div>

        </div>
        <div className="flex mb-3">
          <div className="w-[50%] pr-2">
            <InputText value={readLoggedBy} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>

        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flex: "50%" }} className={'notes_form_text_font'}>Logged Date/Time</div>
          <div style={{ flex: "50%", marginLeft: "1em" }} className={'notes_form_text_font'}>Last Updated</div>
        </div>

        <div className="flex mb-2">
          <div className="w-[50%] pr-2">

            <InputText value={UTCToDesiredTimezone(readLoggedDate, timezoneForUI)} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
          <div className="w-[50%] pl-2">
            <InputText value={UTCToDesiredTimezone(readLastUpdated, timezoneForUI)} disabled className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font" />
          </div>
        </div>


      </Dialog>
      {/* Read only End */}

      {/* Add Note */}
      <Dialog header={<>
        <div style={{ display: "flex" }} >
          {/* <img src={EditIcon} alt="Add Notes"></img> */}
          <i className="pi pi-file-edit" style={{fontSize:"20px"}}></i>
          <div style={{ fontSize: "16px", color: "#D4D4D4", marginLeft: "8px", fontWeight: "500" }}>Add Note</div>

        </div>
      </>} visible={displayAdd} style={{ width: '40vw' }} footer={renderFooterAdd('displayAdd')} onHide={() => onHide('displayAdd')}>

      <div className="flex mb-2" style={{ marginTop: "1.5rem" }}>
          <div className="pt-2 w-[30%] notes_form_text_font">
          Customer Name<span style={{ color: 'red',fontSize: '1.2rem' }}>*</span>
          </div>        
          <div className="w-[90%]">
                    <AutoComplete
                        value={readOnlyCustomerName}
                        suggestions={customerNameSuggestions}
                        completeMethod={(e) => searchCustomerName(e, 'customer')}
                        onChange={handleCustomerChange}
                        virtualScrollerOptions={{ itemSize: 50 }}
                        className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font custom-autocomplete"
                    />
                </div>
        </div>


        <div className="flex mb-2" >
          <div className="pt-2 w-[30%] notes_form_text_font">
            Site Name<span style={{ color: 'red',fontSize: '1.2rem' }}>*</span>
          </div>

          <div className="w-[90%]">
          <AutoComplete
                        value={readOnlySiteName}
                        suggestions={siteNameSuggestions}
                        completeMethod={(e) => searchCustomerName(e, 'site')}
                        onChange={(e) => SetreadOnlySiteName(e.value)}
                        onSelect={handleSiteSelect}
                        virtualScrollerOptions={{ itemSize: 50 }}
                        className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font custom-autocomplete"
                    />    
              </div>
        </div>

        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Note Category<span style={{ color: 'red',fontSize: '1.2rem' }}>*</span>
          </div>

          <div className="w-[90%]">
          <Dropdown
            value={readNoteCat}
            options={noteCategoryOptions}
            onChange={(e) => SetreadNoteCat(e.value)}
            className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font custom-dropdown-margin custom-dropdown-label"
           />       
          </div>
        </div>

        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Note Tags
          </div>

          <div className="w-[90%]">
          <MultiSelect
            value={readNoteTag}
            options={noteTagOptions}
            onChange={(e) => SetreadNoteTag(e.value)}
            selectAllLabel="Select All"
            className="text-left w-[100%] h-[38px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font custom-multiselect"
        />        
          </div>
        </div>

        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Details <span style={{ color: 'red',fontSize: '1.2rem' }}>*</span>
          </div>

          <div className="w-[90%]">

            <InputTextarea 
            value={readDetails}
             rows={4} 
             className="text-left w-[100%] h-[70px] border border-solid border-borderColor px-2 py-1 rounded notes_form_text_font"
            onChange={(e) => { SetreadDetails(e.target.value) }} />
          </div>
        </div>

        <div className="flex mb-2">
          <div className="pt-2 w-[30%] notes_form_text_font">
            Work Performed 
            {readNoteCat === 'Site - Maintenance' && (
           <span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>
             )}
          </div>

          <div className="w-[90%]">
         < NotesCalendar date={workPerformedDate} setDate={setWorkPerformedDate}/>
          </div>
        </div>
      </Dialog>

      {/* End Add Notes */}

      {
        tempLoading == false ?
          <div className="flex md:flex md:flex-grow flex-row justify-end space-x-1 top_btns">
            {hasPermission(getActiveRole(userPrefstate.data), 'note-add') && 
            <button className="bg-[#ffd266] text-black font-bold py-1 px-1 rounded border border-gray-300 "  onClick={() => onClick('displayAdd', 'center','')} >Add Notes</button>}
            <MultiSelect
              filter
              filterTemplate={`\u00A0\ All Note Category`}
              value={checkedNoteCat}
              onChange={(e) => handleNoteCategoryChange(e)}
              options={noteCategory}
              optionLabel="category"
              placeholder="Note Category"
              maxSelectedLabels={2}
              className="cus_multi_select_notes"
              selectedItemsLabel={checkedNoteCatLength}
            // pt={{ checkboxContainer: { onClick: null } }}
            />
            <CSVLink data={csvData} filename={"notes.csv"} className="xl_download_btn ml-2 text-textColor">
              <span>
                <i className="pi pi-download cursor-default select-none " ></i><span className="text-sm cursor-default select-none"> &nbsp;Download Spreadsheet</span>
              </span>
            </CSVLink>
          </div>
          : <></>
      }


      {
        tempLoading == false ? 
        <PrimeDatatable
          stripedRows
          sortField={SortField}
          sortOrder={SortFieldSeq}
          value={filteredValues}
          paginator
          className="notes_table_css cursor-default select-none"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          rows={12}
          rowsPerPageOptions={[12, 20, 30, 40, 50]}    
        >

          <Column
            //   filterElement={testBody}
            headerStyle={{ fontSize: "14px" }}
            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
            // sortable
            showFilterMatchModes={false}
            field="siteName"
            body={(body_val) => {
              return (
                <>
                 <a href={`overview?AssetId=${body_val.siteId}&CustomerId=${body_val.customerID}`}> {body_val.siteName?body_val.siteName:"-"} </a> 
                </>
              );
            }}
            header={`Site Name`}
          ></Column>

          {hasPermission(getActiveRole(userPrefstate.data), 'note-customerName') && (<Column
            //   filterElement={testBody}
            headerStyle={{ fontSize: "14px" }}
            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
            // sortable
            showFilterMatchModes={false}
            field="customerName"
            body={(body_val) => {
              return (
                <>
                  {body_val.customerName}
                </>
              );
            }}
            header={`Customer Name`}
          ></Column>)}
          <Column
            //   filterElement={testBody}
            headerStyle={{ fontSize: "14px" }}
            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
            // sortable
            showFilterMatchModes={false}
            field="category"
            body={(body_val) => {
              return (
                <>
                  {body_val.category}
                </>
              );
            }}
            header={`Note Category`}
          ></Column>


          {/* Details Start */}

          <Column
            //   filterElement={testBody}
            headerStyle={{ fontSize: "14px" }}
            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
            // sortable
            showFilterMatchModes={false}
            field="details"
            body={(body_val) => {

              let str_len = body_val?.details?.length;
              if (str_len > 90) {
                let trimmed_string = body_val?.details?.substring(0, 90);
                return (
                  <>
                    {trimmed_string}... <a href="javascript:void(0)" onClick={() => onClick('displayBasic', 'center', body_val)} style={{ color: "#D4D4D4", fontWeight: "700" }}>view more</a>
                  </>
                );
              } else {
                return (
                  <>
                    {body_val?.details}
                  </>
                );

              }

            }}
            header={`Details`}
          ></Column>

          {/* Details End */}

          {/* Tags Start */}

          <Column
            //   filterElement={testBody}
            headerStyle={{ fontSize: "14px" }}
            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
            // sortable
            showFilterMatchModes={false}
            field="tag"
            body={(body_val) => {
              return (
                <>
                  {body_val.tag}
                </>
              );
            }}
            header={`Tags`}
          ></Column>
          {/* Tags End */}

          {/* LoggedBy Start */}

          <Column
            //   filterElement={testBody}
            headerStyle={{ fontSize: "14px" }}
            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
            // sortable
            showFilterMatchModes={false}
            field="loggedBy"
            body={(body_val) => {
              return (
                <>
                  {body_val.loggedBy}
                </>
              );
            }}
            header={`Logged By`}
          ></Column>

          {/* LoggedBy End */}

          {/* LoggedDate Start */}
          <Column
            //   filterElement={testBody}
            headerStyle={{ fontSize: "14px" }}
            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
            // sortable
            showFilterMatchModes={false}
            field="loggedOn"
            body={(body_val) => {
              return (
                <>
                  {convertUTCToTimezone(body_val.loggedOn, timezoneForUI)}
                </>
              );
            }}
            header={`Logged Date`}
          ></Column>
          {/* LoggedDate End */}

          {/* LastUpdated Start */}
          <Column
            //   filterElement={testBody}
            headerStyle={{ fontSize: "14px" }}
            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}
            // sortable
            showFilterMatchModes={false}
            field="updatedOn"
            body={(body_val) => {

              return (
                <>
                  {(body_val.updatedOn != null) ? convertUTCToTimezone(body_val.updatedOn, timezoneForUI) : ""}
                </>
              );
            }}
            header={`Last Updated`}
          ></Column>
          {/* LastUpdated End */}

          {/* Action Start */}
          <Column
            //   filterElement={testBody}
            headerStyle={{ fontSize: "14px" }}
            style={{ borderTopLeftRadius: "8px", fontSize: "12px" }}

            showFilterMatchModes={false}
            // field="LastUpdated"
            body={(body_val) => {
              return (
                <>
                  <div className="flex space-x-4">
                    {/* <img
                      style={{ cursor: "pointer" }}
                      src={EyeEnabled}
                      alt=""
                      onClick={() => onClick('displayBasic', 'center', body_val)}
                      className="view"           
                    /> */}
                    <i
                      style={{ cursor: "pointer" }}
                      className="pi pi-eye view text-xl cursor-pointer" // PrimeIcons eye icon class
                      onClick={() =>
                        onClick("displayBasic", "center", body_val)
                      }
                      title="View"
                    />
                    <Tooltip target=".view" content="View" position="top" />
                    {hasPermission(
                      getActiveRole(userPrefstate.data),
                      "note-edit"
                    ) && (
                      <>
                        <i
                          style={{ cursor: "pointer" }}
                          className="pi pi-pencil edit-icon text-xl cursor-pointer"
                          onClick={() =>
                            onClick("displayEdit", "center", body_val)
                          }
                          title="Edit Note"
                        />
                        {/* <img  style={{ cursor: "pointer" }} src={EditIcon} onClick={() => onClick('displayEdit', 'center', body_val)} className="edit-icon" alt="Edit Note" /> */}

                        <Tooltip
                          target=".edit-icon"
                          content="Edit"
                          position="top"
                        />
                      </>
                    )}
                    {hasPermission(
                      getActiveRole(userPrefstate.data),
                      "note-delete"
                    ) && (
                      <>
                        {/* <img
                          src={DeleteIcon}
                          onClick={() => {
                            onClickDelete("deleteConfirm", "center", body_val);
                          }}
                          style={{
                            fontSize: "1.5rem",
                            marginLeft: "6px",
                            cursor: "pointer",
                          }}
                          className="delete-icon"
                          alt="Delete Note"
                        ></img> */}
                        <i
                          className="pi pi-trash delete-icon text-xl cursor-pointer"
                          onClick={() =>
                            onClickDelete("deleteConfirm", "center", body_val)
                          }
                          style={{
                            fontSize: "1.5rem",
                            marginLeft: "6px",
                            cursor: "pointer",
                          }}
                          title="Delete Note"
                        />
                        <Tooltip
                          target=".delete-icon"
                          content="Delete"
                          position="top"
                        />
                      </>
                    )}
                    {/* <i onClick={()=>{
              onClick('deleteConfirm', 'center', body_val)

            }} className="pi pi-delete-left" style={{ fontSize: '1.5rem', marginLeft: "3px",cursor:"pointer" }} /> */}
                  </div>
                </>
              );
            }}
            header={`Actions`}
          ></Column>
          {/* Action End */}
        </PrimeDatatable> : (
          (renderLoading)
        )
      }

    </div>

  </>
  );


}
export default Notes;