import React, { useState, useRef, useEffect } from "react";
import { Map, Marker, Popup, NavigationControl } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertTabMapProps,
  AssetAlertsMarkerType,
  OperationSite,
} from "../Types/OperationsSite";
import { AppDispatch } from "../../store";
import {
  fetchSiteDetails,
  fetchAssetAlerts,
} from "../../store/thunk/MapSliceThunk";
import { appInsights } from "../../utils/appInsights";
import {
  getTimzeZoneForAPI,
  getJsonStringify,
  getClientIP,
} from "../../utils/CommonUtils";
import RedMarker from "../../assets/img/red_marker.svg";
import GreenMarker from "../../assets/img/green_marker.svg";
import YellowMarker from "../../assets/img/yellow_marker.svg";
import AlertDetailsPopup from "../AlertDetailsPopup";
import SensorDetailsPopup from "../SensorDetailsPopup";
import { assetToSite } from "./OperartionOverviewGoogleMaps";
import { MAPBOX_TOKEN } from "../../utils/Constants";

function MapComponent({
  alertTypeTab,
  startDate,
  endDate,
  searchResults,
  emissionMarker,
  setEmissionMarker,
  customerIdValue,
  setCustomerIdValue,
  selectedKeys,
  alertTypeResult,
  activeMarker,
  setActiveMarker,
  selectedAlertTypeOptions,
}: AlertTabMapProps & {
  searchResults: OperationSite[];
  emissionMarker: string;
  setEmissionMarker: React.Dispatch<React.SetStateAction<any | null>>;
  customerIdValue: string;
  setCustomerIdValue: React.Dispatch<React.SetStateAction<string>>;
  selectedKeys: any[] | null;
  alertTypeResult: any;
  activeMarker: String | null;
  setActiveMarker: React.Dispatch<React.SetStateAction<string | null>>;
  selectedAlertTypeOptions: any | null;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const siteDetails = useSelector((state: any) => state.mapSiteState);
  const [siteDetailsByFilter, setSiteDetailsByFilter] = useState<any[]>([]);
  const [styleLoaded, setStyleLoaded] = useState(false);
  const filteredData = React.useMemo(() => {
    if (alertTypeTab === "site") {
      return searchResults.filter((item: any) => item.siteId || item.id);
    } else {
      return searchResults.filter((item: any) => item.siteID);
    }
  }, [searchResults]);

  const assetAlertsData = useSelector((state: any) => state.assetAlertState);
  const assetAlerts = assetAlertsData.data as AssetAlertsMarkerType[];
  const assetAlertSites = assetAlerts
    .map((asset) => assetToSite(asset))
    .filter((asset) => asset.longitude !== null && asset.latitude !== null);

  const userPrefstate = useSelector((state: any) => state.userPreferenceState);
  let timeZone = getTimzeZoneForAPI(userPrefstate.data.timeZone);
  const prevStartDateRef = useRef<any>({ startDate: "" });
  const prevEndDateRef = useRef<any>({ endDate: "" });
  const mapRef = useRef<any>(null);
  const [mapZoomCenter, setMapZoomCenter] = useState<any>({ lat: 0, lng: 0 });
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  // Handler to toggle map styles
  const [mapStyle, setMapStyle] = useState<string>(
    "mapbox://styles/mapbox/satellite-v9"   // Default to Street View
  ); 
  // const toggleMapStyle = (style: string) => {
  //   setMapStyle(style);
  // };

  useEffect(() => {
    // Check if the maps API has loaded
    if (window.google && window.google.maps) {
      setGoogleMapsLoaded(true);
    }
  }, []);

  useEffect(() => {
    let isDateChange = (getJsonStringify(prevStartDateRef.current.startDate) !== getJsonStringify(startDate) && getJsonStringify(prevStartDateRef.current.endDate) !== getJsonStringify(endDate));
    prevStartDateRef.current = { startDate };
    prevEndDateRef.current = { endDate };
    if (isDateChange) {
      dispatch(fetchSiteDetails({ startDate, endDate, timeZone }))
      dispatch(fetchAssetAlerts({ startDate, endDate }))
    }
  }, [dispatch, endDate, startDate])

  useEffect(() => {
    let sites: OperationSite[] = [];
    let filteredSites: OperationSite[] = [];
    let noWindSites: OperationSite[] = [];
    let noDataSites: OperationSite[] = [];
    let badMethaneSites: OperationSite[] = [];
    let badTemparatureSites: OperationSite[] = [];
    let fanFailedSites: OperationSite[] = [];
    let noPolygonSites: OperationSite[] = [];
    let noAlertSites: OperationSite[] = [];
    let healthySites: OperationSite[] = [];
    if (filteredData.length > 0) {
      sites = siteDetails.data.filter((site: OperationSite) => filteredData.some(item => item.id === site.id));
      if (alertTypeTab === "site" && siteDetails.data?.length > 0) {
        sites = sites.filter((site: OperationSite) => site.isAlert);
      } else if (alertTypeTab === "asset" && siteDetails.data?.length > 0) {
        sites = (filteredData as unknown as AssetAlertsMarkerType[]).filter(item => item.siteID).map(asset => assetToSite(asset))//siteDetails.data.filter((site: OperationSite) => site.isAssetAlert);


        if (Object.keys(selectedKeys!).length === 0) {
          setSiteDetailsByFilter([])
        } else if (selectedKeys) {
          if ("Select All" in selectedKeys!) {
            sites = sites;//siteDetails.data.filter((site: OperationSite) => site.isAssetAlert);
          }
          if ("Flagged Assets" in selectedKeys! && Object.keys(selectedKeys).length === 8) {
            sites = sites.filter(a => a.alertDetails?.assetAlertCount !== 0)
          } else {
          }
          if ("Flagged Assets" in selectedKeys!) {
            sites = sites.filter(a => a.alertDetails?.assetAlertCount !== 0)
            if ("No Wind" in selectedKeys) {
              noWindSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Wind"))
            } else {
              noWindSites = []
            }
            if ("No Data" in selectedKeys) {
              noDataSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Data"))
            } else {
              noDataSites = [];
            }
            if ("Bad Methane" in selectedKeys) {
              badMethaneSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "Bad Methane"))
            } else {
              badMethaneSites = [];
            }
            if ("Bad Temperature" in selectedKeys) {
              badTemparatureSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "Bad Temperature"))
            } else {
              badTemparatureSites = [];
            }
            if ("Fan Failed" in selectedKeys) {
              fanFailedSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "Fan Failed"))
            } else {
              fanFailedSites = [];
            }
            if ("No Polygon configured" in selectedKeys) {
              noPolygonSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Polygon configured"))
            } else {
              noPolygonSites = []
            }
            if ("No Alert configured" in selectedKeys) {
              noAlertSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Alert configured"))
            } else {
              noAlertSites = []
            }
            //filteredSites = [...noWindSites, ...noDataSites, ...badMethaneSites, ...badTemparatureSites, ...fanFailedSites, ...noPolygonSites, ...noAlertSites]
            noWindSites.forEach(item => {
              if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
                filteredSites.push(item)
              }
            })
            noDataSites.forEach(item => {
              if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
                filteredSites.push(item)
              }
            })
            badMethaneSites.forEach(item => {
              if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
                filteredSites.push(item)
              }
            })
            badTemparatureSites.forEach(item => {
              if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
                filteredSites.push(item)
              }
            })
            fanFailedSites.forEach(item => {
              if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
                filteredSites.push(item)
              }
            })
            noPolygonSites.forEach(item => {
              if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
                filteredSites.push(item)
              }
            })
            noAlertSites.forEach(item => {
              if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
                filteredSites.push(item)
              }
            })
            if (healthySites.length === 0) {
              sites = filteredSites
            } else if (filteredSites.length === 0) {
              sites = healthySites
            } else {
              sites = [...filteredSites, ...healthySites]
            }
            setSiteDetailsByFilter(sites);
          }
          else {
            filteredSites = []
            if(healthySites.length === 0) {
              sites = filteredSites
            } else if(filteredSites.length === 0) {
              sites = healthySites
            } else {
              sites = [...filteredSites, ...healthySites]
            }
            setSiteDetailsByFilter(sites);
          }
          if ("Healthy Assets" in selectedKeys!) {

            if (filteredData.length > 0) {
              healthySites = (filteredData as unknown as AssetAlertsMarkerType[]).filter(a => a.alertDetails?.assetAlertCount === 0).map(asset => assetToSite(asset));
            } else {
              healthySites = assetAlertSites.filter(a => a.alertDetails?.assetAlertCount === 0)
            }

            // healthySites = assetAlertSites.filter(a => a.alertDetails?.assetAlertCount === 0)

            if (healthySites.length === 0) {
              sites = filteredSites
            } else if (filteredSites.length === 0) {
              sites = healthySites
            } else {
              sites = [...filteredSites, ...healthySites]
            }

            setSiteDetailsByFilter(sites);

          } else {
            healthySites = [];
            if (healthySites.length === 0) {
              sites = filteredSites
            } else if (filteredSites.length === 0) {
              sites = healthySites
            } else {
              sites = [...filteredSites, ...healthySites]
            }
            setSiteDetailsByFilter(sites);
          }

        }       
      }      
    } else {
      if (alertTypeTab === "site" && siteDetails.data?.length > 0) {
        sites = [];
      } else if (alertTypeTab === "asset" && siteDetails.data?.length > 0) {
        sites = [];//siteDetails.data.filter((site: OperationSite) => site.isAssetAlert);
      }
      setSiteDetailsByFilter(sites);
    }
  }, [alertTypeTab, siteDetails.data, filteredData, selectedKeys, selectedAlertTypeOptions]);

  useEffect(() => {
    if (filteredData.length === 0) {
      let sites: OperationSite[] = [];
      let filteredSites: OperationSite[] = [];
      let noWindSites: OperationSite[] = [];
      let noDataSites: OperationSite[] = [];
      let badMethaneSites: OperationSite[] = [];
      let badTemparatureSites: OperationSite[] = [];
      let fanFailedSites: OperationSite[] = [];
      let noPolygonSites: OperationSite[] = [];
      let noAlertSites: OperationSite[] = [];
      let healthySites: OperationSite[] = [];
      if (alertTypeTab === "site" && siteDetails.data?.length > 0) {
        sites = siteDetails.data.filter((site: OperationSite) => site.isAlert);
      } else if (alertTypeTab === "asset" && siteDetails.data?.length > 0) {
        sites = assetAlertSites;
        if (Object.keys(selectedKeys!).length === 0) {
          sites = [];
        } else if (selectedKeys) {
          if ("Select All" in selectedKeys!) {
            sites = assetAlertSites;//siteDetails.data.filter((site: OperationSite) => site.isAssetAlert);
          } else if ("Flagged Assets" in selectedKeys! && Object.keys(selectedKeys).length === 7) {
            sites = assetAlertSites.filter(a => a.alertDetails?.assetAlertCount !== 0)            
          } else if ("Flagged Assets" in selectedKeys!) {
            sites = assetAlertSites.filter(a => a.alertDetails?.assetAlertCount !== 0)            
            if ("No Wind" in selectedKeys) {
              noWindSites = assetAlertSites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Wind"))
            } else {
              noWindSites = []
            }
            if ("No Data" in selectedKeys) {
              noDataSites = assetAlertSites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Data"))
            } else {
              noDataSites = [];
            }
            if ("Bad Methane" in selectedKeys) {
              badMethaneSites = assetAlertSites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "Bad Methane"))
            } else {
              badMethaneSites = [];
            }
            if ("Bad Temperature" in selectedKeys) {
              badTemparatureSites = assetAlertSites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "Bad Temperature"))
            } else {
              badTemparatureSites = [];
            }
            if ("Fan Failed" in selectedKeys) {
              fanFailedSites = assetAlertSites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "Fan Failed"))
            } else {
              fanFailedSites = [];
            }
            if ("No Polygon configured" in selectedKeys) {
              noPolygonSites = assetAlertSites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Polygon configured"))
            } else {
              noPolygonSites = []
            }
            if ("No Alert configured" in selectedKeys) {
              noAlertSites = assetAlertSites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Alert configured"))
            } else {
              noAlertSites = []
            }
            filteredSites = [...noWindSites, ...noDataSites, ...badMethaneSites, ...badTemparatureSites, ...fanFailedSites, ...noPolygonSites, ...noAlertSites]
            sites = filteredSites
          } else if ("Healthy Assets" in selectedKeys!) {
            sites = assetAlertSites.filter(a => a.alertDetails?.assetAlertCount === 0)
          }
        }
      }
    }
  }, [alertTypeTab, siteDetails.data, selectedKeys])

  useEffect(() => {
    let sites: OperationSite[] = [];
    
    if (alertTypeTab === "site") {
      if (alertTypeResult.length > 0) {
        if (filteredData.length > 0) {
          sites = filteredData.filter((site: OperationSite) => site.isAlert).filter((site: OperationSite) => alertTypeResult.some((item: { siteId: string | undefined; }) => item.siteId === site.id));
          setSiteDetailsByFilter(sites);
        } else {
          sites = siteDetails.data.filter((site: OperationSite) => site.isAlert).filter((site: OperationSite) => alertTypeResult.some((item: { siteId: string | undefined; }) => item.siteId === site.id));
          setSiteDetailsByFilter([]);
          handleOnLoad(mapRef.current);
        }
      } else {
        if (alertTypeTab === "site" && siteDetails.data?.length > 0) {
          sites = siteDetails.data.filter((site: OperationSite) => site.isAlert);
        }
        setSiteDetailsByFilter(sites);
      }
    } else {
      const assetAlertFilteredData = searchResults.filter((item: any) => item.siteID);

      let filteredSites: OperationSite[] = [];
      let noWindSites: OperationSite[] = [];
      let noDataSites: OperationSite[] = [];
      let badMethaneSites: OperationSite[] = [];
      let badTemparatureSites: OperationSite[] = [];
      let fanFailedSites: OperationSite[] = [];
      let noPolygonSites: OperationSite[] = [];
      let noAlertSites: OperationSite[] = [];
      let healthySites: OperationSite[] = [];
      sites = (assetAlertFilteredData as unknown as AssetAlertsMarkerType[]).filter(item => item.siteID).map(asset => assetToSite(asset));
      if (Object.keys(selectedKeys!).length === 0) {
        setSiteDetailsByFilter([])
      } else if (selectedKeys) {
        if ("Select All" in selectedKeys!) {
          sites = sites;
        }
        if ("Flagged Assets" in selectedKeys! && Object.keys(selectedKeys).length === 8) {
          sites = sites.filter(a => a.alertDetails?.assetAlertCount !== 0)
        } else {
        }
        if ("Flagged Assets" in selectedKeys!) {
          sites = sites.filter(a => a.alertDetails?.assetAlertCount !== 0)
          if ("No Wind" in selectedKeys) {
            noWindSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Wind"))
          } else {
            noWindSites = []
          }
          if ("No Data" in selectedKeys) {
            noDataSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Data"))
          } else {
            noDataSites = [];
          }
          if ("Bad Methane" in selectedKeys) {
            badMethaneSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "Bad Methane"))
          } else {
            badMethaneSites = [];
          }
          if ("Bad Temperature" in selectedKeys) {
            badTemparatureSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "Bad Temperature"))
          } else {
            badTemparatureSites = [];
          }
          if ("Fan Failed" in selectedKeys) {
            fanFailedSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "Fan Failed"))
          } else {
            fanFailedSites = [];
          }
          if ("No Polygon configured" in selectedKeys) {
            noPolygonSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Polygon configured"))
          } else {
            noPolygonSites = []
          }
          if ("No Alert configured" in selectedKeys) {
            noAlertSites = sites.filter(a => a.alertDetails?.alertInfo.some(ai => ai.alertType === "No Alert configured"))
          } else {
            noAlertSites = []
          }
          noWindSites.forEach(item => {
            if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
              filteredSites.push(item)
            }
          })
          noDataSites.forEach(item => {
            if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
              filteredSites.push(item)
            }
          })
          badMethaneSites.forEach(item => {
            if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
              filteredSites.push(item)
            }
          })
          badTemparatureSites.forEach(item => {
            if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
              filteredSites.push(item)
            }
          })
          fanFailedSites.forEach(item => {
            if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
              filteredSites.push(item)
            }
          })
          noPolygonSites.forEach(item => {
            if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
              filteredSites.push(item)
            }
          })
          noAlertSites.forEach(item => {
            if (filteredSites.findIndex(fs => fs.id === item.id) === -1) {
              filteredSites.push(item)
            }
          })
          if (healthySites.length === 0) {
            sites = filteredSites
          } else if (filteredSites.length === 0) {
            sites = healthySites
          } else {
            sites = [...filteredSites, ...healthySites]
          }
          setSiteDetailsByFilter(sites);
        }
        else {
          filteredSites = []
          if (healthySites.length === 0) {
            sites = filteredSites
          } else if (filteredSites.length === 0) {
            sites = healthySites
          } else {
            sites = [...filteredSites, ...healthySites]
          }
          setSiteDetailsByFilter(sites);
        }
        if ("Healthy Assets" in selectedKeys!) {
          if (filteredData.length > 0) {
            healthySites = (filteredData as unknown as AssetAlertsMarkerType[]).filter(a => a.alertDetails?.assetAlertCount === 0).map(asset => assetToSite(asset));
            // healthySites = (assetAlertFilteredData as unknown as AssetAlertsMarkerType[]).filter(a => a.alertDetails?.assetAlertCount === 0).map(asset => assetToSite(asset as AssetAlertsMarkerType));
          } else {
            healthySites = assetAlertSites.filter(a => a.alertDetails?.assetAlertCount === 0)
          }

          // healthySites = assetAlertSites.filter(a => a.alertDetails?.assetAlertCount === 0)

          if (healthySites.length === 0) {
            sites = filteredSites
          } else if (filteredSites.length === 0) {
            sites = healthySites
          } else {
            sites = [...filteredSites, ...healthySites]
          }

          setSiteDetailsByFilter(sites);
        } else {
          healthySites = [];
          if (healthySites.length === 0) {
            sites = filteredSites
          } else if (filteredSites.length === 0) {
            sites = healthySites
          } else {
            sites = [...filteredSites, ...healthySites]
          }
          setSiteDetailsByFilter(sites);
        }
      }

      setSiteDetailsByFilter(sites);
    }
  }, [alertTypeResult, filteredData, siteDetails.data, alertTypeTab, selectedAlertTypeOptions])

  useEffect(() => {
    if (alertTypeTab === "site") {
      let sites: OperationSite[] = [];
      if (alertTypeResult.length > 0) {
        if (filteredData.length > 0) {
          sites = filteredData.filter((site: OperationSite) => site.isAlert).filter((site: OperationSite) => alertTypeResult.some((item: { siteId: string | undefined; }) => item.siteId === site.id));
          setSiteDetailsByFilter(sites);
        } else {
          //sites = siteDetails.data.filter((site: OperationSite) => site.isAlert).filter((site: OperationSite) => alertTypeResult.some((item: { siteId: string | undefined; }) => item.siteId === site.id));
          setSiteDetailsByFilter([]);
          handleOnLoad(mapRef.current);
        }
      } else {
        if (alertTypeTab === "site" && siteDetails.data?.length > 0) {
          sites = siteDetails.data.filter((site: OperationSite) => site.isAlert);
        }
        setSiteDetailsByFilter(sites);
      }
    }
  }, [alertTypeResult, filteredData, siteDetails.data, alertTypeTab, selectedAlertTypeOptions])

  useEffect(() => {
    const fetchIPAndTrackEvent = async () => {
      try {
        const ip = await getClientIP();
        let currentUser: any = localStorage.getItem('user');
        currentUser = JSON.parse(currentUser);
        try {
          appInsights.trackEvent({
            name: 'User Auth Details - Operation Overview Google Map Page',
            properties: {
              userEmail: currentUser?.userName,
              userName: currentUser?.lastName + ' ' + currentUser?.firstName,
              authenticateUserId: currentUser?._id,
              currentTime: new Date().toISOString(),
              clientIP: ip,
            },
          });
        } catch (error) {
          //console.error('Error with Azure AppInsights:', error);
        }
      } catch (error) {
        //console.error("Error fetching IP:", error);
      }
    };

    fetchIPAndTrackEvent();
  }, []);

  const handleActiveMarker = (marker: string, customerId: string) => {
    /*  if (marker === activeMarker) {
    return;
  } */
    setActiveMarker(marker);
    setEmissionMarker(marker);
    setCustomerIdValue(customerId);
  };
  const updateSitePositions = (map: any, sites: any) => {
    if (googleMapsLoaded) {
    const bounds = new window.google.maps.LatLngBounds();
    // Iterate over all markers and extend the bounds
    sites.forEach((site: any) => {
      bounds.extend(new window.google.maps.LatLng(Number(site.latitude), Number(site.longitude)));
    });
    // Fit the map to the calculated bounds
    // Optionally, you can set a minimum zoom level to prevent the map from zooming in too much
    const maxZoom = 20; // adjust this value according to your needs
    setTimeout(() => {
      if (map && map.fitBounds) {
        map.fitBounds(bounds);
        const listener = window.google.maps.event.addListener(map, "idle", () => {
          if (map.getZoom() > maxZoom) map.setZoom(maxZoom);
          window.google.maps.event.removeListener(listener);
        });
      }
    }, 500);
  }
  }
  const handleOnLoad = (map: any) => {
    try {
      setTimeout(() => {
        if (siteDetailsByFilter.length) {
          updateSitePositions(map,siteDetailsByFilter);
        }else if (!siteDetails.error && siteDetails.data && siteDetails.data.length) {
          updateSitePositions(map,siteDetails.data);
        }
      }, 500);
    } catch (error) {
      //console.log("Map zoom render : ", error);
    }
  };

  if (siteDetails.isLoading) {
    return <h1 className="text-textColor p-5 h-screen">Loading site details for map</h1>;
  }

  return (
    !siteDetails.error &&
    siteDetails.data && (
      <div className="overview-map">
        <Map
          ref={mapRef}
          initialViewState={{
            longitude: mapZoomCenter.lng,
            latitude: mapZoomCenter.lat,
            zoom: 1,
          }}
          style={{ width: "100%", height: "80.5vh" }}
          mapStyle={mapStyle}
          // mapStyle="mapbox://styles/mapbox/satellite-v9"
          mapboxAccessToken={MAPBOX_TOKEN}
          onLoad={() => setStyleLoaded(true)}
          onMove={(e) => {
            setMapZoomCenter({
              lat: e.viewState.latitude,
              lng: e.viewState.longitude,
            });
          }}
        >
          <NavigationControl position="bottom-left" />
          {siteDetailsByFilter.map((site: OperationSite) => {
            if (
              !filteredData ||
              filteredData.some((item) => item.customerName === site.customerName)
            ) {
              return (
                <Marker
                  key={site.id}
                  longitude={Number(site.longitude) || 0} // Fallback to 0 if undefined or null
                  latitude={Number(site.latitude) || 0}
                  onClick={() =>
                    handleActiveMarker(site?.id!, site?.customerID || "")
                  }
                >
                  {/* <img
                  src={
                    site.isAlert
                      ? RedMarker
                      : site.alertDetails?.assetAlertCount! > 0
                      ? YellowMarker
                      : GreenMarker
                  }
                  alt="marker"
                  style={{ width: 50, height: 50 }}
                /> */}
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      borderRadius: "50%", // Makes it a circle
                      backgroundColor:
                        site.isAlert
                          ? "#F04438" // Red
                          : site.alertDetails?.assetAlertCount! > 0
                            ? "#FFB217" // Yellow
                            : "#12B76A", // Green
                    }}
                  ></div>
                  {activeMarker === site.id &&
                    (site.isAlert || site.isAssetAlert) ? (
                    <Popup
                      longitude={Number(site.longitude) || 0} // Fallback to 0 if undefined or null
                      latitude={Number(site.latitude) || 0}
                      closeButton={true}
                      closeOnClick={false}
                      onClose={() => {
                        setActiveMarker(null);
                        setEmissionMarker(null);
                      }}
                    >
                      {site.isAlert ? (
                        <AlertDetailsPopup site={site} />
                      ) : site.isAssetAlert ? (
                        <SensorDetailsPopup site={site} />
                      ) : null}
                    </Popup>
                  ) : null}
                </Marker>
              );
            }
            return (
              <Marker
                key={site.id}
                longitude={Number(site.longitude) || 0} // Fallback to 0 if undefined or null
                latitude={Number(site.latitude) || 0}
                onClick={() =>
                  handleActiveMarker(site?.id!, site?.customerID || "")
                }
              >
                {/* <img
                src={
                  site.isAlert
                    ? RedMarker
                    : site.alertDetails?.assetAlertCount! > 0
                    ? YellowMarker
                    : GreenMarker
                }
                alt="marker"
                style={{ width: 50, height: 50 }}
              /> */}
                <div
                  style={{
                    width: 50,
                    height: 50,
                    backgroundColor: site.isAlert
                      ? "#F04438" // Red
                      : site.alertDetails?.assetAlertCount! > 0
                        ? "#FFB217" // Yellow
                        : "#12B76A", // Green
                    borderRadius: '50%', // to make it circular like a marker
                  }}
                ></div>

                {activeMarker === site.id &&
                  (site.isAlert || site.isAssetAlert) ? (
                  <Popup
                    longitude={Number(site.longitude) || 0} // Fallback to 0 if undefined or null
                    latitude={Number(site.latitude) || 0}
                    closeButton={false}
                    closeOnClick={false}
                    onClose={() => {
                      setActiveMarker(null);
                      setEmissionMarker(null);
                    }}
                  >
                    {site.isAlert ? (
                      <AlertDetailsPopup
                        key={`emission_alert_${site.id}`}
                        site={site}
                      />
                    ) : site.isAssetAlert ? (
                      <SensorDetailsPopup
                        key={`asset_alert_${site.id}`}
                        site={site}
                      />
                    ) : null}
                  </Popup>
                ) : null}
              </Marker>
            );
          })}
        </Map>
      </div>
    )
  );
}

export default MapComponent;

