import React, { useEffect, useRef, useState } from 'react';
import { OperationSite } from './Types/OperationsSite';
// import { debounce } from 'lodash';
import { filterByAlertType } from '../utils/CommonUtils';
interface SearchTabProps {
  siteDetails: OperationSite[];
  emissionDetails: any[];
  onSearch: (searchResults: OperationSite[]) => void;
  updateSearchText: (searchText: string) => void;
  assetDetails: any[];
  isClearSearchText: boolean;
  handleIsClearSearchText: any;
  startDate: Date;
  endDate: Date;
  selectedAlertTypeOptions: any;
  alertTypeTab: string;
}

const SearchTab: React.FC<SearchTabProps> = (
  { siteDetails,
    emissionDetails,
    onSearch,
    updateSearchText,
    assetDetails,
    isClearSearchText,
    handleIsClearSearchText,
    startDate,
    endDate,
    selectedAlertTypeOptions,
    alertTypeTab }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const searchHandler = () => {
    const isExactMatch = searchTerm.startsWith('"') && searchTerm.endsWith('"');
    const cleanedSearchTerm = searchTerm.replace(/^["'](.*)["']$/, '$1').trim().toLowerCase();
    let allFilterSearchList = [];
    if (alertTypeTab === "site") { // Emission alerts
      if (selectedAlertTypeOptions.value === "Emission Rate Alerts") {
        allFilterSearchList = siteDetails.concat(filterByAlertType(emissionDetails, ["Custom", "Standard"]));
      } else if (selectedAlertTypeOptions.value === "Emission Concentration Alerts") {
        allFilterSearchList = siteDetails.concat(filterByAlertType(emissionDetails, ["concentration-CH4", "LEL"]));
      } else {
        allFilterSearchList = siteDetails.concat(emissionDetails);
      }
    } else { // Asset alerts
      allFilterSearchList = siteDetails.concat(assetDetails);
    }

    const searchResults: any = allFilterSearchList.filter((item:any) => {
      if (item) {
        const assetAlertType = item.alertDetails?.alertInfo?.map((info:any) => info.alertType.toLowerCase()) || [];  // asset alert 
        const types = item.alerts?.map((info: any) => info.type.toLowerCase()) || []; // emission alert
        if (isExactMatch) {
          return (
            (item.customerName && item.customerName.toLowerCase() === cleanedSearchTerm) ||
            (item.siteName && item.siteName.toLowerCase() === cleanedSearchTerm) ||
            (item.thingNo && item.thingNo.toLowerCase() === cleanedSearchTerm) ||
            (item.alarmTypes && item.alarmTypes.some((type: any) => type.toLowerCase() === cleanedSearchTerm)) ||
            assetAlertType.includes(cleanedSearchTerm) ||
            types.includes(cleanedSearchTerm)
          );
        } else {
          // Perform partial match
          return (
            (item.customerName && item.customerName.toLowerCase().includes(cleanedSearchTerm)) ||
            (item.siteName && item.siteName.toLowerCase().includes(cleanedSearchTerm)) ||
            (item.thingNo && item.thingNo.toLowerCase().includes(cleanedSearchTerm)) ||
            (item.alarmTypes && item.alarmTypes.some((type: any) => type.toLowerCase() === cleanedSearchTerm)) ||
            assetAlertType.includes(cleanedSearchTerm) ||
            types.includes(cleanedSearchTerm)
          );
        }
      }
      return false;
    });
    handleIsClearSearchText();
    updateSearchText(searchTerm.toLowerCase());
    onSearch(searchResults);
  };
  useEffect(() => {
    searchHandler();
  }, [startDate, endDate, emissionDetails, siteDetails, assetDetails, selectedAlertTypeOptions])

  useEffect(() => {
    updateSearchText("");
    setSearchTerm("");
  }, [isClearSearchText]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchHandler();
    }
  };
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      searchHandler();
    }
  };
  const handleFocus = () => {
    handleIsClearSearchText();
  }
  return (
    <>
      <div className="ml-3 mt-3">
        <div
          className="p-0.5 rounded-md bg-gradient-to-r from-white-500 to-white-500 flex items-center"
          style={{ border: "1px solid #2C3746" }}
        >
          <div
            className="text-white p-1 hover:cursor-pointer"
            onClick={searchHandler}
          >
            <svg
              width="15"
              height="19"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.25 14.7188C16.5625 15.0312 16.5625 15.5 16.25 15.7812C16.125 15.9375 15.9375 16 15.75 16C15.5312 16 15.3438 15.9375 15.1875 15.7812L11 11.5938C9.875 12.5 8.46875 13 6.96875 13C3.40625 13 0.5 10.0938 0.5 6.5C0.5 2.9375 3.375 0 6.96875 0C10.5312 0 13.4688 2.9375 13.4688 6.5C13.4688 8.03125 12.9688 9.4375 12.0625 10.5312L16.25 14.7188ZM2 6.5C2 9.28125 4.21875 11.5 7 11.5C9.75 11.5 12 9.28125 12 6.5C12 3.75 9.75 1.5 7 1.5C4.21875 1.5 2 3.75 2 6.5Z"
                fill="#4A90A8"
              />
            </svg>
          </div>
          <input
            type="text"
            className="text-xs bg-transparent focus:outline-none"
            placeholder="Search customers, sites, or alerts"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyPress}
            onKeyUp={handleKeyUp}
            onFocus={handleFocus}
            style={{ width: "200px", color: "#DCE0E1" }}
          />
        </div>
      </div>
    </>
  );
};

export default SearchTab;
